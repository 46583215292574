import React, { useCallback, useEffect } from "react";
import { UserLayout } from "../../components/layout/UserLayout";
import { LoaderWithBackdrop } from "../../components/loaders/LoaderWithBackdrop";
import { useAppDispatch, useAppSelector } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { setRefreshToken } from "../../store/slices/user";
import { useLogout } from "../../hooks/useLogout";
import { getMe } from "../../store/thunks/user";

export const AuthToken: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { resetStorage } = useLogout();

  const { loading } = useAppSelector((state) => state.loader);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const authUserByToken = useCallback(
    async (refreshToken: string) => {
      try {
        resetStorage();
        dispatch(setRefreshToken({ refreshToken }));
        localStorage.setItem("refreshToken", refreshToken);

        await dispatch(getMe());

        navigate("/");
      } catch (error) {
        console.error("Error while [authUserByToken]", error);
      }
    },
    [dispatch, navigate, resetStorage],
  );

  useEffect(() => {
    if (token) {
      authUserByToken(token);
    }
  }, [authUserByToken, dispatch, resetStorage, token]);

  return (
    <>
      <UserLayout>{null}</UserLayout>
      <LoaderWithBackdrop isShow={loading} />
    </>
  );
};
