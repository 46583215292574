import _ from "lodash";
import { Area } from "react-easy-crop";
import moment from "moment";
import { IAlbumType } from "../store/slices/albums";
import i18next from "i18next";
import { PrivacyGroup } from "../store/slices/groups";

export const getCropImageInFormDataFromUrlAndCoodrinates = async (
  croppedAreaPixels: Area,
  imageUrl: string,
) => {
  try {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    const image = new Image();

    image.src = imageUrl;

    if (!ctx) {
      console.error(
        "Error while getCropBlobImageFromUrlAndCoodrinates --- ctx is not valid",
        ctx,
      );
      return null;
    }

    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
    );

    const formData = new FormData();

    const blob: Blob = await new Promise((resolveBlob, rejectBlob) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          rejectBlob(new Error("Blob is null"));
          return;
        }
        resolveBlob(blob);
      }, "image/png");
    });

    const uniqImageNameWithId = _.uniqueId("cropped_image_");
    formData.append(uniqImageNameWithId, blob, `${uniqImageNameWithId}.png`);

    return { croppedImageUniqName: uniqImageNameWithId, formData };
  } catch (e: any) {
    console.error("Error while getCropBlobImageFromUrlAndCoodrinates", e);
    throw new Error(e);
  }
};

export const getFileExtFromDroppedFile = (file: any) => {
  if (!file.name) {
    const filePath = _.get(file, "path");

    return filePath.split(".").pop().toLowerCase();
  }

  const fileName = _.get(file, "name");

  return fileName.split(".").pop().toLowerCase();
};

export const getDateFromPhotoCreated = (date?: string) => {
  if (!date) {
    return "";
  }

  return moment(date).format("MMM DD, YYYY");
};

export const getDateFromPhotoCreatedWithTime = (date?: string) => {
  if (!date) {
    return "";
  }

  return moment(date).format("ddd HH:mm [GMT]Z");
};

export const getAlbumNamesFromAlbumsArray = (
  albums: Pick<IAlbumType, "title" | "id">[],
) => {
  return _.chain(albums)
    .map((album) => album.title || i18next.t("photos.album_without_name"))
    .join(", ")
    .value();
};

export const getGroupTitlesFromGroupsArray = (groups: PrivacyGroup[]) => {
  return _.chain(groups)
    .map((group) => group.title)
    .join(", ")
    .value();
};

export const getValuesFromMetadata = (metadata?: any) => {
  if (_.isEmpty(metadata)) {
    return null;
  }

  const imageHeight = metadata["Image Height"]?.value;
  const imageWidth = metadata["Image Width"]?.value;
  const imageFileType = metadata["FileType"]?.value;
  const imageCopyright = metadata["ICC Copyright"]?.value;

  return {
    size:
      imageWidth && imageHeight
        ? `${imageWidth}px X ${imageHeight}px`
        : undefined,
    fileType: imageFileType,
    copyright: imageCopyright,
  };
};

export const withHexOpacity = (hexColor: string, opacity: number) => {
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  const correctedOpacity = Math.min(Math.max(opacity, 0), 1);

  return `rgba(${r}, ${g}, ${b}, ${correctedOpacity})`;
};

export const generateSharingLink = (photoId: number) => {
  const photoLink = `${window.location.origin}/file/${photoId}`;

  return photoLink;
};
