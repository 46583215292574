import { TextField } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { materialStyles } from "../styles";
import SearchIcon from "@mui/icons-material/Search";
import { IUserType } from "../../../store/slices/user";
import { useAppDispatch } from "../../../store";
import { UserModal } from "../../modals/userModal";
import { setUserId } from "../../../store/slices/userProfile";
import _ from "lodash";
import { IMaterialStyleItem } from "../../../types";
import {
  useSearchPeoples,
  DISPLAY_USERS_FROM_LENGTH,
} from "../../../hooks/useSearchPeoples";
import { AppAutocomplete } from "../../inputs/AppAutocomplete";
import { UserAutocompleteOption } from "../../items/UserAutocompleteOption";
import { useAuth } from "../../../hooks/useAuth";
import { useToast } from "rc-toastr";
import { setMapPhotosFilters } from "../../../store/slices/map";
import { useFiltersTypeByPage } from "../../../hooks/useFiltersTypeByPage";

type ISearchUserInput = {
  customTextInputSx?: IMaterialStyleItem;
  onChangeValue?: (user: IUserType) => void;
  children?: React.ReactNode;
};

export const SearchUserInput: React.FC<ISearchUserInput> = ({
  onChangeValue,
  customTextInputSx,
  children,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const isAuth = useAuth();
  const { toast } = useToast();

  const [search, setSearch] = useState("");

  const { findedUsers, debouncedSearch, loading } = useSearchPeoples(search);

  const type = useFiltersTypeByPage();

  const [isOpenUserModal, setIsOpenUserModal] = useState(false);

  const onSearchChange = useCallback((event: any) => {
    const { value } = event.target;

    setSearch(value);
  }, []);

  const onOpenUserModal = useCallback(
    (user: IUserType) => {
      if (!isAuth) {
        toast.warning(
          t("common.this_functionality_available_registered_users"),
        );
        return;
      }

      const findedUser = _.find(findedUsers, { id: user.id });

      if (!findedUser) {
        return;
      }

      switch (type) {
        case "MAP":
          setSearch(findedUser.username);
          dispatch(setMapPhotosFilters({ peoples: [findedUser] }));
          return;
      }

      dispatch(setUserId(user.id));
      setIsOpenUserModal(true);
    },
    [dispatch, type, findedUsers, isAuth, t, toast],
  );

  const onCloseUserModal = useCallback(() => {
    setIsOpenUserModal(false);
  }, []);

  return (
    <>
      <AppAutocomplete
        options={findedUsers}
        getOptionLabel={(option) => option.username}
        loading={loading}
        noOptionsText={
          debouncedSearch.length >= DISPLAY_USERS_FROM_LENGTH
            ? t("header.unable_find_user")
            : t("header.to_search_enter_more_than")
        }
        onChange={(_event, value) =>
          value &&
          (onChangeValue ? onChangeValue(value) : onOpenUserModal(value.id))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            name="searchInput"
            id="searchInput"
            sx={
              customTextInputSx ? customTextInputSx : materialStyles.searchInput
            }
            onChange={onSearchChange}
            fullWidth
            placeholder={t("header.search_users")}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon sx={materialStyles.searchIcon} />,
              endAdornment: children,
            }}
          />
        )}
        renderOption={(props, option) => (
          <UserAutocompleteOption
            user={option}
            onOptionClick={(user) =>
              onChangeValue ? onChangeValue(user) : onOpenUserModal(user)
            }
            {...props}
          />
        )}
      />
      <UserModal isOpen={isOpenUserModal} onClose={onCloseUserModal} />
    </>
  );
};
