import { Grid, Typography, Button } from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles, styles } from "./styles";
import { useTranslation } from "react-i18next";
import { AuthModalHeader } from "./AuthModalHeader";
import ShieldIcon from "../../../../assets/images/icons/shield-tick-icon.svg";
import { IAuthModalType } from "..";

type ICongratulationsForm = {
  onChangeAuthModalType: (type: IAuthModalType) => void;
  onClose: () => void;
};

export const CongratulationsForm: React.FC<ICongratulationsForm> = ({
  onChangeAuthModalType,
  onClose,
}) => {
  const { t } = useTranslation();

  const onGoToLogin = useCallback(() => {
    onChangeAuthModalType("LOGIN");
  }, [onChangeAuthModalType]);

  return (
    <Grid sx={materialStyles.innerAuthModalBox}>
      <AuthModalHeader title={t("auth_modal.congratulations")} />
      <Typography sx={materialStyles.modalSubtitle}>
        {t("auth_modal.password_reset_successful")}
      </Typography>
      <Grid sx={materialStyles.formContainer}>
        <Grid sx={materialStyles.mailIconContainer}>
          <img src={ShieldIcon} alt="Mail" style={styles.mailIcon} />
        </Grid>
        <Button
          onClick={onGoToLogin}
          sx={materialStyles.loginButton}
          variant="contained"
        >
          {t("auth_modal.login")}
        </Button>
        <p style={styles.closeButton} onClick={onClose}>
          {t("common.close")}
        </p>
      </Grid>
    </Grid>
  );
};
