import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL } from "@ffmpeg/util";
import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch } from "../store";
import { setLoading } from "../store/slices/loading";

export const useLoadFFmpeg = (isEnable: boolean) => {
  const ffmpegRef = useRef(new FFmpeg());
  const dispatch = useAppDispatch();

  const load = useCallback(async () => {
    try {
      dispatch(setLoading(true));

      const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";

      const ffmpegMemo = ffmpegRef.current;

      await ffmpegMemo.load({
        coreURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          "text/javascript",
        ),
        wasmURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          "application/wasm",
        ),
      });
    } catch (error) {
      console.error("Error while [load]", error);
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isEnable) {
      return;
    }
    load();
  }, [isEnable, load]);

  return {
    ffmpegRef,
  };
};
