import { createSlice } from "@reduxjs/toolkit";
import { Countries, ICountriesType } from "../../constants/Countries";

type IRegistrationType = {
  email?: string;
  code?: string;
  phone?: string;
  phoneParams: ICountriesType;
};

interface IRegistrationSliceInitialState {
  regUser: IRegistrationType;
}

const registrationSliceInitialState: IRegistrationSliceInitialState = {
  regUser: {
    phoneParams: Countries[0] as ICountriesType,
  },
};

const registrationUserSlice = createSlice({
  name: "registrationUser",
  initialState: registrationSliceInitialState,
  reducers: {
    setEmail(state, action) {
      state.regUser = { ...state.regUser, email: action.payload };
    },
    setCode(state, action) {
      state.regUser = { ...state.regUser, code: action.payload };
    },
    setPhone(state, action) {
      state.regUser = { ...state.regUser, phone: action.payload };
    },
    setPhoneParams(state, action) {
      state.regUser = { ...state.regUser, phoneParams: action.payload };
    },
    resetRegistrationInfo(state) {
      state.regUser = registrationSliceInitialState.regUser;
    },
  },
});

export default registrationUserSlice.reducer;
export const {
  setEmail,
  setCode,
  setPhone,
  resetRegistrationInfo,
  setPhoneParams,
} = registrationUserSlice.actions;
