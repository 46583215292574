import { Box, styled, Typography } from "@mui/material";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { materialStyles } from "./styles";
import {
  TVideoEditor,
  TVideoEditorTimingsOptions,
} from "../../../hooks/useVideoEditorReducer";
import { useTranslation } from "react-i18next";

const Div = styled("div")({});

type TCurrenlyGrabber = {
  type: "start" | "main" | "end";
};

type TPlayback = {
  videoRef?: RefObject<HTMLVideoElement>;
  timings: TVideoEditorTimingsOptions;
  initialVideoDuration: TVideoEditor["initialVideoDuration"];
  onChangeTimings?: (timings: TVideoEditorTimingsOptions) => void;
};

export const Playback: React.FC<TPlayback> = ({
  videoRef,
  timings,
  initialVideoDuration,
  onChangeTimings,
}) => {
  const { t } = useTranslation();
  const currentlyGrabbedRef = useRef<TCurrenlyGrabber | null>(null);
  const currentlyStartGraddedRef = useRef<boolean>(false);
  const playbackRef = useRef<HTMLDivElement>(null);

  const [videoProgress, setVideoProgress] = useState(0);
  const [videoTimeCode, setVideoTimeCode] = useState(0);

  const handleMouseMoveWhenGrabbed = useCallback(
    (event: any) => {
      if (!videoRef?.current) {
        return;
      }

      if (!currentlyGrabbedRef.current) {
        return;
      }

      if (!playbackRef.current) {
        return;
      }

      const xCoordinate =
        event.type === "touchmove" ? event.touches[0].clientX : event.clientX;

      const type = currentlyGrabbedRef.current.type;
      let playbackRect = playbackRef.current.getBoundingClientRect();
      let seekRatio = (xCoordinate - playbackRect.left) / playbackRect.width;

      let time = timings;
      let seek = initialVideoDuration * seekRatio;

      if (type !== "main") {
        currentlyStartGraddedRef.current = true;
        setVideoProgress(0);
      }

      switch (true) {
        case type === "main" && seek < time.end - 0.2 && seek > time.start:
          videoRef.current.currentTime = seek;
          setVideoTimeCode(seek);
          break;
        case type === "start" && seek < time.end - 0.2 && seek > 0:
          videoRef.current.currentTime = seek;
          setVideoTimeCode(seek);
          time["start"] = seek;
          onChangeTimings && onChangeTimings(time);
          break;
        case type === "end" &&
          seek > time.start + 0.2 &&
          time.start - 0.2 - 0.2 &&
          seek < initialVideoDuration:
          setVideoTimeCode(time.start);
          videoRef.current.currentTime = time.start;
          time["end"] = seek;
          onChangeTimings && onChangeTimings(time);
          break;
      }
    },
    [initialVideoDuration, onChangeTimings, timings, videoRef],
  );

  const onStartMouseDown = useCallback(
    (event: any) => {
      currentlyGrabbedRef.current = { type: "start" };
      handleMouseMoveWhenGrabbed(event);
    },
    [handleMouseMoveWhenGrabbed],
  );

  const onEndMouseDown = useCallback(
    (event: any) => {
      currentlyGrabbedRef.current = { type: "end" };
      handleMouseMoveWhenGrabbed(event);
    },
    [handleMouseMoveWhenGrabbed],
  );

  const onMainMouseDown = useCallback(
    (event: any) => {
      currentlyGrabbedRef.current = { type: "main" };
      handleMouseMoveWhenGrabbed(event);
    },
    [handleMouseMoveWhenGrabbed],
  );

  const handleMouseUp = useCallback(() => {
    currentlyGrabbedRef.current = null;
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMoveWhenGrabbed);
    window.addEventListener("touchmove", handleMouseMoveWhenGrabbed);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchend", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMoveWhenGrabbed);
      window.addEventListener("touchmove", handleMouseMoveWhenGrabbed);
      window.removeEventListener("mouseup", handleMouseUp);
      window.addEventListener("touchend", handleMouseUp);
    };
  }, [handleMouseMoveWhenGrabbed, handleMouseUp]);

  useEffect(() => {
    if (!videoRef?.current) {
      return;
    }

    const video = videoRef.current;

    const handleTimeUpdate = () => {
      if (currentlyStartGraddedRef.current) {
        currentlyStartGraddedRef.current = false;
        return;
      }

      const currentTime = video.currentTime - timings.start;
      const duration = video.duration;

      setVideoProgress((currentTime / duration) * 100);
      setVideoTimeCode(video.currentTime);
    };

    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [timings.start, videoRef]);

  const onPlaybackClick = useCallback(
    (event: any) => {
      if (!playbackRef.current) {
        return;
      }

      if (!videoRef?.current) {
        return;
      }

      const xCoordinate =
        event.type === "touchmove" ? event.touches[0].clientX : event.clientX;

      let playbackRect = playbackRef.current.getBoundingClientRect();
      let seekRatio = (xCoordinate - playbackRect.left) / playbackRect.width;
      let seek = initialVideoDuration * seekRatio;

      if (seek < timings.end - 0.2 && seek > timings.start) {
        videoRef.current.currentTime = seek;
        setVideoTimeCode(seek);
      }
    },
    [initialVideoDuration, timings.end, timings.start, videoRef],
  );

  if (!videoRef?.current) {
    return null;
  }

  return (
    <Box sx={materialStyles.playbackContainer}>
      <Typography sx={materialStyles.playbackText}>
        {t("add_photo.cropp_video")}
      </Typography>
      <Div
        onClick={onPlaybackClick}
        ref={playbackRef}
        sx={materialStyles.playback}
      >
        <Div
          sx={[
            materialStyles.playbackProgress,
            {
              width: `${videoProgress}%`,
              left: `${(timings.start / initialVideoDuration) * 100}%`,
            },
          ]}
        />
        <Div
          onMouseDown={onMainMouseDown}
          onTouchStart={onMainMouseDown}
          sx={[
            materialStyles.playbackMainThumb,
            {
              left: `${(videoTimeCode / initialVideoDuration) * 100}%`,
            },
          ]}
        />
        <Div
          onMouseDown={onStartMouseDown}
          onTouchStart={onStartMouseDown}
          sx={[
            materialStyles.playbackThumb,
            { left: `${(timings.start / initialVideoDuration) * 100}%` },
          ]}
        />
        <Div
          onMouseDown={onEndMouseDown}
          onTouchStart={onEndMouseDown}
          sx={[
            materialStyles.playbackThumb,
            { left: `${(timings.end / initialVideoDuration) * 100 - 1}%` },
          ]}
        />
      </Div>
      <Box sx={materialStyles.playbackTimecodes}>
        <Typography sx={materialStyles.playbackText}>
          {`${timings.start.toFixed(2)}s`}
        </Typography>
        <Typography
          sx={materialStyles.playbackText}
        >{`${timings.end.toFixed(2)}s`}</Typography>
      </Box>
    </Box>
  );
};
