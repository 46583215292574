// import { Colors, theme } from "../../../theme";
import { theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  container: {
    maxWidth: "100%",
    height: "100%",
  },
  saveButton: {
    width: "100%",
    mt: 3,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
};
