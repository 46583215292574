import { Box, Modal } from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles } from "./styles";
import { CreatePrivacyGroupForm } from "./components/CreatePrivacyGroupForm";
import { UpdatePrivacyGroupForm } from "./components/UpdatePrivacyGroupForm";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";
import { useAppDispatch, useAppSelector } from "../../../store";
import { resetUpdateGroupSlice } from "../../../store/slices/updateGroup";

type ICreateOrUpdatePrivacyGroupModal = {
  isOpen: boolean;
  onClose: () => void;
  type?: "CREATE" | "UPDATE";
};

export const CreateOrUpdatePrivacyGroupModal: React.FC<
  ICreateOrUpdatePrivacyGroupModal
> = ({ isOpen, onClose: onCloseFromProps, type }) => {
  const { loading } = useAppSelector((state) => state.loader);
  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    if (type === "UPDATE") {
      dispatch(resetUpdateGroupSlice());
    }
    onCloseFromProps();
  }, [dispatch, onCloseFromProps, type]);

  return (
    <Modal open={isOpen} onClose={onClose} sx={materialStyles.modal}>
      <Box sx={materialStyles.modalBox}>
        {type === "CREATE" ? (
          <CreatePrivacyGroupForm onClose={onClose} />
        ) : null}
        {type === "UPDATE" ? (
          <UpdatePrivacyGroupForm onClose={onClose} />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
