import { Colors, theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";
import { withHexOpacity } from "../../utils";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  buttonWithIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    flex: 1,
  },
  buttonWithIconContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "8px",
  },
  buttonWithIconTitle: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[700],
    textAlign: "left",
  },
  buttonWithIconSubtitle: {
    fontSize: "12px",
    lineHeight: "20px",
    color: theme.palette.grey[900],
    textAlign: "left",
  },
  arrowIcon: {
    color: theme.palette.grey[700],
    width: "18px",
    height: "18px",
  },
  buttonWithIconSwitcher: {
    "& .MuiSwitch-track": {
      backgroundColor: "white",
    },
  },
  buttonWithIconAndSwitcher: {
    marginRight: "-8px",
    marginTop: "-5px",
    flex: 0,
    width: "100%",
  },
  buttonWithTitleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  activeButtonTitle: {
    color: theme.palette.primary.main,
  },
  buttonTitle: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[700],
  },
  buttonSubtitle: {
    fontSize: "12px",
    lineHeight: "20px",
    color: theme.palette.grey[900],
  },
  additionalMenuButtonIcon: {
    color: Colors.white,
  },
  popoverContainer: {
    backgroundColor: theme.palette.grey[50],
    width: "230px",
  },
  additionalMenuItems: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  additionalMenuItem: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "10px",
    flex: 1,
  },
  additionalMenuItemTitle: {
    color: Colors.white,
  },
  activeFavoriteIcon: {
    color: theme.palette.error.main,
  },
  favoriteIcon: {
    color: Colors.white,
  },
  emojiPopoverContainer: {
    backgroundColor: theme.palette.grey[50],
    padding: "8px",
    borderRadius: "30px",
  },
  emojiButton: {
    color: Colors.white,
    height: "46px",
  },
  emojiCount: {
    fontSize: "12px",
    lineHeight: "14px",
    marginLeft: "6px",
    color: theme.palette.grey[900],
  },
  emojiActiveButton: {
    backgroundColor: theme.palette.grey[600],

    "&:hover": {
      backgroundColor: withHexOpacity(theme.palette.grey[600], 0.5),
    },
  },
  addToFavoritesButton: {
    textTransform: "none",
    letterSpacing: 0,
    color: Colors.white,
  },
  emojiWithAddToFavoritesButtonContainer: {
    display: "flex",
  },
  emojiListContainer: {
    display: "flex",
    paddingRight: "5px",
    borderRight: `1px solid ${theme.palette.grey[900]}`,
    gap: "6px",
  },
  emojiIcon: {
    width: "16px",
    aspectRatio: 1,
  },
};
