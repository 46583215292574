import { createSlice } from "@reduxjs/toolkit";
import { IUserType } from "./user";
import { DEFAULT_PAGE, DEFAULT_SUPPORT_PAGE_SIZE } from "../../constants/api";

export interface ISupportMessage {
  id: number;
  is_read: boolean;
  message: string;
  created_at: string;
  from: IUserType & { is_support: boolean };
  files?: { path: string }[];
}

export interface ISupportRequest {
  id: number;
  created_at: string;
  is_active: boolean;
  type: number;
  link: string;
  messages: ISupportMessage[];
}

interface ISupportRequestsSliceInitialState {
  supportRequests: {
    data: ISupportRequest[];
    page: number;
    pageSize: number;
  };
  count: number;
}

const supportRequestsSliceInitialState: ISupportRequestsSliceInitialState = {
  supportRequests: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_SUPPORT_PAGE_SIZE,
  },
  count: 0,
};

const supportRequestsSlice = createSlice({
  name: "supportRequests",
  initialState: supportRequestsSliceInitialState,
  reducers: {
    setSupportRequests(state, action) {
      state.supportRequests.data = action.payload;
    },
    addSupportRequests(state, action) {
      state.supportRequests.data = [
        ...state.supportRequests.data,
        action.payload,
      ];
    },
    resetSupportRequest(state) {
      state.count = supportRequestsSliceInitialState.count;
      state.supportRequests = supportRequestsSliceInitialState.supportRequests;
    },
  },
});

export default supportRequestsSlice.reducer;
export const { setSupportRequests, addSupportRequests, resetSupportRequest } =
  supportRequestsSlice.actions;
