import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Toast } from "rc-toastr/dist/types";
import React, { useCallback, useContext, useMemo } from "react";
import { useAuth } from "../../hooks/useAuth";
import { theme } from "rc-toastr";
import { useTranslation } from "react-i18next";
import { materialStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { CommonModalsDispatchContext } from "../providers/CommonModalsProvider";

type IAppToaster = {
  toast: Toast;
  onClose: (id: number) => void;
  visible: boolean;
};

//TODO: Remove custom library and rewrite to own library

export const AppToaster: React.FC<IAppToaster> = ({
  toast,
  onClose,
  visible,
}) => {
  const isAuth = useAuth();
  const { t } = useTranslation();

  const dispatchCommonModals = useContext(CommonModalsDispatchContext);

  const isShowSignUpButton = useMemo(
    () => toast.type === "warning" && !isAuth,
    [toast.type, isAuth],
  );

  const onSignUp = useCallback(() => {
    dispatchCommonModals && dispatchCommonModals({ type: "SHOW_AUTH_MODAL" });
    onClose(toast.id);
  }, [dispatchCommonModals, onClose, toast.id]);

  if (!visible) {
    return null;
  }

  return (
    <Grid
      sx={[
        materialStyles.toaster,
        { backgroundColor: theme.colors[toast.type] },
      ]}
    >
      <Grid sx={materialStyles.toastIcon}>{theme.icons[toast.type]}</Grid>
      <Grid>
        <Typography>{toast.message}</Typography>
        {isShowSignUpButton ? (
          <Button
            onClick={onSignUp}
            variant="contained"
            sx={materialStyles.signUpButton}
          >
            <Typography>{t("auth_modal.sign_up")}</Typography>
          </Button>
        ) : null}
      </Grid>
      <IconButton
        onClick={() => onClose(toast.id)}
        sx={materialStyles.closeIconСontainer}
      >
        <CloseIcon sx={materialStyles.closeIcon} />
      </IconButton>
    </Grid>
  );
};
