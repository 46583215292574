import React from "react";
import { PrivacyGroup } from "../../../store/slices/groups";
import { Box, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import _ from "lodash";
import { GroupItem } from "./components/GroupItem";
import { ALL_GROUP } from "../../../hooks/usePrivacyGroups";
import { IMaterialStyleItem } from "../../../types";

type IGroupsList = {
  groups: PrivacyGroup[];
  selectedGroups?: PrivacyGroup[];
  onGroupClick?: (group: PrivacyGroup, isSelected?: boolean) => void;
  title?: string;
  sx?: IMaterialStyleItem;
  listContainerSx?: IMaterialStyleItem;
  listItemSx?: IMaterialStyleItem;
};

export const GroupsList: React.FC<IGroupsList> = ({
  title,
  groups,
  onGroupClick,
  selectedGroups,
  sx,
  listContainerSx,
  listItemSx,
}) => {
  return (
    <Box sx={sx}>
      {title ? (
        <Typography sx={materialStyles.groupsListTitle}>{title}</Typography>
      ) : null}
      <Box
        sx={[
          materialStyles.groupsListContainer,
          listContainerSx ? listContainerSx : false,
        ]}
      >
        <GroupItem
          sx={listItemSx}
          group={ALL_GROUP}
          onClick={onGroupClick}
          isSelected={Boolean(_.find(selectedGroups, { id: ALL_GROUP.id }))}
        />
        {_.map(groups, (group) => (
          <GroupItem
            sx={listItemSx}
            key={group.id}
            group={group}
            onClick={onGroupClick}
            isSelected={Boolean(_.find(selectedGroups, { id: group.id }))}
          />
        ))}
      </Box>
    </Box>
  );
};
