import { Grid, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrivacyGroups } from "../../../hooks/usePrivacyGroups";
import { materialStyles } from "./styles";
import { BlockWithTitle } from "../../blocks/blockWithTitle/BlockWithTitle";
import _ from "lodash";
import { BlockWithTitleItem } from "../../blocks/blockWithTitle/BlockWithTitleItem";
import { BlockWithTitleDivider } from "../../blocks/blockWithTitle/BlockWithTitleDivider";
import { BlockWithTitleButton } from "../../blocks/blockWithTitle/BlockWithTitleButton";
import { CreateOrUpdatePrivacyGroupModal } from "../../modals/createOrUpdatePrivacyGroupModal";
import { useAppDispatch } from "../../../store";
import { PrivacyGroup } from "../../../store/slices/groups";
import { setGroupIdForUpdate } from "../../../store/slices/updateGroup";

export const PrivacyGroups: React.FC = () => {
  const { t } = useTranslation();
  const { groups } = usePrivacyGroups();
  const dispatch = useAppDispatch();

  const [isOpenCreateGroupModal, setIsOpenCreateGroupModal] = useState(false);
  const [isOpenUpdateGroupModal, setIsOpenUpdateGroupModal] = useState(false);

  const onOpenCreateGroupModal = useCallback(() => {
    setIsOpenCreateGroupModal(true);
  }, []);

  const onCloseCreateGroupModal = useCallback(() => {
    setIsOpenCreateGroupModal(false);
  }, []);

  const onOpenUpdateGroupModal = useCallback(
    (group: PrivacyGroup) => {
      dispatch(setGroupIdForUpdate(group.id));
      setIsOpenUpdateGroupModal(true);
    },
    [dispatch],
  );

  const onCloseUpdateGroupModal = useCallback(() => {
    setIsOpenUpdateGroupModal(false);
  }, []);

  return (
    <>
      <Grid>
        <Typography sx={materialStyles.contentHeaderTitle}>
          {t("settings.privacy_groups")}
        </Typography>
        <Grid sx={materialStyles.blocks}>
          <BlockWithTitle title={t("settings.manage_privacy_groups")}>
            {_.map(groups, (group, index) => (
              <>
                <BlockWithTitleItem
                  type={group.is_editable ? "EDIT" : "DEFAULT"}
                  title={group.title}
                  onClick={() => onOpenUpdateGroupModal(group)}
                />
                <BlockWithTitleDivider />
              </>
            ))}
            <BlockWithTitleButton
              title={t("settings.create_group")}
              onClick={onOpenCreateGroupModal}
            />
          </BlockWithTitle>
        </Grid>
      </Grid>
      <CreateOrUpdatePrivacyGroupModal
        onClose={onCloseCreateGroupModal}
        isOpen={isOpenCreateGroupModal}
        type="CREATE"
      />
      <CreateOrUpdatePrivacyGroupModal
        onClose={onCloseUpdateGroupModal}
        isOpen={isOpenUpdateGroupModal}
        type="UPDATE"
      />
    </>
  );
};
