import { Colors, theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  page: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100vh",
    maxWidth: "100vw",
  },
  photo: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "relative",

    justifyContent: {
      xs: "flex-end",
      sm: "flex-end",
      md: "space-between",
    },

    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
    },
  },
  picture: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  media: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    flex: 1,
    objectFit: "contain",
    maxWidth: "100%",
    maxHeight: "85%",
  },
  youTubeMedia: {
    minWidth: "85%",
    minHeight: "85%",
  },
  iconBlocks: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
  },
  headerIcon: {
    color: Colors.white,
  },
  notFoundContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  notFoundTitle: {
    color: Colors.white,
    fontSize: "48px",
    lineHeight: "56px",
    fontWeight: 700,
    textAlign: "center",
  },
  notFoundDescription: {
    color: Colors.white,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    opacity: 0.75,
    whiteSpace: "pre",
    marginTop: "16px",
  },
  goHomeButton: {
    borderRadius: "7px",
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",
    letterSpacing: 0,
    fontSize: "16px",
    lineHeight: "24px",

    padding: "12px 74px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  notFoundButtons: {
    marginTop: "32px",
    gap: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  orDivider: {
    color: Colors.white,
    opacity: 0.75,
    fontSize: "14px",
    lineHeight: "24px",
  },
  loginButton: {
    textTransform: "none",
    letterSpacing: 0,
    fontSize: "16px",
    lineHeight: "24px",
  },
};
