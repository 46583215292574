import React, { useEffect, useRef } from "react";
import { IMaterialStyleItem } from "../../types";
import { Grid } from "@mui/material";

type TWithHandleScrollGrid = {
  onEndReached?: () => void;
  sx?: IMaterialStyleItem;
  children: React.ReactNode;
};

export const WithHandleScrollGrid: React.FC<TWithHandleScrollGrid> = ({
  onEndReached,
  sx,
  children,
}) => {
  const containerRef = useRef<any>(null);

  useEffect(() => {
    const gridElement = containerRef.current;

    const handleScroll = () => {
      if (
        Math.round(gridElement.scrollTop + gridElement.clientHeight) >=
          gridElement.scrollHeight ||
        Math.floor(gridElement.scrollTop + gridElement.clientHeight) >=
          gridElement.scrollHeight
      ) {
        onEndReached && onEndReached();
      }
    };

    gridElement.addEventListener("scroll", handleScroll);

    return () => {
      gridElement.removeEventListener("scroll", handleScroll);
    };
  }, [onEndReached]);

  return (
    <Grid ref={containerRef} sx={sx}>
      {children}
    </Grid>
  );
};
