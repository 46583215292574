import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getMySubscribers } from "../store/thunks/explore";
import {
  resetExploreSubscribers,
  setExploreSubscribersPage,
  setExploreSubscribersPageSize,
  setExploreSubscribersTotalElements,
  setSubscribers,
} from "../store/slices/explore";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../constants/api";

export const useFetchSubscribers = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { subscribers } = useAppSelector((state) => state.explore);

  const fetchSubscribers = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        if (!isAuth) {
          return;
        }

        const response = await dispatch(getMySubscribers(page, pageSize));
        dispatch(setSubscribers(response?.list));
        dispatch(setExploreSubscribersTotalElements(response?.totalElements));
      } catch (error) {
        console.error("Error while [fetchPeoples]", error);
      }
    },
    [dispatch, isAuth]
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setExploreSubscribersPage(subscribers.page + 1));
    dispatch(setExploreSubscribersPageSize(DEFAULT_PAGE_SIZE));
  }, [subscribers.page, dispatch]);

  const refetchSubscribers = useCallback(() => {
    fetchSubscribers(subscribers.page);
  }, [fetchSubscribers, subscribers.page]);

  useEffect(() => {
    fetchSubscribers();

    return () => {
      dispatch(resetExploreSubscribers());
    };
  }, [dispatch, fetchSubscribers, isAuth]);

  useEffect(() => {
    if (subscribers.page !== DEFAULT_PAGE) {
      fetchSubscribers(subscribers.page, subscribers.pageSize);
    }
  }, [subscribers.page, subscribers.pageSize, fetchSubscribers]);

  return {
    subscribers: subscribers.data,
    totalElements: subscribers.totalElements,
    refetchSubscribers,
    onUpdatePageOrPageSize,
  };
};
