import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getPublicMedia } from "../store/thunks/globe";
import {
  resetExplorePublicMedia,
  setExplorePublicMedia,
  setExplorePublicMediaPage,
  setExplorePublicMediaPageSize,
} from "../store/slices/explore";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../constants/api";

export const useFetchExplorePublicFeed = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { publicMedia } = useAppSelector((state) => state.explore);

  const fetchFeed = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        if (isAuth) {
          return;
        }

        const media = await dispatch(getPublicMedia(page || 1, pageSize));

        dispatch(setExplorePublicMedia(media));
      } catch (error) {
        console.error("Error while [fetchFeed]", error);
      }
    },
    [dispatch, isAuth]
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setExplorePublicMediaPage(publicMedia.page + 1));
    dispatch(setExplorePublicMediaPageSize(DEFAULT_PAGE_SIZE));
  }, [publicMedia.page, dispatch]);

  const refetchFeed = useCallback(() => {
    fetchFeed(publicMedia.page, publicMedia.pageSize);
  }, [fetchFeed, publicMedia.page, publicMedia.pageSize]);

  useEffect(() => {
    fetchFeed();

    return () => {
      dispatch(resetExplorePublicMedia());
    };
  }, [dispatch, fetchFeed, isAuth]);

  useEffect(() => {
    if (publicMedia.page !== DEFAULT_PAGE) {
      fetchFeed(publicMedia.page, publicMedia.pageSize);
    }
  }, [publicMedia.page, publicMedia.pageSize, fetchFeed]);

  return {
    publicPosts: publicMedia.data,
    onUpdatePageOrPageSize,
    refetchFeed,
  };
};
