import { IMaterialStyleType, ICssStyleType } from "../../../types";
import { theme } from "../../../theme";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  settingsList: {
    flex: 1,
  },
  settingsListContainer: {
    backgroundColor: theme.palette.grey[600],
    borderRadius: "3px",
  },
  settingsListButton: {},
  settingListActiveButton: {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
  },
  settingsListTitle: {
    color: theme.palette.grey[700],
    fontSize: "14px",
    lineHeight: "20px",
  },
  settingsListActiveTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  listDivider: {
    borderColor: theme.palette.grey[800],
  },
};
