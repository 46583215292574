import { Grid, Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type TModalHeader = {
  title?: string;
  subtitle?: string;
};

export const ModalHeader: React.FC<TModalHeader> = ({ title, subtitle }) => {
  return (
    <Grid sx={materialStyles.modalHeaderBlock}>
      <Typography sx={materialStyles.modalHeaderTitle}>{title}</Typography>
      <Typography sx={materialStyles.modalHeaderSubtitle}>
        {subtitle}
      </Typography>
    </Grid>
  );
};
