import { useToast } from "rc-toastr";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useCopy = () => {
  const { toast } = useToast();
  const { t } = useTranslation();

  const onCopy = useCallback(
    (value: string) => {
      navigator.clipboard.writeText(value).then(() => {
        toast.success(t("common.copy_success"));
      });
    },
    [t, toast]
  );

  return onCopy;
};
