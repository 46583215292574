import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  chatUserContainer: {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    padding: {
      xs: "8px 12px",
      sm: "8px 12px",
      md: "20px 24px",
    },
    display: "flex",
    alignItems: "center",
  },
  chatUsername: {
    fontSize: "20px",
    color: Colors.white,
    fontWeight: "700",
    lineHeight: "28px",
    marginLeft: "16px",
  },
  chatSendInputContainer: {
    padding: "24px",
  },
  chatSendInput: {
    backgroundColor: theme.palette.grey[600],

    "& .MuiInputBase-root": {
      backgroundColor: `${theme.palette.grey[600]} !important`,
    },

    "& .MuiFilledInput-root": {
      backgroundColor: theme.palette.grey[600],
      borderColor: theme.palette.background.default,

      "&.Mui-focused": {
        backgroundColor: theme.palette.grey[600],
      },

      "&.Mui-focused:after": {
        display: "block",
        borderBottom: `1px solid ${theme.palette.grey["A100"]}`,
      },

      "&::before, &::after": {
        display: "none",
      },

      "& :hover": {
        backgroundColor: theme.palette.grey[600],
      },
    },
    "& input": {
      paddingTop: "12px",
      paddingBottom: "12px",
      paddingLeft: "20px",
      fontSize: "14px",
      color: theme.palette.grey["A100"],
      backgroundColor: theme.palette.grey[600],
    },
    "& fieldset": {
      border: "none",
    },
  },
  sendButton: {
    color: theme.palette.primary.main,
  },
  chatMessagesContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column-reverse",
    overflow: "auto",
    padding: "0px 24px",
  },
  myChatMessageContainer: {
    justifyContent: "flex-end",
  },
  chatMessageContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px",
  },
  messageContainer: {
    backgroundColor: theme.palette.grey[600],
    padding: "8px 16px",
    borderRadius: "12px",
    marginLeft: "16px",
    marginRight: "16px",
  },
  myMessageContainer: {
    backgroundColor: theme.palette.primary.main,
  },
  message: {
    color: Colors.white,
    fontSize: "14px",
    lineHeight: "22px",
  },
  charUserMobileBackButton: {
    color: Colors.white,
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
    },
  },
};
