import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";
import { withHexOpacity } from "../../../../utils";

export const styles: ICssStyleType = {
  postImage: {
    width: "100%",
    height: "423px",
    objectFit: "cover",
    borderRadius: "20px",
    marginTop: "18px",
  },
};

export const materialStyles: IMaterialStyleType = {
  postContainer: {
    width: {
      xs: "100%",
      sm: "100%",
      md: "100%",
      lg: "918px",
    },
  },
  postUserContainer: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    textAlign: "left",
  },
  postUsername: {
    fontSize: "18px",
    color: Colors.white,
    lineHeight: "23px",
    marginLeft: "16px",
  },
  postHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  followButton: {
    color: Colors.white,
    letterSpacing: 0,
    textTransform: "none",
    fontSize: "16px",
    padding: "2px 21px",
    borderRadius: "10px",
    boxShadow: "none",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  unfollowButton: {
    color: Colors.white,
    backgroundColor: "#585858",
    letterSpacing: 0,
    textTransform: "none",
    fontSize: "16px",
    padding: "2px 21px",
    borderRadius: "10px",
    boxShadow: "none",

    "&:hover": {
      backgroundColor: "#585858",
    },
  },
  postDescription: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.grey[900],
    marginTop: "18px",
  },
  imageContainer: {
    position: "relative",
  },
  favoritesButton: {
    position: "absolute",
    transition: "0.3s",
    bottom: "10px",
    opacity: 1,
    right: "10px",

    "&:hover": {
      backgroundColor: withHexOpacity(Colors.black, 0.65),
    },
  },
  activeFavoritesButton: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    bottom: "10px",
    opacity: 1,
    right: "10px",
    transition: "0.3s",
    backgroundColor: withHexOpacity(Colors.black, 0.7),
    borderRadius: "30px",

    "&:hover": {
      backgroundColor: withHexOpacity(Colors.black, 0.65),
    },
  },
  commentsButton: {
    margin: "0 14px 0 7px",
  },
  commentsButtonIcon: {
    color: Colors.white,
  },
  commentsButtonText: {
    color: Colors.white,
    fontSize: "14px",
    marginLeft: "8px",
  },
  activeFavoritePhotoIcon: {
    color: theme.palette.error.main,
  },
  favoritePhotoIcon: {
    color: Colors.white,
  },
  activityBlock: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    marginTop: "18px",
  },
  activityText: {
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.palette.grey["A700"],
  },
  activityDivider: {
    width: "2px",
    height: "2px",
    borderRadius: "50%",
    backgroundColor: theme.palette.grey["A700"],
  },
  headerPostButtons: {
    display: "flex",
    gap: "8px",
  },
  headerPostIcon: {
    color: Colors.white,
  },
};
