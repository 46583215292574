import { IMaterialStyleType, ICssStyleType } from "../../../types";
import { Colors, theme } from "../../../theme";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  contentHeaderTitle: {
    color: Colors.white,
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "52px",
    marginBottom: "10px",
  },
  blocks: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    mb: 2,
  },
  blockWithTitleText: {
    color: theme.palette.grey[700],
    fontSize: "12px",
    lineHeight: "20px",
    margin: "16px",
  },
  accountInformationFooter: {
    marginTop: "64px",
  },
  accountInformationFooterDivider: {
    borderColor: theme.palette.grey[600],
  },
  accountInformationFooterText: {
    fontSize: "12px",
    lineHeight: "20px",
    color: theme.palette.grey[700],
    marginTop: "17px",
  },
  deleteAccountButton: {
    color: theme.palette.error.main,
  },
  settingsAvatarBlock: {
    paddingTop: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
