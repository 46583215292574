import {
  ButtonBase,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { UserAvatar } from "../../../user/UserAvatar";
import { BlockText } from "../../../blocks/blockTextBlockWithBorder/BlockText";
import { BlockTextTitle } from "../../../blocks/blockTextBlockWithBorder/BlockTextTitle";
import { BlockTextContent } from "../../../blocks/blockTextBlockWithBorder/BlockTextContent";
import { BlockTextDivider } from "../../../blocks/blockTextBlockWithBorder/BlockTextDivider";
import { BlockDetails } from "../../../blocks/blockDetails/BlockDetails";
import { BlockDetailsTitle } from "../../../blocks/blockDetails/BlockDetailsTitle";
import _ from "lodash";
import { MapComponent } from "../../../map/MapComponent";
import { TagsList } from "../../../lists/tagsList";
import { useAppDispatch, useAppSelector } from "../../../../store";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { BlockDetailsContent } from "../../../blocks/blockDetails/BlockDetailsContent";
import {
  getAlbumNamesFromAlbumsArray,
  getDateFromPhotoCreated,
  getDateFromPhotoCreatedWithTime,
  getGroupTitlesFromGroupsArray,
  getValuesFromMetadata,
} from "../../../../utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useCopy } from "../../../../hooks/useCopy";
import { CommonModalsDispatchContext } from "../../../providers/CommonModalsProvider";
import { setUserId } from "../../../../store/slices/userProfile";
import { useAuth } from "../../../../hooks/useAuth";

type PhotoInfoDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  photoId?: number;
  isLoading?: boolean;
};

export const PhotoInfoDrawer: React.FC<PhotoInfoDrawerProps> = ({
  isOpen,
  onClose,
  photoId,
  isLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dispatchCommonModals = useContext(CommonModalsDispatchContext);

  const isAuth = useAuth();

  const onCopy = useCopy();

  const { photo, sharingGroups } = useAppSelector((state) => state.userPhoto);

  const { user } = useAppSelector((state) => state.user);

  const parsedMetadataValues = useMemo(
    () => getValuesFromMetadata(photo?.metadata),
    [photo?.metadata],
  );

  const isMyPhoto = useMemo(
    () => Boolean(user && photo && user.id === photo.user_id),
    [photo, user],
  );

  const onCloseInfoBlock = useCallback(() => {
    onClose();
  }, [onClose]);

  const onClickUser = useCallback(() => {
    if (!photo) {
      return;
    }
    if (!isAuth) {
      dispatchCommonModals && dispatchCommonModals({ type: "SHOW_AUTH_MODAL" });
      return;
    }

    onClose();

    dispatch(setUserId(photo.user.id));
    dispatchCommonModals && dispatchCommonModals({ type: "SHOW_USER_MODAL" });
  }, [dispatch, dispatchCommonModals, isAuth, onClose, photo]);

  return (
    <Drawer
      sx={materialStyles.drawer}
      PaperProps={{ sx: materialStyles.drawerContainer }}
      anchor="right"
      hideBackdrop
      open={isOpen}
      onClose={onClose}
    >
      <Grid sx={materialStyles.innerInfoBlock}>
        {isLoading ? (
          <Grid sx={materialStyles.loaderContainer}>
            <CircularProgress />
          </Grid>
        ) : null}
        {photo && !isLoading ? (
          <>
            <Grid sx={materialStyles.infoBlockHeader}>
              <IconButton onClick={onCloseInfoBlock}>
                <CloseOutlinedIcon sx={materialStyles.headerIcon} />
              </IconButton>
              <Typography sx={materialStyles.headerTitle}>
                {t("photos.info")}
              </Typography>
            </Grid>
            {photo.user ? (
              <ButtonBase
                onClick={onClickUser}
                disabled={isMyPhoto}
                sx={materialStyles.userInfoBlock}
              >
                <UserAvatar user={photo.user} type="AVATAR_LIST" />
                <Typography sx={materialStyles.userInfoName}>
                  {photo.user.username}
                </Typography>
              </ButtonBase>
            ) : null}
            {photo.description ? (
              <BlockText sx={materialStyles.descriptionBlock}>
                <BlockTextTitle title={t("photos.description")} />
                <BlockTextContent content={photo.description} />
                <BlockTextDivider />
              </BlockText>
            ) : null}
            <BlockDetails sx={materialStyles.detailsBlock}>
              <BlockDetailsTitle title={t("photos.details")} />
              {isMyPhoto ? (
                <BlockDetailsContent
                  titleWidth={90}
                  title={t("photos.visibility")}
                  content={
                    photo.is_public
                      ? t("photos.photo_is_public")
                      : t("photos.private_photo")
                  }
                />
              ) : null}
              {isMyPhoto && !_.isEmpty(sharingGroups) ? (
                <BlockDetailsContent
                  titleWidth={90}
                  title={t("photos.sharing_groups")}
                  content={
                    photo.is_public
                      ? t("photos.all")
                      : getGroupTitlesFromGroupsArray(sharingGroups)
                  }
                />
              ) : null}
              {isMyPhoto && !_.isEmpty(photo.albums) ? (
                <BlockDetailsContent
                  titleWidth={90}
                  title={t("photos.albums")}
                  content={getAlbumNamesFromAlbumsArray(photo.albums)}
                />
              ) : null}
              <BlockDetailsContent
                titleWidth={90}
                title={t("photos.created")}
                content={getDateFromPhotoCreated(photo?.created_at)}
                subContent={getDateFromPhotoCreatedWithTime(photo?.created_at)}
              />
              {parsedMetadataValues && parsedMetadataValues.size ? (
                <BlockDetailsContent
                  titleWidth={90}
                  title={t("photos.image_size")}
                  content={parsedMetadataValues.size}
                />
              ) : null}
              {parsedMetadataValues && parsedMetadataValues.fileType ? (
                <BlockDetailsContent
                  titleWidth={90}
                  title={t("photos.file_type")}
                  content={parsedMetadataValues.fileType}
                />
              ) : null}
              <BlockDetailsContent
                titleWidth={90}
                title={t("photos.coordinates")}
                content={`${photo.latitude} ${photo.longitude}`}
              >
                <ButtonBase
                  onClick={() => onCopy(`${photo.latitude} ${photo.longitude}`)}
                >
                  <ContentCopyIcon sx={materialStyles.copyIcon} />
                </ButtonBase>
              </BlockDetailsContent>
              {parsedMetadataValues && parsedMetadataValues.copyright ? (
                <BlockDetailsContent
                  titleWidth={90}
                  title={t("photos.copyright")}
                  content={parsedMetadataValues.copyright}
                />
              ) : null}
            </BlockDetails>
            <Grid sx={materialStyles.tagList}>
              <TagsList disabled disabledCreate tags={photo?.tags || []} />
            </Grid>
            <MapComponent
              photos={[photo]}
              zoom={6}
              initialLongitude={photo.longitude}
              initialLatitude={photo.latitude}
              containerStyle={{
                width: "100%",
                minHeight: "400px",
                marginTop: "36px",
                borderRadius: "8px",
              }}
            />
          </>
        ) : null}
      </Grid>
    </Drawer>
  );
};
