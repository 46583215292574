import { TextField, TextFieldProps, TextFieldVariants } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type IFiltersAutocompleteInput = { variant?: TextFieldVariants } & Omit<
  TextFieldProps,
  "variant"
>;

export const FiltersAutocompleteInput: React.FC<IFiltersAutocompleteInput> = (
  props
) => {
  return <TextField {...props} sx={materialStyles.filtersAutocompleteInput} />;
};
