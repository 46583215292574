import { createSlice } from "@reduxjs/toolkit";

interface ISocketSliceInitialState {
  isConnected: boolean;
}

const socketSliceInitialState: ISocketSliceInitialState = {
  isConnected: false,
};

const socketSlice = createSlice({
  name: "socket",
  initialState: socketSliceInitialState,
  reducers: {
    setSocketConnected(state, action) {
      state.isConnected = action.payload;
    },
  },
});

export default socketSlice.reducer;
export const { setSocketConnected } = socketSlice.actions;
