import React, { useCallback, useState } from "react";
import { UserLayout } from "../../components/layout/UserLayout";
import { PageTitle } from "../../components/titles/PageTitle";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { materialStyles } from "./styles";
import AddIcon from "@mui/icons-material/Add";
import { BlockEmptyWithAdd } from "../../components/blocks/blockEmpty/BlockEmptyWithAdd";
import { NewAlbumModal } from "../../components/modals/newAlbumModal";
import { useAppSelector } from "../../store";
import _ from "lodash";
import { AppAlbumList } from "../../components/lists/albumLists/AppAlbumList";
import { LoaderWithBackdrop } from "../../components/loaders/LoaderWithBackdrop";
import { useFetchAlbums } from "../../hooks/useFetchAlbums";

type IAlbumsPage = {};

export const Albums: React.FC<IAlbumsPage> = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { loading } = useAppSelector((state) => state.loader);

  const { albums, refetchAlbums, onUpdatePageOrPageSize } = useFetchAlbums();

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onCreateAlbum = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onCloseAlbumModal = useCallback(async () => {
    refetchAlbums();
  }, [refetchAlbums]);

  return (
    <>
      <UserLayout onEndReached={onUpdatePageOrPageSize}>
        <PageTitle title={t("albums.albums")}>
          <Button
            startIcon={<AddIcon />}
            sx={materialStyles.createAlbumButton}
            variant="contained"
            onClick={onCreateAlbum}
          >
            {t("albums.create_album")}
          </Button>
        </PageTitle>
        {_.isEmpty(albums) ? (
          <BlockEmptyWithAdd
            title={t("albums.create_album_empty")}
            onClick={onCreateAlbum}
          />
        ) : null}
        <AppAlbumList albums={albums} onCloseAlbumModal={onCloseAlbumModal} />
      </UserLayout>
      <NewAlbumModal isOpen={isOpen} onClose={onClose} />
      <LoaderWithBackdrop isShow={loading} />
    </>
  );
};
