import { Button, Grid } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";
import { IMaterialStyleItem } from "../../../types";

type IBlockWithTitleButton = {
  title: string;
  onClick: () => void;
  buttonSx?: IMaterialStyleItem;
};

export const BlockWithTitleButton: React.FC<IBlockWithTitleButton> = ({
  title,
  onClick,
  buttonSx,
}) => {
  return (
    <Grid sx={[materialStyles.blockItem, materialStyles.blockItemButton]}>
      <Button
        sx={[materialStyles.itemButton, buttonSx ? buttonSx : false]}
        onClick={onClick}
      >
        {title}
      </Button>
    </Grid>
  );
};
