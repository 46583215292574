import { Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type IBlockTextTitle = {
  title: string;
};

export const BlockTextTitle: React.FC<IBlockTextTitle> = ({ title }) => {
  return <Typography sx={materialStyles.blockTitle}>{title}</Typography>;
};
