import React, { useEffect } from "react";
import { useAppDispatch } from "../../store";
import { getSystemState } from "../../store/thunks/system";

type ICommonAuthHandle = {};

export const CommonSystemStateHandle: React.FC<ICommonAuthHandle> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getSystemStateEffect = async () => {
      try {
        await dispatch(getSystemState());
      } catch (error) {
        console.error("Error while [getSystemStateEffect]", error);
      }
    };

    getSystemStateEffect();
  }, [dispatch]);

  return null;
};
