import { useTranslation } from "react-i18next";
import { UserLayout } from "../../components/layout/UserLayout";
import { PageTitle } from "../../components/titles/PageTitle";
import { PeoplesList } from "../../components/lists/peoplesList";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback, useMemo, useState } from "react";
import { UserModal } from "../../components/modals/userModal";
import { setUserId } from "../../store/slices/userProfile";
import { LoaderWithBackdrop } from "../../components/loaders/LoaderWithBackdrop";
import { useAuth } from "../../hooks/useAuth";
import { useToast } from "rc-toastr";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import { materialStyles } from "./styles";
import { PostsLists } from "../../components/lists/postsLists";
import { useFetchExploreAllowedFeed } from "../../hooks/useFetchExploreAllowedFeed";
import { useFetchExplorePublicFeed } from "../../hooks/useFetchExplorePublicFeed";
import { useFetchPeoples } from "../../hooks/useFetchPeoples";
import { useFetchSubscribers } from "../../hooks/useFetchSubscribers";
import { useFetchSubscriptions } from "../../hooks/useFetchSubscriptions";
import _ from "lodash";

export type IExploreTypeTab = "PEOPLES" | "SUBSCRIBERS" | "SUBSCRIPTIONS";

//TODO: need to separate sub\sups/most_popular and remove explore slice
export const Explore = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isAuth = useAuth();
  const { toast } = useToast();
  const { loading } = useAppSelector((state) => state.loader);

  const [tabValue, setTabValue] = useState<IExploreTypeTab>("SUBSCRIPTIONS");
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);

  const {
    allowedPosts,
    onUpdatePageOrPageSize,
    refetchFeed,
    resetAllowedMedia,
  } = useFetchExploreAllowedFeed({
    feedType: _.includes(["SUBSCRIBERS", "SUBSCRIPTIONS"], tabValue)
      ? _.toLower(tabValue)
      : undefined,
    sortBy: tabValue === "PEOPLES" ? "top" : undefined,
  });

  const {
    publicPosts,
    onUpdatePageOrPageSize: onUpdatePublicPageOrPageSize,
    refetchFeed: refetchPublicFeed,
  } = useFetchExplorePublicFeed();

  const {
    peoples,
    onUpdatePageOrPageSize: onUpdatePeoplesPageOrPageSize,
    totalElements: peoplesTotalElements,
  } = useFetchPeoples();

  const {
    subscribers,
    onUpdatePageOrPageSize: onUpdateSubscribersPageOrPageSize,
    totalElements: subscribersTotalElements,
  } = useFetchSubscribers();

  const {
    subscriptions,
    onUpdatePageOrPageSize: onUpdateSubscriptionsPageOrPageSize,
    totalElements: subscriptionsTotalElements,
  } = useFetchSubscriptions();

  const photosOnMap = useMemo(() => {
    switch (true) {
      case isAuth:
        return allowedPosts;
      case !isAuth:
        return publicPosts;
    }
  }, [isAuth, allowedPosts, publicPosts]);

  const onOpenUserModal = useCallback(
    (id: number) => {
      if (!isAuth) {
        toast.info(t("common.this_functionality_available_registered_users"));
        return;
      }
      dispatch(setUserId(id));
      setIsOpenUserModal(true);
    },
    [dispatch, isAuth, t, toast]
  );

  const onCloseUserModal = useCallback(() => {
    resetAllowedMedia();
    refetchFeed();
    setIsOpenUserModal(false);
  }, [refetchFeed, resetAllowedMedia]);

  const handleTabChange = useCallback(
    (_event: any, value: IExploreTypeTab) => {
      resetAllowedMedia();
      setTabValue(value);
    },
    [resetAllowedMedia]
  );

  const onEndReached = useCallback(() => {
    if (isAuth) {
      onUpdatePageOrPageSize();
      return;
    }

    onUpdatePublicPageOrPageSize();
  }, [isAuth, onUpdatePageOrPageSize, onUpdatePublicPageOrPageSize]);

  const onClosePhotoModal = useCallback(() => {
    if (isAuth) {
      refetchFeed();
      return;
    }

    refetchPublicFeed();
  }, [refetchFeed, refetchPublicFeed, isAuth]);

  return (
    <>
      <UserLayout onEndReached={onEndReached}>
        <PageTitle title={t("explore.explore")} />
        {isAuth ? (
          <TabContext value={tabValue}>
            <Box>
              <TabList sx={materialStyles.tabList} onChange={handleTabChange}>
                <Tab
                  sx={materialStyles.tabContainer}
                  label={t("explore.subscriptions")}
                  icon={
                    <Grid id="counter" sx={materialStyles.tabCounter}>
                      {subscriptionsTotalElements}
                    </Grid>
                  }
                  value="SUBSCRIPTIONS"
                  iconPosition="bottom"
                />
                <Tab
                  sx={materialStyles.tabContainer}
                  label={t("explore.subscribers")}
                  value="SUBSCRIBERS"
                  icon={
                    <Grid id="counter" sx={materialStyles.tabCounter}>
                      {subscribersTotalElements}
                    </Grid>
                  }
                  iconPosition="bottom"
                />
                <Tab
                  sx={materialStyles.tabContainer}
                  label={t("explore.most_popular")}
                  value="PEOPLES"
                  icon={
                    <Grid id="counter" sx={materialStyles.tabCounter}>
                      {peoplesTotalElements}
                    </Grid>
                  }
                  iconPosition="bottom"
                />
              </TabList>
            </Box>
            <TabPanel sx={materialStyles.tabPanelContainer} value="SUBSCRIBERS">
              <PeoplesList
                peoples={subscribers}
                onClick={onOpenUserModal}
                listEmptyText={t("explore.subscribers-list-empty")}
                onEndReached={onUpdateSubscribersPageOrPageSize}
              />
            </TabPanel>
            <TabPanel
              sx={materialStyles.tabPanelContainer}
              value="SUBSCRIPTIONS"
            >
              <PeoplesList
                peoples={subscriptions}
                onClick={onOpenUserModal}
                listEmptyText={t("explore.subscriptions-list-empty")}
                onEndReached={onUpdateSubscriptionsPageOrPageSize}
              />
            </TabPanel>
            <TabPanel sx={materialStyles.tabPanelContainer} value="PEOPLES">
              <PeoplesList
                onEndReached={onUpdatePeoplesPageOrPageSize}
                peoples={peoples}
                onClick={onOpenUserModal}
              />
            </TabPanel>
          </TabContext>
        ) : null}
        <PostsLists
          photos={photosOnMap}
          onClosePhotoModal={onClosePhotoModal}
          onCloseUserModal={onCloseUserModal}
          type={tabValue}
        />
        <UserModal isOpen={isOpenUserModal} onClose={onCloseUserModal} />
      </UserLayout>
      <LoaderWithBackdrop isShow={loading} />
    </>
  );
};
