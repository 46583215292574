import React, { useCallback } from "react";
import { materialStyles } from "./styles";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type TConfirmModal = {
  isOpen: boolean;
  onClose: () => void;
  confirmText?: string;
  onConfirm?: () => void;
};

export const ConfirmModal: React.FC<TConfirmModal> = ({
  isOpen,
  onClose,
  onConfirm: onConfirmFromProps,
  confirmText,
}) => {
  const { t } = useTranslation();

  const onConfirm = useCallback(() => {
    onClose();

    onConfirmFromProps && onConfirmFromProps();
  }, [onClose, onConfirmFromProps]);

  return (
    <Modal open={isOpen} sx={materialStyles.modal} onClose={onClose}>
      <Box sx={materialStyles.modalBox}>
        <Grid sx={materialStyles.innerModalContainer}>
          <Typography sx={materialStyles.confirmModalHeaderTitle}>
            {confirmText}
          </Typography>
          <Button
            variant="contained"
            sx={materialStyles.confirmButton}
            onClick={onConfirm}
          >
            {t("common.confirm")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};
