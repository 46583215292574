import { IMaterialStyleType, ICssStyleType } from "../../../types";
import { Colors, theme } from "../../../theme";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  infoBlock: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  blockIcon: {
    fill: theme.palette.primary.main,
  },
  infoTextBlock: {
    marginLeft: "20px",
    flex: 1,

    display: "block",
  },
  infoBlockTitle: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
  },
  infoBlockValue: {
    color: Colors.white,
    fontSize: "12px",
    lineHeight: "16px",
  },
  infoBlockActiveValue: {
    color: theme.palette.primary.main,
    fontSize: "12px",
    lineHeight: "16px",
  },
  progressBarBlock: {
    height: "7px",
    flex: 1,
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.dark,
    position: "relative",
    overflow: "hidden",
    marginTop: "8px",
    marginBottom: "8px",
  },
  progressBarLine: {
    position: "absolute",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
  },
  infoBlockItem: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "8px",
  },
};
