import { useEffect, useState } from "react";

export const useDebounce: <T = any>(
  value: T,
  delay?: number,
  delayCallback?: () => void
) => T = (value, delay = 500, delayCallback) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      if (delayCallback) {
        delayCallback();
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, delayCallback]);

  return debouncedValue;
};
