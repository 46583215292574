import { createSlice } from "@reduxjs/toolkit";

interface ILoadingSliceInitialState {
  loading: boolean;
  queue: number;
}

const loadingSliceInitialState: ILoadingSliceInitialState = {
  loading: false,
  queue: 0,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState: loadingSliceInitialState,
  reducers: {
    setLoading(state, action) {
      if (action.payload) {
        if (!state.queue) {
          state.loading = action.payload;
        }
        state.queue = state.queue + 1;
        return;
      }

      if (state.queue) {
        state.queue = state.queue - 1;

        if (!state.queue) {
          state.loading = action.payload;
        }
      }
    },
  },
});

export default loadingSlice.reducer;
export const { setLoading } = loadingSlice.actions;
