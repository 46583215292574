import { Box, Modal } from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles } from "./styles";
import { ShareMainContent } from "./components/ShareMainContent";
import { useAppDispatch, useAppSelector } from "../../../store";
import { resetSharing } from "../../../store/slices/sharing";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";

type IShareModal = {
  isOpen: boolean;
  onClose: () => void;
  type: "PHOTO" | "ALBUM";
};

export const ShareModal: React.FC<IShareModal> = ({
  isOpen,
  onClose,
  type,
}) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.loader);

  const onCloseModal = useCallback(() => {
    dispatch(resetSharing());
    onClose();
  }, [dispatch, onClose]);

  return (
    <Modal sx={materialStyles.modal} open={isOpen} onClose={onCloseModal}>
      <Box sx={materialStyles.modalBox}>
        <ShareMainContent type={type} onClose={onCloseModal} />
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
