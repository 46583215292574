import { Grid, Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";
import CloudIcon from "@mui/icons-material/Cloud";
import { useTranslation } from "react-i18next";
import { ProgressBar } from "./ProgressBar";
import { useAppSelector } from "../../../store";
import { calculateUsagePercentage, perseBytesToGB } from "../../../utils";

type IStorageInfo = {};

export const StorageInfo: React.FC<IStorageInfo> = () => {
  const { t } = useTranslation();

  const { storage } = useAppSelector((state) => state.system);

  if (!storage) {
    return null;
  }

  return (
    <Grid sx={materialStyles.infoBlock}>
      <CloudIcon sx={materialStyles.blockIcon} />
      <Grid sx={materialStyles.infoTextBlock}>
        <Typography sx={materialStyles.infoBlockTitle}>
          {t("common.storage")}
        </Typography>
        <ProgressBar
          progress={calculateUsagePercentage(storage.used, storage.total)}
        />
        <Typography sx={materialStyles.infoBlockValue}>
          {t("common.used_storage_value", {
            usedValue: perseBytesToGB(storage.used),
            maxValue: perseBytesToGB(storage.total),
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};
