import { Colors, HEADER_HEIGHT } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";
import { withHexOpacity } from "../../utils";

export const styles: ICssStyleType = {
  map: {
    height: "100vh",
    flex: 1,
  },
  filtersIcon: {
    color: Colors.white,
    width: "20px",
    height: "17px",
  },
};

export const materialStyles: IMaterialStyleType = {
  appImageList: {
    marginTop: "16px",
  },
  filtersBlock: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  mapFiltersButton: {
    backgroundColor: withHexOpacity(Colors.black, 0.3),
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: withHexOpacity(Colors.black, 0.3),
    },
  },
  mapFiltersContainer: {
    display: "flex",
    width: "100%",
    position: "absolute",
    marginTop: `${HEADER_HEIGHT + 38.9}px`,
    padding: "0 29px",
    zIndex: 9,
    justifyContent: "flex-end",
  },
};
