import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getAlbum } from "../store/thunks/albums";
import { setUserAlbum } from "../store/slices/userAlbum";

type TFetchUserAlbumById = {
  isEnable?: boolean;
  id: number | null;
};

export const useFetchUserAlbumById = ({
  isEnable,
  id,
}: TFetchUserAlbumById) => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { album } = useAppSelector((state) => state.userAlbum);

  const fetchAlbum = useCallback(async () => {
    if (!isAuth) {
      return;
    }

    if (!id) {
      return;
    }

    try {
      const response = await dispatch(getAlbum(id));
      if (!response) {
        throw new Error();
      }
      dispatch(setUserAlbum(response));
    } catch (error: any) {
      console.error("Error while [fetchAlbum]", error);
    }
  }, [dispatch, id, isAuth]);

  useEffect(() => {
    if (isEnable) {
      fetchAlbum();
    }
  }, [fetchAlbum, isEnable]);

  return { album };
};
