import { Box, Modal } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";
import { useAppSelector } from "../../../store";
import { CreateSupportRequestForm } from "./components/CreateSupportRequestForm";

type ICreateSupportRequestModal = {
  isOpen: boolean;
  onClose: () => void;
  type?: "CREATE";
};

export const CreateSupportRequestModal: React.FC<
  ICreateSupportRequestModal
> = ({ isOpen, onClose, type = "CREATE" }) => {
  const { loading } = useAppSelector((state) => state.loader);

  return (
    <Modal open={isOpen} onClose={onClose} sx={materialStyles.modal}>
      <Box sx={materialStyles.modalBox}>
        {type === "CREATE" ? (
          <CreateSupportRequestForm onClose={onClose} />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
