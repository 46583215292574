import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { materialStyles } from "./styles";
import { ChatUser } from "./components/ChatUser";
import { ChatMessages } from "./components/ChatMessages";
import { ChatSendInput } from "./components/ChatSendInput";
import { useAppDispatch, useAppSelector } from "../../store";
import { resetChat } from "../../store/slices/chat";

export const ChatComponent: React.FC = () => {
  const { user } = useAppSelector((state) => state.chat);
  const dispatch = useAppDispatch();

  const messagesScrollEndContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      dispatch(resetChat());
    };
  }, [dispatch]);

  return (
    <Box sx={materialStyles.chatContainer}>
      {user ? (
        <>
          <ChatUser />
          <ChatMessages
            messagesScrollEndContainerRef={messagesScrollEndContainerRef}
          />
          <ChatSendInput />
        </>
      ) : null}
    </Box>
  );
};
