import { Divider, Grid, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { materialStyles } from "./styles";
import { BlockWithTitle } from "../../blocks/blockWithTitle/BlockWithTitle";
import { useAppSelector } from "../../../store";
import { BlockWithTitleItem } from "../../blocks/blockWithTitle/BlockWithTitleItem";
import { BlockWithTitleDivider } from "../../blocks/blockWithTitle/BlockWithTitleDivider";
import { BlockWithTitleButton } from "../../blocks/blockWithTitle/BlockWithTitleButton";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../../hooks/useLogout";
import {
  IUserEditModalContentType,
  UserEditModal,
} from "../../modals/userEditModal";
import { DeleteAccountModal } from "../../modals/deleteAccountModal";
import { UserAvatar } from "../../user/UserAvatar";
import { SelectAvatarPhotoModal } from "../../modals/selectAvatarPhotoModal";

type IAccounInformation = {};

export const AccountInformation: React.FC<IAccounInformation> = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const [userEditModalOpenType, setUserEditModalOpenType] =
    useState<IUserEditModalContentType | null>(null);

  const [isUploadAvatarModalOpen, setIsUploadAvatarModalOpen] = useState(false);

  const [isUserDeleteAccountModalOpen, setIsUserDeleteAccountModalOpen] =
    useState(false);

  const { resetStorage } = useLogout();

  const onLogOut = useCallback(() => {
    resetStorage();
    navigate("/");
  }, [navigate, resetStorage]);

  const onOpenDeleteAccountModal = useCallback(() => {
    setIsUserDeleteAccountModalOpen(true);
  }, []);

  const onCloseDeleteAccountModal = useCallback(() => {
    setIsUserDeleteAccountModalOpen(false);
  }, []);

  const onOpenUseEditModal = useCallback((type: IUserEditModalContentType) => {
    setUserEditModalOpenType(type);
  }, []);

  const onCloseUserEditModal = useCallback(() => {
    setUserEditModalOpenType(null);
  }, []);

  const onUploadNewAvatarModalOpen = useCallback(() => {
    setIsUploadAvatarModalOpen(true);
  }, []);

  const onUploadNewAvatarModalClose = useCallback(() => {
    setIsUploadAvatarModalOpen(false);
  }, []);

  if (!user) {
    return null;
  }

  return (
    <>
      <Grid>
        <Typography sx={materialStyles.contentHeaderTitle}>
          {t("settings.account_information")}
        </Typography>
        <Grid sx={materialStyles.blocks}>
          <BlockWithTitle title={t("settings.account_details")}>
            <Grid sx={materialStyles.settingsAvatarBlock}>
              <UserAvatar user={user} type="IN_PEOPLE_LIST" />
            </Grid>
            <BlockWithTitleButton
              title={t("settings.upload_new_avatar")}
              onClick={onUploadNewAvatarModalOpen}
            />
            <BlockWithTitleDivider />
            <BlockWithTitleItem
              onClick={() => onOpenUseEditModal("USERNAME")}
              type="EDIT"
              title={user.username}
            />
            <BlockWithTitleDivider />
            <BlockWithTitleItem
              onClick={() => onOpenUseEditModal("EMAIL")}
              type="EDIT"
              title={user.email || t("settings.add_your_email")}
            />
            <BlockWithTitleDivider />
            <BlockWithTitleItem
              onClick={() => onOpenUseEditModal("PASSWORD")}
              type="EDIT"
              title={t("settings.password_with_stars")}
            />
            <BlockWithTitleDivider />
            <BlockWithTitleButton
              title={t("settings.log_out_of_all_devices")}
              onClick={onLogOut}
            />
            <BlockWithTitleDivider />
            <BlockWithTitleButton
              title={t("settings.delete_account")}
              onClick={onOpenDeleteAccountModal}
              buttonSx={materialStyles.deleteAccountButton}
            />
          </BlockWithTitle>
        </Grid>
        <Grid sx={materialStyles.accountInformationFooter}>
          <Divider sx={materialStyles.accountInformationFooterDivider} />
          <Typography sx={materialStyles.accountInformationFooterText}>
            {t("settings.to_manage_or_delete")}
          </Typography>
        </Grid>
      </Grid>
      <UserEditModal
        type={userEditModalOpenType}
        isOpen={Boolean(userEditModalOpenType)}
        onClose={onCloseUserEditModal}
      />
      <DeleteAccountModal
        isOpen={isUserDeleteAccountModalOpen}
        onClose={onCloseDeleteAccountModal}
      />
      <SelectAvatarPhotoModal
        initialType="UPLOAD_NEW_AVATAR"
        isOpen={isUploadAvatarModalOpen}
        onClose={onUploadNewAvatarModalClose}
      />
    </>
  );
};
