import { theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  appImageList: {
    marginTop: "16px",
  },
  buttonsHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  removeFromTrashButton: {
    textTransform: "none",
    letterSpacing: 0,

    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
  },
  restoreFromTrashButton: {
    textTransform: "none",
    letterSpacing: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "12px 24px",
    borderRadius: "30px",
    marginLeft: "24px",

    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
  },
  showOnMobile: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
    },
  },
};
