import { createSlice } from "@reduxjs/toolkit";
import { IUserType } from "./user";
import _ from "lodash";
import { IPhotoType } from "./myPhotos";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../constants/api";
import { catchChangesByKeys } from "../../utils";

interface IExploreSliceInitialState {
  subscriptions: {
    data: IUserType[];
    page: number;
    pageSize: number;
    totalElements: number;
  };
  subscribers: {
    data: IUserType[];
    page: number;
    pageSize: number;
    totalElements: number;
  };
  peoples: {
    data: IUserType[];
    page: number;
    pageSize: number;
    totalElements: number;
  };
  publicMedia: {
    data: IPhotoType[];
    page: number;
    pageSize: number;
  };
  allowedMedia: {
    data: IPhotoType[];
    page: number;
    pageSize: number;
  };
}

const exploreSliceInitialState: IExploreSliceInitialState = {
  subscriptions: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalElements: 0,
  },
  subscribers: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalElements: 0,
  },
  peoples: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalElements: 0,
  },
  publicMedia: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  allowedMedia: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
};

const exploreSlice = createSlice({
  name: "explore",
  initialState: exploreSliceInitialState,
  reducers: {
    setSubscribers(state, action) {
      const subscribers = state.subscribers.data;

      if (_.isEmpty(subscribers)) {
        state.subscribers.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy(
        [...state.subscribers.data, ...action.payload],
        "id",
      );
      state.subscribers.data = updatedItems;
    },
    resetExploreSubscribers(state) {
      state.subscribers.data = [];
      state.subscribers.page = DEFAULT_PAGE;
      state.subscribers.pageSize = DEFAULT_PAGE_SIZE;
    },
    setExploreSubscribersPage(state, action) {
      state.subscribers.page = action.payload;
    },
    setExploreSubscribersPageSize(state, action) {
      state.subscribers.pageSize = action.payload;
    },
    setExploreSubscribersTotalElements(state, action) {
      state.subscribers.totalElements = action.payload;
    },
    setSubscriptions(state, action) {
      const subscriptions = state.subscriptions.data;

      if (_.isEmpty(subscriptions)) {
        state.subscriptions.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy(
        [...state.subscriptions.data, ...action.payload],
        "id",
      );
      state.subscriptions.data = updatedItems;
    },
    resetExploreSubscriptions(state) {
      state.subscriptions.data = [];
      state.subscriptions.page = DEFAULT_PAGE;
      state.subscriptions.pageSize = DEFAULT_PAGE_SIZE;
    },
    setExploreSubscriptionsPage(state, action) {
      state.subscriptions.page = action.payload;
    },
    setExploreSubscriptionsPageSize(state, action) {
      state.subscriptions.pageSize = action.payload;
    },
    setExploreSubscriptionsTotalElements(state, action) {
      state.subscriptions.totalElements = action.payload;
    },
    setSubscription(state, action) {
      const newSubscriptions = [...state.subscriptions.data, action.payload];
      state.subscriptions.data = newSubscriptions;
      state.subscriptions.totalElements = state.subscriptions.totalElements + 1;
    },
    removeSubscription(state, action) {
      const filteredSubscriptions = _.filter(
        state.subscriptions.data,
        (subscription) => subscription.id !== action.payload,
      );
      state.subscriptions.data = filteredSubscriptions;
      state.subscriptions.totalElements = state.subscriptions.totalElements - 1;
    },
    removeAllMediaByUserId(state, action) {
      const filteredMedia = _.filter(
        state.allowedMedia.data,
        (media) => media.user?.id !== action.payload,
      );

      state.allowedMedia.data = filteredMedia;
    },

    setExplorePeoples(state, action) {
      const peoples = state.peoples.data;

      if (_.isEmpty(peoples)) {
        state.peoples.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy(
        [...state.peoples.data, ...action.payload],
        "id",
      );
      state.peoples.data = updatedItems;
    },
    resetExplorePeoples(state) {
      state.peoples.data = [];
      state.peoples.page = DEFAULT_PAGE;
      state.peoples.pageSize = DEFAULT_PAGE_SIZE;
      state.peoples.totalElements = 0;
    },
    setExplorePeoplesPage(state, action) {
      state.peoples.page = action.payload;
    },
    setExplorePeoplesPageSize(state, action) {
      state.peoples.pageSize = action.payload;
    },
    setExplorePeoplesTotalElements(state, action) {
      state.peoples.totalElements = action.payload;
    },
    resetExplore(state) {
      state.subscribers = exploreSliceInitialState.subscribers;
      state.subscriptions = exploreSliceInitialState.subscriptions;
      state.allowedMedia = exploreSliceInitialState.allowedMedia;
      state.publicMedia = exploreSliceInitialState.publicMedia;
    },
    setExplorePublicMedia(state, action) {
      const publicMedia = state.publicMedia.data;

      if (_.isEmpty(publicMedia)) {
        state.publicMedia.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy(
        [...state.publicMedia.data, ...action.payload],
        "id",
      );
      state.publicMedia.data = updatedItems;
    },
    setExploreAllowedMedia(state, action) {
      const allowedMedia = state.allowedMedia.data;

      if (_.isEmpty(allowedMedia)) {
        state.allowedMedia.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy([...allowedMedia, ...action.payload], "id");

      const itemsWithCheckedChanges = catchChangesByKeys(
        updatedItems,
        action.payload,
        ["is_favorite", "comments", "user"],
      );

      state.allowedMedia.data = itemsWithCheckedChanges;
    },
    removePhotoFromExplore(state, action) {
      const removedPhotos = _.filter(
        state.allowedMedia.data,
        (photo) => photo.id !== action.payload,
      );

      state.allowedMedia.data = removedPhotos;
    },
    resetExploreAllowedMedia(state) {
      state.allowedMedia.data = [];
      state.allowedMedia.page = DEFAULT_PAGE;
      state.allowedMedia.pageSize = DEFAULT_PAGE_SIZE;
    },
    resetExplorePublicMedia(state) {
      state.publicMedia.data = [];
      state.publicMedia.page = DEFAULT_PAGE;
      state.publicMedia.pageSize = DEFAULT_PAGE_SIZE;
    },
    setExploreAllowedMediaPage(state, action) {
      state.allowedMedia.page = action.payload;
    },
    setExplorePublicMediaPage(state, action) {
      state.publicMedia.page = action.payload;
    },
    setExploreAllowedMediaPageSize(state, action) {
      state.allowedMedia.pageSize = action.payload;
    },
    setExplorePublicMediaPageSize(state, action) {
      state.publicMedia.pageSize = action.payload;
    },
  },
});

export default exploreSlice.reducer;
export const {
  setSubscribers,
  resetExploreSubscribers,
  setExploreSubscribersPage,
  setExploreSubscribersPageSize,
  setExploreSubscribersTotalElements,
  setSubscriptions,
  resetExploreSubscriptions,
  setExploreSubscriptionsPage,
  setExploreSubscriptionsPageSize,
  setExploreSubscriptionsTotalElements,
  setExplorePeoples,
  setSubscription,
  removeSubscription,
  resetExplore,
  setExplorePublicMedia,
  setExploreAllowedMedia,
  setExploreAllowedMediaPage,
  setExplorePublicMediaPage,
  setExploreAllowedMediaPageSize,
  setExplorePublicMediaPageSize,
  resetExploreAllowedMedia,
  resetExplorePublicMedia,
  setExplorePeoplesPage,
  setExplorePeoplesPageSize,
  setExplorePeoplesTotalElements,
  resetExplorePeoples,
  removeAllMediaByUserId,
  removePhotoFromExplore,
} = exploreSlice.actions;
