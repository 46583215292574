import { AxiosError } from "axios";
import { IErrorType } from "../store/slices/errors";
import _ from "lodash";
import { IPhotoEmotionsType } from "../store/slices/myPhotos";
import i18n from "../assets/i18n";

export const UNKNOWN_ERROR: IErrorType = {
  message: i18n.t("errors.unknown_error"),
  error: "UNKNOWN_ERROR",
  statusCode: 0,
};

export const CODE_NOT_CORRECT_OR_EXPIRED_ERROR: IErrorType = {
  message: i18n.t("errors.code_not_valid_or_expired"),
  error: "CODE_NOT_CORRECT_OR_EXPIRED_ERROR",
  statusCode: 0,
};

export const wrapToAxiosError = (error?: IErrorType) => {
  if (!error) {
    return { response: { data: UNKNOWN_ERROR } };
  }

  return { response: { data: error } };
};

export const getResponseFromAxiosError = (error?: AxiosError) => {
  if (!error) {
    return UNKNOWN_ERROR;
  }

  if (!error.response) {
    return UNKNOWN_ERROR;
  }

  if (!error.response.data) {
    return UNKNOWN_ERROR;
  }

  return error.response.data as IErrorType;
};

export const getMessageFromResponseError = (error: IErrorType) => {
  switch (error.error) {
    case "CREDENTIALS_INVALID":
      return i18n.t("errors.user_not_exist");
    case "TOKEN_ALREADY_SENT":
      return i18n.t("errors.message_sending_limit_has_been_exceeded");
    case "TOKEN_INVALID":
      return i18n.t("errors.token_not_valid");
    default:
      if (_.isString(error.message)) {
        return error.message;
      }

      const stringMessage = _.first(error.message).message;

      return _.isString(stringMessage)
        ? stringMessage
        : (UNKNOWN_ERROR.message as string);
  }
};

export const isTokenExpired = (token?: string | null) => {
  if (!token) return true;
  const base64payload = token
    .split(".")[1]
    .replace(/-/g, "+")
    .replace(/_/g, "/");
  const jsonpayload = decodeURIComponent(
    atob(base64payload)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join(""),
  );
  const exp = JSON.parse(jsonpayload).exp;

  return !!exp && exp < Date.now() / 1000;
};

export const getTotalEmojiFromMedia = (emotions?: IPhotoEmotionsType) => {
  const values = _.values(emotions);

  let result = 0;

  _.forEach(values, (value) => {
    result += value;
  });

  return result;
};

export const catchChangesByKeys = (
  oldArray: any[],
  newArray: any[],
  keys: string[],
) => {
  const updatedArray = _.map(oldArray, (oldItem) => {
    const newItem = _.find(newArray, { id: oldItem.id });
    if (newItem) {
      let hasChanges = false;
      const updatedItem = { ...oldItem };

      _.forEach(keys, (key) => {
        if (
          oldItem[key] !== newItem[key] &&
          !_.isEqual(oldItem[key], newItem[key])
        ) {
          updatedItem[key] = newItem[key];
          hasChanges = true;
        }
      });

      if (hasChanges) {
        return updatedItem;
      }
    }

    return oldItem;
  });

  return _.cloneDeep(updatedArray);
};
