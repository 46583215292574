import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { materialStyles, styles } from "./styles";
import Map, {
  MapLayerMouseEvent,
  MapRef,
  Marker,
  ViewStateChangeEvent,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAP_BOX_ACCESS_TOKEN, MAP_BOX_STYLE_URL } from "../../constants";
import { IPhotoType } from "../../store/slices/myPhotos";
import Supercluster from "supercluster";
import { Grid, Typography } from "@mui/material";
import { ImageSlidesModal } from "../modals/imageSlidesModal";
import { AppMapSearchBox } from "./components/AppMapSearchBox";
import { TPrepareFileType } from "../../store/slices/uploadFile";
import { Image } from "../common/Image";
import { Video } from "../common/Video";
import { useAppDispatch } from "../../store";
import { initMediaSlider } from "../../store/slices/slider";

const MAX_ZOOM = 20;
const DEFAULT_ZOOM = 1.2;

type BoundsType = [number, number, number, number];

export type IMapClusterModalPoints = {
  id: number;
  url: string;
};

export type IMapMarker = {
  url: string;
  longitude: number;
  latitude: number;
  fileType: TPrepareFileType;
};

type IMapComponentProps = {
  containerStyle?: CSSProperties;
  photos?: IPhotoType[];
  markers?: IMapMarker[];
  onMarkerClick?: (photoId: number) => void;
  zoom?: number;
  initialLongitude?: number;
  initialLatitude?: number;
  onClosePhotoModal?: () => void;
  onMapClick?: (lng: number, lat: number) => void;
};

const supercluster = new Supercluster({
  radius: 40,
  maxZoom: MAX_ZOOM,
});

function calculateInitialZoom(width: number, height: number) {
  const baseZoom = DEFAULT_ZOOM;
  const widthFactor = width / 1024;

  return baseZoom * widthFactor;
}

export const MapComponent: React.FC<IMapComponentProps> = ({
  containerStyle,
  photos,
  markers,
  onMarkerClick: onMarkerClickFromProps,
  zoom = DEFAULT_ZOOM,
  initialLongitude,
  initialLatitude,
  onClosePhotoModal,
  onMapClick,
}) => {
  const dispatch = useAppDispatch();
  const [clusters, setClusters] = useState<
    | Supercluster.PointFeature<Supercluster.AnyProps>[]
    | Supercluster.ClusterFeature<Supercluster.AnyProps>[]
  >([]);
  const [points, setPoints] = useState<any>([]);
  const [bounds, setBounds] = useState<BoundsType>([-180, -85, 180, 85]);
  const [clusterZoom, setClusterZoom] = useState<number>(1);
  const [isOpenMediaSlides, setIsOpenMediaSlides] = useState(false);

  const [mapZoom, setMapZoom] = useState(zoom);

  const mapRef = useRef<MapRef>(null);

  const memoInitialLongitude = useMemo(
    () => initialLongitude || undefined,
    [initialLongitude],
  );

  const memoInitialLatitude = useMemo(
    () => initialLatitude || undefined,
    [initialLatitude],
  );

  useEffect(() => {
    const loadSuperclasterPoints = () => {
      try {
        if (!photos) {
          return;
        }

        const memoPoints = _.map(photos, (photo) => ({
          type: "Feature",
          properties: {
            id: photo.id,
            cluster: false,
            url: photo.thumbnail,
            longitude: photo.longitude,
            latitude: photo.latitude,
            photo,
          },
          geometry: {
            type: "Point",
            coordinates: [photo.longitude, photo.latitude],
          },
        }));

        setPoints(memoPoints);
      } catch (error) {
        console.error("Error while [loadSuperclaster]", error);
      }
    };

    loadSuperclasterPoints();
  }, [photos]);

  useEffect(() => {
    supercluster.load(points);
    setClusters(supercluster.getClusters(bounds, clusterZoom));
  }, [bounds, clusterZoom, points]);

  useEffect(() => {
    if (mapRef.current) {
      const mapBounds = mapRef.current
        .getMap()
        .getBounds()
        .toArray()
        .flat() as BoundsType;
      setBounds(mapBounds);
    }
  }, []);

  const onZoomEnd = useCallback((e: ViewStateChangeEvent) => {
    setClusterZoom(e.viewState.zoom);
  }, []);

  const onClusterClick = useCallback(
    (clusterId: number) => {
      if (!mapRef.current) {
        return;
      }

      const childrenPoints = supercluster.getLeaves(clusterId, 300);

      const parseChildrenPoints = _.chain(childrenPoints)
        .filter((point) => !point.properties.cluster)
        .map((point) => _.get(point, "properties.photo"))
        .value();

      dispatch(
        initMediaSlider({
          initialMedia: _.first(parseChildrenPoints),
          media: parseChildrenPoints,
        }),
      );

      setIsOpenMediaSlides(true);
    },
    [dispatch],
  );

  const onCloseMapClusterModal = useCallback(() => {
    onClosePhotoModal && onClosePhotoModal();
    setIsOpenMediaSlides(false);
  }, [onClosePhotoModal]);

  const onMarkerClick = useCallback(
    (photo: IPhotoType) => {
      onMarkerClickFromProps && onMarkerClickFromProps(photo.id);
      dispatch(initMediaSlider({ initialMedia: photo, media: [photo] }));
      setIsOpenMediaSlides(true);
    },
    [dispatch, onMarkerClickFromProps],
  );

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      onMapClick &&
        onMapClick(
          _.get(event, "lngLat.lng", 0),
          _.get(event, "lngLat.lat", 0),
        );
    },
    [onMapClick],
  );

  const onSelectPoint = useCallback(
    (lng: number, lat: number) => {
      mapRef.current?.flyTo({
        center: [lng, lat],
        essential: true,
        zoom: mapRef.current.getZoom(),
      });

      onMapClick && onMapClick(lng, lat);
    },
    [onMapClick],
  );

  useEffect(() => {
    if (zoom !== DEFAULT_ZOOM) {
      return;
    }

    const width = window.innerWidth;
    const height = window.innerHeight;

    const calculatedZoom = calculateInitialZoom(width, height);

    setTimeout(() => {
      setMapZoom(calculatedZoom);
    }, 500);
  }, [zoom]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    mapRef.current.zoomTo(mapZoom);
  }, [mapZoom]);

  return (
    <>
      <Map
        mapLib={import("mapbox-gl")}
        mapboxAccessToken={MAP_BOX_ACCESS_TOKEN}
        style={{ ...containerStyle }}
        mapStyle={MAP_BOX_STYLE_URL}
        maxZoom={MAX_ZOOM}
        initialViewState={{
          zoom: mapZoom,
          longitude: memoInitialLongitude,
          latitude: memoInitialLatitude,
        }}
        ref={mapRef}
        onZoomEnd={onZoomEnd}
        onClick={handleMapClick}
      >
        {onMapClick ? <AppMapSearchBox onSelectPoint={onSelectPoint} /> : null}
        {_.map(clusters, (cluster) => {
          try {
            const {
              cluster: isCluster,
              url,
              cluster_id: clusterId,
              point_count,
              id,
              photo,
            } = cluster.properties;

            const [longitude, latitude] = cluster.geometry.coordinates;

            if (isCluster && clusterId) {
              const childrenPoints = supercluster.getLeaves(clusterId);

              if (!childrenPoints) return;

              const childrenPoint = _.chain(childrenPoints)
                .filter((point) => !point.properties.cluster)
                .first()
                .value() as any;

              if (!childrenPoint) return;

              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  longitude={longitude}
                  latitude={latitude}
                  onClick={() => onClusterClick(clusterId)}
                >
                  <Grid>
                    <Grid sx={materialStyles.markerCountBlock}>
                      <Typography sx={materialStyles.markerCount}>
                        {point_count}
                      </Typography>
                    </Grid>
                    <img
                      style={styles.marker}
                      alt="marker"
                      src={childrenPoint.properties.url}
                    />
                  </Grid>
                </Marker>
              );
            }

            return (
              <Marker
                key={`marker-${id}`}
                longitude={longitude}
                latitude={latitude}
              >
                <img
                  onClick={() => onMarkerClick && onMarkerClick(photo)}
                  style={styles.marker}
                  alt="marker"
                  src={url}
                />
              </Marker>
            );
          } catch {}
        })}
        {_.map(markers, (marker, index) => (
          <Marker
            key={`marker-${index}`}
            longitude={marker.longitude}
            latitude={marker.latitude}
          >
            {marker.fileType === "IMAGE" ? (
              <Image style={styles.marker} alt="marker" src={marker.url} />
            ) : null}
            {marker.fileType === "VIDEO" ? (
              <Video
                autoPlay={false}
                muted
                style={styles.marker}
                src={marker.url}
                playsInline
              />
            ) : null}
          </Marker>
        ))}
        <ImageSlidesModal
          isOpen={isOpenMediaSlides}
          onClose={onCloseMapClusterModal}
        />
      </Map>
    </>
  );
};
