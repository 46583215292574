import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  imageList: {
    gridTemplateColumns: {
      xs: "repeat(2, 1fr) !important",
      sm: "repeat(2, 1fr) !important",
      md: "repeat(5, 1fr) !important",
    },
    gap: {
      xs: "7px !important",
      sm: "7px !important",
      md: "0px !important",
    },
  },
};
