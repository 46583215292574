import {
  Button,
  Grid,
  IconButton,
  Popover,
  PopoverOrigin,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { materialStyles } from "./styles";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { IMaterialStyleItem } from "../../types";
import { IEmotion, IEmotionsKeys, useEmotions } from "../../hooks/useEmotions";
import { IPhotoEmotionsType } from "../../store/slices/myPhotos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const DEFAULT_POPOVER_ORIGIN: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

type IFavoritesButton = {
  isFavorite?: boolean;
  onAddOrRemoveFavoritePhoto?: () => void;
  onAddEmoji?: () => void;
  iconButtonSx?: IMaterialStyleItem;
  iconButtonId?: string;
  anchorOrigin?: PopoverOrigin;
  photoEmotions?: IPhotoEmotionsType;
  emotionsOwn?: IEmotionsKeys;
  onSendEmotion?: (emotion: IEmotion, isActive: boolean) => void;
};

export const FavoritesButton: React.FC<IFavoritesButton> = ({
  isFavorite,
  onAddOrRemoveFavoritePhoto: onAddOrRemoveFromFavoritesFromProps,
  iconButtonSx,
  iconButtonId,
  anchorOrigin = DEFAULT_POPOVER_ORIGIN,
  photoEmotions,
  emotionsOwn,
  onSendEmotion,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const { emotions } = useEmotions();

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const onAddOrRemoveFromFavorites = useCallback(() => {
    handleClose();
    onAddOrRemoveFromFavoritesFromProps &&
      onAddOrRemoveFromFavoritesFromProps();
  }, [handleClose, onAddOrRemoveFromFavoritesFromProps]);

  return (
    <>
      <IconButton id={iconButtonId} sx={iconButtonSx} onClick={handleOpen}>
        {isFavorite ? (
          <FavoriteIcon sx={materialStyles.activeFavoriteIcon} />
        ) : (
          <FavoriteBorderIcon sx={materialStyles.favoriteIcon} />
        )}
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        slotProps={{
          paper: {
            sx: materialStyles.emojiPopoverContainer,
          },
        }}
      >
        <Grid sx={materialStyles.emojiWithAddToFavoritesButtonContainer}>
          <Grid sx={materialStyles.emojiListContainer}>
            {_.map(emotions, (emotion) => {
              const isActive = emotionsOwn === emotion.key;

              return (
                <IconButton
                  key={emotion.key}
                  onClick={() =>
                    onSendEmotion && onSendEmotion(emotion, isActive)
                  }
                  sx={[
                    materialStyles.emojiButton,
                    isActive && materialStyles.emojiActiveButton,
                  ]}
                >
                  <FontAwesomeIcon
                    scale={0.5}
                    width={20}
                    height={20}
                    icon={emotion.emotion as IconProp}
                  />
                  <Typography sx={materialStyles.emojiCount}>
                    {_.get(photoEmotions, emotion.key, 0)}
                  </Typography>
                </IconButton>
              );
            })}
          </Grid>
          <Button
            onClick={onAddOrRemoveFromFavorites}
            endIcon={isFavorite ? <CloseIcon /> : <AddIcon />}
            sx={materialStyles.addToFavoritesButton}
          >
            {isFavorite
              ? t("photos.remove_from_favorites")
              : t("photos.add_to_favorites")}
          </Button>
        </Grid>
      </Popover>
    </>
  );
};
