import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getUserPhotosById } from "../store/thunks/user";
import {
  resetProfileUserPhotos,
  setProfileUserPhotos,
  setProfileUserPhotosPage,
  setProfileUserPhotosPageSize,
} from "../store/slices/userProfile";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../constants/api";

type TFetchUserPhotosById = {
  isEnable?: boolean;
  id: number | null;
};

export const useFetchUserPhotosById = ({
  isEnable,
  id,
}: TFetchUserPhotosById) => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { userPhotos } = useAppSelector((state) => state.userProfile);

  const fetchUserPhotos = useCallback(
    async (page?: number, pageSize?: number) => {
      if (!isAuth) {
        return;
      }

      if (!id) {
        return;
      }

      try {
        const response = await dispatch(
          getUserPhotosById({ id, page, pageSize })
        );

        if (!response) {
          throw new Error();
        }

        dispatch(setProfileUserPhotos(response));
      } catch (error: any) {
        console.error("Error while [fetchUserPhotos]", error);
      }
    },
    [dispatch, id, isAuth]
  );

  const refetchUserPhotos = useCallback(() => {
    fetchUserPhotos(userPhotos.page);
  }, [fetchUserPhotos, userPhotos.page]);

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setProfileUserPhotosPage(userPhotos.page + 1));
    dispatch(setProfileUserPhotosPageSize(DEFAULT_PAGE_SIZE));
  }, [userPhotos.page, dispatch]);

  useEffect(() => {
    if (isEnable) {
      fetchUserPhotos();
    }

    return () => {
      dispatch(resetProfileUserPhotos());
    };
  }, [dispatch, fetchUserPhotos, isEnable]);

  useEffect(() => {
    if (userPhotos.page !== DEFAULT_PAGE) {
      fetchUserPhotos(userPhotos.page, userPhotos.pageSize);
    }
  }, [userPhotos.page, userPhotos.pageSize, fetchUserPhotos]);

  return {
    userPhotos: userPhotos.data,
    onUpdatePageOrPageSize,
    refetchUserPhotos,
  };
};
