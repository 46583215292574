import { createSlice } from "@reduxjs/toolkit";

export enum ERROR_STATUS_CODES {
  NOT_FOUND = 404,
}

export type IErrorType = {
  message: string | any[];
  error: string;
  statusCode: number;
  code?: string;
};

interface IErrorSliceInitialState {
  error: IErrorType | null;
}

const errorSliceInitialState: IErrorSliceInitialState = {
  error: null,
};

const errorSlice = createSlice({
  name: "error",
  initialState: errorSliceInitialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export default errorSlice.reducer;
export const { setError } = errorSlice.actions;
