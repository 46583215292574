import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  peoplesListWithTitleContainer: {
    marginTop: "24px",
  },
  peoplesListContainer: {
    overflow: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  peopleConteiner: {
    minWidth: "110px",
    maxWidth: "110px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "12px",
    overflow: "hidden",
  },
  listTitle: {
    color: theme.palette.grey[700],
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  avatarContainer: {
    padding: "14px 16px 16px 16px",
  },
  avatarUsername: {
    fontSize: "14px",
    lineHeight: "22px",
    color: Colors.white,
    fontWeight: 700,
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  listEmptyText: {
    color: theme.palette.grey[900],
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: "8px",
  },
};
