import { theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBox: {
    backgroundColor: theme.palette.background.default,
    border: "none",
    borderRadius: "6px",
    width: {
      xs: `98vw`,
      sm: `98vw`,
      md: "460px",
    },
    display: "flex",
    position: "relative",
  },
};
