import React, { useCallback, useMemo, useState } from "react";
import { IUserModalType } from "..";
import { Box, Button, Grid, IconButton, Typography, Tab } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { materialStyles } from "./styles";
import { FullSizeModalHeader } from "../../../header/FullSizeModalHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UserAvatar } from "../../../user/UserAvatar";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useTranslation } from "react-i18next";
import {
  subscribeOnUser,
  unsubscribeOnUser,
} from "../../../../store/thunks/explore";
import {
  removeSubscription,
  setSubscription,
} from "../../../../store/slices/explore";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import { ComplaintModal } from "../../complaintModal";
import { setActionId } from "../../../../store/slices/complaint";
import { AppImageList } from "../../../lists/imageList/AppImageList";
import {
  setSubscribe,
  updateUserPhotosInStorage,
} from "../../../../store/slices/userProfile";
import { IPhotoType } from "../../../../store/slices/myPhotos";
import { AppAlbumList } from "../../../lists/albumLists/AppAlbumList";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { BlockEmptyWithIcon } from "../../../blocks/blockEmpty/BlockEmptyWithIcon";
import _ from "lodash";
import { useFetchUserAlbumsById } from "../../../../hooks/useFetchUserAlbumsById";
import { useFetchUserPhotosById } from "../../../../hooks/useFetchUserPhotosById";
import { WithHandleScrollGrid } from "../../../helpers/WithHandleScrollGrid";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { getChat } from "../../../../store/thunks/chats";
import { ChatWithUserNotFoundError } from "../../../../store/classes";
import { addDraftChatMessage } from "../../../../store/slices/messages";
import { getDraftChatInstance } from "../../../../utils";
import { setChatUser } from "../../../../store/slices/chat";
import { useNavigate } from "react-router-dom";

type IUserProfileContent = {
  onClose: () => void;
  onChangeUserModalType: (type: IUserModalType) => void;
};

type IUserTab = "PHOTOS" | "ALBUMS";

export const UserProfileContent: React.FC<IUserProfileContent> = ({
  onClose,
  onChangeUserModalType,
}) => {
  const navigate = useNavigate();
  const { user, id } = useAppSelector((state) => state.userProfile);

  const { user: myUserProfile } = useAppSelector((state) => state.user);
  const [tabValue, setTabValue] = useState<IUserTab>("PHOTOS");

  const { userAlbums, onUpdatePageOrPageSize: onUpdateAlbumsPageOrPageSize } =
    useFetchUserAlbumsById({ isEnable: true, id });

  const {
    userPhotos,
    onUpdatePageOrPageSize: onUpdatePhotosPageOrPageSize,
    refetchUserPhotos,
  } = useFetchUserPhotosById({ isEnable: true, id });

  const [isUserComplaintModalOpen, setIsUserComplaintModalOpen] =
    useState(false);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const isSubscribed = useMemo(
    () => Boolean(user?.is_subscribed),
    [user?.is_subscribed],
  );

  const onSubscribe = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      await dispatch(subscribeOnUser(id));
      dispatch(setSubscribe(true));
      dispatch(setSubscription(user));
    } catch (error) {
      console.error("Error while onSubscribe", error);
    }
  }, [dispatch, id, user]);

  const onUnsubscribe = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      await dispatch(unsubscribeOnUser(id));
      dispatch(setSubscribe(false));
      dispatch(removeSubscription(id));
    } catch (error) {
      console.error("Error while onUnsubscribe", error);
    }
  }, [dispatch, id]);

  const onOpenUserComplaintModal = useCallback(() => {
    dispatch(setActionId(id));
    setIsUserComplaintModalOpen(true);
  }, [dispatch, id]);

  const onCloseUserComplaintModal = useCallback(() => {
    setIsUserComplaintModalOpen(false);
  }, []);

  const onUpdatePhotoToFavorites = useCallback(
    (photo: IPhotoType) => {
      dispatch(updateUserPhotosInStorage(photo));
    },
    [dispatch],
  );

  const handleTabChange = useCallback((event: any, value: IUserTab) => {
    setTabValue(value);
  }, []);

  const onUpdatePageOrPageSize = useCallback(() => {
    switch (tabValue) {
      case "ALBUMS":
        onUpdateAlbumsPageOrPageSize();
        break;
      case "PHOTOS":
        onUpdatePhotosPageOrPageSize();
        break;
    }
  }, [onUpdateAlbumsPageOrPageSize, onUpdatePhotosPageOrPageSize, tabValue]);

  const onGoToChatWithUser = useCallback(async () => {
    try {
      if (!user) {
        return;
      }

      const data = await dispatch(getChat(user.id));

      if (!data) {
        throw new Error();
      }

      if (_.isEmpty(data)) {
        const draftChatInstance = getDraftChatInstance(user, myUserProfile);
        dispatch(addDraftChatMessage(draftChatInstance));
      }

      dispatch(setChatUser(user));

      onClose();
      navigate("/messages");
    } catch (error: any) {
      if (error instanceof ChatWithUserNotFoundError) {
        const draftChatInstance = getDraftChatInstance(user, myUserProfile);
        dispatch(addDraftChatMessage(draftChatInstance));
        dispatch(setChatUser(draftChatInstance.user));

        onClose();
        navigate("/messages");
        return;
      }
      console.error("Error while [onGoToChatWithUser]", error);
    }
  }, [dispatch, myUserProfile, navigate, onClose, user]);

  if (!user) {
    return null;
  }

  return (
    <Grid sx={materialStyles.innerModalForm}>
      <FullSizeModalHeader
        Icon={<ArrowBackIcon sx={materialStyles.headerIcon} />}
        onIconClick={onClose}
      >
        {myUserProfile ? (
          <Grid sx={materialStyles.headerBlock}>
            <IconButton onClick={onGoToChatWithUser}>
              <ChatOutlinedIcon sx={materialStyles.headerIcon} />
            </IconButton>
            <IconButton onClick={onOpenUserComplaintModal}>
              <ReportGmailerrorredOutlinedIcon sx={materialStyles.headerIcon} />
            </IconButton>
            <Button
              sx={[
                materialStyles.subscribeButton,
                isSubscribed && materialStyles.unsubscribeButton,
              ]}
              startIcon={
                isSubscribed ? (
                  <CloseIcon sx={materialStyles.headerIcon} />
                ) : (
                  <DoneIcon sx={materialStyles.headerIcon} />
                )
              }
              onClick={isSubscribed ? onUnsubscribe : onSubscribe}
            >
              {isSubscribed
                ? t("user_profile.unsubscribe")
                : t("user_profile.subscribe")}
            </Button>
          </Grid>
        ) : null}
      </FullSizeModalHeader>
      <WithHandleScrollGrid
        onEndReached={onUpdatePageOrPageSize}
        sx={materialStyles.mainBlock}
      >
        <Grid sx={materialStyles.userProfileInfoBlock}>
          <UserAvatar type="IN_PEOPLE_LIST" user={user} />
          <Typography sx={materialStyles.userProfileName}>
            {user.username}
          </Typography>
          {user.email ? (
            <Typography sx={materialStyles.userProfileEmail}>
              {user.email}
            </Typography>
          ) : null}
          <Grid sx={materialStyles.userSocialInfo}>
            <Typography sx={materialStyles.userSocialInfoText}>{`${t(
              "user_profile.followers_count",
              {
                count: user.subscribers_count,
              },
            )}`}</Typography>
            <Grid sx={materialStyles.userSocialInfoDot} />
            <Typography sx={materialStyles.userSocialInfoText}>{`${t(
              "user_profile.following_count",
              {
                count: user.subscriptions_count,
              },
            )}`}</Typography>
          </Grid>
        </Grid>
        <TabContext value={tabValue}>
          <Box>
            <TabList onChange={handleTabChange} centered>
              <Tab
                sx={materialStyles.tabContainer}
                label={t("user_profile.photos_count", {
                  count: userPhotos.length,
                })}
                value="PHOTOS"
              />
              <Tab
                sx={materialStyles.tabContainer}
                label={t("user_profile.albums_count", {
                  count: userAlbums.length,
                })}
                value="ALBUM"
              />
            </TabList>
          </Box>
          <TabPanel sx={materialStyles.tabPanelContainer} value="PHOTOS">
            {!_.isEmpty(userPhotos) ? (
              <AppImageList
                onUpdatePhotoToFavorites={onUpdatePhotoToFavorites}
                enableOpenPhoto
                photos={userPhotos}
                onClosePhotoModal={refetchUserPhotos}
              />
            ) : null}
            {_.isEmpty(userPhotos) ? (
              <BlockEmptyWithIcon title={t("user_profile.user_photo_list")} />
            ) : null}
          </TabPanel>
          <TabPanel sx={materialStyles.tabPanelContainer} value="ALBUM">
            {!_.isEmpty(userAlbums) ? (
              <AppAlbumList albums={userAlbums} />
            ) : null}
            {_.isEmpty(userAlbums) ? (
              <BlockEmptyWithIcon title={t("user_profile.user_album_list")} />
            ) : null}
          </TabPanel>
        </TabContext>
      </WithHandleScrollGrid>
      <ComplaintModal
        isOpen={isUserComplaintModalOpen}
        onClose={onCloseUserComplaintModal}
        type="USER"
      />
    </Grid>
  );
};
