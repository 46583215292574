import { useCallback, useEffect, useReducer } from "react";
import { socket } from "../socket";
import { IHandledEmotion } from "./useUserPhotoSocketEmotions";
import { IEmotion, IEmotionsKeys } from "./useEmotions";
import { IPhotoEmotionsType } from "../store/slices/myPhotos";
import _ from "lodash";
import { useAppSelector } from "../store";

type TLocalProccessSocketEmotions = {
  id?: number | null;
  emotionsOwn?: IEmotionsKeys;
  emotions?: IPhotoEmotionsType;
};

type ILocalEmotionState = {
  emotionsOwn?: IEmotionsKeys;
  emotions?: IPhotoEmotionsType;
};

type ILocalEmotionAction =
  | {
      type: "ADD_EMOTION" | "REMOVE_EMOTION" | "SET_OWN_EMOTION";
      key?: IEmotionsKeys;
    }
  | {
      type: "RESET_STATE";
      emotionsOwn?: IEmotionsKeys;
      emotions?: IPhotoEmotionsType;
    };

const localEmotionReducer = (
  state: ILocalEmotionState,
  action: ILocalEmotionAction
) => {
  switch (action.type) {
    case "ADD_EMOTION":
      if (state.emotions && action.key) {
        let emotionCount = state.emotions[action.key];
        return {
          ...state,
          emotions: {
            ...state.emotions,
            [action.key]: emotionCount ? emotionCount + 1 : 1,
          },
        };
      }
      return state;
    case "REMOVE_EMOTION":
      if (state.emotions && action.key) {
        let emotionCount = state.emotions[action.key];
        return {
          ...state,
          emotions: {
            ...state.emotions,
            [action.key]: emotionCount - 1,
          },
        };
      }
      return state;
    case "SET_OWN_EMOTION":
      if (_.first(state.emotionsOwn) === action.key) {
        return {
          ...state,
          emotionsOwn: undefined,
        };
      }

      return {
        ...state,
        emotionsOwn: action.key ? action.key : undefined,
      };
    case "RESET_STATE":
      return {
        emotions: action.emotions,
        emotionsOwn: action.emotionsOwn,
      };
    default:
      throw new Error(`Unknown action ${action}`);
  }
};

export const useLocalProccessSocketEmotions = ({
  id,
  emotionsOwn,
  emotions,
}: TLocalProccessSocketEmotions) => {
  const { isConnected } = useAppSelector((state) => state.socket);
  const { id: userId } = useAppSelector((state) => state.user);
  const [emotionsLocalState, dispatchLocalEmotions] = useReducer(
    localEmotionReducer,
    {
      emotions,
      emotionsOwn,
    }
  );

  const handleAddEmotion = useCallback(
    (emotion: IHandledEmotion) => {
      if (emotion.media === id) {
        dispatchLocalEmotions({ type: "ADD_EMOTION", key: emotion.emotion });

        if (emotion.user === userId) {
          dispatchLocalEmotions({
            type: "SET_OWN_EMOTION",
            key: emotion.emotion,
          });
        }
      }
    },
    [id, userId]
  );

  const handleRemoveEmotion = useCallback(
    (emotion: IHandledEmotion) => {
      if (emotion.media === id) {
        dispatchLocalEmotions({ type: "REMOVE_EMOTION", key: emotion.emotion });

        if (emotion.user === userId) {
          dispatchLocalEmotions({ type: "SET_OWN_EMOTION" });
        }
      }
    },
    [id, userId]
  );

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    socket.on("media:emotion", handleAddEmotion);
    socket.on("media:emotion-remove", handleRemoveEmotion);

    return () => {
      if (isConnected) {
        socket.off("media:emotion", handleAddEmotion);
        socket.off("media:emotion-remove", handleRemoveEmotion);
      }
    };
  }, [handleAddEmotion, handleRemoveEmotion, isConnected]);

  const onSendEmotion = useCallback(
    (emotion: IEmotion, isActive: boolean) => {
      if (!isConnected) {
        return;
      }

      if (isActive) {
        socket.emit("media:emotion-remove", {
          emotion: emotion.key,
          media: id,
        });
        return;
      }
      socket.emit("media:emotion", { emotion: emotion.key, media: id });
    },
    [id, isConnected]
  );

  useEffect(() => {
    if (id) {
      dispatchLocalEmotions({ type: "RESET_STATE", emotions, emotionsOwn });
    }
  }, [emotions, emotionsOwn, id]);

  return {
    localEmotions: emotionsLocalState,
    onSendEmotion,
  };
};
