import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  blockEmptyWithAdd: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  blockEmptyWithAddButton: {
    backgroundColor: theme.palette.error.main,
    width: "56px",
    height: "56px",

    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  blockEmptyWithAddIcon: {
    color: Colors.white,
  },
  blockEmptyTitle: {
    fontSize: "20px",
    lineHeight: "28px",
    color: theme.palette.grey[700],
    textAlign: "center",
    marginTop: "25px",
  },
  blockEmptyWithIcon: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
};
