import { Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type IBlockTextContent = {
  content: string;
};

export const BlockTextContent: React.FC<IBlockTextContent> = ({ content }) => {
  return (
    <Typography sx={materialStyles.blockTextContent}>{content}</Typography>
  );
};
