import { theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  drawerContainer: {
    backgroundColor: theme.palette.background.default,
    width: {
      xs: "100%",
      sm: "100%",
      md: "440px",
    },
  },
};
