import { Colors } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  drawerContainer: {
    zIndex: 20,
  },
  filtersMainTitle: {
    color: Colors.white,
    fontSize: "28px",
    fontWeight: "bold",
    padding: "16px",
  },
};
