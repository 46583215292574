import { Colors, theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  autocompletePaper: {
    backgroundColor: theme.palette.grey[600],
    marginTop: "8px",

    "& .MuiAutocomplete-noOptions": {
      color: Colors.white,
    },

    "& .MuiAutocomplete-loading": {
      color: Colors.white,
    },
  },
  filtersAutocompleteInput: {
    border: `0px solid ${theme.palette.background.default} !important`,
    backgroundColor: `${theme.palette.grey["A400"]}`,
    borderRadius: "10px",
    color: Colors.white,

    "& input": {
      color: Colors.white,
    },

    "& fieldset": {
      border: "none",
    },

    "& ::placeholder": {
      color: `${theme.palette.grey["A700"]}`,
      opacity: 1,
    },
  },
};
