import { Grid, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import React from "react";

type IPageTitleProps = {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
};

export const PageTitle: React.FC<IPageTitleProps> = ({
  title,
  children,
  subtitle,
}) => {
  return (
    <Grid sx={materialStyles.pageTitleContainer}>
      <Grid sx={materialStyles.pageTitleBlock}>
        <Typography sx={materialStyles.pageTitle}>{title}</Typography>
        {subtitle ? (
          <Typography sx={materialStyles.pageSubtitle}>{subtitle}</Typography>
        ) : null}
      </Grid>
      {children ? children : null}
    </Grid>
  );
};
