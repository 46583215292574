import React from "react";
import { useAppSelector } from "../../store";
import { NotFound } from "../../pages/NotFound";

type IWithGuard = {
  children: React.ReactNode;
};

export const WithGuard: React.FC<IWithGuard> = ({ children }) => {
  const { user } = useAppSelector((state) => state.user);

  if (!user) {
    return <NotFound />;
  }

  return <>{children}</>;
};
