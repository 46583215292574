import { IMaterialStyleType, ICssStyleType } from "../../types";
import { Colors, theme } from "../../theme";

export const styles: ICssStyleType = {};

export const USER_AVATAR_BACKGROUND = theme.palette.primary.light;

export const materialStyles: IMaterialStyleType = {
  toaster: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: 2,
    gap: 1,
  },
  toastIcon: {
    marginTop: "2px",
    minWidth: "20px",
    minHeight: "20px",
  },
  closeIconСontainer: {
    width: "14px",
    height: "14px",
    color: Colors.white,
    position: "absolute",
    right: "6px",
    top: "6px",
  },
  closeIcon: {
    width: "14px",
    height: "14px",
    color: Colors.white,
  },
  signUpButton: {
    mt: 1,
    p: 0.3,
    borderRadius: "4px",
    backgroundColor: theme.palette.success.light,
    textTransform: "none",

    "&:hover": {
      backgroundColor: theme.palette.success.light,
    },
  },
};
