import { Box, IconButton, Modal } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { materialStyles } from "./styles";
import { LoginForm } from "./components/LoginForm";
import { RegistrationForm } from "./components/RegistrationForm";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";
import { useAppDispatch, useAppSelector } from "../../../store";
import { CodeForm } from "./components/CodeForm";
import { FinalRegistrationForm } from "./components/FinalRegistrationForm";
import { ForgotPasswordForm } from "./components/ForgotPasswordForm";
import { NewPasswordForm } from "./components/NewPasswordForm";
import { CongratulationsForm } from "./components/CongratulationsForm";
import { resetRegistrationInfo } from "../../../store/slices/registration";
import { SetUserNicknameForm } from "./components/SetUserNicknameForm";
import CloseIcon from "@mui/icons-material/Close";

export type IAuthModalType =
  | "LOGIN"
  | "SIGNUP"
  | "CODE_EMAIL"
  | "CODE_PHONE"
  | "FORGOT_PASSWORD_CODE_EMAIL"
  | "FORGOT_PASSWORD_CODE_PHONE"
  | "FINAL_SIGNUP"
  | "FORGOT_PASSWORD"
  | "NEW_PASSWORD"
  | "RESET_PASSWORD_CONGRATULATIONS"
  | "SET_USER_NICKNAME";

type IAuthModal = {
  isOpen: boolean;
  onClose: () => void;
  initialModalType?: IAuthModalType;
};

export const AuthModal: React.FC<IAuthModal> = ({
  isOpen,
  onClose,
  initialModalType = "LOGIN",
}) => {
  const [authModalType, setAuthModalType] =
    useState<IAuthModalType>(initialModalType);

  const { loading } = useAppSelector((state) => state.loader);
  const dispatch = useAppDispatch();

  const onChangeAuthModalType = useCallback((type: IAuthModalType) => {
    setAuthModalType(type);
  }, []);

  useEffect(() => {
    setAuthModalType(initialModalType);
  }, [isOpen, initialModalType]);

  const onCloseModal = useCallback(() => {
    dispatch(resetRegistrationInfo());
    onClose();
  }, [dispatch, onClose]);

  return (
    <Modal open={isOpen} onClose={onCloseModal} sx={materialStyles.authModal}>
      <Box sx={materialStyles.authModalBox}>
        <IconButton onClick={onCloseModal} sx={materialStyles.closeButton}>
          <CloseIcon />
        </IconButton>
        {authModalType === "LOGIN" ? (
          <LoginForm
            onClose={onCloseModal}
            onChangeAuthModalType={onChangeAuthModalType}
          />
        ) : null}
        {authModalType === "SIGNUP" ? (
          <RegistrationForm
            onClose={onCloseModal}
            onChangeAuthModalType={onChangeAuthModalType}
          />
        ) : null}
        {authModalType === "CODE_EMAIL" ? (
          <CodeForm
            type="EMAIL"
            onClose={onCloseModal}
            onChangeAuthModalType={onChangeAuthModalType}
          />
        ) : null}
        {authModalType === "CODE_PHONE" ? (
          <CodeForm
            type="PHONE"
            onClose={onCloseModal}
            onChangeAuthModalType={onChangeAuthModalType}
          />
        ) : null}
        {authModalType === "FINAL_SIGNUP" ? (
          <FinalRegistrationForm
            onClose={onCloseModal}
            onChangeAuthModalType={onChangeAuthModalType}
          />
        ) : null}
        {authModalType === "FORGOT_PASSWORD" ? (
          <ForgotPasswordForm
            onClose={onCloseModal}
            onChangeAuthModalType={onChangeAuthModalType}
          />
        ) : null}
        {authModalType === "FORGOT_PASSWORD_CODE_EMAIL" ? (
          <CodeForm
            type="EMAIL"
            onClose={onCloseModal}
            onChangeAuthModalType={onChangeAuthModalType}
            isResetPassword
          />
        ) : null}
        {authModalType === "FORGOT_PASSWORD_CODE_PHONE" ? (
          <CodeForm
            type="PHONE"
            onClose={onCloseModal}
            onChangeAuthModalType={onChangeAuthModalType}
            isResetPassword
          />
        ) : null}
        {authModalType === "NEW_PASSWORD" ? (
          <NewPasswordForm
            onClose={onCloseModal}
            onChangeAuthModalType={onChangeAuthModalType}
          />
        ) : null}
        {authModalType === "RESET_PASSWORD_CONGRATULATIONS" ? (
          <CongratulationsForm
            onClose={onCloseModal}
            onChangeAuthModalType={onChangeAuthModalType}
          />
        ) : null}
        {authModalType === "SET_USER_NICKNAME" ? (
          <SetUserNicknameForm
            onClose={onCloseModal}
            onChangeAuthModalType={onChangeAuthModalType}
          />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
