import { IMaterialStyleType, ICssStyleType } from "../../types";
import { Colors, theme } from "../../theme";
import { HEADER_HEIGHT } from "../../theme";
import { withHexOpacity } from "../../utils";

export const styles: ICssStyleType = {
  filtersIcon: {
    color: Colors.white,
    width: "20px",
    height: "17px",
  },
};

export const materialStyles: IMaterialStyleType = {
  appHeader: {
    position: "absolute",
    display: "flex",
    height: HEADER_HEIGHT,
    backgroundColor: theme.palette.background.default,
    width: "100%",
    zIndex: 21,
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: {
      xs: "8px",
      sm: "12px",
      md: "38px",
    },
    paddingRight: {
      xs: "16px",
      sm: "16px",
      md: "38px",
    },
    flexDirection: "row",
    alignItems: "center",
  },
  headerActionBlock: {
    marginLeft: {
      xs: "0px",
      sm: "0px",
      md: "70px",
    },
    alignItems: "center",
    justifyContent: "flex-end",
  },
  searchInput: {
    border: `0px solid ${theme.palette.background.default} !important`,
    backgroundColor: `${theme.palette.grey[100]}`,
    borderRadius: "10px",
    color: Colors.white,

    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },

    "& input": {
      color: Colors.white,
    },

    "& fieldset": {
      border: "none",
    },

    "& ::placeholder": {
      color: `${theme.palette.grey[200]}`,
      opacity: 1,
    },

    "& .MuiOutlinedInput-root": {
      paddingRight: "10px !important",
    },
  },
  logoContainer: {
    overflow: "hidden",

    width: {
      xs: "35px",
      sm: "35px",
      md: "173px",
    },
    cursor: "pointer",
  },
  searchIcon: {
    fill: `${theme.palette.grey[200]}`,
    marginRight: "6px",
  },
  userBlock: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "16px",
    alignItems: "center",
  },
  userName: {
    fontSize: "14px",
    lineHeight: "20px",
    color: Colors.white,
  },
  userAvatar: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    marginLeft: "16px",
    backgroundColor: "#ADCBFA", // TODO: Add this color if need to theme
  },
  themeBlock: {
    marginLeft: {
      xs: 0,
      sm: 0,
      md: "41px",
    },
    display: "flex",
    flexDirection: "row",
  },
  themeIcon: {
    fill: Colors.white,
    width: "16px",
    height: "16px",
  },
  uploadButton: {
    backgroundColor: theme.palette.success.main,
    height: "37px",
    textTransform: "none",
    color: Colors.white,
    fontSize: "14px",
    lineHeight: "20px",
    paddingLeft: "23px",
    paddingRight: "23px",
    borderRadius: "8px",
    fontWeight: 500,
    marginLeft: {
      xs: 0,
      sm: 0,
      md: "16px",
    },
    minWidth: "auto",

    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  loginButton: {
    backgroundColor: theme.palette.background.default,
    color: Colors.white,
    fontSize: "14px",
    lineHeight: "20px",
    boxShadow: "none",
    textTransform: "none",
    whiteSpace: "nowrap",
    marginLeft: "16px",

    padding: {
      xs: 0,
      sm: 0,
      md: "6px 16px",
    },

    minWidth: {
      xs: 0,
      sm: 0,
      md: "61px",
    },

    "& p": {
      display: {
        xs: "none",
        sm: "none",
        md: "flex",
      },
    },

    "&:hover": {
      backgroundColor: theme.palette.background.default,
      boxShadow: "none",
    },
  },
  fullSizeModalHeader: {
    display: "flex",
    backgroundColor: theme.palette.grey[600],
    padding: "24px 36px 24px 36px",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  fullSizeModalHeaderWithGradient: {
    background: `linear-gradient(180deg, ${Colors.black} 0%, rgba(0, 0, 0, 0) 100%)`,
    position: "absolute",
    width: "100%",
    top: 0,
    zIndex: 100,
  },
  toggleButtonsInHeader: {
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
  },
  fullSizeModalPositionRelative: {
    position: "relative",
  },
  searchPaper: {
    backgroundColor: theme.palette.grey[600],
    marginTop: "8px",

    "& .MuiAutocomplete-noOptions": {
      color: Colors.white,
    },

    "& .MuiAutocomplete-loading": {
      color: Colors.white,
    },
  },
  searchOption: {
    gap: "8px",
  },
  searchUserName: {
    color: Colors.white,
  },
  whiteSearchIcon: {
    color: Colors.white,
    width: "20px",
    height: "20px",
  },
  mobileSearchButton: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
    },
  },
  mobileSideMenuIcon: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
    },
  },
  sideMenuIcon: {
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
  },
  mapFiltersButton: {
    backgroundColor: {
      xs: "transparent",
      sm: "transparent",
      md: withHexOpacity(Colors.black, 0.3),
    },
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: {
        xs: "transparent",
        sm: "transparent",
        md: withHexOpacity(Colors.black, 0.3),
      },
    },
  },
  showOnMobile: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
    },
  },
  hideOnMobile: {
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
  },
  rightFullSizeHeaderComponent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  messagesHeaderContainer: {
    display: "flex",
    padding: {
      xs: "8px 12px",
      sm: "56px 8px 16px",
      md: "20px 24px",
    },
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    flexDirection: "row",
    alignItems: "center",
  },
  messagesHeaderCounter: {
    backgroundColor: theme.palette.error.main,
    borderRadius: "12px",
    width: "30px",
    color: Colors.white,
    textAlign: "center",
    marginLeft: "8px",
  },
  messagesHeaderTitle: {
    fontSize: "30px",
    color: Colors.white,
    fontWeight: "700",
    lineHeight: "52px",
  },
  modalHeaderBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeaderTitle: {
    color: Colors.white,
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 700,
  },
  modalHeaderSubtitle: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[900],
    textAlign: "center",
  },
};
