import { useCallback, useState } from "react";
import { getGlobeMediaById } from "../store/thunks/globe";
import { useAppDispatch, useAppSelector } from "../store";
import {
  setUserPhoto,
  setSharingGroups,
  setMediaComments,
} from "../store/slices/userPhoto";
import {
  getMediaComments,
  getMediaSharingGroupsById,
} from "../store/thunks/media";

export const useUserPhoto = () => {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);

  const getUserPhoto = useCallback(
    async (photoId: number) => {
      try {
        setLoading(true);
        const response = await dispatch(getGlobeMediaById(photoId));

        if (!response) {
          throw new Error();
        }

        dispatch(setUserPhoto(response));

        const userId = response.user_id;

        if (user?.id === userId) {
          const sharingGroups = await dispatch(
            getMediaSharingGroupsById(photoId),
          );

          if (!sharingGroups) {
            throw new Error();
          }

          dispatch(setSharingGroups(sharingGroups));
        }

        const commentsData = await dispatch(getMediaComments(photoId));

        if (!commentsData) {
          throw new Error();
        }

        dispatch(setMediaComments(commentsData));
      } catch (error) {
        console.error("Error while [getUserPhoto]", error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, user?.id],
  );

  return {
    getUserPhoto,
    isLoading,
  };
};
