import { createSlice } from "@reduxjs/toolkit";

interface IUserEditSliceInitialState {
  userName: { newUserName: string } | null;
  email: { newUserEmail: string } | null;
  password: { newPassword: string; repeatNewPassword: string } | null;
}

const userEditSliceInitialState: IUserEditSliceInitialState = {
  userName: null,
  email: null,
  password: null,
};

const userEditSlice = createSlice({
  name: "userEdit",
  initialState: userEditSliceInitialState,
  reducers: {
    setNewUserName(state, action) {
      state.userName = { newUserName: action.payload };
    },
    setNewUserEmail(state, action) {
      state.email = { newUserEmail: action.payload };
    },
    setNewUserPassword(state, action) {
      state.password = {
        newPassword: action.payload,
        repeatNewPassword: action.payload,
      };
    },
    resetUserEditStorage(state) {
      state.userName = userEditSliceInitialState.userName;
      state.email = userEditSliceInitialState.email;
      state.password = userEditSliceInitialState.password;
    },
  },
});

export default userEditSlice.reducer;
export const {
  setNewUserName,
  setNewUserEmail,
  setNewUserPassword,
  resetUserEditStorage,
} = userEditSlice.actions;
