import { Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { INewAlbumModalType } from "..";
import { materialStyles } from "./styles";
import { FullSizeModalHeader } from "../../../header/FullSizeModalHeader";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { AppImageList } from "../../../lists/imageList/AppImageList";
import DoneIcon from "@mui/icons-material/Done";
import { setAlbumImageIds } from "../../../../store/slices/newAlbum";
import { createAlbum } from "../../../../store/thunks/albums";
import _ from "lodash";
import { addAlbum } from "../../../../store/slices/albums";
import { useFetchPhotos } from "../../../../hooks/useFetchPhotos";
import { WithHandleScrollGrid } from "../../../helpers/WithHandleScrollGrid";

type ISelectAlbumPhotoForm = {
  onClose: () => void;
  onChangeNewAlbumModalType: (type: INewAlbumModalType) => void;
};

export const SelectAlbumPhotoForm: React.FC<ISelectAlbumPhotoForm> = ({
  onClose,
  onChangeNewAlbumModalType,
}) => {
  const { t } = useTranslation();
  const { imageIds } = useAppSelector((state) => state.newAlbum);
  const dispatch = useAppDispatch();

  const { photos, onUpdatePageOrPageSize } = useFetchPhotos();

  const goToBack = useCallback(() => {
    onChangeNewAlbumModalType("NEW_ALBUM_MAIN");
    dispatch(setAlbumImageIds([]));
  }, [dispatch, onChangeNewAlbumModalType]);

  const onSelectedImages = useCallback(
    (imageIds: number[]) => {
      dispatch(setAlbumImageIds(imageIds));
    },
    [dispatch]
  );

  const onCreateNewAlbum = useCallback(async () => {
    try {
      const response = await dispatch(createAlbum());

      if (!response) {
        return;
      }

      dispatch(addAlbum({ ...response, media_count: imageIds.length }));

      onClose();
    } catch (error) {
      console.error("Error while [onCreateNewAlbum]", error);
    }
  }, [dispatch, imageIds.length, onClose]);

  return (
    <Grid sx={materialStyles.innerModalForm}>
      <FullSizeModalHeader
        TitleComponent={
          <Grid sx={materialStyles.headerBlock}>
            <IconButton onClick={goToBack}>
              <CloseIcon sx={materialStyles.headerIcon} />
            </IconButton>
            <Typography sx={materialStyles.headerTitle}>
              {_.isEmpty(imageIds)
                ? t("new_album.add_to_album")
                : t("new_album.selected_value", { selected: imageIds.length })}
            </Typography>
          </Grid>
        }
      >
        <Grid sx={materialStyles.headerBlock}>
          {/* <Button sx={materialStyles.selectFromComputerButton} disabled>
            {t("new_album.select_from_computer")}
          </Button> */}
          <Button
            startIcon={<DoneIcon sx={materialStyles.headerIcon} />}
            sx={materialStyles.doneButton}
            onClick={onCreateNewAlbum}
            disabled={_.isEmpty(imageIds)}
          >
            {t("common.done")}
          </Button>
        </Grid>
      </FullSizeModalHeader>
      <WithHandleScrollGrid
        onEndReached={onUpdatePageOrPageSize}
        sx={materialStyles.mainImageBlock}
      >
        <AppImageList
          photos={photos}
          enableSelection
          onSelected={onSelectedImages}
          enableFavorites={false}
        />
      </WithHandleScrollGrid>
    </Grid>
  );
};
