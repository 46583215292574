import { createSlice } from "@reduxjs/toolkit";
import { IPhotoType } from "./myPhotos";
import _ from "lodash";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../constants/api";

interface ITrashSliceInitialState {
  trash: {
    data: IPhotoType[];
    page: number;
    pageSize: number;
  };
}

const trashSliceInitialState: ITrashSliceInitialState = {
  trash: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
};

const trashSlice = createSlice({
  name: "trash",
  initialState: trashSliceInitialState,
  reducers: {
    setTrash(state, action) {
      const trash = state.trash.data;

      if (_.isEmpty(trash)) {
        state.trash.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy(
        [...state.trash.data, ...action.payload],
        "id"
      );

      state.trash.data = updatedItems;
    },
    addToTrash(state, action) {
      state.trash.data = [...state.trash.data, action.payload];
    },
    removeFromTrash(state, action) {
      const newTrash = _.filter(
        state.trash.data,
        (item) => item.id !== action.payload
      );
      state.trash.data = newTrash;
    },
    resetTrash(state) {
      state.trash = trashSliceInitialState.trash;
    },
    setTrashPage(state, action) {
      state.trash.page = action.payload;
    },
    setTrashPageSize(state, action) {
      state.trash.pageSize = action.payload;
    },
  },
});

export default trashSlice.reducer;
export const {
  setTrash,
  resetTrash,
  addToTrash,
  removeFromTrash,
  setTrashPage,
  setTrashPageSize,
} = trashSlice.actions;
