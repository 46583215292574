import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBox: {
    backgroundColor: theme.palette.background.default,
    border: "none",
    borderRadius: "6px",
    width: {
      xs: `98vw`,
      sm: `98vw`,
      md: "460px",
    },
    display: "flex",
    position: "relative",
  },
  innerModalContainer: {
    padding: "24px 19px 31px 19px",
    flex: 1,
  },
  confirmModalHeaderTitle: {
    color: Colors.white,
    fontSize: "20px",
    lineHeight: "22px",
    fontWeight: 700,
    marginBottom: "24px",
    textAlign: "center",
  },
  confirmButton: {
    height: "50px",
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",

    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: Colors.white,
      backgroundColor: theme.palette.grey[400],
    },
  },
};
