import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getSupportRequests } from "../store/thunks/support";
import { setSupportRequests } from "../store/slices/supportRequests";
import _ from "lodash";

export const useFetchSupportRequests = () => {
  const dispatch = useAppDispatch();
  const { supportRequests } = useAppSelector((state) => state.supportRequests);
  const isAuth = useAuth();

  const fetchSupportRequests = useCallback(async () => {
    try {
      if (!isAuth) {
        return;
      }

      const data = await dispatch(getSupportRequests());

      if (!data) {
        throw new Error();
      }

      dispatch(setSupportRequests(data));
    } catch (error: any) {
      console.error("Error while [fetchSupportRequests]", error);
    }
  }, [dispatch, isAuth]);

  useEffect(() => {
    fetchSupportRequests();
  }, [fetchSupportRequests]);

  const parsedSupportRequests = useMemo(
    () => _.orderBy(supportRequests.data, ["created_at"], ["desc"]),
    [supportRequests.data],
  );

  return {
    supportRequests: parsedSupportRequests,
  };
};
