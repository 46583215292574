import React from "react";
import { ButtonBase, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EmptyImageIcon from "../../../assets/images/icons/empty-image-icon.svg";
import { materialStyles, styles } from "./styles";
import { IAlbumType } from "../../../store/slices/albums";
import _ from "lodash";

type IAppAlbumListItem = {
  album: IAlbumType;
  onClick?: (id: number) => void;
};

export const AppAlbumListItem: React.FC<IAppAlbumListItem> = ({
  album,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <ButtonBase
      onClick={() => onClick && onClick(album.id)}
      sx={materialStyles.albumItemContainer}
    >
      <Grid sx={materialStyles.albumCoverContainer}>
        {album.cover ? (
          <img
            style={styles.albumCover}
            src={_.get(album, "cover.preview")}
            alt="EmptyImageIcon"
          />
        ) : (
          <img src={EmptyImageIcon} alt="EmptyImageIcon" />
        )}
      </Grid>
      <Grid sx={materialStyles.albumTitleContainer}>
        <Typography sx={materialStyles.albumTitle}>{album.title}</Typography>
        <Typography sx={materialStyles.albumItemsCount}>
          {t("albums.value_items", { items: album.media_count })}
        </Typography>
      </Grid>
    </ButtonBase>
  );
};
