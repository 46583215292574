import { Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { materialStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";

type IFiltersWrapper = {
  onSubmit: (event: any) => void;
  children: React.ReactNode;
  onClose: () => void;
  onResetFilters?: () => void;
  disableReset?: boolean;
};

export const FiltersWrapper: React.FC<IFiltersWrapper> = ({
  onSubmit,
  children,
  onClose,
  onResetFilters,
  disableReset = false,
}) => {
  const { t } = useTranslation();

  const onClearAll = useCallback(() => {
    onResetFilters && onResetFilters();
    onClose();
  }, [onClose, onResetFilters]);

  return (
    <Grid sx={materialStyles.filtersForm}>
      <Grid sx={materialStyles.titleBlock}>
        <IconButton onClick={onClose}>
          <CloseIcon sx={materialStyles.closeIcon} />
        </IconButton>
        <Typography sx={materialStyles.filtersMainTitle}>
          {t("filters.filters")}
        </Typography>
      </Grid>
      <Grid sx={materialStyles.filtersContent}>{children}</Grid>
      <Grid sx={materialStyles.bottomButtonsContainer}>
        {onResetFilters && !disableReset ? (
          <Grid sx={materialStyles.cancelButtonContainer}>
            <Button sx={materialStyles.cancelButton} onClick={onClearAll}>
              {t("filters.clear_filters")}
            </Button>
          </Grid>
        ) : null}
        <Button
          onClick={onSubmit}
          sx={materialStyles.submitButton}
          type="submit"
        >
          {t("filters.apply")}
        </Button>
      </Grid>
    </Grid>
  );
};
