import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  addUsersToGroupByIds,
  getPrivacyGroupById,
  removeUsersFromGroupByIds,
} from "../../../../store/thunks/groups";
import {
  removeUserFromGroup,
  setGroupForUpdate,
  setGroupUsersForUpdate,
} from "../../../../store/slices/updateGroup";
import { SearchUserInput } from "../../../header/components/SearchUserInput";
import _ from "lodash";
import { UserWithRemoveItem } from "../../../items/UserWithRemoveItem";
import { IUserType } from "../../../../store/slices/user";
import { useToast } from "rc-toastr";

type IUpdatePrivacyGroupForm = {
  onClose: () => void;
};

export const UpdatePrivacyGroupForm: React.FC<IUpdatePrivacyGroupForm> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { groupId, group, users } = useAppSelector(
    (state) => state.updateGroup,
  );

  const [localAddedUsersToGroup, setAddedUsersToGroup] = useState<IUserType[]>(
    [],
  );

  const [deletedUsersFromGroup, setDeletedUsersFromGroup] = useState<number[]>(
    [],
  );

  const { toast } = useToast();

  const fetchGroup = useCallback(async () => {
    try {
      if (!groupId) {
        return;
      }

      const response = await dispatch(getPrivacyGroupById(groupId));

      if (!response) {
        throw new Error();
      }

      dispatch(
        setGroupForUpdate({
          title: response.title,
          id: response.id,
          is_custom: response.is_custom,
        }),
      );

      dispatch(setGroupUsersForUpdate(response.users));
    } catch (error: any) {
      console.error("Error while [fetchGroup]", error);
    }
  }, [dispatch, groupId]);

  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  const onAddUserToLocalState = useCallback(
    (user: IUserType) => {
      const isUserAlreadyAdded = _.find(users, { id: user.id });
      const isUserAlreadyAddedLocal = _.find(localAddedUsersToGroup, {
        id: user.id,
      });

      const isAlreadyDeleted = _.includes(deletedUsersFromGroup, user.id);

      if (isAlreadyDeleted) {
        const filteredDeletedUsers = _.filter(
          deletedUsersFromGroup,
          (id) => id !== user.id,
        );
        setDeletedUsersFromGroup(filteredDeletedUsers);
      }

      if (isUserAlreadyAdded || isUserAlreadyAddedLocal) {
        toast.error(t("sharing.user_already_added"));
        return;
      }

      setAddedUsersToGroup([...localAddedUsersToGroup, user]);
    },
    [deletedUsersFromGroup, localAddedUsersToGroup, t, toast, users],
  );

  const onRemoveUserFromLocalState = useCallback(
    (id: IUserType["id"]) => {
      const isUserAlreadyAddedLocal = _.find(localAddedUsersToGroup, {
        id,
      });

      if (isUserAlreadyAddedLocal) {
        const filteredLocalSharingUsers = _.filter(
          localAddedUsersToGroup,
          (user) => user.id !== id,
        );
        setAddedUsersToGroup(filteredLocalSharingUsers);
        return;
      }

      setDeletedUsersFromGroup([...deletedUsersFromGroup, id]);
      dispatch(removeUserFromGroup(id));
    },
    [deletedUsersFromGroup, dispatch, localAddedUsersToGroup],
  );

  const onSaveChanges = useCallback(async () => {
    try {
      if (!groupId) {
        return;
      }

      const userIdsToAdd = _.map(localAddedUsersToGroup, (user) => user.id);

      if (!_.isEmpty(userIdsToAdd)) {
        await dispatch(addUsersToGroupByIds(groupId, userIdsToAdd));
      }

      if (!_.isEmpty(deletedUsersFromGroup)) {
        await dispatch(
          removeUsersFromGroupByIds(groupId, deletedUsersFromGroup),
        );
      }

      onClose();
    } catch (error: any) {
      console.error("Error while [onSaveChanges]", error);
    }
  }, [
    deletedUsersFromGroup,
    dispatch,
    groupId,
    localAddedUsersToGroup,
    onClose,
  ]);

  return (
    <Grid sx={materialStyles.modalInnerBlock}>
      <Grid sx={materialStyles.modalHeaderBlock}>
        <Typography sx={materialStyles.modalHeaderTitle}>
          {t("settings.update_group")}
        </Typography>
        <Typography sx={materialStyles.modalHeaderSubtitle}>
          {t("settings.private_groups_sharing_photos")}
        </Typography>
      </Grid>
      <Grid sx={materialStyles.modalForm}>
        <TextField
          name="title"
          value={group?.title}
          disabled
          sx={materialStyles.input}
          fullWidth
          placeholder={t("upload_photo.enter_group_title")}
        />
        <SearchUserInput
          onChangeValue={onAddUserToLocalState}
          customTextInputSx={materialStyles.searchUserInput}
        />
        <Grid sx={materialStyles.selectedLocalUser}>
          {_.map([...users, ...localAddedUsersToGroup], (user) => (
            <UserWithRemoveItem
              user={user}
              onRemoveClick={onRemoveUserFromLocalState}
            />
          ))}
        </Grid>
        <Button
          disabled={
            _.isEmpty(localAddedUsersToGroup) &&
            _.isEmpty(deletedUsersFromGroup)
          }
          sx={materialStyles.saveButton}
          variant="contained"
          onClick={onSaveChanges}
        >
          {t("common.save")}
        </Button>
      </Grid>
    </Grid>
  );
};
