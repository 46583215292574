export const parseGPSCoordinate = (
  coordinate: number[],
  hemisphere: string
) => {
  if (!coordinate) return;

  const degrees = coordinate[0];
  const minutes = coordinate[1];
  const seconds = coordinate[2];

  if (!degrees || !minutes || !seconds) return;

  let decimalMinutes = minutes / 60;
  let decimalSeconds = seconds / 3600;

  let decimalCoordinate = degrees + decimalMinutes + decimalSeconds;

  if (hemisphere === "S" || hemisphere === "W") {
    decimalCoordinate = -decimalCoordinate;
  }

  return decimalCoordinate;
};
