import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { IFiltersDrawerType } from "../components/filters";
import _ from "lodash";
import { useAuth } from "./useAuth";

const RESERVER_ROUTES = [
  "explore",
  "settings",
  "favorites",
  "albums",
  "trash",
  "messages",
  "photos",
];

export const useFiltersTypeByPage = () => {
  const { pathname } = useLocation();
  const isAuth = useAuth();

  const type = useMemo(() => {
    let type: IFiltersDrawerType | null = null;

    if (!isAuth) {
      return type;
    }

    const parsedPathname =
      pathname !== "/" ? _.replace(pathname, "/", "") : pathname;

    //TODO: Need to rewrite this logic
    const isUserOnMap =
      !_.includes(RESERVER_ROUTES, parsedPathname) &&
      pathname.split("/").length === 2;

    switch (true) {
      case parsedPathname === "/" || isUserOnMap:
        type = "MAP";
        break;
      case parsedPathname === "photos":
        type = "PHOTOS";
        break;
    }

    return type;
  }, [isAuth, pathname]);

  return type;
};
