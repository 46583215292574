import { Grid, Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type IBlockDetailsContent = {
  title: string;
  titleWidth?: number;
  content: string | number;
  subContent?: string;
  children?: React.ReactNode;
};

export const BlockDetailsContent: React.FC<IBlockDetailsContent> = ({
  title,
  titleWidth,
  content,
  subContent,
  children,
}) => {
  return (
    <Grid sx={materialStyles.blockDetailsContainer}>
      <Typography
        sx={materialStyles.blockDetailsContentTitle}
        style={{ minWidth: titleWidth }}
      >
        {title}
      </Typography>
      <Grid sx={materialStyles.row}>
        <Grid>
          <Typography sx={materialStyles.blockDetailsConterText}>
            {content}
          </Typography>
          {subContent ? (
            <Typography sx={materialStyles.blockDetailsConterText}>
              {subContent}
            </Typography>
          ) : null}
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
};
