import React, { useCallback } from "react";
import { materialStyles, styles } from "./styles";
import { Grid, Button, Typography } from "@mui/material";
import { IAuthModalType } from "..";
import { AuthModalHeader } from "./AuthModalHeader";
import { useTranslation } from "react-i18next";
import MailCodeIcon from "../../../../assets/images/icons/mail-code-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { CodeInput } from "../../../codeInput";
import { useFormik } from "formik";
import _ from "lodash";
import { setCode } from "../../../../store/slices/registration";
import {
  passwordReset,
  passwordVerify,
  sendEmailValidation,
  sendPhoneValidation,
  validateCodeEmail,
  validateCodePhone,
} from "../../../../store/thunks/user";
import { useTimer } from "../../../../hooks/useTimer";
import { useToast } from "rc-toastr";

type ICodeForm = {
  onChangeAuthModalType: (type: IAuthModalType) => void;
  onClose: () => void;
  type: "PHONE" | "EMAIL";
  isResetPassword?: boolean;
};

type ICodeFormValues = {
  code: string;
};

const VALID_CODE_SECONDS = 600;

export const CodeForm: React.FC<ICodeForm> = ({
  onClose,
  onChangeAuthModalType,
  type,
  isResetPassword,
}) => {
  const { t } = useTranslation();
  const { regUser } = useAppSelector((state) => state.regUser);
  const dispatch = useAppDispatch();
  const { time, isExpired, resetTimer } = useTimer(VALID_CODE_SECONDS);

  const { toast } = useToast();
  const onSubmit = useCallback(
    async (details: ICodeFormValues) => {
      try {
        dispatch(setCode(details.code));

        if (isExpired) {
          toast.error(t("auth_modal.code_is_expired_need_to_resend"));
          return;
        }

        switch (type) {
          case "EMAIL":
            if (isResetPassword) {
              await dispatch(passwordVerify(details.code));
              break;
            }
            await dispatch(validateCodeEmail(details.code));
            break;
          case "PHONE":
            if (isResetPassword) {
              await dispatch(passwordVerify(details.code));
              break;
            }
            await dispatch(validateCodePhone(details.code));
            break;
        }

        if (isResetPassword) {
          onChangeAuthModalType("NEW_PASSWORD");
          return;
        }
        onChangeAuthModalType("FINAL_SIGNUP");
      } catch (error) {
        console.error("Error while [onSubmit]", error);
      }
    },
    [
      dispatch,
      isExpired,
      isResetPassword,
      onChangeAuthModalType,
      t,
      toast,
      type,
    ]
  );

  const { values, handleSubmit, setFieldValue, errors, isValid } = useFormik({
    onSubmit,
    initialValues: { code: "" },
    validate: (values) => {
      return _.pickBy({
        code: !_.trim(values.code),
      });
    },
  });

  const onCodeValueChange = useCallback(
    (value: string) => {
      setFieldValue("code", value);
    },
    [setFieldValue]
  );

  const onResendCode = useCallback(async () => {
    try {
      setFieldValue("code", "");

      if (isResetPassword) {
        await dispatch(passwordReset());
        resetTimer();
        return;
      }

      switch (type) {
        case "EMAIL":
          await dispatch(sendEmailValidation(_.get(regUser, "email", "")));
          break;
        case "PHONE":
          await dispatch(sendPhoneValidation(_.get(regUser, "phone", "")));
          break;
      }
      resetTimer();
    } catch (error) {
      console.error("Error while [onResendCode]", error);
    }
  }, [dispatch, isResetPassword, regUser, resetTimer, setFieldValue, type]);

  if (!regUser) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid sx={materialStyles.innerAuthModalBox}>
        <AuthModalHeader
          title={
            isResetPassword
              ? t("auth_modal.reset_your_password")
              : t("auth_modal.lets_go")
          }
        />
        <Grid sx={materialStyles.formContainer}>
          <Grid sx={materialStyles.mailIconContainer}>
            <img src={MailCodeIcon} alt="Mail" style={styles.mailIcon} />
          </Grid>
          <Typography sx={materialStyles.codeVerifyTitle}>
            {type === "EMAIL"
              ? t("auth_modal.we_just_emailed_you")
              : t("auth_modal.we_just_call_you")}
          </Typography>
          {type === "EMAIL" ? (
            <Typography sx={materialStyles.codeVerifySubtitle}>
              {t("auth_modal.please_enter_the_code_we_emailed_you")}
            </Typography>
          ) : null}
          {type === "EMAIL" && regUser.email ? (
            <Typography sx={materialStyles.codeValue}>
              {regUser.email}
            </Typography>
          ) : null}
          {type === "PHONE" && regUser.phone ? (
            <Typography sx={materialStyles.codeValue}>
              {regUser.phone}
            </Typography>
          ) : null}
          <Typography sx={materialStyles.verificationCodeLabel}>
            {t("auth_modal.confirmation_code")}
          </Typography>
          <CodeInput
            isError={Boolean(errors.code)}
            value={values.code}
            onChange={onCodeValueChange}
          />
          <Button
            type="submit"
            disabled={!isValid}
            sx={materialStyles.loginButton}
            variant="contained"
          >
            {t("auth_modal.verify")}
          </Button>
          {isExpired ? (
            <Grid sx={materialStyles.resendButtonContainer}>
              <Button
                onClick={onResendCode}
                sx={materialStyles.resendButton}
                variant="text"
              >
                {t("auth_modal.resend_code")}
              </Button>
            </Grid>
          ) : (
            <Typography sx={materialStyles.codeValidTimer}>
              {t("auth_modal.code_valid_timer", { time })}
            </Typography>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
