import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../constants/api";
import { catchChangesByKeys } from "../../utils";

type IAlbumCover = {
  id: number;
  preview: string;
  thumbnail: string;
};

export type IAlbumType = {
  id: number;
  user_id: number;
  title: string;
  description: string;
  cover_id: number;
  is_public: boolean;
  media_count: number | string;
  cover: IAlbumCover | null;
  created_at: string;
};

interface IMyPhotosSliceInitialState {
  albums: {
    data: IAlbumType[];
    page: number;
    pageSize: number;
  };
  count: number | null;
}

const myPhotosSliceInitialState: IMyPhotosSliceInitialState = {
  albums: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  count: null,
};

const albumsSlice = createSlice({
  name: "albums",
  initialState: myPhotosSliceInitialState,
  reducers: {
    setAlbums(state, action) {
      const albums = state.albums.data;

      if (_.isEmpty(albums)) {
        state.albums.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy(
        [...state.albums.data, ...action.payload],
        "id"
      );

      const itemsWithCheckedChanges = catchChangesByKeys(
        updatedItems,
        action.payload,
        ["media_count"]
      );

      state.albums.data = itemsWithCheckedChanges;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    addAlbum(state, action) {
      state.albums.data = [...state.albums.data, action.payload];
    },
    updateAlbumInStorage(state, action) {
      const newAlbum = action.payload;
      const albums = state.albums.data;

      if (!newAlbum.id) {
        return;
      }

      const albumIndex = _.findIndex(
        albums,
        (album) => album.id === newAlbum.id
      );
      const newAlbums = _.update(albums, albumIndex, () => newAlbum);

      state.albums = newAlbums;
    },
    removeAlbumFromStorage(state, action) {
      const removedPhotos = _.filter(
        state.albums.data,
        (album) => album.id !== action.payload
      );

      state.albums.data = removedPhotos;
    },
    resetAlbums(state) {
      state.albums = myPhotosSliceInitialState.albums;
      state.count = myPhotosSliceInitialState.count;
    },
    setAlbumsPage(state, action) {
      state.albums.page = action.payload;
    },
    setAlbumsPageSize(state, action) {
      state.albums.pageSize = action.payload;
    },
  },
});

export default albumsSlice.reducer;
export const {
  setAlbums,
  setCount,
  addAlbum,
  removeAlbumFromStorage,
  resetAlbums,
  updateAlbumInStorage,
  setAlbumsPage,
  setAlbumsPageSize,
} = albumsSlice.actions;
