import React from "react";
import { IUserType } from "../../store/slices/user";
import { Grid, IconButton, Typography } from "@mui/material";
import { UserAvatar } from "../user/UserAvatar";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";

type ISharingItems = {
  user?: IUserType | null;
  onDeleteClick?: (id: number) => void;
  isOwner?: boolean;
};

export const SharingItems: React.FC<ISharingItems> = ({
  user,
  onDeleteClick,
  isOwner = false,
}) => {
  const { t } = useTranslation();
  if (!user) {
    return null;
  }

  return (
    <Grid sx={materialStyles.sharingItem}>
      <Grid sx={materialStyles.sharingItemUserContainer}>
        <UserAvatar type="DEFAULT" user={user} />
        <Grid>
          <Typography sx={materialStyles.userName}>
            {user.username || "UserName"}
          </Typography>
          {user.email ? (
            <Typography sx={materialStyles.email}>{user.email}</Typography>
          ) : null}
        </Grid>
      </Grid>
      {onDeleteClick ? (
        <IconButton onClick={() => onDeleteClick(user.id)}>
          <DeleteOutlineOutlinedIcon sx={materialStyles.deleteIcon} />
        </IconButton>
      ) : null}
      {isOwner ? (
        <Typography sx={materialStyles.userRoleTitle}>
          {t("sharing.owner")}
        </Typography>
      ) : null}
    </Grid>
  );
};
