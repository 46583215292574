import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  modal: {
    display: "flex",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    maxWidth: "100%",
  },
  headerIcon: {
    color: Colors.white,
  },
  modalInnerContent: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "auto",
  },
  searchConteiner: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    margin: "14px",
  },
  searchInput: {
    border: `0px solid ${theme.palette.background.default} !important`,
    backgroundColor: `${theme.palette.grey[100]}`,
    borderRadius: "10px",
    color: Colors.white,

    "& input": {
      color: Colors.white,
    },

    "& fieldset": {
      border: "none",
    },

    "& ::placeholder": {
      color: `${theme.palette.grey[200]}`,
      opacity: 1,
    },
  },
  searchIcon: {
    fill: `${theme.palette.grey[200]}`,
    marginRight: "12px",
  },
  usersBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    width: "100%",
  },
  userBlock: {
    display: "flex",
    padding: "8px 14px 8px 14px",
    width: "100%",
    justifyContent: "flex-start",
    gap: "8px",
    textAlign: "left",
  },
  listEmptyText: {
    fontSize: "16px",
    textAlign: "center",
    width: "100%",
    color: Colors.white,
  },
  userName: {
    color: Colors.white,
  },
};
