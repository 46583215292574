import { Colors } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  searchBoxContainer: {
    position: "absolute",
    top: 0,
    width: "96%",
    display: "flex",
    flexDirection: "column",
    p: 1,
  },
  searchBoxContainerPopover: {
    display: "flex",
    backgroundColor: Colors.white,
    p: 1,
    gap: 1,
    flexDirection: "column",
    mt: 0.5,
    borderRadius: 1,
  },
  searchBoxPopoverButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    textAlign: "left",
  },
  searchBoxPopoverButtonTitle: {
    fontSize: "15px",
  },
  searchBoxPopoverButtonSubtitle: {
    textAlign: "left",
    fontSize: "12px",
  },
  searchBoxInput: {
    width: "100%",
    backgroundColor: Colors.white,
    overflow: "hidden",
  },
};
