import { useCallback, useEffect, useReducer } from "react";
import { useFetchChat } from "./useFetchChat";
import { Message } from "../store/slices/messages";
import { useAppSelector } from "../store";
import { socket } from "../socket";

type TLocalMessagesState = {
  messages: Message[];
};

type TLocalMessagesAction =
  | {
      type: "SET_MESSAGES";
      messeges: Message[];
    }
  | {
      type: "ADD_MESSAGE";
      message: Message;
    }
  | { type: "RESET_MESSAGES" };

const initialMessagesState: TLocalMessagesState = {
  messages: [],
};

const localMessagesReducer = (
  state: TLocalMessagesState,
  action: TLocalMessagesAction,
) => {
  switch (action.type) {
    case "SET_MESSAGES":
      return { ...state, messages: action.messeges };
    case "ADD_MESSAGE":
      return { ...state, messages: [action.message, ...state.messages] };
    case "RESET_MESSAGES":
      return initialMessagesState;
    default:
      return state;
  }
};
export const useLocalProccessSocketMessages = () => {
  const [messagesState, dispatchMessagesState] = useReducer(
    localMessagesReducer,
    initialMessagesState,
  );

  const { isConnected } = useAppSelector((state) => state.socket);
  const { user } = useAppSelector((state) => state.user);
  const { user: chatUser } = useAppSelector((state) => state.chat);

  const { messages } = useFetchChat();

  useEffect(() => {
    dispatchMessagesState({ type: "SET_MESSAGES", messeges: messages });
  }, [messages]);

  const handleChatSend = useCallback(
    (message: Message) => {
      if (!user || !chatUser) {
        return;
      }
      if (
        (message.sender_id === user.id && chatUser.id === message.user_id) ||
        (message.user_id === user.id && chatUser.id === message.sender_id)
      ) {
        dispatchMessagesState({ type: "ADD_MESSAGE", message });
      }
    },
    [chatUser, user],
  );

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    socket.on("chat:send", handleChatSend);

    return () => {
      if (isConnected) {
        socket.off("chat:send", handleChatSend);
      }
    };
  }, [handleChatSend, isConnected]);

  return { messagesState };
};
