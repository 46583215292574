import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { checkOrRefreshToken, getMe } from "../../store/thunks/user";
import { getMediaStorageInfo } from "../../store/thunks/media";

type ICommonUserHandle = {};

export const CommonUserHandle: React.FC<ICommonUserHandle> = () => {
  const { token, user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getUser = async () => {
      try {
        if (token && !user) {
          const checkedToken = await dispatch(checkOrRefreshToken(token));
          await dispatch(getMe(checkedToken));
          await dispatch(getMediaStorageInfo(checkedToken));
          return;
        }

        const localToken = localStorage.getItem("token");
        if (localToken && !user) {
          const checkedToken = await dispatch(checkOrRefreshToken(localToken));
          await dispatch(getMe(checkedToken));
          await dispatch(getMediaStorageInfo(checkedToken));
        }
      } catch (error) {
        console.error("Error while [getUser]", error);
      }
    };

    getUser();
  }, [dispatch, token, user]);

  return null;
};
