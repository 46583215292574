import React, { useCallback } from "react";
import { IAuthModalType } from "..";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import _ from "lodash";
import { useAppDispatch } from "../../../../store";
import { changeUsername } from "../../../../store/thunks/user";
import { setUserName } from "../../../../store/slices/user";

type IFormValues = {
  userName: string;
};

type ISetUserNicknameForm = {
  onChangeAuthModalType: (type: IAuthModalType) => void;
  onClose: () => void;
};

export const SetUserNicknameForm: React.FC<ISetUserNicknameForm> = ({
  onChangeAuthModalType,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      try {
        const newUserName = await dispatch(changeUsername(values.userName));
        if (!newUserName) {
          return;
        }

        dispatch(setUserName(newUserName));

        onClose();
      } catch (error) {
        console.error("Error while [onSubmit]", error);
      }
    },
    [dispatch, onClose]
  );

  const { values, errors, isValid, handleChange, handleSubmit } = useFormik({
    onSubmit,
    initialValues: {
      userName: "",
    },
    validate: (values) => {
      return _.pickBy({
        userName: !_.trim(values.userName),
      });
    },
  });

  return (
    <Grid sx={materialStyles.innerAuthModalBox}>
      <form onSubmit={handleSubmit}>
        <Typography sx={materialStyles.setNicknameModalTitle}>
          {t("auth_modal.set_nickname_your_account")}
        </Typography>
        <TextField
          error={Boolean(errors.userName)}
          name="userName"
          value={values.userName}
          onChange={handleChange}
          sx={materialStyles.authInput}
          fullWidth
          label={t("auth_modal.username")}
          placeholder={t("auth_modal.enter_username")}
        />
        <Button
          type="submit"
          disabled={!isValid}
          sx={materialStyles.loginButton}
          variant="contained"
        >
          {t("auth_modal.set_username")}
        </Button>
      </form>
    </Grid>
  );
};
