import { Box, Modal } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { materialStyles } from "./styles";
import { UploadNewFileForm } from "./components/UploadNewFileForm";
import { useAppDispatch, useAppSelector } from "../../../store";
import { resetFile } from "../../../store/slices/uploadFile";
import { ShareUploadedFileForm } from "./components/ShareUploadedFileForm";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";
import { YouTubeVideoEmbedForm } from "./components/YouTubeVideoEmbedForm";
import { SelectedGooglePhotoForm } from "./components/SelectedGooglePhotoForm";

export type IUploadPhotoModalType =
  | "UPLOAD_NEW_PHOTO"
  | "CROP_UPLOADED_PHOTO"
  | "EMBED_YOUTUBE_VIDEO"
  | "SHARE_UPLOADED_FILE"
  | "IMPORT_GOOGLE_MEDIA";

type IUploadPhoto = {
  isOpen: boolean;
  onClose: () => void;
  initialModalType?: IUploadPhotoModalType;
};

export const UploadPhotoModal: React.FC<IUploadPhoto> = ({
  isOpen,
  onClose,
  initialModalType = "UPLOAD_NEW_PHOTO",
}) => {
  const [uploadPhotoModalType, setUploadPhotoModalType] =
    useState<IUploadPhotoModalType>(initialModalType);

  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.loader);

  const onChangeUploadModalType = useCallback((type: IUploadPhotoModalType) => {
    setUploadPhotoModalType(type);
  }, []);

  const onModalClose = useCallback(() => {
    dispatch(resetFile());
    onClose();
    onChangeUploadModalType(initialModalType);
  }, [dispatch, initialModalType, onChangeUploadModalType, onClose]);

  useEffect(() => {
    onChangeUploadModalType(initialModalType);
  }, [initialModalType, onChangeUploadModalType]);

  return (
    <Modal open={isOpen} onClose={onModalClose} sx={materialStyles.uploadModal}>
      <Box sx={materialStyles.uploadModalBox}>
        {uploadPhotoModalType === "UPLOAD_NEW_PHOTO" ? (
          <UploadNewFileForm
            onChangeUploadModalType={onChangeUploadModalType}
            onClose={onModalClose}
          />
        ) : null}
        {uploadPhotoModalType === "EMBED_YOUTUBE_VIDEO" ? (
          <YouTubeVideoEmbedForm
            onChangeUploadModalType={onChangeUploadModalType}
          />
        ) : null}
        {uploadPhotoModalType === "SHARE_UPLOADED_FILE" ? (
          <ShareUploadedFileForm
            onChangeUploadModalType={onChangeUploadModalType}
            onClose={onModalClose}
          />
        ) : null}
        {uploadPhotoModalType === "IMPORT_GOOGLE_MEDIA" ? (
          <SelectedGooglePhotoForm
            onChangeUploadModalType={onChangeUploadModalType}
          />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
