import React, { useCallback } from "react";
import { UserLayout } from "../../components/layout/UserLayout";
import { useAppDispatch, useAppSelector } from "../../store";
import { MapComponent } from "../../components/map/MapComponent";
import { AppImageList } from "../../components/lists/imageList/AppImageList";
import { materialStyles, styles } from "./styles";
import _ from "lodash";
import { BlockEmptyWithIcon } from "../../components/blocks/blockEmpty/BlockEmptyWithIcon";
import { useTranslation } from "react-i18next";
import EmptyFavoritesIcon from "../../assets/images/icons/empty-favorites-icon.svg";
import {
  IPhotoType,
  setPhotosFilters,
  updatePhotoInStorage,
} from "../../store/slices/myPhotos";
import { LoaderWithBackdrop } from "../../components/loaders/LoaderWithBackdrop";
import { PageTitle } from "../../components/titles/PageTitle";
import { Grid } from "@mui/material";
import {
  AppliedFilters,
  IAnyFilters,
} from "../../components/items/AppliedFilters";
import { useFetchPhotos } from "../../hooks/useFetchPhotos";

type IPhotosPageType = {};

export const Photos: React.FC<IPhotosPageType> = () => {
  const { mode } = useAppSelector((state) => state.visualMode);
  const { filters } = useAppSelector((state) => state.myPhotos);
  const { loading } = useAppSelector((state) => state.loader);

  const { photos, refetchMyPhotos, onUpdatePageOrPageSize } = useFetchPhotos();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const onUpdatePhotoToFavorites = useCallback(
    (photo: IPhotoType) => {
      dispatch(updatePhotoInStorage(photo));
    },
    [dispatch]
  );

  const onRemoveFilters = useCallback(
    (updatedFilters: IAnyFilters) => {
      dispatch(setPhotosFilters(updatedFilters));
    },
    [dispatch]
  );

  const onClosePhotoModal = useCallback(async () => {
    refetchMyPhotos();
  }, [refetchMyPhotos]);

  return (
    <>
      <UserLayout
        onEndReached={onUpdatePageOrPageSize}
        disableMargin={mode === "GLOBE"}
      >
        {mode === "GRID" ? (
          <PageTitle title={t("photos.photos")}>
            <Grid sx={materialStyles.filtersBlock}>
              <AppliedFilters
                filters={filters}
                type="PHOTOS"
                onRemoveFilter={onRemoveFilters}
              />
            </Grid>
          </PageTitle>
        ) : null}
        {mode === "GRID" ? (
          <AppImageList
            containerStyle={materialStyles.appImageList}
            photos={photos}
            enableOpenPhoto
            onUpdatePhotoToFavorites={onUpdatePhotoToFavorites}
            onClosePhotoModal={onClosePhotoModal}
          />
        ) : null}
        {mode === "GLOBE" ? (
          <Grid sx={materialStyles.mapFiltersContainer}>
            <Grid sx={materialStyles.filtersBlock}>
              <AppliedFilters
                filters={filters}
                type="PHOTOS"
                onRemoveFilter={onRemoveFilters}
              />
            </Grid>
          </Grid>
        ) : null}
        {mode === "GLOBE" ? (
          <MapComponent
            onClosePhotoModal={onClosePhotoModal}
            containerStyle={styles.map}
            photos={photos}
          />
        ) : null}
        {_.isEmpty(photos) && mode === "GRID" ? (
          <BlockEmptyWithIcon
            Icon={<img src={EmptyFavoritesIcon} alt="EmptyFavoritesIcon" />}
            title={t("photos.photo_list_is_empty")}
          />
        ) : null}
      </UserLayout>
      <LoaderWithBackdrop isShow={loading} />
    </>
  );
};
