import React from "react";
import { useAppSelector } from "../../../store";
import { Grid, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";

export const UploaderLine: React.FC = () => {
  const { stack } = useAppSelector((state) => state.uploaderStack);
  const { t } = useTranslation();

  return (
    <Grid
      sx={[
        materialStyles.uploaderLineConteiner,
        Boolean(stack?.procent)
          ? materialStyles.activeUploaderLineConteiner
          : false,
      ]}
    >
      <Grid
        sx={[
          materialStyles.progressBar,
          { width: !stack?.procent ? "0%" : `${stack?.procent}%` },
        ]}
      />
      <Typography sx={materialStyles.uploaderLineText}>
        {stack?.procent === 100
          ? t("upload_photo.file_being_prepared_publication")
          : `${stack?.procent || 100}%`}
      </Typography>
    </Grid>
  );
};
