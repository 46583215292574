import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getPublicMedia } from "../store/thunks/globe";
import {
  resetMapPublicMedia,
  setMapPublicMedia,
  setMapPublicMediaPage,
  setMapPublicMediaPageSize,
} from "../store/slices/map";
import {
  DEFAULT_GLOBE_PAGE_SIZE,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "../constants/api";

export const useFetchMapPublicMedia = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { publicMedia } = useAppSelector((state) => state.map);

  const { mode } = useAppSelector((state) => state.visualMode);

  const fetchPublicMedia = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        if (isAuth) {
          return;
        }

        const media = await dispatch(getPublicMedia(page, pageSize));

        dispatch(setMapPublicMedia(media));
      } catch (error) {
        console.error("Error while [fetchPublicMedia]", error);
      }
    },
    [dispatch, isAuth]
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setMapPublicMediaPage(publicMedia.page + 1));
    dispatch(setMapPublicMediaPageSize(DEFAULT_PAGE_SIZE));
  }, [publicMedia.page, dispatch]);

  const refetchPublicMedia = useCallback(() => {
    fetchPublicMedia(publicMedia.page);
  }, [fetchPublicMedia, publicMedia.page]);

  useEffect(() => {
    const defaultPageSize = {
      GRID: DEFAULT_PAGE_SIZE,
      GLOBE: DEFAULT_GLOBE_PAGE_SIZE,
    }[mode];

    fetchPublicMedia(DEFAULT_PAGE, defaultPageSize);

    return () => {
      dispatch(resetMapPublicMedia());
    };
  }, [dispatch, fetchPublicMedia, mode]);

  useEffect(() => {
    if (publicMedia.page !== DEFAULT_PAGE) {
      fetchPublicMedia(publicMedia.page, publicMedia.pageSize);
    }
  }, [publicMedia.page, publicMedia.pageSize, fetchPublicMedia]);

  return {
    publicMedia: publicMedia.data,
    onUpdatePageOrPageSize,
    refetchPublicMedia,
  };
};
