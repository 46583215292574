import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { materialStyles, styles } from "./styles";
import { IEditModalContentType, IEditModalType } from "..";
import { useTranslation } from "react-i18next";
import SuccessCircleIcon from "../../../../assets/images/icons/success-circle-icon.svg";

type ISeccessEditContent = {
  type: IEditModalContentType;
  onClose: () => void;
  onChangeModalType: (type: IEditModalType) => void;
};

export const SuccessEditContent: React.FC<ISeccessEditContent> = ({
  type,
  onClose,
  onChangeModalType,
}) => {
  const { t } = useTranslation();

  return (
    <Grid sx={materialStyles.innerModalContainer}>
      <Grid sx={materialStyles.confirmContainer}>
        <img
          style={styles.successIcon}
          src={SuccessCircleIcon}
          alt="SuccessCircleIcon"
        />
      </Grid>
      <Typography sx={materialStyles.confirmModalHeaderTitle}>
        {
          {
            ALBUM: t("edit.success_album_edit"),
            PHOTO: t("edit.success_photo_edit"),
          }[type]
        }
      </Typography>
      <Button sx={materialStyles.closeButton} onClick={onClose}>
        {t("common.close")}
      </Button>
    </Grid>
  );
};
