import React, { useCallback } from "react";
import { UserLayout } from "../../components/layout/UserLayout";
import { PageTitle } from "../../components/titles/PageTitle";
import { useTranslation } from "react-i18next";
import { BlockEmptyWithIcon } from "../../components/blocks/blockEmpty/BlockEmptyWithIcon";
import EmptyFavoritesIcon from "../../assets/images/icons/empty-favorites-icon.svg";
import { useAppSelector } from "../../store";
import { AppImageList } from "../../components/lists/imageList/AppImageList";
import { materialStyles, styles } from "./styles";
import _ from "lodash";
import { LoaderWithBackdrop } from "../../components/loaders/LoaderWithBackdrop";
import { MapComponent } from "../../components/map/MapComponent";
import { useFetchFavorites } from "../../hooks/useFetchFavorites";

type IFavoritesPage = {};

export const Favorites: React.FC<IFavoritesPage> = () => {
  const { t } = useTranslation();

  const { loading } = useAppSelector((state) => state.loader);

  const { mode } = useAppSelector((state) => state.visualMode);

  const { favoritePhotos, onUpdatePageOrPageSize, refetchMyFavorites } =
    useFetchFavorites();

  const onClosePhotoModal = useCallback(async () => {
    refetchMyFavorites();
  }, [refetchMyFavorites]);

  return (
    <>
      <UserLayout
        onEndReached={onUpdatePageOrPageSize}
        disableMargin={mode === "GLOBE"}
      >
        {mode === "GRID" ? (
          <PageTitle title={t("favorites.favorites")} />
        ) : null}
        {mode === "GRID" ? (
          <AppImageList
            enableOpenPhoto
            containerStyle={materialStyles.appImageList}
            photos={favoritePhotos}
            type="FAVORITES"
            onClosePhotoModal={onClosePhotoModal}
          />
        ) : null}
        {mode === "GLOBE" && !_.isEmpty(favoritePhotos) ? (
          <MapComponent
            onClosePhotoModal={onClosePhotoModal}
            photos={favoritePhotos}
            containerStyle={styles.map}
          />
        ) : null}
        {_.isEmpty(favoritePhotos) ? (
          <BlockEmptyWithIcon
            Icon={<img src={EmptyFavoritesIcon} alt="EmptyFavoritesIcon" />}
            title={t("favorites.add_favorites_empty")}
          />
        ) : null}
      </UserLayout>
      <LoaderWithBackdrop isShow={loading} />
    </>
  );
};
