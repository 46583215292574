import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  listContainer: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    overflow: "auto",
    paddingTop: "12px",
  },
  emptyChatListContainer: {
    margin: 2,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  emptyChatListTitle: {
    color: Colors.white,
    fontSize: "18px",
    textAlign: "center",
  },
  emptyChatListSubtitle: {
    color: theme.palette.grey[700],
    mt: 0.5,
    textAlign: "center",
  },
};
