import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../store";
import { getMediaFromGoogleAccount } from "../store/thunks/media";
import _ from "lodash";
import { IPhotoType, MediaType } from "../store/slices/myPhotos";
import { getResponseFromAxiosError } from "../utils";

interface MediaMetadata {
  creationTime: string;
  width: string;
  height: string;
  photo?: {
    cameraMake: string;
    cameraModel: string;
    focalLength: number;
    apertureFNumber: number;
    isoEquivalent: number;
    exposureTime: string;
  };
  video?: {
    cameraMake: string;
    cameraModel: string;
    fps: number;
    status: number;
  };
}

interface ContributorInfo {
  profilePictureBaseUrl: string;
  displayName: string;
}

export interface IMediaImportGoogle {
  id: number;
  is_imported: boolean;
  description: string;
  productUrl: string;
  baseUrl: string;
  mimeType: string;
  mediaMetadata: MediaMetadata;
  contributorInfo?: ContributorInfo;
  filename: string;
}

enum GOOGLE_PHOTO_ERRORS {
  NO_REFRESH_TOKEN = "NO_REFRESH_TOKEN",
  NO_DATA = "NO_DATA",
}

export const useFetchMediaFromGoogleAccount = () => {
  const dispatch = useAppDispatch();

  const [googleMedia, setGoogleMedia] = useState<IMediaImportGoogle[]>([]);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [isGooglePhotoRefreshTokenError, setIsGooglePhotoRefreshTokenError] =
    useState<boolean>(false);
  const [isGooglePhotoNoDataError, setIsGooglePhotoNoDataError] =
    useState<boolean>(false);

  const fetchMediaFromGoogleAccount = useCallback(
    async (nextPage?: string) => {
      try {
        const response = await dispatch(getMediaFromGoogleAccount(nextPage));

        if (!response) {
          throw new Error();
        }

        if (nextPage) {
          setGoogleMedia((prev) => [...prev, ...response.mediaItems]);
        } else {
          setGoogleMedia(response.mediaItems);
        }

        setNextPage(response.nextPageToken);
      } catch (error: any) {
        const errorFromResponse = getResponseFromAxiosError(error);

        switch (true) {
          case errorFromResponse.code === GOOGLE_PHOTO_ERRORS.NO_REFRESH_TOKEN:
            setIsGooglePhotoRefreshTokenError(true);
            return;
          case errorFromResponse.code === GOOGLE_PHOTO_ERRORS.NO_DATA:
            setIsGooglePhotoNoDataError(true);
            return;
        }

        console.error("Error while [fetchMediaFromGoogleAccount]", error);
      }
    },
    [dispatch],
  );

  const onUpdatePageToken = useCallback(() => {
    if (nextPage) {
      fetchMediaFromGoogleAccount(nextPage);
    }
  }, [fetchMediaFromGoogleAccount, nextPage]);

  useEffect(() => {
    fetchMediaFromGoogleAccount();
  }, [fetchMediaFromGoogleAccount]);

  const parsedGoogleMedia: IPhotoType[] = useMemo(
    () =>
      _.chain(googleMedia)
        .filter({ is_imported: false })
        .map((media) => ({
          created_at: media.mediaMetadata.creationTime,
          description: media.description,
          id: media.id,
          latitude: 0,
          longitude: 0,
          preview: media.baseUrl,
          thumbnail: media.baseUrl,
          full: media.baseUrl,
          original: media.baseUrl,
          title: media.filename,
          is_favorite: false,
          type: MediaType.PHOTO,
        }))
        .value(),
    [googleMedia],
  );

  return {
    media: parsedGoogleMedia,
    isGooglePhotoRefreshTokenError,
    isGooglePhotoNoDataError,
    onUpdatePageToken,
  };
};
