import AABFlag from "./flags/AABFlag.svg";
import AfghanistanFlag from "./flags/AfghanistanFlag.svg";
import AlandIslandsFlag from "./flags/AlandIslandsFlag.svg";
import AlbaniaFlag from "./flags/AlbaniaFlag.svg";
import AlgeriaFlag from "./flags/AlgeriaFlag.svg";
import AmericanSamoaFlag from "./flags/AmericanSamoaFlag.svg";
import AndorraFlag from "./flags/AndorraFlag.svg";
import AngolaFlag from "./flags/AngolaFlag.svg";
import AnguliaFlag from "./flags/AnguliaFlag.svg";
import AntillesFlag from "./flags/AntillesFlag.svg";
import ArgentinaFlag from "./flags/ArgentinaFlag.svg";
import ArmeniaFlag from "./flags/ArmeniaFlag.svg";
import ArubaFlag from "./flags/ArubaFlag.svg";
import AustraliaFlag from "./flags/AustraliaFlag.svg";
import AustriaFlag from "./flags/AustriaFlag.svg";
import AzerbaijanFlag from "./flags/AzerbaijanFlag.svg";
import BahamasFlag from "./flags/BahamasFlag.svg";
import BAHFlag from "./flags/BAHFlag.svg";
import BahrainFlag from "./flags/BahrainFlag.svg";
import BangladeshFlag from "./flags/BangladeshFlag.svg";
import BarbadosFlag from "./flags/BarbadosFlag.svg";
import BelarusFlag from "./flags/BelarusFlag.svg";
import BelgiumFlag from "./flags/BelgiumFlag.svg";
import BelizeFlag from "./flags/BelizeFlag.svg";
import BeninFlag from "./flags/BeninFlag.svg";
import BermudaFlag from "./flags/BermudaFlag.svg";
import BoliviaFlag from "./flags/BoliviaFlag.svg";
import BotswanaFlag from "./flags/BotswanaFlag.svg";
import BrazyliyaFlag from "./flags/BrazyliyaFlag.svg";
import BritishVirginIslandsFlag from "./flags/BritishVirginIslandsFlag.svg";
import BruneiFlag from "./flags/BruneiFlag.svg";
import BulgariaFlag from "./flags/BulgariaFlag.svg";
import BurkinaFasoFlag from "./flags/BurkinaFasoFlag.svg";
import BurundiFlag from "./flags/BurundiFlag.svg";
import ButaneFlag from "./flags/ButaneFlag.svg";
import CambodiaFlag from "./flags/CambodiaFlag.svg";
import CameroonFlag from "./flags/CameroonFlag.svg";
import CanadaFlag from "./flags/CanadaFlag.svg";
import CapeVerdeFlag from "./flags/CapeVerdeFlag.svg";
import CARFlag from "./flags/CARFlag.svg";
import CaymanIslandsFlag from "./flags/CaymanIslandsFlag.svg";
import ChadFlag from "./flags/ChadFlag.svg";
import ChileFlag from "./flags/ChileFlag.svg";
import ChinaFlag from "./flags/ChinaFlag.svg";
import ColombiaFlag from "./flags/ColombiaFlag.svg";
import CommonwealthOfTheNorhernMarianaIslandsFlag from "./flags/CommonwealthOfTheNorhernMarianaIslandsFlag.svg";
import ComorosAndMayotteFlag from "./flags/ComorosAndMayotteFlag.svg";
import CongoFlag from "./flags/CongoFlag.svg";
import CookIslandsFlag from "./flags/CookIslandsFlag.svg";
import CostaRicaFlag from "./flags/CostaRicaFlag.svg";
import CotedlvoireFlag from "./flags/CotedlvoireFlag.svg";
import CroatiaFlag from "./flags/CroatiaFlag.svg";
import CubaFlag from "./flags/CubaFlag.svg";
import CuracaoFlag from "./flags/CuracaoFlag.svg";
import CyprusFlag from "./flags/CyprusFlag.svg";
import CzechRepublicFlag from "./flags/CzechRepublicFlag.svg";
import DanmarkFlag from "./flags/DanmarkFlag.svg";
import DiegoGarciaFlag from "./flags/DiegoGarciaFlag.svg";
import DjiboutiFlag from "./flags/DjiboutiFlag.svg";
import DominicaFlag from "./flags/DominicaFlag.svg";
import DominicanRepublicFlag from "./flags/DominicanRepublicFlag.svg";
import EcuadorFlag from "./flags/EcuadorFlag.svg";
import EgyptFlag from "./flags/EgyptFlag.svg";
import ElSalvadorFlag from "./flags/ElSalvadorFlag.svg";
import EmptyFlag from "./flags/EmptyFlag.svg";
import EquatorialGuineaFlag from "./flags/EquatorialGuineaFlag.svg";
import EritreaFlag from "./flags/EritreaFlag.svg";
import EstoniaFlag from "./flags/EstoniaFlag.svg";
import EthiopiaFlag from "./flags/EthiopiaFlag.svg";
import FalklandIslandsFlag from "./flags/FalklandIslandsFlag.svg";
import FaroeIslandsFlag from "./flags/FaroeIslandsFlag.svg";
import FijiFlag from "./flags/FijiFlag.svg";
import FinlandFlag from "./flags/FinlandFlag.svg";
import FranceFlag from "./flags/FranceFlag.svg";
import FrenchPolynesiaFlag from "./flags/FrenchPolynesiaFlag.svg";
import GabonFlag from "./flags/GabonFlag.svg";
import GambiaFlag from "./flags/GambiaFlag.svg";
import GeorgiaFlag from "./flags/GeorgiaFlag.svg";
import GermanyFlag from "./flags/GermanyFlag.svg";
import GhanaFlag from "./flags/GhanaFlag.svg";
import GibraltarFlag from "./flags/GibraltarFlag.svg";
import GreeceFlag from "./flags/GreeceFlag.svg";
import GreenlandFlag from "./flags/GreenlandFlag.svg";
import GrenadaFlag from "./flags/GrenadaFlag.svg";
import GuamFlag from "./flags/GuamFlag.svg";
import GuatemalaFlag from "./flags/GuatemalaFlag.svg";
import GuineaBissauFlag from "./flags/GuineaBissauFlag.svg";
import GuineaFlag from "./flags/GuineaFlag.svg";
import GuyanaFlag from "./flags/GuyanaFlag.svg";
import HaitiFlag from "./flags/HaitiFlag.svg";
import HondurasFlag from "./flags/HondurasFlag.svg";
import HongKongFlag from "./flags/HongKongFlag.svg";
import HungaryFlag from "./flags/HungaryFlag.svg";
import IcelandFlag from "./flags/IcelandFlag.svg";
import IndiaFlag from "./flags/IndiaFlag.svg";
import IndonesiaFlag from "./flags/IndonesiaFlag.svg";
import IranFlag from "./flags/IranFlag.svg";
import IraqFlag from "./flags/IraqFlag.svg";
import IrishRepublicFlag from "./flags/IrishRepublicFlag.svg";
import IsraelFlag from "./flags/IsraelFlag.svg";
import ItalyFlag from "./flags/ItalyFlag.svg";
import JamaicaFlag from "./flags/JamaicaFlag.svg";
import JapanFlag from "./flags/JapanFlag.svg";
import JordanFlag from "./flags/JordanFlag.svg";
import KazakhstanFlag from "./flags/KazakhstanFlag.svg";
import KenyaFlag from "./flags/KenyaFlag.svg";
import KiribatiRepublicFlag from "./flags/KiribatiRepublicFlag.svg";
import KittsAndNevisFlag from "./flags/KittsAndNevisFlag.svg";
import KuwaitFlag from "./flags/KuwaitFlag.svg";
import KyrgyzstanFlag from "./flags/KyrgyzstanFlag.svg";
import LaosFlag from "./flags/LaosFlag.svg";
import LatviaFlag from "./flags/LatviaFlag.svg";
import LebanonFlag from "./flags/LebanonFlag.svg";
import LesothoFlag from "./flags/LesothoFlag.svg";
import LiberiaFlag from "./flags/LiberiaFlag.svg";
import LibyaFlag from "./flags/LibyaFlag.svg";
import LiechtensteinFlag from "./flags/LiechtensteinFlag.svg";
import LithuaniaFlag from "./flags/LithuaniaFlag.svg";
import LuciaFlag from "./flags/LuciaFlag.svg";
import LuxembourgFlag from "./flags/LuxembourgFlag.svg";
import MacaoFlag from "./flags/MacaoFlag.svg";
import MacedoniaFlag from "./flags/MacedoniaFlag.svg";
import MadagascarFlag from "./flags/MadagascarFlag.svg";
import MalawiFlag from "./flags/MalawiFlag.svg";
import MalaysiaFlag from "./flags/MalaysiaFlag.svg";
import MaldivesFlag from "./flags/MaldivesFlag.svg";
import MaliFlag from "./flags/MaliFlag.svg";
import MaltaFlag from "./flags/MaltaFlag.svg";
import MaroccoFlag from "./flags/MaroccoFlag.svg";
import MarshallIslandsFlag from "./flags/MarshallIslandsFlag.svg";
import MartiniqueFlag from "./flags/MartiniqueFlag.svg";
import MauritaniaFlag from "./flags/MauritaniaFlag.svg";
import MauritiusFlag from "./flags/MauritiusFlag.svg";
import MexicoFlag from "./flags/MexicoFlag.svg";
import MicronesiaFlag from "./flags/MicronesiaFlag.svg";
import MoldovaFlag from "./flags/MoldovaFlag.svg";
import MonacoFlag from "./flags/MonacoFlag.svg";
import MongoliaFlag from "./flags/MongoliaFlag.svg";
import MontenegroFlag from "./flags/MontenegroFlag.svg";
import MontserratFlag from "./flags/MontserratFlag.svg";
import MozambiqueFlag from "./flags/MozambiqueFlag.svg";
import MyanmarFlag from "./flags/MyanmarFlag.svg";
import NamibiaFlag from "./flags/NamibiaFlag.svg";
import NauruFlag from "./flags/NauruFlag.svg";
import NepalFlag from "./flags/NepalFlag.svg";
import NetherlandsFlag from "./flags/NetherlandsFlag.svg";
import NewCaledoniaFlag from "./flags/NewCaledoniaFlag.svg";
import NewZealandFlag from "./flags/NewZealandFlag.svg";
import NicaraguaFlag from "./flags/NicaraguaFlag.svg";
import NigerFlag from "./flags/NigerFlag.svg";
import NigeriaFlag from "./flags/NigeriaFlag.svg";
import NiueFlag from "./flags/NiueFlag.svg";
import NorfolkIslandFlag from "./flags/NorfolkIslandFlag.svg";
import NorthKoreaFlag from "./flags/NorthKoreaFlag.svg";
import NorthYemenFlag from "./flags/NorthYemenFlag.svg";
import NorwayFlag from "./flags/NorwayFlag.svg";
import NotMatchedFlag from "./flags/NotMatchedFlag.svg";
import OAEFlag from "./flags/OAEFlag.svg";
import OmanFlag from "./flags/OmanFlag.svg";
import PakistanFlag from "./flags/PakistanFlag.svg";
import PalayFlag from "./flags/PalayFlag.svg";
import PalestinFlag from "./flags/PalestinFlag.svg";
import PanamaFlag from "./flags/PanamaFlag.svg";
import PapuaNewGuineaFlag from "./flags/PapuaNewGuineaFlag.svg";
import ParaguayFlag from "./flags/ParaguayFlag.svg";
import PeruFlag from "./flags/PeruFlag.svg";
import PhilippinesFlag from "./flags/PhilippinesFlag.svg";
import PolandFlag from "./flags/PolandFlag.svg";
import PortugalFlag from "./flags/PortugalFlag.svg";
import PuertoRicoFlag from "./flags/PuertoRicoFlag.svg";
import QatarFlag from "./flags/QatarFlag.svg";
import RepublicOfSouthAfricaFlag from "./flags/RepublicOfSouthAfricaFlag.svg";
import RomaniaFlag from "./flags/RomaniaFlag.svg";
import RussiaFlag from "./flags/RussiaFlag.svg";
import RwandeseRepublicFlag from "./flags/RwandeseRepublicFlag.svg";
import SanMarinoFlag from "./flags/SanMarinoFlag.svg";
import SaoTomeAndPrincipeFlag from "./flags/SaoTomeAndPrincipeFlag.svg";
import SaudiArabiaFlag from "./flags/SaudiArabiaFlag.svg";
import SenegalFlag from "./flags/SenegalFlag.svg";
import SeychellesFlag from "./flags/SeychellesFlag.svg";
import SierraLeoneFlag from "./flags/SierraLeoneFlag.svg";
import SingaporeFlag from "./flags/SingaporeFlag.svg";
import SintMaartenFlag from "./flags/SintMaartenFlag.svg";
import SlovakiaFlag from "./flags/SlovakiaFlag.svg";
import SloveniaFlag from "./flags/SloveniaFlag.svg";
import SolomonIslandsFlag from "./flags/SolomonIslandsFlag.svg";
import SomaliaFlag from "./flags/SomaliaFlag.svg";
import SouthKoreaFlag from "./flags/SouthKoreaFlag.svg";
import SouthSudanFlag from "./flags/SouthSudanFlag.svg";
import SpainFlag from "./flags/SpainFlag.svg";
import SrbijaFlag from "./flags/SrbijaFlag.svg";
import SriLankaFlag from "./flags/SriLankaFlag.svg";
import StHelenaFlag from "./flags/StHelenaFlag.svg";
import StVincentAndTheGrenadinesFlag from "./flags/StVincentAndTheGrenadinesFlag.svg";
import SudanFlag from "./flags/SudanFlag.svg";
import SurinameFlag from "./flags/SurinameFlag.svg";
import SwazilandFlag from "./flags/SwazilandFlag.svg";
import SwedenFlag from "./flags/SwedenFlag.svg";
import SwitzerlandFlag from "./flags/SwitzerlandFlag.svg";
import SyriaFlag from "./flags/SyriaFlag.svg";
import TadjikistanFlag from "./flags/TadjikistanFlag.svg";
import TaiwanFlag from "./flags/TaiwanFlag.svg";
import TanzaniaFlag from "./flags/TanzaniaFlag.svg";
import ThailandFlag from "./flags/ThailandFlag.svg";
import TogoleseRepublicFlag from "./flags/TogoleseRepublicFlag.svg";
import TokelauFlag from "./flags/TokelauFlag.svg";
import TongaFlag from "./flags/TongaFlag.svg";
import TrinidadAndTobagoFlag from "./flags/TrinidadAndTobagoFlag.svg";
import TunisiaFlag from "./flags/TunisiaFlag.svg";
import TurkeyFlag from "./flags/TurkeyFlag.svg";
import TurkmenistanFlag from "./flags/TurkmenistanFlag.svg";
import TurksAndCaicosIslandsFlag from "./flags/TurksAndCaicosIslandsFlag.svg";
import TuvaluFlag from "./flags/TuvaluFlag.svg";
import UgandaFlag from "./flags/UgandaFlag.svg";
import UkraineFlag from "./flags/UkraineFlag.svg";
import UnitedKingdomFlag from "./flags/UnitedKingdomFlag.svg";
import UruguayFlag from "./flags/UruguayFlag.svg";
import USAFlag from "./flags/USAFlag.svg";
import UsVirginIslandsFlag from "./flags/UsVirginIslandsFlag.svg";
import UzbekistanFlag from "./flags/UzbekistanFlag.svg";
import VanuatuFlag from "./flags/VanuatuFlag.svg";
import VaticanFlag from "./flags/VaticanFlag.svg";
import VenezuelaFlag from "./flags/VenezuelaFlag.svg";
import VietnamFlag from "./flags/VietnamFlag.svg";
import WesternSamoaFlag from "./flags/WesternSamoaFlag.svg";
import ZaireFlag from "./flags/ZaireFlag.svg";
import ZambiaFlag from "./flags/ZambiaFlag.svg";
import ZimbabweFlag from "./flags/ZimbabweFlag.svg";

const Flags = {
  AustraliaFlag,
  AustriaFlag,
  AzerbaijanFlag,
  AlandIslandsFlag,
  AlbaniaFlag,
  AlgeriaFlag,
  AnguliaFlag,
  AngolaFlag,
  UkraineFlag,
  OAEFlag,
  ArmeniaFlag,
  ArgentinaFlag,
  EmptyFlag,
  GermanyFlag,
  FranceFlag,
  UnitedKingdomFlag,
  USAFlag,
  ChinaFlag,
  AABFlag,
  AndorraFlag,
  ArubaFlag,
  AmericanSamoaFlag,
  AfghanistanFlag,
  AntillesFlag,
  BAHFlag,
  BangladeshFlag,
  BarbadosFlag,
  BahrainFlag,
  BelgiumFlag,
  BurkinaFasoFlag,
  BulgariaFlag,
  BeninFlag,
  BahamasFlag,
  BoliviaFlag,
  BotswanaFlag,
  ButaneFlag,
  BelarusFlag,
  BrazyliyaFlag,
  BruneiFlag,
  BurundiFlag,
  BermudaFlag,
  BelizeFlag,
  CanadaFlag,
  CameroonFlag,
  ChileFlag,
  CongoFlag,
  SwitzerlandFlag,
  ZaireFlag,
  CARFlag,
  ColombiaFlag,
  CotedlvoireFlag,
  CapeVerdeFlag,
  CostaRicaFlag,
  CubaFlag,
  CyprusFlag,
  CzechRepublicFlag,
  DjiboutiFlag,
  DominicaFlag,
  DominicanRepublicFlag,
  DanmarkFlag,
  EstoniaFlag,
  SpainFlag,
  GreeceFlag,
  IsraelFlag,
  ItalyFlag,
  MoldovaFlag,
  MontenegroFlag,
  NetherlandsFlag,
  PolandFlag,
  PortugalFlag,
  RomaniaFlag,
  TurkeyFlag,
  FinlandFlag,
  EcuadorFlag,
  EgyptFlag,
  EritreaFlag,
  EthiopiaFlag,
  FalklandIslandsFlag,
  FijiFlag,
  MicronesiaFlag,
  FaroeIslandsFlag,
  GabonFlag,
  GrenadaFlag,
  GeorgiaFlag,
  GhanaFlag,
  GibraltarFlag,
  GreenlandFlag,
  GambiaFlag,
  GuineaFlag,
  EquatorialGuineaFlag,
  CuracaoFlag,
  CookIslandsFlag,
  ComorosAndMayotteFlag,
  CroatiaFlag,
  GuatemalaFlag,
  GuyanaFlag,
  GuamFlag,
  GuineaBissauFlag,
  HaitiFlag,
  HondurasFlag,
  HongKongFlag,
  HungaryFlag,
  IndonesiaFlag,
  IndiaFlag,
  DiegoGarciaFlag,
  IraqFlag,
  IranFlag,
  IcelandFlag,
  IrishRepublicFlag,
  JordanFlag,
  JapanFlag,
  JamaicaFlag,
  CambodiaFlag,
  CaymanIslandsFlag,
  KazakhstanFlag,
  KenyaFlag,
  KittsAndNevisFlag,
  KuwaitFlag,
  KyrgyzstanFlag,
  NorthKoreaFlag,
  SouthKoreaFlag,
  LaosFlag,
  LatviaFlag,
  LebanonFlag,
  LesothoFlag,
  LiberiaFlag,
  LibyaFlag,
  LiechtensteinFlag,
  LithuaniaFlag,
  SriLankaFlag,
  LuciaFlag,
  LuxembourgFlag,
  MaroccoFlag,
  MonacoFlag,
  MadagascarFlag,
  MacaoFlag,
  MacedoniaFlag,
  MalawiFlag,
  MalaysiaFlag,
  MaldivesFlag,
  MaliFlag,
  MaltaFlag,
  MauritiusFlag,
  MauritaniaFlag,
  MexicoFlag,
  MongoliaFlag,
  MontserratFlag,
  MozambiqueFlag,
  MyanmarFlag,
  NamibiaFlag,
  NepalFlag,
  NewZealandFlag,
  NicaraguaFlag,
  NigerFlag,
  NigeriaFlag,
  NorwayFlag,
  OmanFlag,
  PanamaFlag,
  PakistanFlag,
  PalayFlag,
  PapuaNewGuineaFlag,
  PeruFlag,
  PhilippinesFlag,
  FrenchPolynesiaFlag,
  ParaguayFlag,
  QatarFlag,
  RwandeseRepublicFlag,
  SrbijaFlag,
  RussiaFlag,
  SeychellesFlag,
  SingaporeFlag,
  SolomonIslandsFlag,
  SwedenFlag,
  SudanFlag,
  SanMarinoFlag,
  SenegalFlag,
  SierraLeoneFlag,
  SlovakiaFlag,
  SloveniaFlag,
  SomaliaFlag,
  StHelenaFlag,
  SurinameFlag,
  SaoTomeAndPrincipeFlag,
  ElSalvadorFlag,
  SyriaFlag,
  SwazilandFlag,
  TurksAndCaicosIslandsFlag,
  ChadFlag,
  TogoleseRepublicFlag,
  ThailandFlag,
  TadjikistanFlag,
  TaiwanFlag,
  TanzaniaFlag,
  TongaFlag,
  TrinidadAndTobagoFlag,
  TunisiaFlag,
  TurkmenistanFlag,
  UruguayFlag,
  UgandaFlag,
  UzbekistanFlag,
  StVincentAndTheGrenadinesFlag,
  BritishVirginIslandsFlag,
  VanuatuFlag,
  VenezuelaFlag,
  VietnamFlag,
  NorthYemenFlag,
  WesternSamoaFlag,
  RepublicOfSouthAfricaFlag,
  ZambiaFlag,
  ZimbabweFlag,
  PuertoRicoFlag,
  KiribatiRepublicFlag,
  MarshallIslandsFlag,
  CommonwealthOfTheNorhernMarianaIslandsFlag,
  MartiniqueFlag,
  NauruFlag,
  NewCaledoniaFlag,
  NiueFlag,
  NorfolkIslandFlag,
  PalestinFlag,
  SaudiArabiaFlag,
  SintMaartenFlag,
  SouthSudanFlag,
  TokelauFlag,
  TuvaluFlag,
  VaticanFlag,
  UsVirginIslandsFlag,
  NotMatchedFlag,
};

export type IFlags = keyof typeof Flags;

export default Flags;
