import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { materialStyles } from "./styles";

type IBlockEmptyWithAdd = {
  title: string;
  onClick?: () => void;
};

export const BlockEmptyWithAdd: React.FC<IBlockEmptyWithAdd> = ({
  title,
  onClick,
}) => {
  return (
    <Grid sx={materialStyles.blockEmptyWithAdd}>
      <IconButton sx={materialStyles.blockEmptyWithAddButton} onClick={onClick}>
        <AddIcon sx={materialStyles.blockEmptyWithAddIcon} />
      </IconButton>
      <Typography sx={materialStyles.blockEmptyTitle}>{title}</Typography>
    </Grid>
  );
};
