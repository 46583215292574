import { createBrowserRouter } from "react-router-dom";
import { Map } from "../pages/Map";
import { UserSettings } from "../pages/UserSettings";
import { Photos } from "../pages/Photos";
import { Favorites } from "../pages/Favorites";
import { Albums } from "../pages/Albums";
import { Trash } from "../pages/Trash";
import { NotFound } from "../pages/NotFound";
import { WithGuard } from "../components/helpers/WithGuard";
import { Explore } from "../pages/Explore";
import { AuthToken } from "../pages/AuthToken";
import { PhotoPage } from "../pages/Photo";
import { MessagesPage } from "../pages/Messages";
import { CommonModalsProvider } from "../components/providers/CommonModalsProvider";

export const router = createBrowserRouter([
  {
    path: "/photos",
    element: (
      <WithGuard>
        <CommonModalsProvider>
          <Photos />
        </CommonModalsProvider>
      </WithGuard>
    ),
  },
  {
    path: "/",
    element: (
      <CommonModalsProvider>
        <Map />
      </CommonModalsProvider>
    ),
  },
  {
    path: "/:username",
    element: (
      <CommonModalsProvider>
        <Map />
      </CommonModalsProvider>
    ),
  },
  {
    path: "settings",
    element: (
      <WithGuard>
        <CommonModalsProvider>
          <UserSettings />
        </CommonModalsProvider>
      </WithGuard>
    ),
  },
  {
    path: "favorites",
    element: (
      <WithGuard>
        <CommonModalsProvider>
          <Favorites />
        </CommonModalsProvider>
      </WithGuard>
    ),
  },
  {
    path: "albums",
    element: (
      <WithGuard>
        <CommonModalsProvider>
          <Albums />
        </CommonModalsProvider>
      </WithGuard>
    ),
  },
  {
    path: "trash",
    element: (
      <WithGuard>
        <CommonModalsProvider>
          <Trash />
        </CommonModalsProvider>
      </WithGuard>
    ),
  },
  {
    path: "explore",
    element: (
      <CommonModalsProvider>
        <Explore />
      </CommonModalsProvider>
    ),
  },
  {
    path: "auth/token",
    element: (
      <CommonModalsProvider>
        <AuthToken />
      </CommonModalsProvider>
    ),
  },
  {
    path: "messages",
    element: (
      <WithGuard>
        <CommonModalsProvider>
          <MessagesPage />
        </CommonModalsProvider>
      </WithGuard>
    ),
  },
  {
    path: "file/:id",
    element: (
      <CommonModalsProvider>
        <PhotoPage />
      </CommonModalsProvider>
    ),
  },
  {
    path: "*",
    element: (
      <CommonModalsProvider>
        <NotFound />
      </CommonModalsProvider>
    ),
  },
]);
