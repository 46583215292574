import { Grid, Typography } from "@mui/material";
import React from "react";
import { IMediaComment } from "../../../../store/slices/userPhoto";
import { materialStyles } from "./styles";
import _ from "lodash";
import { UserAvatar } from "../../../user/UserAvatar";
import { parseCreateDateToFormatDiff } from "../../../../utils";

type ICommentItemProps = {
  comment: IMediaComment;
};

export const CommentItem: React.FC<ICommentItemProps> = ({ comment }) => {
  return (
    <Grid sx={materialStyles.commentItemContainer}>
      <UserAvatar type="AVATAR_LIST" user={comment.user} />
      <Grid sx={materialStyles.commentContainer}>
        <Typography sx={materialStyles.commentText}>
          <span id="username">{_.get(comment, "user.username", null)}</span>
          {comment.comment}
        </Typography>
        <Grid sx={materialStyles.additionalCommentContainer}>
          <Typography sx={materialStyles.additionalText}>
            {parseCreateDateToFormatDiff(comment.created_at)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
