import { Box, Modal } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { materialStyles } from "./styles";
import { NewAlbumMainForm } from "./components/NewAlbumMainForm";
import { SelectAlbumPhotoForm } from "./components/SelectAlbumPhotoForm";
import { useAppDispatch, useAppSelector } from "../../../store";
import { resetNewAlbum } from "../../../store/slices/newAlbum";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";

export type INewAlbumModalType = "NEW_ALBUM_MAIN" | "SELECT_ALBUM_PHOTO";

type INewAlbumModal = {
  isOpen: boolean;
  onClose: () => void;
  initialModalType?: INewAlbumModalType;
};

export const NewAlbumModal: React.FC<INewAlbumModal> = ({
  isOpen,
  onClose,
  initialModalType = "NEW_ALBUM_MAIN",
}) => {
  const [newAlbumModalType, setNewAlbumModalType] =
    useState<INewAlbumModalType>(initialModalType);

  const { loading } = useAppSelector((state) => state.loader);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setNewAlbumModalType(initialModalType);
  }, [isOpen, initialModalType]);

  const onCloseModal = useCallback(() => {
    dispatch(resetNewAlbum());
    onClose();
  }, [dispatch, onClose]);

  const onChangeNewAlbumModalType = useCallback((type: INewAlbumModalType) => {
    setNewAlbumModalType(type);
  }, []);

  return (
    <Modal sx={materialStyles.modal} open={isOpen} onClose={onClose}>
      <Box sx={materialStyles.modalContainer}>
        {newAlbumModalType === "NEW_ALBUM_MAIN" ? (
          <NewAlbumMainForm
            onClose={onCloseModal}
            onChangeNewAlbumModalType={onChangeNewAlbumModalType}
          />
        ) : null}
        {newAlbumModalType === "SELECT_ALBUM_PHOTO" ? (
          <SelectAlbumPhotoForm
            onClose={onCloseModal}
            onChangeNewAlbumModalType={onChangeNewAlbumModalType}
          />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
