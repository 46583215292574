import { Grid, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { BlockWithTitle } from "../../blocks/blockWithTitle/BlockWithTitle";
import { BlockWithTitleButton } from "../../blocks/blockWithTitle/BlockWithTitleButton";
import { CreateSupportRequestModal } from "../../modals/createSupportRequestModal";
import { useFetchSupportRequests } from "../../../hooks/useFetchSupportRequests";
import _ from "lodash";
import { BlockWithTitleItem } from "../../blocks/blockWithTitle/BlockWithTitleItem";
import { BlockWithTitleDivider } from "../../blocks/blockWithTitle/BlockWithTitleDivider";
import { ISupportRequest } from "../../../store/slices/supportRequests";
import { useAppDispatch } from "../../../store";
import { setSupportRequestId } from "../../../store/slices/supportRequestConversation";
import { SupportConversationModal } from "../../modals/supportConversationModal";
import { parseCreateDateToFormatDiff } from "../../../utils";

export const Support: React.FC = () => {
  const { t } = useTranslation();

  const { supportRequests } = useFetchSupportRequests();

  const dispatch = useAppDispatch();

  const [isOpenCreateSupportRequestModal, setIsOpenCreateSupportRequestModal] =
    useState(false);
  const [isOpenSupportConversationModal, setSupportConversationModal] =
    useState(false);

  const onOpenCreateSupportRequestModal = useCallback(() => {
    setIsOpenCreateSupportRequestModal(true);
  }, []);

  const onCloseCreateSupportRequestModal = useCallback(
    () => setIsOpenCreateSupportRequestModal(false),
    [],
  );

  const onOpenSupportConversationModal = useCallback(
    (id: ISupportRequest["id"]) => {
      dispatch(setSupportRequestId(id));
      setSupportConversationModal(true);
    },
    [dispatch],
  );

  const onCloseSupportConversationModal = useCallback(() => {
    setSupportConversationModal(false);
  }, []);

  return (
    <Grid>
      <Typography sx={materialStyles.contentHeaderTitle}>
        {t("settings.support")}
      </Typography>
      <Grid sx={materialStyles.blocks}>
        <BlockWithTitle title={t("settings.support_requests")}>
          {_.map(supportRequests, (request) => (
            <>
              <BlockWithTitleItem
                type="ARROW"
                title={t("settings.support_request_with_number", {
                  id: request.id,
                })}
                subtitle={parseCreateDateToFormatDiff(request.created_at)}
                onClick={() => onOpenSupportConversationModal(request.id)}
              />
              <BlockWithTitleDivider />
            </>
          ))}
          <BlockWithTitleButton
            title={t("settings.create_support_request")}
            onClick={onOpenCreateSupportRequestModal}
          />
        </BlockWithTitle>
      </Grid>
      <CreateSupportRequestModal
        isOpen={isOpenCreateSupportRequestModal}
        onClose={onCloseCreateSupportRequestModal}
      />
      <SupportConversationModal
        isOpen={isOpenSupportConversationModal}
        onClose={onCloseSupportConversationModal}
      />
    </Grid>
  );
};
