import { ButtonBase, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { materialStyles } from "./styles";
import { Message } from "../../../../store/slices/messages";
import { UserAvatar } from "../../../user/UserAvatar";
import { parseCreateDateToFormatDiff } from "../../../../utils";
import { IUserType } from "../../../../store/slices/user";
import { useAppSelector } from "../../../../store";
import { useSocketChatHandle } from "../../../../hooks/useSocketChatHandle";

type TChatListItem = {
  chat: Message;
  onClick?: (id: Message["id"], user?: Message["user"]) => void;
  activeChatUser?: IUserType | null;
};

export const ChatListItem: React.FC<TChatListItem> = ({
  chat,
  activeChatUser,
  onClick,
}) => {
  const { user } = useAppSelector((state) => state.user);

  const { onSocketJoin, onSocketLeave } = useSocketChatHandle(chat);

  const isActive = useMemo(() => {
    if (!activeChatUser) {
      return false;
    }

    if (activeChatUser.id === chat.user_id) {
      return true;
    }

    if (activeChatUser.id === chat.sender_id) {
      return true;
    }

    return false;
  }, [activeChatUser, chat.sender_id, chat.user_id]);

  const chatUser = useMemo(() => {
    if (!user) {
      return;
    }

    if (chat.sender_id === user.id) {
      return chat.user;
    }

    if (chat.user_id === user.id) {
      return chat.sender;
    }
  }, [chat.sender, chat.sender_id, chat.user, chat.user_id, user]);

  useEffect(() => {
    onSocketJoin();

    return () => {
      onSocketLeave();
    };
  }, [onSocketJoin, onSocketLeave]);

  return (
    <ButtonBase
      onClick={() => onClick && onClick(chat.id, chatUser)}
      sx={[
        materialStyles.itemContainer,
        isActive && materialStyles.itemActiveContainer,
      ]}
    >
      <UserAvatar type="DEFAULT" user={chatUser} />
      <Grid sx={materialStyles.itemContainerInfo}>
        <Grid sx={materialStyles.itemInnerContainerInfo}>
          <Typography sx={materialStyles.itemUsername}>
            {chatUser?.username}
          </Typography>
          <Typography sx={materialStyles.itemLastMessage}>
            {chat.message}
          </Typography>
        </Grid>
        <Grid sx={materialStyles.itemLastMessageContainer}>
          <Typography sx={materialStyles.itemLastMessageTime}>
            {parseCreateDateToFormatDiff(chat.created_at)}
          </Typography>
          {!chat.is_read && user?.id !== chat.sender_id ? (
            <Grid sx={materialStyles.chatIsNotReadIndicator} />
          ) : null}
        </Grid>
      </Grid>
    </ButtonBase>
  );
};
