import {
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
  ButtonBase,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { materialStyles } from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { useAppDispatch, useAppSelector } from "../../../store";
import _ from "lodash";
import { UserAvatar } from "../../user/UserAvatar";
import { UserModal } from "../userModal";
import { setUserId } from "../../../store/slices/userProfile";
import {
  useSearchPeoples,
  DISPLAY_USERS_FROM_LENGTH,
} from "../../../hooks/useSearchPeoples";
import { useAuth } from "../../../hooks/useAuth";
import { useToast } from "rc-toastr";
import { useFiltersTypeByPage } from "../../../hooks/useFiltersTypeByPage";
import { setMapPhotosFilters } from "../../../store/slices/map";

type IMobileSearchInput = {
  isOpen: boolean;
  onClose: () => void;
};

export const MobileSearchInput: React.FC<IMobileSearchInput> = ({
  isOpen,
  onClose,
}) => {
  const [search, setSearch] = useState("");
  const { toast } = useToast();
  const { t } = useTranslation();
  const { loading } = useAppSelector((state) => state.loader);
  const dispatch = useAppDispatch();

  const type = useFiltersTypeByPage();

  const isAuth = useAuth();

  const { findedUsers, debouncedSearch } = useSearchPeoples(search, true);

  const [isOpenUserModal, setIsOpenUserModal] = useState(false);

  const onSearchChange = useCallback((event: any) => {
    const { value } = event.target;

    setSearch(value);
  }, []);

  const onOpenUserModal = useCallback(
    (id: number) => {
      if (!isAuth) {
        toast.warning(
          t("common.this_functionality_available_registered_users")
        );
        return;
      }

      const findedUser = _.find(findedUsers, { id });

      if (!findedUser) {
        return;
      }

      switch (type) {
        case "MAP":
          dispatch(setMapPhotosFilters({ peoples: [findedUser] }));
          onClose();
          return;
      }

      dispatch(setUserId(id));
      setIsOpenUserModal(true);
    },
    [dispatch, findedUsers, isAuth, onClose, t, toast, type]
  );

  const onCloseUserModal = useCallback(() => {
    setIsOpenUserModal(false);
  }, []);

  return (
    <Modal sx={materialStyles.modal} open={isOpen} onClose={onClose}>
      <Box sx={materialStyles.modalContainer}>
        <Grid sx={materialStyles.modalInnerContent}>
          <Grid sx={materialStyles.searchConteiner}>
            <IconButton onClick={onClose}>
              <ArrowBackIcon sx={materialStyles.headerIcon} />
            </IconButton>
            <TextField
              name="searchInput"
              id="searchInput"
              sx={materialStyles.searchInput}
              fullWidth
              onChange={onSearchChange}
              placeholder={t("header.search_users")}
              InputProps={{
                startAdornment: <SearchIcon sx={materialStyles.searchIcon} />,
              }}
            />
          </Grid>
          <Grid sx={materialStyles.usersBlock}>
            {_.isEmpty(findedUsers) && !loading ? (
              <Typography sx={materialStyles.listEmptyText}>
                {debouncedSearch.length >= DISPLAY_USERS_FROM_LENGTH
                  ? t("header.unable_find_user")
                  : t("header.to_search_enter_more_than")}
              </Typography>
            ) : null}
            {!loading
              ? _.map(findedUsers, (user) => (
                  <ButtonBase
                    key={user.id}
                    onClick={() => onOpenUserModal(user.id)}
                    sx={materialStyles.userBlock}
                  >
                    <UserAvatar user={user} type="PHOTO_PAGE" />
                    <Typography sx={materialStyles.userName}>
                      {user.username}
                    </Typography>
                  </ButtonBase>
                ))
              : null}
            {loading ? (
              <Grid sx={materialStyles.loadingContainer}>
                <CircularProgress />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <UserModal isOpen={isOpenUserModal} onClose={onCloseUserModal} />
      </Box>
    </Modal>
  );
};
