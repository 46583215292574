import { Button, Grid, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles } from "./styles";
import { IEditModalContentType, IEditModalType } from "..";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { editMediaById } from "../../../../store/thunks/media";
import { editAlbum } from "../../../../store/thunks/albums";
import { setUserPhoto } from "../../../../store/slices/userPhoto";
import { setUserAlbum } from "../../../../store/slices/userAlbum";
import { updateAlbumInStorage } from "../../../../store/slices/albums";

type IConfirmEditModalForm = {
  type: IEditModalContentType;
  onClose: () => void;
  onChangeModalType: (type: IEditModalType) => void;
};

export const ConfirmEditModalForm: React.FC<IConfirmEditModalForm> = ({
  type,
  onClose,
  onChangeModalType,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { actionId } = useAppSelector((state) => state.edit);

  const onConfirm = useCallback(async () => {
    try {
      if (!actionId) {
        return;
      }

      switch (type) {
        case "PHOTO":
          const newPhoto = await dispatch(editMediaById(actionId));
          dispatch(setUserPhoto(newPhoto));
          onChangeModalType("SUCCESS_MODAL");
          break;
        case "ALBUM":
          const newAlbum = await dispatch(editAlbum(actionId));
          dispatch(setUserAlbum(newAlbum));
          dispatch(updateAlbumInStorage(newAlbum));
          onChangeModalType("SUCCESS_MODAL");
          break;
      }
    } catch (error) {
      console.error("Error while onConfirm", error);
    }
  }, [actionId, dispatch, onChangeModalType, type]);

  return (
    <Grid sx={materialStyles.innerModalContainer}>
      <Typography sx={materialStyles.confirmModalHeaderTitle}>
        {
          {
            ALBUM: t("edit.confirm_album_edit"),
            PHOTO: t("edit.confirm_photo_edit"),
          }[type]
        }
      </Typography>
      <Button
        variant="contained"
        sx={materialStyles.confirmButton}
        onClick={onConfirm}
      >
        {t("common.confirm")}
      </Button>
    </Grid>
  );
};
