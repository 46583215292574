import {
  ButtonBase,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { materialStyles } from "./styles";
import _ from "lodash";

export type IMenuItems = {
  title: string;
  onPress?: () => void;
};

type IAdditionalMenuButton = {
  menuItems: IMenuItems[];
};

export const AdditionalMenuButton: React.FC<IAdditionalMenuButton> = ({
  menuItems,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClickItem = useCallback(
    (item: IMenuItems) => {
      handleClose();
      item.onPress && item.onPress();
    },
    [handleClose]
  );

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertOutlinedIcon sx={materialStyles.additionalMenuButtonIcon} />
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: materialStyles.popoverContainer,
          },
        }}
      >
        <Grid sx={materialStyles.additionalMenuItems}>
          {_.map(menuItems, (item) => (
            <ButtonBase
              key={item.title}
              sx={materialStyles.additionalMenuItem}
              onClick={() => onClickItem(item)}
            >
              <Typography sx={materialStyles.additionalMenuItemTitle}>
                {item.title}
              </Typography>
            </ButtonBase>
          ))}
        </Grid>
      </Popover>
    </>
  );
};
