import { useCallback, useRef, useState } from "react";

type UseSwipeEffectProps = {
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
};

export const useSwipeEffect = ({
  onSwipeLeft,
  onSwipeRight,
}: UseSwipeEffectProps) => {
  const ref = useRef(null);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const threshold = 50;
  const allowedTime = 500;

  const handleTouchStart = useCallback((e: any) => {
    const touchObj = e.changedTouches[0];
    setStartX(touchObj.pageX);
    setStartY(touchObj.pageY);
    setStartTime(new Date().getTime());
  }, []);

  const handleTouchEnd = useCallback(
    (e: any) => {
      const touchObj = e.changedTouches[0];
      const distX = touchObj.pageX - startX;
      const distY = touchObj.pageY - startY;
      const elapsedTime = new Date().getTime() - startTime;

      if (elapsedTime <= allowedTime) {
        if (Math.abs(distX) >= threshold && Math.abs(distY) <= 100) {
          if (distX > 0) {
            onSwipeLeft && onSwipeLeft();
          } else {
            onSwipeRight && onSwipeRight();
          }
        }
      }
    },
    [onSwipeLeft, onSwipeRight, startTime, startX, startY]
  );

  return {
    ref,
    handleTouchStart,
    handleTouchEnd,
  };
};
