import moment from "moment";
import { IUserType } from "../store/slices/user";

export const getDraftChatInstance = (
  user?: IUserType | null,
  sender?: IUserType | null,
) => {
  return {
    id: "DRAFT",
    user,
    user_id: user?.id,
    sender,
    sender_id: sender?.id,
    message: "",
    is_read: false,
    created_at: moment().toISOString(),
  };
};
