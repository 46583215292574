import { createSlice } from "@reduxjs/toolkit";
import { IFullPhotoType } from "./myPhotos";
import { IUserType } from "./user";
import _ from "lodash";
import { PrivacyGroup } from "./groups";

export type IMediaComment = {
  id: number;
  media_id: number;
  parent_id: number;
  comment: string;
  user: IUserType;
  created_at: string;
};

interface IUserPhotoSliceInitialState {
  id: number | null;
  photo: IFullPhotoType | null;
  sharingGroups: PrivacyGroup[];
  comments: IMediaComment[];
}

const userPhotoSliceInitialState: IUserPhotoSliceInitialState = {
  id: null,
  photo: null,
  sharingGroups: [],
  comments: [],
};

const userPhotoSlice = createSlice({
  name: "userPhoto",
  initialState: userPhotoSliceInitialState,
  reducers: {
    setUserPhotoId(state, action) {
      state.id = action.payload;
    },
    setUserPhoto(state, action) {
      state.photo = action.payload;
    },
    setIsFavorites(state, action) {
      if (!state.photo) {
        return;
      }
      const memoPhoto = state.photo;
      state.photo = { ...memoPhoto, is_favorite: action.payload };
    },
    setSharingGroups(state, action) {
      state.sharingGroups = action.payload;
    },
    addSharingGroup(state, action) {
      state.sharingGroups = [...state.sharingGroups, ...action.payload];
    },
    removeSharingGroup(state, action) {
      const filteredSharingUser = _.filter(
        state.sharingGroups,
        (group) => !_.includes(action.payload, group.id),
      );
      state.sharingGroups = filteredSharingUser;
    },
    resetUserPhoto(state) {
      state.photo = userPhotoSliceInitialState.photo;
      state.id = userPhotoSliceInitialState.id;
      state.sharingGroups = userPhotoSliceInitialState.sharingGroups;
      state.comments = userPhotoSliceInitialState.comments;
    },
    setMediaComments(state, action) {
      state.comments = action.payload;
    },
    addMediaComments(state, action) {
      state.comments = [...state.comments, action.payload];
    },
    setPhotoEmotionOnw(state, action) {
      if (!state.photo) {
        return;
      }

      if (_.first(state.photo.emotionsOwn) === action.payload) {
        state.photo.emotionsOwn = [];
        return;
      }

      state.photo.emotionsOwn = action.payload ? [action.payload] : [];
    },
    setPhotoEmotion(state, action) {
      if (!state.photo) {
        return;
      }

      if (state.photo.emotions) {
        let emotionCount = state.photo.emotions[action.payload];
        state.photo.emotions[action.payload] = emotionCount
          ? emotionCount + 1
          : 1;
      }
    },
    removePhotoEmotion(state, action) {
      if (!state.photo) {
        return;
      }

      if (state.photo.emotions) {
        let emotionCount = state.photo.emotions[action.payload];
        state.photo.emotions[action.payload] = emotionCount - 1;
      }
    },
    changeMediaSharingAccess(state, action) {
      if (!state.photo) {
        return;
      }

      state.photo.is_public = action.payload;
    },
  },
});

export default userPhotoSlice.reducer;
export const {
  setUserPhotoId,
  setUserPhoto,
  resetUserPhoto,
  setSharingGroups,
  addSharingGroup,
  removeSharingGroup,
  setIsFavorites,
  setMediaComments,
  addMediaComments,
  setPhotoEmotionOnw,
  setPhotoEmotion,
  removePhotoEmotion,
  changeMediaSharingAccess,
} = userPhotoSlice.actions;
