import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  blockDetailsTitle: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "400",
    color: theme.palette.grey[700],
    textTransform: "uppercase",
  },
  blockDetailsContainer: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  blockDetailsContentTitle: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "400",
    color: theme.palette.grey[900],
  },
  blockDetailsConterText: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "400",
    color: Colors.white,
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
  },
};
