import { createSlice } from "@reduxjs/toolkit";

interface IEditSliceInitialState {
  actionId: number | null;
  props: {
    [key: string]: any;
  } | null;
}

const editSliceInitialState: IEditSliceInitialState = {
  actionId: null,
  props: null,
};

const editSlice = createSlice({
  name: "edit",
  initialState: editSliceInitialState,
  reducers: {
    setActionId(state, action) {
      state.actionId = action.payload;
    },
    setProps(state, action) {
      state.props = action.payload;
    },
    resetEditSlice(state) {
      state.actionId = editSliceInitialState.actionId;
      state.props = editSliceInitialState.props;
    },
  },
});

export default editSlice.reducer;
export const { setActionId, setProps, resetEditSlice } = editSlice.actions;
