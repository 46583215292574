import { IMaterialStyleType, ICssStyleType } from "../../../types";
import { Colors, HEADER_HEIGHT, theme } from "../../../theme";

export const styles: ICssStyleType = {
  improveButton: {
    fontSize: "14px",
    lineHeight: "21px",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
};

export const materialStyles: IMaterialStyleType = {
  userAvatarButton: {
    backgroundColor: theme.palette.background.default,
    color: Colors.white,
    fontSize: "14px",
    lineHeight: "20px",
    boxShadow: "none",
    textTransform: "none",
    whiteSpace: "nowrap",
    marginLeft: {
      xs: 0,
      sm: 0,
      md: "16px",
    },
    minWidth: "auto",

    "& #userName": {
      display: {
        xs: "none",
        sm: "none",
        md: "block",
      },
      maxWidth: "200px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    "&:hover": {
      backgroundColor: theme.palette.background.default,
      boxShadow: "none",
    },
  },
  popoverContainer: {
    backgroundColor: theme.palette.grey[50],
    paddingTop: "24px",
    paddingBottom: "24px",
    paddingLeft: "20px",
    paddingRight: "20px",
    width: "230px",
  },
  popoverAvatar: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    backgroundColor: "#ADCBFA", // TODO: Add this color if need to theme
  },
  userInfoBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "28px",
  },
  userNameAndEmailBlock: {
    marginLeft: "14px",
  },
  userName: {
    color: Colors.white,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  userEmail: {
    fontSize: "14px",
    lineHeight: "21px",
    color: theme.palette.grey[400],
  },
  memoryValue: {
    fontSize: "12px",
    lineHeight: "18px",
    color: theme.palette.grey[400],
  },
  additionalButtons: {
    marginBottom: "20px",
  },
  popoverDivider: {
    borderColor: `${theme.palette.grey[400]}`,
    marginBottom: "8px",
  },
  popoverButton: {
    textTransform: "none",
    color: Colors.white,

    "&:hover": {
      backgroundColor: theme.palette.grey[50],
      boxShadow: "none",
    },
  },
  toggleButtonsInHeaderPopover: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
    },
  },
  toggleButtonGroup: {
    borderRadius: "10px",
    border: {
      xs: `1px solid ${theme.palette.grey[400]}`,
      sm: `1px solid ${theme.palette.grey[400]}`,
      md: `1px solid ${theme.palette.grey[50]}`,
    },
    marginLeft: {
      xs: "0",
      sm: "0",
      md: "16px",
    },
    marginBottom: {
      xs: "6px",
      sm: "6px",
      md: "0px",
    },
    height: "50px",
    flex: {
      xs: 1,
      sm: 1,
      md: 0,
    },
  },
  toggleButton: {
    borderRadius: "10px",
    border: "none",
    color: "white",
    textTransform: "none",
    margin: "7px",
    flex: {
      xs: 1,
      sm: 1,
      md: 0,
    },

    borderTopLeftRadius: "6px !important",
    borderBottomRightRadius: "6px !important",
    borderTopRightRadius: "6px !important",
    borderBottomLeftRadius: "6px !important",
    lineHeight: "1px",

    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: Colors.white,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: Colors.white,
    },
  },
  uploaderLineConteiner: {
    top: HEADER_HEIGHT - 14,
    position: "absolute",
    left: 0,
    height: "14px",
    overflow: "hidden",
    width: "100%",
    backgroundColor: theme.palette.success.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "0.2s",
    zIndex: 20,
  },
  activeUploaderLineConteiner: {
    top: HEADER_HEIGHT,
  },
  uploaderLineText: {
    color: Colors.white,
    fontSize: "12px",
    zIndex: 2,
  },
  progressBar: {
    left: 0,
    zIndex: 1,
    position: "absolute",
    transition: "0.2s",
    height: "100%",
    backgroundColor: theme.palette.success.dark,
  },
};
