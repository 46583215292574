import { useTranslation } from "react-i18next";

export type IComplaintReasonType = "USER" | "ALBUM" | "PHOTO";

export const useComplaintReasons = (type: IComplaintReasonType) => {
  const { t } = useTranslation();

  switch (type) {
    case "USER":
      return [
        {
          id: 1,
          message: t(
            "complaint.messages.user.violence_or_dangerous_organizations"
          ),
        },
        {
          id: 2,
          message: t(
            "complaint.messages.user.sale_of_illegal_or_regulated_goods"
          ),
        },
        {
          id: 3,
          message: t("complaint.messages.user.scam_or_fraud"),
        },
      ];
    case "ALBUM":
      return [
        {
          id: 1,
          message: t(
            "complaint.messages.user.violence_or_dangerous_organizations"
          ),
        },
        {
          id: 2,
          message: t(
            "complaint.messages.user.sale_of_illegal_or_regulated_goods"
          ),
        },
        {
          id: 3,
          message: t("complaint.messages.user.scam_or_fraud"),
        },
      ];
    case "PHOTO":
      return [
        {
          id: 1,
          message: t(
            "complaint.messages.user.violence_or_dangerous_organizations"
          ),
        },
        {
          id: 2,
          message: t(
            "complaint.messages.user.sale_of_illegal_or_regulated_goods"
          ),
        },
        {
          id: 3,
          message: t("complaint.messages.user.scam_or_fraud"),
        },
      ];
    default:
      return [];
  }
};
