import React, { useCallback, useMemo } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { IAppliedFilter, parseFiltersForAppliedFilters } from "../../utils";
import { materialStyles } from "./styles";
import _ from "lodash";
import ClearIcon from "@mui/icons-material/Clear";

export type IAnyFilters = { [key: string]: any };

export type IAppliedFiltersType = "PHOTOS" | "MAP";

type IAppliedFilters = {
  filters: IAnyFilters;
  type: IAppliedFiltersType;
  onRemoveFilter?: (newFilters: IAnyFilters) => void;
};

export const AppliedFilters: React.FC<IAppliedFilters> = ({
  filters,
  type,
  onRemoveFilter,
}) => {
  const appliedFilters = useMemo(
    () => parseFiltersForAppliedFilters(filters, type),
    [filters, type]
  );

  const onRemove = useCallback(
    (appliedFilter: IAppliedFilter) => {
      const filterValueByKey = _.get(filters, appliedFilter.key);
      const isFilterValueArray = _.isArray(filterValueByKey);

      const updatedFilters = _.cloneDeep(filters);

      switch (true) {
        case isFilterValueArray:
          _.set(updatedFilters, appliedFilter.key, []);
          break;
        default:
          _.set(updatedFilters, appliedFilter.key, null);
          break;
      }

      onRemoveFilter && onRemoveFilter(updatedFilters);
    },
    [filters, onRemoveFilter]
  );

  if (!appliedFilters) {
    return null;
  }

  return (
    <Grid sx={materialStyles.appliedFiltersContainer}>
      {_.map(appliedFilters, (appliedFilter) => (
        <Grid key={appliedFilter.key} sx={materialStyles.filterItem}>
          <Typography sx={materialStyles.tagItemTitle}>
            {appliedFilter.value}
          </Typography>
          <IconButton
            onClick={() => onRemove(appliedFilter)}
            sx={materialStyles.removeFilterButton}
          >
            <ClearIcon sx={materialStyles.removeFilterIcon} />
          </IconButton>
        </Grid>
      ))}
    </Grid>
  );
};
