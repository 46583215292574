import axios from "axios";
import { AppDispatch, RootState } from "..";
import { setLoading } from "../slices/loading";
import { checkOrRefreshToken } from "./user";
import { API_URL } from "../api";
import { setError } from "../slices/errors";
import { getResponseFromAxiosError } from "../../utils";
import { DEFAULT_PAGE, DEFAULT_SUPPORT_PAGE_SIZE } from "../../constants/api";
import { ISupportRequest } from "../slices/supportRequests";

export const getSupportRequests = (page?: number, pageSize?: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/support`, {
        params: {
          page: page || DEFAULT_PAGE,
          limit: pageSize || DEFAULT_SUPPORT_PAGE_SIZE,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getSupportRequests]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const createSupportRequest = (email: string, message: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/support`,
        {
          type: 0,
          email,
          message,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [createSupportRequest]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const uploadFileToSupportRequest = (requestId: number, file: File) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await axios.post(
        `${API_URL}/support/${requestId}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [uploadFileToSupportRequest]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getSupportRequestMessages = (id: ISupportRequest["id"]) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/support/${id}/messages`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getSupportRequestMessages]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
