import { Grid, IconButton, Button } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { materialStyles, styles } from "./styles";
import WozereLogo from "../../assets/images/wozere-logo.svg";
import { useTranslation } from "react-i18next";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import { useAppSelector } from "../../store";
import { UserHeaderBlock } from "./components/UserHeaderBlock";
import { UploadPhotoModal } from "../modals/uploadPhotoModal";
import { ChangeVisualModeButtons } from "./components/ChangeVisualModeButtons";
import { SearchUserInput } from "./components/SearchUserInput";
import { MobileSearchInput } from "../modals/mobileUserSearchModal";
import SearchIcon from "@mui/icons-material/Search";
import SideMenuIcon from "../../assets/images/icons/side-menu-icon.svg";
import FiltersIcon from "../../assets/images/icons/filters-icon.svg";
import { FiltersDrawer, IFiltersDrawerType } from "../filters";
import { useFiltersTypeByPage } from "../../hooks/useFiltersTypeByPage";
import { CommonModalsDispatchContext } from "../providers/CommonModalsProvider";
import { UploaderLine } from "./components/UploaderLine";

type IAppHeader = {
  onOpenMobileSideMenu?: () => void;
  onOpenOrCloseSideMenu?: () => void;
};

export const AppHeader: React.FC<IAppHeader> = ({
  onOpenMobileSideMenu,
  onOpenOrCloseSideMenu,
}) => {
  const { t } = useTranslation();
  const [uploadOpen, setUploadOpen] = useState(false);
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  const { user } = useAppSelector((state) => state.user);

  const type = useFiltersTypeByPage();

  const dispatchCommonModals = useContext(CommonModalsDispatchContext);

  const [openFiltersType, setOpenFiltersType] =
    useState<IFiltersDrawerType | null>(null);

  const openAuthModal = useCallback(() => {
    dispatchCommonModals && dispatchCommonModals({ type: "SHOW_AUTH_MODAL" });
  }, [dispatchCommonModals]);

  const openUploadPhotoModal = useCallback(() => {
    setUploadOpen(!uploadOpen);
  }, [uploadOpen]);

  const openMobileSearch = useCallback(() => {
    setMobileSearchOpen(!mobileSearchOpen);
  }, [mobileSearchOpen]);

  const navigateToMain = useCallback(() => {
    window.location.href = "/";
  }, []);

  const onOpenFilters = useCallback(() => {
    setOpenFiltersType(type);
  }, [type]);

  const onCloseFilters = useCallback(() => {
    setOpenFiltersType(null);
  }, []);

  return (
    <>
      <Grid sx={materialStyles.appHeader}>
        <IconButton
          sx={materialStyles.mobileSideMenuIcon}
          onClick={onOpenMobileSideMenu}
        >
          <img src={SideMenuIcon} alt="SideMenuIcon" />
        </IconButton>
        <IconButton
          sx={materialStyles.sideMenuIcon}
          onClick={onOpenOrCloseSideMenu}
        >
          <img src={SideMenuIcon} alt="SideMenuIcon" />
        </IconButton>
        <Grid sx={materialStyles.logoContainer}>
          <img onClick={navigateToMain} src={WozereLogo} alt="Logo" />
        </Grid>
        <Grid sx={materialStyles.headerActionBlock} display="flex" flex={1}>
          <SearchUserInput>
            {Boolean(type) ? (
              <IconButton
                sx={materialStyles.mapFiltersButton}
                onClick={onOpenFilters}
              >
                <img
                  style={styles.filtersIcon}
                  src={FiltersIcon}
                  alt="FiltersIcon"
                />
              </IconButton>
            ) : null}
          </SearchUserInput>
          {Boolean(type) ? (
            <IconButton
              sx={[
                materialStyles.mapFiltersButton,
                materialStyles.showOnMobile,
              ]}
              onClick={onOpenFilters}
            >
              <img
                style={styles.filtersIcon}
                src={FiltersIcon}
                alt="FiltersIcon"
              />
            </IconButton>
          ) : null}
          <IconButton
            onClick={openMobileSearch}
            sx={materialStyles.mobileSearchButton}
          >
            <SearchIcon sx={materialStyles.whiteSearchIcon} />
          </IconButton>
          <Grid sx={materialStyles.toggleButtonsInHeader}>
            <ChangeVisualModeButtons />
          </Grid>
          <Button
            onClick={!user ? openAuthModal : openUploadPhotoModal}
            sx={materialStyles.uploadButton}
            variant="contained"
            startIcon={<FileUploadOutlinedIcon />}
          >
            {t("header.upload")}
          </Button>
          {user ? <UserHeaderBlock user={user} /> : null}
          {!user ? (
            <Button
              onClick={openAuthModal}
              sx={materialStyles.loginButton}
              variant="contained"
              endIcon={<LoginOutlinedIcon />}
            >
              <p>{t("header.log_in")}</p>
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <UploaderLine />
      <UploadPhotoModal isOpen={uploadOpen} onClose={openUploadPhotoModal} />
      <MobileSearchInput isOpen={mobileSearchOpen} onClose={openMobileSearch} />
      <FiltersDrawer
        isOpen={Boolean(openFiltersType)}
        onClose={onCloseFilters}
        type={openFiltersType}
      />
    </>
  );
};
