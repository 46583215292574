import { Button, Grid, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles } from "./styles";
import { IUserEditModalContentType, IUserEditModalType } from "..";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  changeEmail,
  changePassword,
  changeUsername,
} from "../../../../store/thunks/user";
import { setUserEmail, setUserName } from "../../../../store/slices/user";

type IConfirmEditModalForm = {
  type: IUserEditModalContentType;
  onClose: () => void;
  onChangeModalType: (type: IUserEditModalType) => void;
};

export const ConfirmUserEditModalForm: React.FC<IConfirmEditModalForm> = ({
  type,
  onClose,
  onChangeModalType,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userName, email, password } = useAppSelector(
    (state) => state.userEdit
  );

  const onConfirm = useCallback(async () => {
    try {
      switch (type) {
        case "USERNAME":
          if (!userName) {
            return;
          }

          const newUserName = await dispatch(
            changeUsername(userName.newUserName)
          );
          if (!newUserName) {
            return;
          }
          dispatch(setUserName(newUserName));
          onChangeModalType("SUCCESS_MODAL");
          return;
        case "EMAIL":
          if (!email) {
            return;
          }

          const newUserEmail = await dispatch(changeEmail(email.newUserEmail));
          if (!newUserEmail) {
            return;
          }
          dispatch(setUserEmail(newUserEmail));
          onChangeModalType("SUCCESS_MODAL");
          return;
        case "PASSWORD":
          if (!password) {
            return;
          }

          const newUserPassword = await dispatch(
            changePassword(password.newPassword)
          );
          if (!newUserPassword) {
            return;
          }
          onChangeModalType("SUCCESS_MODAL");
          return;
      }
    } catch (error) {
      console.error("Error while onConfirm", error);
    }
  }, [dispatch, email, onChangeModalType, password, type, userName]);

  return (
    <Grid sx={materialStyles.innerModalContainer}>
      <Typography sx={materialStyles.confirmModalHeaderTitle}>
        {
          {
            USERNAME: t("edit_user.confirm_username_edit"),
            EMAIL: t("edit_user.confirm_email_edit"),
            PASSWORD: t("edit_user.confirm_password_edit"),
          }[type]
        }
      </Typography>
      <Button
        variant="contained"
        sx={materialStyles.confirmButton}
        onClick={onConfirm}
      >
        {t("common.confirm")}
      </Button>
    </Grid>
  );
};
