import { Grid } from "@mui/material";
import React from "react";
import { AccountInformation } from "./components/AccountInformation";
import { ISettingsContentTypes } from "../../pages/UserSettings";
import { materialStyles } from "./styles";
import { PrivacyGroups } from "./components/PrivacyGroups";
import { Support } from "./components/Support";

type ISettingsContentComponent = {
  type: ISettingsContentTypes;
};

export const SettingsContentComponent: React.FC<ISettingsContentComponent> = ({
  type,
}) => {
  return (
    <Grid sx={materialStyles.settingsContent}>
      {type === "ACCOUNT_INFORMATION" ? <AccountInformation /> : null}
      {type === "PRIVACY_GROUPS" ? <PrivacyGroups /> : null}
      {type === "SUPPORT" ? <Support /> : null}
    </Grid>
  );
};
