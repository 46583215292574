import { useCallback, useEffect, useReducer, useRef } from "react";
import { IPhotoType } from "../store/slices/myPhotos";
import _ from "lodash";
import { useSocketMediaHandle } from "./useSocketMediaHandle";
import { useAppSelector } from "../store";

export const SLIDE_TIMEOUT = 500;

export const SLIDE_SPEED = SLIDE_TIMEOUT / 2;

export type SliderDirection = "left" | "right" | "down" | "up";

type SlidersStateType = {
  slideIn: boolean;
  slideDirection: SliderDirection;
  slideLeftDisable: boolean;
  slideRightDisable: boolean;
  index: number;
  photo: IPhotoType | null;
  loading: boolean;
};

type SlidersActionType =
  | {
      type: "INIT";
      state: SlidersStateType;
    }
  | {
      type: "MOVE_OUT";
      slideDirection: SliderDirection;
      slideIn: boolean;
    }
  | {
      type: "SET_LOADING";
      value: boolean;
    }
  | {
      type: "SET_PHOTO";
      photo: IPhotoType | null;
      index: number;
      loading: boolean;
    }
  | {
      type: "MOVE_IN";
      slideDirection: SliderDirection;
      slideIn: boolean;
      loading: boolean;
    }
  | {
      type: "SET_DISABLE_SLIDE_LEFT" | "SET_DISABLE_SLIDE_RIGHT";
      value: boolean;
    }
  | {
      type: "SUBSCRIBE_OR_UNSUBSCRIBE_TO_USER";
      value: boolean;
    }
  | { type: "RESET" };

const initialSlidesState: SlidersStateType = {
  slideIn: true,
  slideDirection: "down",
  index: 0,
  photo: null,
  loading: false,
  slideLeftDisable: false,
  slideRightDisable: false,
};

const slidesReducer = (state: SlidersStateType, action: SlidersActionType) => {
  switch (action.type) {
    case "INIT":
      return action.state;
    case "MOVE_OUT":
      return {
        ...state,
        slideDirection: action.slideDirection,
        slideIn: action.slideIn,
      };
    case "SET_PHOTO":
      return {
        ...state,
        photo: action.photo,
        index: action.index,
        loading: action.loading,
      };
    case "MOVE_IN":
      return {
        ...state,
        slideDirection: action.slideDirection,
        slideIn: action.slideIn,
        loading: action.loading,
      };
    case "SET_DISABLE_SLIDE_LEFT":
      return {
        ...state,
        slideLeftDisable: action.value,
      };
    case "SET_DISABLE_SLIDE_RIGHT":
      return {
        ...state,
        slideRightDisable: action.value,
      };
    case "SUBSCRIBE_OR_UNSUBSCRIBE_TO_USER":
      return {
        ...state,
        photo: state.photo
          ? {
              ...state.photo,
              user: state.photo.user
                ? {
                    ...state.photo.user,
                    is_subscribed: action.value,
                  }
                : undefined,
            }
          : null,
      };
    case "RESET":
      return initialSlidesState;
    default:
      return initialSlidesState;
  }
};

export const useImageSlider = () => {
  const oppDirection = useRef<SliderDirection | null>("down");

  const {
    initialMedia,
    initialMediaIndex,
    isLastInitialMedia,
    isFirstInitialMedia,
    media,
  } = useAppSelector((state) => state.slider);

  const [slidesState, dispatchSlides] = useReducer(
    slidesReducer,
    initialSlidesState,
  );

  const { onSocketJoin, onSocketLeave } = useSocketMediaHandle(
    slidesState.photo?.id || null,
  );

  useEffect(() => {
    if (initialMedia) {
      dispatchSlides({
        type: "INIT",
        state: {
          ...initialSlidesState,
          index: initialMediaIndex,
          photo: initialMedia || null,
          loading: false,
          slideLeftDisable: isFirstInitialMedia,
          slideRightDisable: isLastInitialMedia,
        },
      });
    }

    return () => {
      dispatchSlides({ type: "RESET" });
    };
  }, [
    initialMedia,
    initialMediaIndex,
    isFirstInitialMedia,
    isLastInitialMedia,
  ]);

  const onArrowClick = useCallback(
    (direction: SliderDirection, notChangeIndex?: boolean) => {
      const photosSize = _.size(media);

      const increment = direction === "left" ? -1 : 1;

      const disableMoveRight =
        slidesState.index + 1 === photosSize && direction === "right";
      const disableMoveLeft = slidesState.index === 0 && direction === "left";

      if (disableMoveLeft || disableMoveRight) {
        return;
      }

      const newIndex =
        (slidesState.index + increment + photosSize) % photosSize;
      const newPhoto = _.nth(media, newIndex);

      oppDirection.current = direction !== "right" ? "right" : "left";

      dispatchSlides({
        type: "MOVE_OUT",
        slideDirection: direction,
        slideIn: false,
      });

      onSocketLeave();

      setTimeout(() => {
        dispatchSlides({
          type: "SET_PHOTO",
          photo: newPhoto || null,
          index: notChangeIndex ? slidesState.index : newIndex,
          loading: true,
        });
      }, SLIDE_TIMEOUT / 2);
    },
    [onSocketLeave, media, slidesState.index],
  );

  const onLoadImage = useCallback(() => {
    setTimeout(() => {
      if (!oppDirection.current) {
        return;
      }

      const isFirstIndex = slidesState.index === 0;
      const isLastIndex = slidesState.index === _.size(media) - 1;

      dispatchSlides({ type: "SET_DISABLE_SLIDE_LEFT", value: isFirstIndex });
      dispatchSlides({ type: "SET_DISABLE_SLIDE_RIGHT", value: isLastIndex });

      dispatchSlides({
        type: "MOVE_IN",
        slideDirection: oppDirection.current,
        slideIn: true,
        loading: false,
      });

      onSocketJoin();
    }, SLIDE_TIMEOUT / 2);
  }, [onSocketJoin, media, slidesState.index]);

  return { slidesState, dispatchSlides, onArrowClick, onLoadImage };
};
