import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  playbackContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    marginTop: 2,
    gap: 1,
  },
  playback: {
    width: "100%",
    height: "14px",
    position: "relative",
    borderRadius: "8px",
    backgroundColor: theme.palette.grey[800],
    cursor: "pointer",
  },
  playbackText: {
    fontSize: "12px",
    color: theme.palette.grey[500],
  },
  playbackTimecodes: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  playbackThumb: {
    position: "absolute",
    width: { xs: "18px", sm: "18px", md: "10px" },
    height: "130%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "3px",
    cursor: "pointer",
    transition: "0.1s",
    mt: -0.2,
  },
  toobarContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
    justifyContent: "space-between",
    mt: 1,
  },
  toolbarButton: {
    color: Colors.white,
  },
  playbackProgress: {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    height: "100%",
    opacity: 0.2,
    transition: "0.2s",
  },
  playbackMainThumb: {
    position: "absolute",
    width: { xs: "9px", sm: "9px", md: "5px" },
    height: "130%",
    backgroundColor: Colors.white,
    borderRadius: "3px",
    cursor: "pointer",
    transition: "0.1s",
    mt: -0.2,
  },
};
