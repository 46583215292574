import { ButtonBase, Grid } from "@mui/material";
import React from "react";
import { IUserType } from "../../../store/slices/user";
import _ from "lodash";
import { UserAvatar } from "../../user/UserAvatar";
import { materialStyles } from "./styles";

type IAvatarList = {
  users: IUserType[];
  children?: React.ReactNode;
  onClickUser?: (userId: number) => void;
};

export const AvatarsList: React.FC<IAvatarList> = ({
  users,
  children,
  onClickUser,
}) => {
  return (
    <Grid sx={materialStyles.avatarList}>
      {_.map(users, (user) => (
        <ButtonBase onClick={() => onClickUser && onClickUser(user.id)}>
          <UserAvatar key={user.id} user={user} type="AVATAR_LIST" />
        </ButtonBase>
      ))}
      {children ? children : null}
    </Grid>
  );
};
