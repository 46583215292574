import { createSlice } from "@reduxjs/toolkit";
import { Area } from "react-easy-crop";
import { ITags } from "./tags";
import _ from "lodash";
import { PrivacyGroup } from "./groups";

export type TPrepareFileType =
  | "IMAGE"
  | "VIDEO"
  | "YOUTUBE_VIDEO"
  | "IMPORTED_GOOGLE_MEDIA";

export type IPreparedFileInfo = {
  id: string;
  url: string;
  isHaveLocation: boolean;
  isHaveLocationByDefault: boolean;
  longitude?: number;
  latitude?: number;
  tags: ITags[];
  privacyGroups: PrivacyGroup[];
  description: string;
  type: TPrepareFileType;
};

interface IUploadFileSliceInitialState {
  files: {
    filesInfo: IPreparedFileInfo[];
    selectedFiles: IPreparedFileInfo[];
    coverFile?: IPreparedFileInfo;
    croppedAreaPixels?: Area;
  } | null;
}

const uploadFileSliceInitialState: IUploadFileSliceInitialState = {
  files: null,
};

const uploadFileSlice = createSlice({
  name: "uploadFile",
  initialState: uploadFileSliceInitialState,
  reducers: {
    setFile(state, action) {
      state.files = action.payload;
    },
    resetFile(state) {
      state.files = null;
    },
    setLocationToSelectedFiles(state, action) {
      if (!state.files) {
        return;
      }

      const selectedFilesWithLocationByDefault = _.filter(
        state.files.selectedFiles,
        "isHaveLocationByDefault",
      );

      const changedFiles = _.chain(state.files.selectedFiles)
        .filter((file) => !file.isHaveLocationByDefault)
        .map((file) => {
          file.latitude = action.payload.latitude;
          file.longitude = action.payload.longitude;
          file.isHaveLocation = true;

          return file;
        })
        .value();

      const selectedFiles = _.uniqBy(
        [...selectedFilesWithLocationByDefault, ...changedFiles],
        "id",
      );

      state.files.selectedFiles = selectedFiles;
      state.files.filesInfo = _.uniqBy(
        [...selectedFiles, ...state.files.filesInfo],
        "id",
      );
    },
    selectFiles(state, action) {
      if (!state.files) {
        return;
      }

      state.files.selectedFiles = [
        ...state.files.selectedFiles,
        ...action.payload,
      ];
    },
    removeSelectedFile(state, action) {
      if (!state.files) {
        return;
      }

      const filteredSelectedFiles = _.filter(
        state.files.selectedFiles,
        (file) => file.id !== action.payload.id,
      );

      state.files.selectedFiles = filteredSelectedFiles;
    },
    resetSelectedFiles(state) {
      if (!state.files) {
        return;
      }

      state.files.selectedFiles = [];
    },
    resetPrivacyGroupInSelectedFiles(state) {
      if (!state.files) {
        return;
      }

      const selectedFiles = _.map(state.files.selectedFiles, (file) => {
        file.privacyGroups = [];
        return file;
      });

      state.files.selectedFiles = selectedFiles;
      state.files.filesInfo = _.uniqBy(
        [...selectedFiles, ...state.files.filesInfo],
        "id",
      );
    },
    removePrivacyGroupFromSelectedFiles(state, action) {
      if (!state.files) {
        return;
      }

      const selectedFiles = _.map(state.files.selectedFiles, (file) => {
        const filteredGroups = _.filter(
          file.privacyGroups,
          (group) => group.id !== action.payload.id,
        );

        file.privacyGroups = filteredGroups;
        return file;
      });

      state.files.selectedFiles = selectedFiles;
      state.files.filesInfo = _.uniqBy(
        [...selectedFiles, ...state.files.filesInfo],
        "id",
      );
    },
    setPrivacyGroupInSelectedFiles(state, action) {
      if (!state.files) {
        return;
      }

      const selectedFiles = _.map(state.files.selectedFiles, (file) => {
        file.privacyGroups = [...file.privacyGroups, action.payload];
        return file;
      });

      state.files.selectedFiles = selectedFiles;
      state.files.filesInfo = _.uniqBy(
        [...selectedFiles, ...state.files.filesInfo],
        "id",
      );
    },
    changeDescriptionInSelectedFiles(state, action) {
      if (!state.files) {
        return;
      }

      const selectedFiles = _.map(state.files.selectedFiles, (file) => {
        file.description = action.payload;
        return file;
      });

      state.files.selectedFiles = selectedFiles;
      state.files.filesInfo = _.uniqBy(
        [...selectedFiles, ...state.files.filesInfo],
        "id",
      );
    },
    removeTagsFromSelectedFiles(state, action) {
      if (!state.files) {
        return;
      }

      const selectedFiles = _.map(state.files.selectedFiles, (file) => {
        const filteredGroups = _.filter(
          file.tags,
          (group) => group.id !== action.payload.id,
        );

        file.tags = filteredGroups;
        return file;
      });

      state.files.selectedFiles = selectedFiles;
      state.files.filesInfo = _.uniqBy(
        [...selectedFiles, ...state.files.filesInfo],
        "id",
      );
    },
    setTagsInSelectedFiles(state, action) {
      if (!state.files) {
        return;
      }

      const selectedFiles = _.map(state.files.selectedFiles, (file) => {
        file.tags = [...file.tags, action.payload];
        return file;
      });

      state.files.selectedFiles = selectedFiles;
      state.files.filesInfo = _.uniqBy(
        [...selectedFiles, ...state.files.filesInfo],
        "id",
      );
    },
    removePhotoFromUploader(state, action) {
      if (!state.files) {
        return;
      }

      const removedPhotoFromSelected = _.filter(
        state.files.selectedFiles,
        (file) => file.id !== action.payload,
      );

      const removedPhotoFromFilesInfo = _.filter(
        state.files.filesInfo,
        (photo) => photo.id !== action.payload,
      );

      state.files.filesInfo = removedPhotoFromFilesInfo;
      state.files.selectedFiles = removedPhotoFromSelected;
    },
    saveAfterVideoEditor(state, action) {
      if (!state.files) {
        return;
      }

      const replaceInFilesInfo = _.map(state.files.filesInfo, (file) => {
        if (file.id === action.payload.id) {
          file.url = action.payload.url;
        }

        return file;
      });

      const replaceInSelectedFiles = _.map(
        state.files.selectedFiles,
        (file) => {
          if (file.id === action.payload.id) {
            file.url = action.payload.url;
          }

          return file;
        },
      );

      const isCoverFile =
        state.files.coverFile && state.files.coverFile.id === action.payload.id;

      if (state.files.coverFile && isCoverFile) {
        state.files.coverFile.url = action.payload.url;
      }

      state.files.filesInfo = replaceInFilesInfo;
      state.files.selectedFiles = replaceInSelectedFiles;
    },
  },
});

export default uploadFileSlice.reducer;
export const {
  setFile,
  resetFile,
  selectFiles,
  removeSelectedFile,
  resetSelectedFiles,
  resetPrivacyGroupInSelectedFiles,
  removePrivacyGroupFromSelectedFiles,
  setPrivacyGroupInSelectedFiles,
  setTagsInSelectedFiles,
  removeTagsFromSelectedFiles,
  setLocationToSelectedFiles,
  changeDescriptionInSelectedFiles,
  removePhotoFromUploader,
  saveAfterVideoEditor,
} = uploadFileSlice.actions;
