import { Box, Modal } from "@mui/material";
import { materialStyles } from "./styles";
import { useCallback, useState } from "react";
import { UserProfileContent } from "./components/UserProfileContent";
import { useAppDispatch, useAppSelector } from "../../../store";
import { resetUserProfile } from "../../../store/slices/userProfile";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";
import { useFetchUserProfileById } from "../../../hooks/useFetchUserProfileById";

export type IUserModalType = "USER_PROFILE";

type IUserModal = {
  isOpen: boolean;
  onClose: () => void;
  initialModalType?: IUserModalType;
};

export const UserModal: React.FC<IUserModal> = ({
  isOpen,
  onClose,
  initialModalType = "USER_PROFILE",
}) => {
  const [userModalType, setUserModalType] = useState(initialModalType);
  const { loading } = useAppSelector((state) => state.loader);
  const { id } = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();

  useFetchUserProfileById({
    id,
    isEnable: isOpen,
  });

  const onChangeUserModalType = useCallback((type: IUserModalType) => {
    setUserModalType(type);
  }, []);

  const onCloseModal = useCallback(() => {
    dispatch(resetUserProfile());
    onClose();
  }, [dispatch, onClose]);

  return (
    <Modal sx={materialStyles.modal} open={isOpen} onClose={onCloseModal}>
      <Box sx={materialStyles.modalContainer}>
        {userModalType === "USER_PROFILE" ? (
          <UserProfileContent
            onClose={onCloseModal}
            onChangeUserModalType={onChangeUserModalType}
          />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
