import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getChats } from "../store/thunks/chats";
import {
  resetMessages,
  setChatsPage,
  setChatsPageSize,
  setMessages,
} from "../store/slices/messages";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../constants/api";

export const useFetchChats = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { chats } = useAppSelector((state) => state.messages);

  const fetchChats = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        if (!isAuth) {
          return;
        }

        const chats = await dispatch(getChats(page, pageSize));

        dispatch(setMessages(chats));
      } catch (error) {
        console.error("Error while [fetchChats]", error);
      }
    },
    [dispatch, isAuth],
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setChatsPage(chats.page + 1));
    dispatch(setChatsPageSize(DEFAULT_PAGE_SIZE));
  }, [chats.page, dispatch]);

  const refetchChats = useCallback(() => {
    fetchChats(chats.page);
  }, [fetchChats, chats.page]);

  useEffect(() => {
    fetchChats(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);

    return () => {
      dispatch(resetMessages());
    };
  }, [dispatch, fetchChats]);

  useEffect(() => {
    if (chats.page !== DEFAULT_PAGE) {
      fetchChats(chats.page, chats.pageSize);
    }
  }, [chats.page, chats.pageSize, fetchChats]);

  return {
    chats: chats.data,
    onUpdatePageOrPageSize,
    refetchChats,
  };
};
