import { Box, Modal } from "@mui/material";
import React, { useCallback, useState } from "react";
import { materialStyles } from "./styles";
import { AlbumMainContent } from "./components/AlbumMainContent";
import { useAppDispatch, useAppSelector } from "../../../store";
import { resetUserAlbum } from "../../../store/slices/userAlbum";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";

export type IUserAlbumModalType = "ALBUM_MAIN" | "SELECT_ALBUM_PHOTO";

type IUserAlbumModal = {
  isOpen: boolean;
  onClose: () => void;
  initialModalType?: IUserAlbumModalType;
  id?: number;
};

export const UserAlbumModal: React.FC<IUserAlbumModal> = ({
  isOpen,
  onClose,
  initialModalType = "ALBUM_MAIN",
  id,
}) => {
  const [userAlbumModalType, setUserAlbumModalType] =
    useState<IUserAlbumModalType>(initialModalType);

  const { loading } = useAppSelector((state) => state.loader);

  const dispatch = useAppDispatch();

  const onChangeUserAlbumModalType = useCallback(
    (type: IUserAlbumModalType) => {
      setUserAlbumModalType(type);
    },
    []
  );

  const onCloseModal = useCallback(() => {
    dispatch(resetUserAlbum());
    onClose();
  }, [dispatch, onClose]);

  return (
    <Modal sx={materialStyles.modal} open={isOpen} onClose={onCloseModal}>
      <Box sx={materialStyles.modalContainer}>
        {userAlbumModalType === "ALBUM_MAIN" ? (
          <AlbumMainContent
            onChangeUserAlbumModalType={onChangeUserAlbumModalType}
            onClose={onCloseModal}
          />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
