import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@mui/material";
import { materialStyles } from "./styles";
import React from "react";
import { IMaterialStyleItem } from "../../types";

type IAppAutocomplete = {
  options: any[];
  renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
  getOptionLabel?: (option: any) => string;
  loading?: boolean;
  noOptionsText?: string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
    ownerState: any
  ) => React.ReactNode;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => void;
  sx?: IMaterialStyleItem;
};

export const AppAutocomplete: React.FC<IAppAutocomplete> = ({
  options,
  renderInput,
  getOptionLabel,
  loading,
  renderOption,
  onChange,
  sx,
}) => {
  return (
    <Autocomplete
      loading={loading}
      sx={sx}
      fullWidth
      options={options}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      componentsProps={{
        paper: { sx: materialStyles.autocompletePaper },
      }}
      onChange={onChange}
    />
  );
};
