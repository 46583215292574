import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";
import { withHexOpacity } from "../../../utils";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  modal: {
    display: "flex",
  },
  modalContainer: {
    flex: 1,
    display: "flex",
  },
  iconBlocks: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
  },
  headerIcon: {
    color: Colors.white,
  },
  slides: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "relative",

    justifyContent: {
      xs: "flex-end",
      sm: "flex-end",
      md: "space-between",
    },

    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
    },
  },
  slidePicture: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  slideItem: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    flex: 1,
    objectFit: "contain",
    maxWidth: "100%",
    maxHeight: "80%",
  },
  slideButton: {
    color: Colors.white,
    width: "40px",
    height: "40px",
    backgroundColor: withHexOpacity("#313131", 0.8),
    borderRadius: "50%",
    margin: "40px 40px 40px 60px",
    zIndex: 10,
  },
  showOnMobile: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
    },
  },
  hideOnMobile: {
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
  },
  privacyGroupsBox: {
    position: "absolute",
    top: "105px",
    right: "48px",
    display: "flex",
    flexDirection: "row",
    gap: 1,
    zIndex: 100,
    alignItems: "center",
  },
  privacyGroupsTitle: {
    color: Colors.white,
  },
  privacyGroupsIconContainer: {
    color: Colors.white,
    width: "18px",
    height: "18px",
  },
  privacyGroupsIcon: {
    color: Colors.white,
    width: "18px",
    height: "18px",
  },
  groupsList: {
    display: "flex",
    position: "absolute",
    bottom: {
      xs: "84px",
      sm: "84px",
      md: "16px",
    },
    width: "100%",
    overflow: "auto",
    alignItems: "center",
    justifyContent: {
      xs: "flex-start",
      sm: "flex-start",
      md: "center",
    },
  },
  groupsListContainer: {
    flexWrap: "nowrap",
    pl: 1,
    pr: 1,
    overflow: "visible",
    width: "fit-content",
  },
  groupsListItem: {
    backgroundColor: theme.palette.background.default,
  },
  userSlideContainer: {
    display: "flex",
    gap: 1,
    alignItems: "center",
    flexDirection: "row",
  },
  userSlideName: {
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
    fontSize: "16px",
    color: Colors.white,
  },
  disabledSlideButton: {
    opacity: 0.4,
  },
  iframeSlideItem: {
    width: "80%",
    height: "80%",
  },
};
