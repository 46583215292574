import { Box, Modal } from "@mui/material";
import React, { useState, useCallback } from "react";
import { materialStyles } from "./styles";
import { EditModalForm } from "./components/EditModalForm";
import { useAppDispatch, useAppSelector } from "../../../store";
import { resetEditSlice } from "../../../store/slices/edit";
import { ConfirmEditModalForm } from "./components/ConfirmEditModalForm";
import { SuccessEditContent } from "./components/SuccessEditContent";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";

export type IEditModalType = "EDIT_MODAL" | "CONFIRM_MODAL" | "SUCCESS_MODAL";

export type IEditModalContentType = "ALBUM" | "PHOTO";

type IEditModal = {
  isOpen: boolean;
  onClose: () => void;
  type: IEditModalContentType;
  initialModalType?: IEditModalType;
};

export const EditModal: React.FC<IEditModal> = ({
  isOpen,
  onClose,
  type,
  initialModalType = "EDIT_MODAL",
}) => {
  const [editModalType, setEditModalType] = useState(initialModalType);
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.loader);

  const onChangeModalType = useCallback(
    (type: IEditModalType) => setEditModalType(type),
    []
  );

  const onCloseModal = useCallback(() => {
    setEditModalType(initialModalType);
    dispatch(resetEditSlice());
    onClose();
  }, [dispatch, initialModalType, onClose]);

  return (
    <Modal open={isOpen} sx={materialStyles.modal} onClose={onCloseModal}>
      <Box sx={materialStyles.modalBox}>
        {editModalType === "EDIT_MODAL" ? (
          <EditModalForm
            type={type}
            onClose={onCloseModal}
            onChangeModalType={onChangeModalType}
          />
        ) : null}
        {editModalType === "CONFIRM_MODAL" ? (
          <ConfirmEditModalForm
            type={type}
            onClose={onCloseModal}
            onChangeModalType={onChangeModalType}
          />
        ) : null}
        {editModalType === "SUCCESS_MODAL" ? (
          <SuccessEditContent
            type={type}
            onClose={onCloseModal}
            onChangeModalType={onChangeModalType}
          />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
