import React from "react";
import { SideMenu } from "../sideMenu";
import { Drawer } from "@mui/material";
import { materialStyles } from "./styles";

type IMobileSideMenu = {
  isOpen: boolean;
  onClose: () => void;
};

export const MobileSideMenu: React.FC<IMobileSideMenu> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Drawer sx={materialStyles.drawerContainer} open={isOpen} onClose={onClose}>
      <SideMenu />
    </Drawer>
  );
};
