import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { getAllowedMedia } from "../store/thunks/globe";
import {
  setExploreAllowedMedia,
  resetExploreAllowedMedia,
  setExploreAllowedMediaPage,
  setExploreAllowedMediaPageSize,
} from "../store/slices/explore";
import { useAuth } from "./useAuth";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../constants/api";

type UseFetchExploreAllowedFeedProps = {
  feedType?: string;
  sortBy?: string;
};

export const useFetchExploreAllowedFeed = ({
  feedType,
  sortBy,
}: UseFetchExploreAllowedFeedProps) => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { allowedMedia } = useAppSelector((state) => state.explore);

  const fetchFeed = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        if (!isAuth) {
          return;
        }

        const media = await dispatch(
          getAllowedMedia(
            page || DEFAULT_PAGE,
            pageSize,
            undefined,
            feedType,
            sortBy
          )
        );

        dispatch(setExploreAllowedMedia(media));
      } catch (error) {
        console.error("Error while [fetchFeed]", error);
      }
    },
    [dispatch, feedType, isAuth, sortBy]
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setExploreAllowedMediaPage(allowedMedia.page + 1));
    dispatch(setExploreAllowedMediaPageSize(DEFAULT_PAGE_SIZE));
  }, [allowedMedia.page, dispatch]);

  const refetchFeed = useCallback(() => {
    fetchFeed(allowedMedia.page, allowedMedia.pageSize);
  }, [allowedMedia.page, allowedMedia.pageSize, fetchFeed]);

  const resetAllowedMedia = useCallback(() => {
    dispatch(resetExploreAllowedMedia());
  }, [dispatch]);

  useEffect(() => {
    fetchFeed(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);

    return () => {
      dispatch(resetExploreAllowedMedia());
    };
  }, [dispatch, fetchFeed, isAuth]);

  useEffect(() => {
    if (allowedMedia.page !== DEFAULT_PAGE) {
      fetchFeed(allowedMedia.page, allowedMedia.pageSize);
    }
  }, [allowedMedia.page, allowedMedia.pageSize, fetchFeed]);

  return {
    allowedPosts: allowedMedia.data,
    onUpdatePageOrPageSize,
    refetchFeed,
    resetAllowedMedia,
  };
};
