import { useCallback } from "react";
import { socket } from "../socket";
import { useAppSelector } from "../store";

export const useSocketSupportConversationHandle = (id: number | null) => {
  const { isConnected } = useAppSelector((state) => state.socket);

  const onSocketJoin = useCallback(() => {
    if (isConnected) {
      socket.emit("support:join", id);
    }
  }, [id, isConnected]);

  const onSocketLeave = useCallback(() => {
    if (isConnected) {
      socket.emit("support:leave", id);
    }
  }, [id, isConnected]);

  return {
    onSocketJoin,
    onSocketLeave,
  };
};
