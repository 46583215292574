import { SIDE_MENU_WIDTH } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  drawerContainer: {
    zIndex: 20,
    width: `${SIDE_MENU_WIDTH}px`,
    position: "absolute",
  },
  drawerPaperContainer: {
    borderRight: "none",
  },
};
