import { Box, Modal } from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles } from "./styles";
import { VideoEditorForm } from "./components/VideoEditorForm";
import { useAppDispatch, useAppSelector } from "../../../store";
import { resetVideoEditor } from "../../../store/slices/videoEditor";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";

export type TVideoEditorModalState = "VIDEO_EDITOR";

type TVideoEditorModal = {
  isOpen: boolean;
  onClose: () => void;
  initialModalType?: TVideoEditorModalState;
};

export const VideoEditorModal: React.FC<TVideoEditorModal> = ({
  isOpen,
  onClose: onCloseFromProps,
  initialModalType = "VIDEO_EDITOR",
}) => {
  const dispatch = useAppDispatch();

  const { loading } = useAppSelector((state) => state.loader);

  const onClose = useCallback(() => {
    dispatch(resetVideoEditor());
    onCloseFromProps();
  }, [dispatch, onCloseFromProps]);

  return (
    <Modal open={isOpen} onClose={onClose} sx={materialStyles.modal}>
      <Box sx={materialStyles.modalBox}>
        {initialModalType === "VIDEO_EDITOR" ? (
          <VideoEditorForm onClose={onClose} />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
