import { Box, IconButton, TextField } from "@mui/material";
import React, { useCallback, useState } from "react";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import SendIcon from "@mui/icons-material/Send";
import { useAppSelector } from "../../../store";
import { socket } from "../../../socket";
import _ from "lodash";

export const ChatSendInput: React.FC = () => {
  const { t } = useTranslation();
  const { isConnected } = useAppSelector((state) => state.socket);
  const { user } = useAppSelector((state) => state.chat);
  const [message, setMessage] = useState("");

  const onChangeMessage = useCallback((event: any) => {
    const { value } = event.target;
    setMessage(value);
  }, []);

  const handleSend = useCallback(
    (event: any) => {
      event.preventDefault();

      if (!isConnected) {
        return;
      }

      if (!user) {
        return;
      }

      socket.emit("chat:send", { message, user_id: user.id });

      setMessage("");
    },
    [isConnected, message, user],
  );

  return (
    <Box sx={materialStyles.chatSendInputContainer}>
      <form onSubmit={handleSend}>
        <TextField
          variant="filled"
          sx={materialStyles.chatSendInput}
          fullWidth
          placeholder={t("messages.type_message")}
          value={message}
          onChange={onChangeMessage}
          InputProps={{
            endAdornment: (
              <IconButton type="submit" disabled={!Boolean(_.trim(message))}>
                <SendIcon sx={materialStyles.sendButton} />
              </IconButton>
            ),
          }}
        />
      </form>
    </Box>
  );
};
