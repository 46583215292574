import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {
  map: {
    height: "100vh",
    flex: 1,
  },
};

export const materialStyles: IMaterialStyleType = {
  appImageList: {
    marginTop: "16px",
  },
};
