import React, { RefObject, useEffect } from "react";
import { Box } from "@mui/material";
import { materialStyles } from "./styles";
import _ from "lodash";
import { ChatMessage } from "./ChatMessage";
import { useLocalProccessSocketMessages } from "../../../hooks/useLocalProccessSocketMessages";

type TChatMesaagesProps = {
  messagesScrollEndContainerRef?: RefObject<HTMLDivElement>;
};

export const ChatMessages: React.FC<TChatMesaagesProps> = ({
  messagesScrollEndContainerRef,
}) => {
  const { messagesState } = useLocalProccessSocketMessages();

  useEffect(() => {
    if (!messagesScrollEndContainerRef?.current) {
      return;
    }

    if (!_.isEmpty(messagesState.messages)) {
      messagesScrollEndContainerRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [messagesScrollEndContainerRef, messagesState.messages]);

  return (
    <Box sx={materialStyles.chatMessagesContainer}>
      <div ref={messagesScrollEndContainerRef} />
      {_.map(messagesState.messages, (message) => (
        <ChatMessage key={message.id} message={message} />
      ))}
    </Box>
  );
};
