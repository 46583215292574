import { ReactNode, useCallback, useRef, useState, useEffect } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import { materialStyles } from "./styles";
import { AppHeader } from "../header/AppHeader";
import { MobileSideMenu } from "../mobileSideMenu";
import { DrawerSideMenu } from "../drawerSideMenu";
import { SIDE_MENU_WIDTH } from "../../theme";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

type IUserLayoutProps = {
  children: ReactNode;
  disableMargin?: boolean;
  onEndReached?: () => void;
  disableOnEndReached?: boolean;
};

export const UserLayout: React.FC<IUserLayoutProps> = ({
  children,
  disableMargin,
  onEndReached,
  disableOnEndReached = false,
}) => {
  const [isOpenMobileSideMenu, setIsOpenMobileSideMenu] = useState(false);
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(false);

  const layoutRef = useRef<any>(null);

  const onOpenOrCloseMobileSideMenu = useCallback(
    () => setIsOpenMobileSideMenu(!isOpenMobileSideMenu),
    [isOpenMobileSideMenu],
  );

  const onOpenOrCloseSideMenu = useCallback(() => {
    setIsOpenSideMenu(!isOpenSideMenu);
  }, [isOpenSideMenu]);

  useEffect(() => {
    const gridElement = layoutRef.current;

    const handleScroll = () => {
      if (
        Math.round(gridElement.scrollTop + gridElement.clientHeight) >=
          gridElement.scrollHeight ||
        Math.floor(gridElement.scrollTop + gridElement.clientHeight) >=
          gridElement.scrollHeight
      ) {
        onEndReached && !disableOnEndReached && onEndReached();
      }
    };

    gridElement.addEventListener("scroll", handleScroll);

    return () => {
      gridElement.removeEventListener("scroll", handleScroll);
    };
  }, [disableOnEndReached, onEndReached]);

  return (
    <Grid sx={materialStyles.page}>
      <AppHeader
        onOpenOrCloseSideMenu={onOpenOrCloseSideMenu}
        onOpenMobileSideMenu={onOpenOrCloseMobileSideMenu}
      />
      <Grid sx={materialStyles.desktopSideMenu}>
        <DrawerSideMenu
          isOpen={isOpenSideMenu}
          onClose={onOpenOrCloseSideMenu}
        />
        <Box
          sx={[
            materialStyles.closer,
            {
              left: isOpenSideMenu ? SIDE_MENU_WIDTH : 0,
              transition: "0.2s",
            },
          ]}
        >
          <IconButton
            onClick={onOpenOrCloseSideMenu}
            sx={materialStyles.closerIconContainer}
          >
            {isOpenSideMenu ? (
              <ArrowBackIosNewIcon sx={materialStyles.closerIcon} />
            ) : (
              <ArrowForwardIosIcon sx={materialStyles.closerIcon} />
            )}
          </IconButton>
        </Box>
      </Grid>
      <Grid sx={materialStyles.mobileSideMenu}>
        <MobileSideMenu
          isOpen={isOpenMobileSideMenu}
          onClose={onOpenOrCloseMobileSideMenu}
        />
      </Grid>
      <Grid
        ref={layoutRef}
        sx={[
          materialStyles.userLayout,
          Boolean(disableMargin) && materialStyles.disableMarginUserLayout,
          !Boolean(disableMargin) && {
            marginLeft: {
              xs: 0,
              sm: 0,
              md: isOpenSideMenu ? `${SIDE_MENU_WIDTH}px` : 0,
            },
            transition: "0.2s",
          },
        ]}
      >
        {children}
      </Grid>
    </Grid>
  );
};
