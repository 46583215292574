import _ from "lodash";

export const calculateMapClasterCountByStep = (
  originalArray: any[] | null,
  step: number
) => {
  if (!originalArray) {
    return [];
  }
  const newArray: any[] = [];

  let memoStep = step;
  let memoCount = 1;

  _.forEach(originalArray, (item, index) => {
    const itt = index + 1;

    if (itt === memoStep + 1) {
      memoCount++;
      memoStep += step * 2;
    }

    newArray.push(memoCount);
  });

  return newArray;
};
