import { Colors, theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {
  marker: {
    minWidth: 45,
    minHeight: 45,
    width: 45,
    height: 45,
    maxWidth: 45,
    maxHeight: 45,
    borderRadius: "50%",
    transition: "0.2s",
    border: `2px solid ${Colors.white}`,
  },
};

export const materialStyles: IMaterialStyleType = {
  markerCountBlock: {
    display: "flex",
    position: "absolute",
    backgroundColor: theme.palette.background.default,
    borderRadius: "50%",
    padding: "2px 6px",
    right: "-10px",
    top: "-10px",
    width: "28px",
    aspectRatio: 1,
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${Colors.white}`,
  },
  markerCount: {
    fontSize: "14px",
    color: Colors.white,
    fontWeight: 500,
  },
};
