import React, { CSSProperties, useCallback, useMemo } from "react";
import { CodeResponse } from "@react-oauth/google";
import { materialStyles } from "./styles";
import { Grid, Button } from "@mui/material";
import Google from "../../../../assets/images/socials/Google.svg";
import { useTranslation } from "react-i18next";
import { IAuthModalType } from "..";
import { useAppDispatch } from "../../../../store";
import { googleAuthCallback } from "../../../../store/thunks/user";
import { GOOGLE_CLIENT_ID } from "../../../../constants";

type ISocialButtons = {
  type: IAuthModalType | "CONNECT_GOOGLE_ACCOUNT";
  style?: CSSProperties;
  onAuthSuccess?: (isNew: boolean) => void;
  state?: string | null;
};

const GOOGLE_AUTH_REDIRECT_URI = "api/auth/google/callback";
const GOOGLE_AUTH_SCOPE =
  "openid email profile https://www.googleapis.com/auth/photoslibrary.readonly.originals";
const GOOGLE_UX_MODE = "redirect";

export const SocialButtons: React.FC<ISocialButtons> = ({
  type,
  style,
  state,
  onAuthSuccess,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onGoogleLoginSuccess = useCallback(
    async (codeResponse: CodeResponse) => {
      try {
        const data = await dispatch(googleAuthCallback(codeResponse));
        onAuthSuccess && onAuthSuccess(data.is_new);
      } catch (error) {
        console.error("Error while google Auth");
      }
    },
    [dispatch, onAuthSuccess],
  );

  const client = (window as any).google.accounts.oauth2.initCodeClient({
    client_id: GOOGLE_CLIENT_ID,
    scope: GOOGLE_AUTH_SCOPE,
    ux_mode: GOOGLE_UX_MODE,
    redirect_uri: `${window.location.origin}/${GOOGLE_AUTH_REDIRECT_URI}`,
    state,
    callback: onGoogleLoginSuccess,
  });

  const buttonTitle = useMemo(() => {
    switch (type) {
      case "LOGIN":
        return t("auth_modal.login_with_google");
      case "SIGNUP":
        return t("auth_modal.sign_up_with_google");
      case "CONNECT_GOOGLE_ACCOUNT":
        return t("auth_modal.connect_google_account");
    }
  }, [t, type]);

  return (
    <Grid style={style} sx={materialStyles.socialBlock}>
      <Button
        sx={materialStyles.socialButton}
        startIcon={<img src={Google} alt="Google" />}
        variant="outlined"
        onClick={() => client.requestCode()}
      >
        {buttonTitle}
      </Button>
    </Grid>
  );
};
