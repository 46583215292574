import { Colors, theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";
import { withHexOpacity } from "../../utils";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  deleteIcon: {
    color: Colors.white,
  },
  sharingItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0",
  },
  sharingItemUserContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  userName: {
    color: Colors.white,
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700,
  },
  email: {
    fontSize: "12px",
    lineHeight: "20px",
    color: theme.palette.grey[900],
  },
  userRoleTitle: {
    fontSize: "12px",
    lineHeight: "19px",
    color: theme.palette.grey[900],
  },
  tagItem: {
    display: "flex",
    alignItems: "center",
    padding: "6px 14px 6px 14px",
    borderRadius: "20px",
    backgroundColor: withHexOpacity(Colors.white, 0.12),
    border: `1px solid ${withHexOpacity(Colors.white, 0.12)}`,
    flexDirection: "row",
    marginBottom: "10px",
    height: "38px",
    marginRight: "10px",
  },
  filterItem: {
    display: "flex",
    alignItems: "center",
    padding: "6px 7px 6px 14px",
    borderRadius: "20px",
    backgroundColor: withHexOpacity(Colors.black, 0.3),
    flexDirection: "row",
  },
  choosenTagItem: {
    border: `1.5px solid ${withHexOpacity(Colors.white, 1)}`,
  },
  tagItemTitle: {
    fontSize: "14px",
    lineHeight: "18px",
    color: Colors.white,
    whiteSpace: "pre",
  },
  appliedFiltersContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "14px",
    alignItems: "center",
    flex: 1,
    overflow: "auto",
    marginRight: "6px",
  },
  removeFilterButton: {
    width: "25px",
    height: "25px",
  },
  removeFilterIcon: {
    color: Colors.white,
    width: "15px",
    height: "15px",
  },
  clearAllFiltersButton: {},
  searchOption: {
    gap: "8px",
  },
  searchUserName: {
    color: Colors.white,
  },
  removeTagIcon: {
    color: Colors.white,
    width: "14px",
    height: "14px",
    marginLeft: "8px",
  },
  userWithRemoveItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.palette.grey["800"],
    width: "fit-content",
    padding: "6px",
    borderRadius: "24px",
  },
  removeIconContainer: {
    color: Colors.white,
    width: "15px",
    height: "15px",
  },
  removeIcon: {
    width: "15px",
    height: "15px",
  },
  itemUsername: {
    color: Colors.white,
    marginLeft: "8px",
    marginRight: "8px",
  },
  checkBox: {
    fontSize: "14px !important",
    lineHeight: "20px",
    letterSpacing: 0,

    "& span": {
      fontSize: "14px !important",
      lineHeight: "20px",
      color: theme.palette.grey[700],
    },

    "& .Mui-disabled": {
      color: `${theme.palette.grey[800]} !important`,
    },

    "&.Mui-checked": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  groupWithCheckboxItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  groupWithCheckboxItemIcon: {
    color: theme.palette.grey[700],
  },
};
