import React, { useEffect, useMemo } from "react";
import { Message } from "../../../store/slices/messages";
import { Box, Grid, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { UserAvatar } from "../../user/UserAvatar";
import { useAppSelector } from "../../../store";
import { socket } from "../../../socket";

type TChatMessage = {
  message: Message;
};

export const ChatMessage: React.FC<TChatMessage> = ({ message }) => {
  const { user } = useAppSelector((state) => state.user);

  const isMyMessage = useMemo(
    () => user?.id === message.sender_id,
    [message.sender_id, user?.id],
  );

  useEffect(() => {
    if (!message.is_read && !isMyMessage) {
      socket.emit("chat:read", message.id);
    }
  }, [isMyMessage, message.id, message.is_read]);

  return (
    <Box
      sx={[
        materialStyles.chatMessageContainer,
        isMyMessage && materialStyles.myChatMessageContainer,
      ]}
    >
      {!isMyMessage ? (
        <UserAvatar type="DEFAULT" user={message.sender} />
      ) : null}
      <Grid
        sx={[
          materialStyles.messageContainer,
          isMyMessage && materialStyles.myMessageContainer,
        ]}
      >
        <Typography sx={materialStyles.message}>{message.message}</Typography>
      </Grid>
      {isMyMessage ? <UserAvatar type="DEFAULT" user={message.sender} /> : null}
    </Box>
  );
};
