import { Box, Modal } from "@mui/material";
import React, { useCallback, useState } from "react";
import { materialStyles } from "./styles";
import { MainSelectAvatarContent } from "./components/MainSelectAvatarContent";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";
import { useAppDispatch, useAppSelector } from "../../../store";
import { resetSelectAvatarProps } from "../../../store/slices/selectAvatar";
import { UploadNewAvatarForm } from "./components/UploadNewAvatarForm";

export type ISelectAvatarPhotoModalType =
  | "UPLOAD_NEW_AVATAR"
  | "SELECT_NEW_AVATAR";

type ISelectAvatarPhotoModal = {
  isOpen: boolean;
  onClose: () => void;
  initialType?: ISelectAvatarPhotoModalType;
};

export const SelectAvatarPhotoModal: React.FC<ISelectAvatarPhotoModal> = ({
  isOpen,
  onClose,
  initialType = "SELECT_NEW_AVATAR",
}) => {
  const { loading } = useAppSelector((state) => state.loader);
  const [selectAvatarPhotoModalType, setSelectAvatarPhotoModalType] =
    useState(initialType);

  const dispatch = useAppDispatch();

  const onChangeSelectModalType = useCallback(
    (type: ISelectAvatarPhotoModalType) => {
      setSelectAvatarPhotoModalType(type);
    },
    []
  );

  const onCloseModal = useCallback(() => {
    dispatch(resetSelectAvatarProps());
    setSelectAvatarPhotoModalType(initialType);
    onClose();
  }, [dispatch, initialType, onClose]);

  return (
    <Modal sx={materialStyles.modal} open={isOpen} onClose={onCloseModal}>
      <Box sx={materialStyles.modalBox}>
        {selectAvatarPhotoModalType === "UPLOAD_NEW_AVATAR" ? (
          <UploadNewAvatarForm
            onChangeSelectModalType={onChangeSelectModalType}
          />
        ) : null}
        {selectAvatarPhotoModalType === "SELECT_NEW_AVATAR" ? (
          <MainSelectAvatarContent onClose={onCloseModal} />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
