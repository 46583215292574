import { Colors, theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  pageTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageTitleBlock: {
    marginRight: "12px",
  },
  pageTitle: {
    fontSize: "30px",
    lineHeight: "53px",
    fontWeight: "bold !important",
    color: Colors.white,
  },
  pageSubtitle: {
    fontSize: "16px",
    lineHeight: "28px",
    color: theme.palette.grey[700],
    textAlign: "center",
  },
};
