import { AppDispatch, RootState } from "..";
import axios from "axios";
import { API_URL } from "../api";
import { setLoading } from "../slices/loading";
import { setError } from "../slices/errors";
import { getResponseFromAxiosError } from "../../utils";
import { checkOrRefreshToken } from "./user";

export const getTags = (title?: string, isUseStorageLoading?: boolean) => {
  return async (dispatch: AppDispatch) => {
    try {
      if (isUseStorageLoading) dispatch(setLoading(true));

      const { data } = await axios.get(`${API_URL}/tags`, {
        params: { limit: 300, title },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getTags]", error);
    } finally {
      if (isUseStorageLoading) dispatch(setLoading(false));
    }
  };
};

export const createTag = (title: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/tags`,
        { title },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        }
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getTags]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
