import { createSlice } from "@reduxjs/toolkit";
import { Message } from "./messages";
import { IUserType } from "./user";

interface IChatSliceInitialState {
  user: IUserType | null;
  messages: Message[];
}

const chatSliceInitialState: IChatSliceInitialState = {
  user: null,
  messages: [],
};

const chatSlice = createSlice({
  name: "chat",
  initialState: chatSliceInitialState,
  reducers: {
    setChatUser(state, action) {
      state.user = action.payload;
    },
    setChatMessages(state, action) {
      state.messages = action.payload;
    },
    resetChat(state) {
      state.user = chatSliceInitialState.user;
      state.messages = chatSliceInitialState.messages;
    },
  },
});

export default chatSlice.reducer;
export const { resetChat, setChatUser, setChatMessages } = chatSlice.actions;
