import _ from "lodash";

export const getUserAttributesForAvatar = (userName?: string) => {
  if (!userName) {
    return;
  }

  const firstTwoLetters = userName.substring(0, 2).toUpperCase();

  return firstTwoLetters;
};

export const calculateUsagePercentage = (
  used: number,
  totalAllowed: number
) => {
  const usagePercentage = (used / totalAllowed) * 100;
  return _.toNumber(usagePercentage.toFixed(2));
};

export const perseBytesToGB = (bytes: number) => {
  const gigabytes = bytes / 1024 ** 3;
  return _.toNumber(gigabytes.toFixed(2));
};
