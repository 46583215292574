import { Button, Grid, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store";
import { SharingItems } from "../../../items/SharingItem";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useCopy } from "../../../../hooks/useCopy";
import { generateSharingLink } from "../../../../utils";

type IShareMainContent = {
  type: "ALBUM" | "PHOTO";
  onClose: () => void;
};

export const ShareMainContent: React.FC<IShareMainContent> = ({
  type,
  onClose,
}) => {
  const { t } = useTranslation();
  const { actionId } = useAppSelector((state) => state.sharing);
  const { photo } = useAppSelector((state) => state.userPhoto);

  const onCopy = useCopy();

  const onCopyLink = useCallback(() => {
    if (!actionId) {
      return;
    }

    const photoLink = generateSharingLink(actionId);
    onCopy(photoLink);
  }, [actionId, onCopy]);

  return (
    <Grid sx={materialStyles.innerModalContainer}>
      <Typography sx={materialStyles.shareModalTitle}>
        {type === "ALBUM"
          ? t("sharing.share_this_album")
          : t("sharing.share_this_file")}
      </Typography>
      <Grid sx={materialStyles.userWithGeneralAccess}>
        <Typography sx={materialStyles.shareModalSubtitle}>
          {t("sharing.general_access")}
        </Typography>
        <SharingItems isOwner user={photo?.user} />
      </Grid>
      <Grid sx={materialStyles.sharingButtons}>
        <Button
          startIcon={<InsertLinkIcon />}
          sx={materialStyles.copyLinkButton}
          onClick={onCopyLink}
        >
          {t("sharing.copy_link")}
        </Button>
      </Grid>
    </Grid>
  );
};
