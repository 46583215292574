import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { materialStyles } from "./styles";
import { useAppSelector } from "../../../../store";
import { useLocalProccessSocketSupportMessages } from "../../../../hooks/useLocalProccessSocketSupportMessages";
import _ from "lodash";
import { SupportMessage } from "./SupportMessage";
import { socket } from "../../../../socket";

export const SupportConversationForm: React.FC = () => {
  const { t } = useTranslation();
  const { supportRequestId } = useAppSelector(
    (state) => state.supportRequestConversation,
  );

  const { isConnected } = useAppSelector((state) => state.socket);

  const [message, setMessage] = useState("");

  const { messagesState } = useLocalProccessSocketSupportMessages();

  const isDisabledSend = useMemo(() => !Boolean(message), [message]);

  const sortedMessages = useMemo(
    () => _.orderBy(messagesState.messages, ["created_at"], ["desc"]),
    [messagesState.messages],
  );

  const onChangeMessage = useCallback((event: any) => {
    const { value } = event.target;
    setMessage(value);
  }, []);

  const handleSend = useCallback(
    (event: any) => {
      event.preventDefault();

      if (!isConnected) {
        return;
      }

      socket.emit("support:reply", { message, request_id: supportRequestId });

      setMessage("");
    },
    [isConnected, message, supportRequestId],
  );

  return (
    <Grid sx={materialStyles.modalInnerBlock}>
      <Grid sx={materialStyles.modalHeaderBlock}>
        <Typography sx={materialStyles.modalHeaderTitle}>
          {t("settings.support_request_with_number", {
            id: supportRequestId,
          })}
        </Typography>
      </Grid>
      <Grid sx={materialStyles.supportConversationContainer}>
        {_.map(sortedMessages, (message) => (
          <SupportMessage key={message.id} message={message} />
        ))}
      </Grid>
      <TextField
        fullWidth
        multiline
        maxRows={4}
        placeholder={t("settings.type_message")}
        sx={materialStyles.sendInput}
        value={message}
        onChange={onChangeMessage}
        InputProps={{
          endAdornment: (
            <Button
              disabled={isDisabledSend}
              onClick={handleSend}
              sx={materialStyles.sendButton}
            >
              {t("settings.send")}
            </Button>
          ),
        }}
      />
    </Grid>
  );
};
