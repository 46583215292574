import React, { useCallback, useEffect, useMemo } from "react";
import { materialStyles } from "./styles";
import { Box, Typography } from "@mui/material";
import { addNewChatMessages, Message } from "../../../store/slices/messages";
import _ from "lodash";
import { ChatListItem } from "./components/ChatListItem";
import { IUserType } from "../../../store/slices/user";
import { useTranslation } from "react-i18next";
import { socket } from "../../../socket";
import { useAppDispatch, useAppSelector } from "../../../store";

type TChatsList = {
  chats: Message[];
  onChatClick?: (id: Message["id"], user?: Message["user"]) => void;
  activeChatUser?: IUserType | null;
};

export const ChatsList: React.FC<TChatsList> = ({
  chats,
  onChatClick,
  activeChatUser,
}) => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const sortedChats = useMemo(
    () => _.orderBy(chats, ["created_at"], ["desc"]),
    [chats],
  );

  const handleAddNewChat = useCallback(
    (message: Message) => {
      if (!user) {
        return;
      }

      const chatUsersIds = _.map(sortedChats, (chat) => [
        chat.sender_id,
        chat.user_id,
      ]);

      const isChatAlreadyCreated = _.find(chatUsersIds, (usersIds) => {
        if (
          _.includes(usersIds, message.user_id) &&
          _.includes(usersIds, message.sender_id)
        ) {
          return true;
        }

        return false;
      });

      if (!isChatAlreadyCreated) {
        dispatch(addNewChatMessages(message));
      }
    },
    [dispatch, sortedChats, user],
  );

  useEffect(() => {
    socket.on("chat:send", handleAddNewChat);

    return () => {
      socket.off("chat:send", handleAddNewChat);
    };
  }, [handleAddNewChat]);

  return (
    <Box sx={materialStyles.listContainer}>
      {_.map(sortedChats, (chat) => (
        <ChatListItem
          key={chat.id}
          chat={chat}
          onClick={onChatClick}
          activeChatUser={activeChatUser}
        />
      ))}
      {_.isEmpty(sortedChats) ? (
        <Box sx={materialStyles.emptyChatListContainer}>
          <Typography sx={materialStyles.emptyChatListTitle}>
            {t("messages.chat_list_empty")}
          </Typography>
          <Typography sx={materialStyles.emptyChatListSubtitle}>
            {t("messages.you_can_find_users_map_and_write_them")}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};
