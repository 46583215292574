import { createSlice } from "@reduxjs/toolkit";

export type TVisualMode = "GRID" | "GLOBE";

interface IVisualModeSliceInitialState {
  mode: TVisualMode;
}

const visualModeSliceInitialState: IVisualModeSliceInitialState = {
  mode: "GLOBE",
};

const visualModeSlice = createSlice({
  name: "visualMode",
  initialState: visualModeSliceInitialState,
  reducers: {
    setVisualMode(state, action) {
      state.mode = action.payload;
    },
  },
});

export default visualModeSlice.reducer;
export const { setVisualMode } = visualModeSlice.actions;
