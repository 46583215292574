import { useCallback, useEffect, useState } from "react";
import { IUserType } from "../store/slices/user";
import { useDebounce } from "./useDebounce";
import { useAppDispatch } from "../store";
import { DISPLAY_USERS_FROM_LENGTH } from "./useSearchPeoples";
import { getTags } from "../store/thunks/tags";

export const useSearchTags = (
  search: string,
  isUseStorageLoading?: boolean
) => {
  const [findedTags, setFindedTags] = useState<IUserType[]>([]);

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const debouncedSearch = useDebounce(search, 500);

  const load = useCallback(
    async (value: string) => {
      try {
        if (!isUseStorageLoading) setLoading(true);

        const response = await dispatch(
          getTags(value, isUseStorageLoading || false)
        );

        if (!response) {
          throw new Error();
        }

        setFindedTags(response);
      } catch (error) {
        console.error("Error while [load]", error);
      } finally {
        if (!isUseStorageLoading) setLoading(false);
      }
    },
    [dispatch, isUseStorageLoading]
  );

  useEffect(() => {
    if (debouncedSearch.length >= DISPLAY_USERS_FROM_LENGTH) {
      load(debouncedSearch);
    }
  }, [debouncedSearch, load]);

  useEffect(() => {
    if (debouncedSearch.length === 0) {
      setFindedTags([]);
    }
  }, [debouncedSearch.length]);

  return {
    findedTags,
    debouncedSearch,
    loading,
  };
};
