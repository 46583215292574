import _ from "lodash";
import { PRIVACY_GROUP_TYPES, PrivacyGroup } from "../store/slices/groups";

export const groupsOrder = [
  PRIVACY_GROUP_TYPES.AUTHORIZED,
  PRIVACY_GROUP_TYPES.FOLLOWERS,
  PRIVACY_GROUP_TYPES.FRIENDS,
  PRIVACY_GROUP_TYPES.FAMILY,
  PRIVACY_GROUP_TYPES.CUSTOM,
  PRIVACY_GROUP_TYPES.RESTRICTED,
];

export function sortedGroupsByType(groups?: PrivacyGroup[] | null) {
  if (!groups) {
    return [] as PrivacyGroup[];
  }

  const sortedGroups = _.sortBy(groups, (group) => {
    const sortedIndex = _.indexOf(groupsOrder, group.type);

    return sortedIndex;
  });

  return sortedGroups;
}
