import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  titleBlock: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: "14px 24px",
  },
  filtersMainTitle: {
    color: Colors.white,
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "bold",
  },
  filtersForm: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  filtersContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "0 24px",
    overflow: "auto",
  },
  submitButton: {
    margin: "16px",
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    flex: 1,
    textTransform: "none",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    letterSpacing: 0,
  },
  filterBlock: {
    marginBottom: "36px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: 2,
  },
  datePickerContainer: {
    width: "100%",
  },
  datePickerInput: {
    width: "100%",
    backgroundColor: theme.palette.grey[600],

    borderRadius: "8px",

    "& .MuiInputBase-input": {
      color: Colors.white,
    },

    "& .MuiIconButton-root": {
      color: theme.palette.grey["A700"],
    },

    "& fieldset": {
      border: "none",
    },

    "& ::placeholder": {
      color: `${theme.palette.grey["A700"]}`,
      opacity: 1,
    },
  },
  filterTitle: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "400",
    color: theme.palette.grey[700],
    textTransform: "uppercase",
    marginBottom: "10px",
  },
  cancelButton: {
    width: "max-content",
    textTransform: "none",
    color: Colors.white,
    letterSpacing: 0,
  },
  cancelButtonContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filtersAutocomplete: {
    marginBottom: "14px",
  },
  clearUserFilterIcon: {
    color: Colors.white,
  },
  closeIcon: {
    color: Colors.white,
  },
  bottomButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};
