import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Box, ButtonBase, TextField, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import "mapbox-gl/dist/mapbox-gl.css";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useTranslation } from "react-i18next";

const DISPLAY_MAP_LOCATIONS_FROM = 3;

type IAppMapSearchBox = {
  onSelectPoint?: (lng: number, lat: number) => void;
};

export const AppMapSearchBox: React.FC<IAppMapSearchBox> = ({
  onSelectPoint,
}) => {
  const { t, i18n } = useTranslation();
  const placesApiLoaded = useMapsLibrary("places");
  const geocodingApiLoaded = useMapsLibrary("geocoding");

  const geocodingServiceRef = useRef<google.maps.Geocoder | null>(null);

  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);

  const [searchInput, setSearchInput] = useState<string>("");
  const [findedLocations, setFindedLocations] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  useEffect(() => {
    if (_.isEmpty(searchInput)) {
      setFindedLocations([]);
    }
  }, [searchInput]);

  useEffect(() => {
    if (!placesApiLoaded) return;

    autocompleteServiceRef.current =
      new window.google.maps.places.AutocompleteService();
  }, [placesApiLoaded]);

  useEffect(() => {
    if (!geocodingApiLoaded) return;

    geocodingServiceRef.current = new window.google.maps.Geocoder();
  }, [geocodingApiLoaded]);

  useEffect(() => {
    if (
      !autocompleteServiceRef.current ||
      searchInput.length < DISPLAY_MAP_LOCATIONS_FROM
    ) {
      return;
    }

    autocompleteServiceRef.current.getPlacePredictions(
      { input: searchInput, language: i18n.language },
      (result, status) => {
        if (!result || status !== "OK") {
          return;
        }

        setFindedLocations(result);
      },
    );
  }, [i18n.language, searchInput]);

  const onChangeSearchValue = useCallback((event: any) => {
    const { value } = event.target;
    setSearchInput(value);
  }, []);

  const onSelectLocation = useCallback(
    async (location: google.maps.places.AutocompletePrediction) => {
      try {
        if (!onSelectPoint) {
          return;
        }

        geocodingServiceRef.current?.geocode(
          { placeId: location.place_id },
          (results, status) => {
            if (!results || status !== "OK") {
              return;
            }

            const findedGeocode = _.first(results);

            if (!findedGeocode) {
              return;
            }

            onSelectPoint(
              findedGeocode.geometry.location.lng(),
              findedGeocode.geometry.location.lat(),
            );
          },
        );

        setFindedLocations([]);
        setSearchInput("");
      } catch (error) {
        console.error("Error while [onSelectLocation]", error);
      }
    },
    [onSelectPoint],
  );

  if (!placesApiLoaded || !geocodingApiLoaded) {
    return null;
  }

  return (
    <Box sx={materialStyles.searchBoxContainer}>
      <TextField
        value={searchInput}
        onChange={onChangeSearchValue}
        size="small"
        placeholder={t("upload_photo.enter_adress")}
        sx={materialStyles.searchBoxInput}
      />
      {!_.isEmpty(findedLocations) ? (
        <Box sx={materialStyles.searchBoxContainerPopover}>
          {_.map(findedLocations, (location) => (
            <ButtonBase
              key={location.place_id}
              sx={materialStyles.searchBoxPopoverButton}
              onClick={() => onSelectLocation(location)}
            >
              <Typography sx={materialStyles.searchBoxPopoverButtonTitle}>
                {location.description}
              </Typography>
            </ButtonBase>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};
