import { AppDispatch, RootState } from "..";
import axios from "axios";
import { API_URL } from "../api";
import { setLoading } from "../slices/loading";
import { setError } from "../slices/errors";
import { getResponseFromAxiosError } from "../../utils";
import { checkOrRefreshToken } from "./user";

export const getMyFavoritesPhoto = (page?: number, pageSize?: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/favorites/media`, {
        params: {
          page,
          limit: pageSize,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getMyFavoritesPhoto]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addFavoritesPhoto = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/favorites/media/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        }
      );

      if (!data) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [addFavoritesPhoto]", error);
    }
  };
};

export const removeFavoritesPhoto = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.delete(`${API_URL}/favorites/media/${id}`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [removeFavoritesPhoto]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
