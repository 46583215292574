import { createSlice } from "@reduxjs/toolkit";
import { ISupportMessage, ISupportRequest } from "./supportRequests";

interface ISupportRequestConversationSliceInitialState {
  supportRequestId: ISupportRequest["id"] | null;
  messages: ISupportMessage[];
}

const supportRequestConversationSliceInitialState: ISupportRequestConversationSliceInitialState =
  {
    supportRequestId: null,
    messages: [],
  };

const supportRequestConversationSlice = createSlice({
  name: "supportRequestConversation",
  initialState: supportRequestConversationSliceInitialState,
  reducers: {
    setSupportRequestId(state, action) {
      state.supportRequestId = action.payload;
    },
    setSupportConversationMessages(state, action) {
      state.messages = action.payload;
    },
    resetSupportConversation(state) {
      state.messages = supportRequestConversationSliceInitialState.messages;
      state.supportRequestId =
        supportRequestConversationSliceInitialState.supportRequestId;
    },
  },
});

export default supportRequestConversationSlice.reducer;
export const {
  setSupportRequestId,
  setSupportConversationMessages,
  resetSupportConversation,
} = supportRequestConversationSlice.actions;
