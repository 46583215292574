import { Grid, Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type IBlockEmptyWithIcon = {
  Icon?: React.ReactNode;
  title: string;
};

export const BlockEmptyWithIcon: React.FC<IBlockEmptyWithIcon> = ({
  Icon,
  title,
}) => {
  return (
    <Grid sx={materialStyles.blockEmptyWithIcon}>
      {Icon ? Icon : null}
      <Typography sx={materialStyles.blockEmptyTitle}>{title}</Typography>
    </Grid>
  );
};
