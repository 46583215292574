import { Drawer } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";
import { PhotosFiltersForm } from "./components/PhotosFiltersForm";
import { MapFiltersForm } from "./components/MapFiltersForm";

export type IFiltersDrawerType = "PHOTOS" | "MAP";

type IFiltersDrawer = {
  type: IFiltersDrawerType | null;
  isOpen: boolean;
  onClose: () => void;
};

export const FiltersDrawer: React.FC<IFiltersDrawer> = ({
  type,
  isOpen,
  onClose,
}) => {
  return (
    <Drawer
      PaperProps={{ sx: materialStyles.drawerContainer }}
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      {type === "PHOTOS" ? (
        <PhotosFiltersForm onClose={onClose} isOpen={isOpen} />
      ) : null}
      {type === "MAP" ? (
        <MapFiltersForm onClose={onClose} isOpen={isOpen} />
      ) : null}
    </Drawer>
  );
};
