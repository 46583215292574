import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {
  albumCover: {
    objectFit: "cover",
    minWidth: "268px",
    width: "100%",
    minHeight: "268px",
  },
};

export const materialStyles: IMaterialStyleType = {
  albumListContainer: {
    display: "grid",

    gridTemplateColumns: {
      xs: "repeat(1, 1fr)",
      sm: "repeat(2, 1fr)",
      lg: "repeat(3, 1fr)",
      xl: "repeat(4, 1fr)",
    },
  },
  albumItemContainer: {
    borderRadius: "20px",
    padding: "14px",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  singleAlbumItemContainer: {
    flex: 0,
  },
  albumTitleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "24px",
    width: "100%",
    maxWidth: "268px",
    overflow: "hidden",
  },
  albumCoverContainer: {
    display: "flex",
    flex: 1,
    minWidth: "268px",
    width: "100%",
    minHeight: "268px",
    maxHeight: "268px",
    borderRadius: "20px",
    backgroundColor: theme.palette.grey[600],
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  albumTitle: {
    fontSize: "20px",
    lineHeight: "28px",
    color: Colors.white,
    fontWeight: 700,
    maxWidth: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  albumItemsCount: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[900],
  },
};
