import { createSlice } from "@reduxjs/toolkit";
import { IAlbumType } from "./albums";
import { IPhotoType } from "./myPhotos";
import { IUserType } from "./user";
import _ from "lodash";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../constants/api";
import { catchChangesByKeys } from "../../utils";

interface IUserAlbumSliceInitialState {
  id: number | null;
  album: IAlbumType | null;
  albumPhotos: { data: IPhotoType[]; page: number; pageSize: number };
  sharingUsers: IUserType[];
}

const userAlbumSliceInitialState: IUserAlbumSliceInitialState = {
  id: null,
  album: null,
  albumPhotos: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  sharingUsers: [],
};

const userAlbumSlice = createSlice({
  name: "userAlbum",
  initialState: userAlbumSliceInitialState,
  reducers: {
    setUserAlbumId(state, action) {
      state.id = action.payload;
    },
    removePhotoFromAlbum(state, action) {
      if (!state.album) return;
      const albumMediaCount = _.get(state, "album.media_count");

      if (albumMediaCount) {
        state.album.media_count = albumMediaCount - 1;
      }

      const removedPhotos = _.filter(
        state.albumPhotos.data,
        (photo) => photo.id !== action.payload
      );

      state.albumPhotos.data = removedPhotos;
    },
    setUserAlbum(state, action) {
      state.album = action.payload;
    },
    setUserAlbumPhotos(state, action) {
      const photos = state.albumPhotos.data;

      if (_.isEmpty(photos)) {
        state.albumPhotos.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy([...photos, ...action.payload], "id");

      const itemsWithCheckedChanges = catchChangesByKeys(
        updatedItems,
        action.payload,
        ["is_favorite"]
      );

      state.albumPhotos.data = itemsWithCheckedChanges;
    },
    setUserAlbumPhotosPage(state, action) {
      state.albumPhotos.page = action.payload;
    },
    setUserAlbumPhotosPageSize(state, action) {
      state.albumPhotos.pageSize = action.payload;
    },
    resetUserAlbumPhotos(state) {
      state.albumPhotos = userAlbumSliceInitialState.albumPhotos;
    },
    setSharingUsers(state, action) {
      state.sharingUsers = action.payload;
    },
    addSharingUsers(state, action) {
      state.sharingUsers = [...state.sharingUsers, ...action.payload];
    },
    removeSharingUsers(state, action) {
      const filteredSharingUser = _.filter(
        state.sharingUsers,
        (user) => !_.includes(action.payload, user.id)
      );
      state.sharingUsers = filteredSharingUser;
    },
    resetUserAlbum(state) {
      state.album = userAlbumSliceInitialState.album;
      state.id = userAlbumSliceInitialState.id;
      state.albumPhotos = userAlbumSliceInitialState.albumPhotos;
      state.sharingUsers = userAlbumSliceInitialState.sharingUsers;
    },
    updateAlbumPhotoInStorage(state, action) {
      const newPhoto = action.payload;
      const photos = state.albumPhotos.data;

      if (!photos) {
        return;
      }

      if (!newPhoto.id) {
        return;
      }

      const photoIndex = _.findIndex(
        photos,
        (photo) => photo.id === newPhoto.id
      );
      const newPhotos = _.update(photos, photoIndex, () => newPhoto);

      state.albumPhotos.data = newPhotos;
    },
  },
});

export default userAlbumSlice.reducer;
export const {
  setUserAlbumId,
  resetUserAlbum,
  setUserAlbum,
  setUserAlbumPhotos,
  setSharingUsers,
  addSharingUsers,
  removeSharingUsers,
  updateAlbumPhotoInStorage,
  removePhotoFromAlbum,
  setUserAlbumPhotosPage,
  setUserAlbumPhotosPageSize,
  resetUserAlbumPhotos,
} = userAlbumSlice.actions;
