import React, { useEffect } from "react";
import { IPhotoType } from "../../../../store/slices/myPhotos";
import { IconButton, ImageListItem } from "@mui/material";
import { materialStyles, styles } from "./styles";
import { IImageListType } from "../AppImageList";
import CustomActiveCheckboxIcon from "../../../../assets/images/icons/custom-checkbox-active.svg";
import { FavoritesButton } from "../../../buttons/FavoritesButton";
import { useAppSelector } from "../../../../store";
import { useSocketMediaHandle } from "../../../../hooks/useSocketMediaHandle";
import { useLocalProccessSocketEmotions } from "../../../../hooks/useLocalProccessSocketEmotions";
import _ from "lodash";
import { IMaterialStyleItem } from "../../../../types";

type IImageListItem = {
  photo: IPhotoType;
  isSelected?: boolean;
  enableFavorites?: boolean;
  imageListType?: IImageListType;
  sx?: IMaterialStyleItem;
  onAddOrRemoveFromFavorites?: (photo: IPhotoType) => void;
  onImageClick?: (photo: IPhotoType) => void;
};

export const AppImageListItem: React.FC<IImageListItem> = ({
  photo,
  isSelected = false,
  enableFavorites = false,
  imageListType = "DEFAULT",
  sx,
  onAddOrRemoveFromFavorites,
  onImageClick,
}) => {
  const { isConnected } = useAppSelector((state) => state.socket);
  const { onSocketJoin, onSocketLeave } = useSocketMediaHandle(photo.id);
  const { onSendEmotion, localEmotions } = useLocalProccessSocketEmotions({
    id: photo.id,
    emotionsOwn: _.first(photo.emotionsOwn),
    emotions: photo.emotions,
  });

  useEffect(() => {
    if (isConnected) {
      onSocketJoin();
    }

    return () => {
      if (isConnected) {
        onSocketLeave();
      }
    };
  }, [isConnected, onSocketJoin, onSocketLeave]);

  return (
    <ImageListItem
      key={photo.id}
      sx={[
        materialStyles.imageListItem,
        sx ? sx : false,
        isSelected && materialStyles.selectedImageListItem,
      ]}
    >
      {isSelected ? (
        <IconButton sx={materialStyles.checkBoxButton}>
          <img src={CustomActiveCheckboxIcon} alt="CustomActiveCheckboxIcon" />
        </IconButton>
      ) : null}
      {enableFavorites &&
      (imageListType === "DEFAULT" || imageListType === "FAVORITES") ? (
        <FavoritesButton
          onAddOrRemoveFavoritePhoto={() =>
            onAddOrRemoveFromFavorites && onAddOrRemoveFromFavorites(photo)
          }
          isFavorite={photo.is_favorite || imageListType === "FAVORITES"}
          iconButtonId={
            photo.is_favorite || imageListType === "FAVORITES"
              ? undefined
              : "additionalButton"
          }
          iconButtonSx={
            photo.is_favorite || imageListType === "FAVORITES"
              ? materialStyles.activeFavoritesButton
              : materialStyles.favoritesButton
          }
          photoEmotions={localEmotions.emotions}
          emotionsOwn={localEmotions.emotionsOwn}
          onSendEmotion={onSendEmotion}
        />
      ) : null}
      <img
        onClick={() => onImageClick && onImageClick(photo)}
        style={styles.image}
        src={photo.preview}
        alt={photo.title}
      />
    </ImageListItem>
  );
};
