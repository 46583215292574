import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { materialStyles, styles } from "./styles";
import { IUserEditModalContentType, IUserEditModalType } from "..";
import { useTranslation } from "react-i18next";
import SuccessCircleIcon from "../../../../assets/images/icons/success-circle-icon.svg";

type ISeccessEditContent = {
  type: IUserEditModalContentType;
  onClose: () => void;
  onChangeModalType: (type: IUserEditModalType) => void;
};

export const SuccessUserEditModalContent: React.FC<ISeccessEditContent> = ({
  type,
  onClose,
  onChangeModalType,
}) => {
  const { t } = useTranslation();

  return (
    <Grid sx={materialStyles.innerModalContainer}>
      <Grid sx={materialStyles.confirmContainer}>
        <img
          style={styles.successIcon}
          src={SuccessCircleIcon}
          alt="SuccessCircleIcon"
        />
        <Typography sx={materialStyles.confirmModalHeaderTitle}>
          {
            {
              USERNAME: t("edit_user.success_username_edit"),
              EMAIL: t("edit_user.success_email_edit"),
              PASSWORD: t("edit_user.success_password_edit"),
            }[type]
          }
        </Typography>
        <Button sx={materialStyles.closeButton} onClick={onClose}>
          {t("common.close")}
        </Button>
      </Grid>
    </Grid>
  );
};
