import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  modalHeaderBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  modalHeaderTitle: {
    color: Colors.white,
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 700,
  },
  modalHeaderSubtitle: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[900],
    textAlign: "center",
  },
  modalInnerBlock: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: {
      xs: "19px 18px 19px",
      sm: "19px 18px 19px",
      md: "19px 18px 41px",
    },
  },
  modalForm: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  saveButton: {
    maxHeight: "50px",
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",
    marginTop: "24px",

    width: {
      xs: `auto`,
      sm: `auto`,
      md: "auto",
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
  searchUserInput: {
    border: `1px solid ${theme.palette.grey[100]} !important`,
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    color: Colors.white,

    marginTop: "24px",
    marginBottom: "14px",

    "& input": {
      color: Colors.white,
    },

    "& fieldset": {
      border: "none",
    },

    "& ::placeholder": {
      color: theme.palette.grey[200],
      opacity: 1,
    },
  },
  input: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: "6px",
    color: Colors.white,
    marginTop: "24px",

    width: {
      xs: `100%`,
      sm: `100%`,
      md: "420px",
    },

    "& .Mui-error": {
      border: "1px solid #FF505F",
    },

    "& .Mui-disabled": {
      color: `${theme.palette.grey[100]} !important`,
      "-webkit-text-fill-color": `${theme.palette.grey[100]} !important`,
    },

    "& input": {
      color: Colors.white,
    },

    "& fieldset": {
      border: "none",
    },

    "& ::placeholder": {
      color: Colors.white,
      opacity: 0.6,
    },

    "& .MuiFormLabel-root": {
      color: Colors.white,
      opacity: 0.6,
      backgroundColor: theme.palette.background.default,

      "&.Mui-error": {
        border: "none",
        color: theme.palette.error.main,
        opacity: 1,
      },
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: Colors.white,
      opacity: 0.6,

      "&.Mui-error": {
        border: "none",
        color: theme.palette.error.main,
        opacity: 1,
      },
    },
  },
  selectedLocalUser: {
    display: "flex",
    flexWrap: "wrap",
    gap: "6px",
    maxHeight: "144px",
    overflow: "auto",
  },
};
