import { createSlice } from "@reduxjs/toolkit";

export type ITags = {
  id: number;
  title: string;
};

interface ITagsSliceInitialState {
  tags: ITags[];
}

const tagsSliceInitialState: ITagsSliceInitialState = {
  tags: [],
};

const tagsSlice = createSlice({
  name: "tags",
  initialState: tagsSliceInitialState,
  reducers: {
    setTags(state, action) {
      state.tags = action.payload;
    },
    addNewTag(state, action) {
      state.tags = [action.payload, ...state.tags];
    },
    resetTags(state) {
      state.tags = tagsSliceInitialState.tags;
    },
  },
});

export default tagsSlice.reducer;
export const { setTags, resetTags, addNewTag } = tagsSlice.actions;
