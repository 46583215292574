import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  addLocationModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addLocationBox: {
    backgroundColor: theme.palette.background.default,
    border: "none",
    borderRadius: "6px",
    width: {
      xs: `78vw`,
      sm: `78vw`,
      md: "460px",
    },
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  addLocationModalInnerContainer: {
    padding: "20px 20px 30px 20px",
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  modalHeaderBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeaderTitle: {
    color: Colors.white,
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 700,
  },
  modalHeaderSubtitle: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[900],
    textAlign: "center",
  },
  searchInput: {
    border: `0px solid ${theme.palette.background.default} !important`,
    backgroundColor: `${theme.palette.grey[100]}`,
    borderRadius: "6px",
    color: Colors.white,

    marginTop: "14px",

    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },

    "& input": {
      color: Colors.white,
    },

    "& fieldset": {
      border: "none",
    },

    "& ::placeholder": {
      color: `${theme.palette.grey[200]}`,
      opacity: 1,
    },
  },
  searchIcon: {
    fill: `${theme.palette.grey[200]}`,
    marginRight: "12px",
  },
  saveButton: {
    maxHeight: "50px",
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",
    marginTop: "24px",

    width: {
      xs: `auto`,
      sm: `auto`,
      md: "auto",
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
  itemsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    marginTop: "14px",
    gap: "14px",
    minHeight: {
      xs: `auto`,
      sm: `auto`,
      md: "385px",
    },
    maxHeight: {
      xs: `auto`,
      sm: `auto`,
      md: "385px",
    },
  },
};
