import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";
import { withHexOpacity } from "../../../../utils";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  groupItem: {
    display: "flex",
    alignItems: "center",
    padding: "6px 14px 6px 14px",
    borderRadius: "20px",
    backgroundColor: withHexOpacity(Colors.white, 0.12),
    border: `1px solid ${withHexOpacity(Colors.white, 0.12)}`,
    flexDirection: "row",
    marginBottom: "10px",
    height: "38px",
    marginRight: "10px",
  },
  restrictedGroupItem: {
    backgroundColor: theme.palette.error.dark,
  },
  choosenGroupItem: {
    backgroundColor: theme.palette.primary.main,
  },
  choosenAllGroupItem: {
    backgroundColor: theme.palette.success.main,
  },
  groupItemTitle: {
    fontSize: "14px",
    lineHeight: "18px",
    color: Colors.white,
    whiteSpace: "pre",
  },
  disabledGroupItem: {
    opacity: 0.5,
  },
};
