import { Divider, Grid, List, ListItemButton, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { ISettingsContentTypes } from "../../../pages/UserSettings";
import { useSettingsItems } from "../../../hooks/useSettingsItems";
import _ from "lodash";
import { materialStyles } from "./styles";

type ISettingsList = {
  type: ISettingsContentTypes;
  onChangeSettingsType: (type: ISettingsContentTypes) => void;
};

export const SettingsList: React.FC<ISettingsList> = ({
  onChangeSettingsType,
  type,
}) => {
  const { items } = useSettingsItems();

  return (
    <Grid sx={materialStyles.settingsList}>
      <List sx={materialStyles.settingsListContainer}>
        {_.map(items, (item, index) => (
          <Fragment key={item.type}>
            <ListItemButton
              onClick={() => onChangeSettingsType(item.type)}
              sx={[
                materialStyles.settingsListButton,
                item.type === type && materialStyles.settingListActiveButton,
              ]}
            >
              <Typography
                sx={[
                  materialStyles.settingsListTitle,
                  item.type === type && materialStyles.settingsListActiveTitle,
                ]}
              >
                {item.title}
              </Typography>
            </ListItemButton>
            {index !== items.length - 1 ? (
              <Divider sx={materialStyles.listDivider} />
            ) : null}
          </Fragment>
        ))}
      </List>
    </Grid>
  );
};
