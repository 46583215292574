import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles, styles } from "./styles";
import { FullSizeModalHeader } from "../../../header/FullSizeModalHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { INewAlbumModalType } from "..";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useFormik } from "formik";
import {
  setAlbumDescription,
  setAlbumTitle,
} from "../../../../store/slices/newAlbum";
import { useToast } from "rc-toastr";

type INewAlbumModalForm = {
  onClose: () => void;
  onChangeNewAlbumModalType: (type: INewAlbumModalType) => void;
};

type INewAlbumFormValues = {
  title: string;
  description: string;
};

export const NewAlbumMainForm: React.FC<INewAlbumModalForm> = ({
  onClose,
  onChangeNewAlbumModalType,
}) => {
  const { title, description } = useAppSelector((state) => state.newAlbum);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { toast } = useToast();

  const goToSelected = useCallback(
    (values: INewAlbumFormValues) => {
      if (!values.title) {
        toast.error(t("new_album.title_is_required"));
        return;
      }
      dispatch(setAlbumTitle(values.title));
      dispatch(setAlbumDescription(values.description));
      onChangeNewAlbumModalType("SELECT_ALBUM_PHOTO");
    },
    [dispatch, onChangeNewAlbumModalType, t, toast]
  );

  const { values, handleChange, handleSubmit } = useFormik({
    onSubmit: goToSelected,
    initialValues: {
      title: title || "",
      description: description || "",
    },
  });

  return (
    <Grid sx={materialStyles.innerModalForm}>
      <FullSizeModalHeader
        onIconClick={onClose}
        Icon={<ArrowBackIcon sx={materialStyles.headerIcon} />}
      >
        <Grid sx={materialStyles.headerChildrenBlock}>
          <IconButton onClick={() => goToSelected(values)}>
            <AddPhotoAlternateOutlinedIcon sx={materialStyles.headerIcon} />
          </IconButton>
          <IconButton onClick={onClose}>
            <DeleteOutlineOutlinedIcon sx={materialStyles.headerIcon} />
          </IconButton>
        </Grid>
      </FullSizeModalHeader>
      <form style={styles.newAlbumForm} onSubmit={handleSubmit}>
        <Grid sx={materialStyles.titleAndDescriptionBlock}>
          <TextField
            sx={materialStyles.albumTitleInput}
            fullWidth
            name="title"
            value={values.title}
            inputProps={{ maxLength: 100 }}
            onChange={handleChange}
            variant="filled"
            placeholder={t("new_album.add_a_title")}
          />
          <TextField
            fullWidth
            placeholder={t("new_album.add_a_description")}
            multiline
            onChange={handleChange}
            value={values.description}
            name="description"
            sx={materialStyles.albumDescriptionInput}
            minRows={3}
            maxRows={10}
          />
        </Grid>
        <Grid sx={[materialStyles.mainBlock, materialStyles.emptyMainBlock]}>
          <Typography sx={materialStyles.mainBlockEmptyTitle}>
            {t("new_album.add_photos")}
          </Typography>
          <Button
            type="submit"
            sx={materialStyles.selectPhotoButton}
            startIcon={<AddIcon />}
          >
            {t("new_album.select_photos")}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};
