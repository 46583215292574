import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  commentItemContainer: {
    width: "100%",
    display: "flex",
  },
  commentContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginLeft: "18px",
  },
  commentText: {
    color: Colors.white,
    lineBreak: "anywhere",

    "#username": {
      fontWeight: "500",
      marginRight: "5px",
    },
  },
  additionalCommentContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
    marginTop: "8px",
  },
  additionalText: {
    color: theme.palette.grey["900"],
    fontSize: "12px",
    lineHeight: "16px",
  },
};
