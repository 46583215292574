import React from "react";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Grid, Divider, Typography } from "@mui/material";

type IOrBlock = {};

export const OrBlock: React.FC<IOrBlock> = () => {
  const { t } = useTranslation();

  return (
    <Grid sx={materialStyles.orBlock}>
      <Divider sx={materialStyles.orDivider} />
      <Typography sx={materialStyles.orText}>{t("common.or")}</Typography>
      <Divider sx={materialStyles.orDivider} />
    </Grid>
  );
};
