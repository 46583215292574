import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getUserAlbumsById } from "../store/thunks/user";
import {
  resetProfileUserAlbums,
  setProfileUserAlbums,
  setProfileUserAlbumsPage,
  setProfileUserAlbumsPageSize,
} from "../store/slices/userProfile";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../constants/api";

type TFetchUserAlbumsById = {
  isEnable?: boolean;
  id: number | null;
};

export const useFetchUserAlbumsById = ({
  isEnable,
  id,
}: TFetchUserAlbumsById) => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { userAlbums } = useAppSelector((state) => state.userProfile);

  const fetchUserAlbums = useCallback(
    async (page?: number, pageSize?: number) => {
      if (!isAuth) {
        return;
      }

      if (!id) {
        return;
      }

      try {
        const response = await dispatch(
          getUserAlbumsById({ id, page, pageSize })
        );
        if (!response) {
          throw new Error();
        }
        dispatch(setProfileUserAlbums(response));
      } catch (error: any) {
        console.error("Error while [fetchUserAlbums]", error);
      }
    },
    [dispatch, id, isAuth]
  );

  const refetchUserAlbums = useCallback(() => {
    fetchUserAlbums(userAlbums.page);
  }, [fetchUserAlbums, userAlbums.page]);

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setProfileUserAlbumsPage(userAlbums.page + 1));
    dispatch(setProfileUserAlbumsPageSize(DEFAULT_PAGE_SIZE));
  }, [userAlbums.page, dispatch]);

  useEffect(() => {
    if (isEnable) {
      fetchUserAlbums();
    }

    return () => {
      dispatch(resetProfileUserAlbums());
    };
  }, [dispatch, fetchUserAlbums, isEnable]);

  useEffect(() => {
    if (userAlbums.page !== DEFAULT_PAGE) {
      fetchUserAlbums(userAlbums.page, userAlbums.pageSize);
    }
  }, [userAlbums.page, userAlbums.pageSize, fetchUserAlbums]);

  return {
    userAlbums: userAlbums.data,
    refetchUserAlbums,
    onUpdatePageOrPageSize,
  };
};
