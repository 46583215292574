import { Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type IAuthModalHeader = {
  title: string;
};

export const AuthModalHeader: React.FC<IAuthModalHeader> = ({ title }) => (
  <Typography sx={materialStyles.modalTitle}>{title}</Typography>
);
