import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../store";
import { getUserByUsername } from "../store/thunks/globe";
import { IUserType } from "../store/slices/user";

export const useFetchUserByUsername = (username?: string) => {
  const dispatch = useAppDispatch();

  const [user, setUser] = useState<IUserType | null>(null);

  const fetchUserByUserName = useCallback(
    async (memoUsername: string) => {
      try {
        const data = await dispatch(getUserByUsername(memoUsername));

        if (!data) {
          throw new Error();
        }

        setUser(data);
      } catch (error) {
        console.error("Error while [fetchUserByUserName]", error);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!username) {
      return;
    }

    fetchUserByUserName(username);
  }, [fetchUserByUserName, username]);

  return {
    user,
  };
};
