import { useCallback, useEffect, useMemo, useState } from "react";
import { parseTimerTime } from "../utils";

//initialValue in seconds 10 min = 600 sec
export const useTimer = (initialValue: number) => {
  const [time, setTime] = useState(initialValue);

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [time]);

  const isExpired = useMemo(() => time === 0, [time]);

  const resetTimer = useCallback(() => {
    setTime(initialValue);
  }, [initialValue]);

  return { time: parseTimerTime(time), isExpired, resetTimer };
};
