import { Grid, Button, TextField, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { materialStyles } from "./styles";
import { AuthModalHeader } from "./AuthModalHeader";
import { useTranslation } from "react-i18next";
import { IAuthModalType } from "..";
import { OrBlock } from "./OrBlock";
import { useFormik } from "formik";
import _ from "lodash";
import { useToast } from "rc-toastr";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  setEmail,
  setPhone,
  setPhoneParams,
} from "../../../../store/slices/registration";
import { passwordReset } from "../../../../store/thunks/user";
import { getMaskLength, plusOnStart } from "../../../../utils/validate";
import { PhoneCodesModal } from "./PhoneCodesModal";
import { ICountriesType } from "../../../../constants/Countries";

type IForgotPasswordForm = {
  onChangeAuthModalType: (type: IAuthModalType) => void;
  onClose: () => void;
};

type IFormValues = {
  email: string;
  phone: string;
};

export const ForgotPasswordForm: React.FC<IForgotPasswordForm> = ({
  onChangeAuthModalType,
  onClose,
}) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const { regUser } = useAppSelector((state) => state.regUser);

  const onSubmit = useCallback(
    async (details: IFormValues) => {
      const isCustomPhone = plusOnStart(details.phone);

      const parsedPhone = isCustomPhone
        ? details.phone
        : `${regUser.phoneParams.code}${details.phone}`;

      try {
        switch (true) {
          case Boolean(details.email && details.phone):
            toast.error(t("errors.enter_one_thing_either_mail_or_phone"));
            return;
          case Boolean(details.email):
            dispatch(setEmail(details.email));
            await dispatch(passwordReset());
            onChangeAuthModalType("FORGOT_PASSWORD_CODE_EMAIL");
            return;
          case Boolean(details.phone):
            dispatch(setPhone(parsedPhone));
            await dispatch(passwordReset());
            onChangeAuthModalType("FORGOT_PASSWORD_CODE_PHONE");
            return;
        }
      } catch (error) {
        console.error("Error while [onSubmit]", error);
      }
    },
    [dispatch, onChangeAuthModalType, regUser.phoneParams.code, t, toast],
  );

  const { values, handleChange, handleSubmit, isValid, errors } = useFormik({
    onSubmit,
    initialValues: {
      email: "",
      phone: "",
    },
    validate: (values) => {
      return _.pickBy({
        email: _.isEmpty(values.phone) && !_.trim(values.email),
        phone: _.isEmpty(values.email) && !_.trim(values.phone),
      });
    },
  });

  const isShowPhoneCodes = useMemo(
    () => !plusOnStart(values.phone),
    [values.phone],
  );

  const phoneMaxLength = useMemo(
    () =>
      isShowPhoneCodes
        ? getMaskLength(regUser.phoneParams.maskPlaceholder)
        : undefined,
    [isShowPhoneCodes, regUser.phoneParams.maskPlaceholder],
  );

  const onChoosePhoneParams = useCallback(
    (item: ICountriesType) => {
      dispatch(setPhoneParams(item));
    },
    [dispatch],
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid sx={materialStyles.innerAuthModalBox}>
        <AuthModalHeader title={t("auth_modal.forgot_password")} />
        <Typography sx={materialStyles.modalSubtitle}>
          {t("auth_modal.set_email_or_password")}
        </Typography>
        <Grid sx={materialStyles.formContainer}>
          <TextField
            error={Boolean(errors.email)}
            name="email"
            sx={materialStyles.authInput}
            value={values.email}
            onChange={handleChange}
            fullWidth
            label={t("auth_modal.email")}
            placeholder={t("auth_modal.enter_email")}
          />
          <OrBlock />
          <TextField
            error={Boolean(errors.phone)}
            name="phone"
            type="number"
            sx={[materialStyles.authInput, materialStyles.marginTop0]}
            value={values.phone}
            onChange={handleChange}
            fullWidth
            label={t("auth_modal.phone")}
            placeholder={regUser.phoneParams.maskPlaceholder}
            inputProps={{ maxLength: phoneMaxLength }}
            InputProps={{
              startAdornment: isShowPhoneCodes ? (
                <PhoneCodesModal
                  onClickItem={onChoosePhoneParams}
                  phoneParams={regUser.phoneParams}
                />
              ) : null,
            }}
          />
          <Button
            type="submit"
            disabled={!isValid}
            sx={materialStyles.loginButton}
            variant="contained"
          >
            {t("common.next")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
