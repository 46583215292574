import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {
  dontHaveText: {
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.grey[400],
  },
  signUpText: {
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  phoneInput: {
    marginTop: 0,
  },
  signUpButton: {
    marginBottom: "50px",
  },
  mailIcon: {
    width: "152px",
    height: "152px",
  },
  forgotButton: {
    color: theme.palette.primary.main,
    textAlign: "right",
    fontSize: "12px",
    lineHeight: "16px",
    marginRight: "10px",
    cursor: "pointer",
    marginTop: "2px",
    width: "fit-content",
  },
  closeButton: {
    color: theme.palette.primary.main,
    textAlign: "center",
    fontSize: "14px",
    lineHeight: "20px",
    marginRight: "10px",
    cursor: "pointer",
    marginTop: "24px",
  },
  countryFlag: {
    width: "24px",
    height: "24px",
  },
};

export const materialStyles: IMaterialStyleType = {
  authModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  authModalBox: {
    backgroundColor: theme.palette.background.default,
    border: "none",
    borderRadius: "6px",
    width: {
      xs: `auto`,
      sm: `auto`,
      md: "544px",
    },
  },
  innerAuthModalBox: {
    width: {
      xs: `98vw`,
      sm: `98vw`,
      md: "544px",
    },
    padding: {
      xs: "20px 20px",
      sm: "14px 16px",
      md: "50px 62px",
    },
    flex: 1,
  },
  modalTitle: {
    color: Colors.white,
    whiteSpace: "pre",
    fontSize: {
      xs: "30px",
      sm: "30px",
      md: "44px",
    },
    fontWeight: "700",
    lineHeight: {
      xs: "52px",
      sm: "52px",
      md: "74px",
    },
  },
  modalSubtitle: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.grey[400],
    whiteSpace: "pre",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  marginTop0: {
    marginTop: 0,
  },
  authInput: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: "6px",
    color: Colors.white,
    marginTop: "24px",

    width: {
      xs: `100%`,
      sm: `100%`,
      md: "420px",
    },

    "& .Mui-error": {
      border: "1px solid #FF505F",
    },

    "& input": {
      color: Colors.white,
    },

    "& fieldset": {
      border: "none",
    },

    "& ::placeholder": {
      color: Colors.white,
      opacity: 0.6,
    },

    "& .MuiFormLabel-root": {
      color: Colors.white,
      opacity: 0.6,
      backgroundColor: theme.palette.background.default,

      "&.Mui-error": {
        border: "none",
        color: theme.palette.error.main,
        opacity: 1,
      },
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: Colors.white,
      opacity: 0.6,

      "&.Mui-error": {
        border: "none",
        color: theme.palette.error.main,
        opacity: 1,
      },
    },
  },
  eyeIcon: {
    width: "24px",
    height: "24px",
    fill: Colors.white,
    opacity: 0.6,
  },
  loginButton: {
    height: "48px",
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",
    marginTop: "24px",

    width: {
      xs: `auto`,
      sm: `auto`,
      md: "420px",
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
  checkBoxGroup: {
    marginTop: "24px",
  },
  checkBox: {
    color: `${theme.palette.grey[400]}`,
    fontSize: "14px !important",
    lineHeight: "20px",
    letterSpacing: 0,

    "& span": {
      fontSize: "14px !important",
      lineHeight: "20px",
      color: theme.palette.grey[400],
    },

    "&.Mui-checked": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  orBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "14px",
    marginBottom: "14px",
  },
  orDivider: {
    flex: 1,
    borderColor: theme.palette.grey[100],
    marginLeft: "10px",
    marginRight: "10px",
  },
  orText: {
    textTransform: "uppercase",
    color: theme.palette.grey[100],
    fontSize: "12px",
  },
  socialBlock: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    marginBottom: "24px",
  },
  socialButton: {
    height: "53px",
    textTransform: "none",
    flex: 1,
    color: theme.palette.grey[400],
    fontSize: "12px",
    letterSpacing: 0,
    border: `1px solid ${theme.palette.grey[100]} !important`,

    "& p": {
      display: {
        xs: `none`,
        sm: `none`,
        md: "flex",
      },
    },

    "& .MuiButton-startIcon": {
      marginLeft: {
        xs: 0,
        sm: 0,
        md: "-4px",
      },
      marginRight: {
        xs: 0,
        sm: 0,
        md: "8px",
      },
    },
  },
  dontHaveBlock: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
  inputIcon: {
    color: Colors.white,
    opacity: 0.6,
    marginRight: "12px",
  },
  mailIconContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  codeVerifyTitle: {
    fontSize: "28px",
    lineHeight: "34px",
    color: theme.palette.grey[500],
  },
  codeVerifySubtitle: {
    fontSize: "16px",
    lineHeight: "20px",
    color: theme.palette.grey[500],
    marginTop: "36px",
  },
  codeValue: {
    fontSize: "16px",
    lineHeight: "20px",
    color: theme.palette.grey[500],
    marginTop: "36px",
  },
  codeValidTimer: {
    fontSize: "12px",
    lineHeight: "20px",
    color: theme.palette.grey[500],
    marginTop: "12px",
    textAlign: "center",
  },
  resendButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "6px",
  },
  resendButton: {
    textTransform: "none",
    letterSpacing: 0,
  },
  verificationCodeLabel: {
    fontSize: "12px",
    lineHeight: "14px",
    color: theme.palette.grey[500],
    marginTop: "36px",
  },
  forgotButtonContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  tabList: {
    marginTop: "24px",
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.grey[700],
      height: "1px",
    },
  },
  tabContainer: {
    color: theme.palette.grey[900],
    textTransform: "none",
    fontSize: "15px",
    lineHeight: "22px",
    flex: 1,
    fontWeight: "400",
    textAlign: "left",
    alignItems: "flex-start",
    borderBottom: `1px solid ${theme.palette.grey[900]}`,
    height: "10px",
    justifyContent: "flex-start",

    "&.Mui-selected": {
      color: theme.palette.grey[700],
    },
  },
  tabPanelContainer: {
    padding: 0,
  },
  popoverContainer: {
    backgroundColor: theme.palette.grey[50],
    width: "230px",
    height: "260px",
  },
  countriesItems: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  countriesItem: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "10px",
    flex: 1,
  },
  countriesItemTitle: {
    color: Colors.white,
    textAlign: "left",
    marginLeft: "8px",

    "& #phone-code": {
      color: theme.palette.grey[900],
      marginLeft: "4px",
    },
  },
  phoneCode: {
    fontSize: "16px",
    lineHeight: "22px",
    color: theme.palette.grey[700],
  },
  setNicknameModalTitle: {
    color: Colors.white,
    fontSize: "30px",
    lineHeight: "36px",
    fontWeight: "700",
  },
};
