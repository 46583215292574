import { AppDispatch } from "..";
import axios from "axios";
import { API_URL } from "../api";
import { setError } from "../slices/errors";
import { getResponseFromAxiosError } from "../../utils";
import { setServerState } from "../slices/system";

export const getSystemState = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axios.get(`${API_URL}/system/status`);

      if (!data) {
        throw new Error();
      }

      dispatch(setServerState(data));
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getSystemState]", error);
    }
  };
};
