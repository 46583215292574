import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";
import { withHexOpacity } from "../../../utils";

export const styles: ICssStyleType = {
  newTagInput: {
    background: "none",
    border: "none",
    outline: "none",
    color: Colors.white,
    width: "85px",
    fontSize: "14px",
    lineHeight: "18px",
  },
};

export const materialStyles: IMaterialStyleType = {
  tagsListContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  newTagItem: {
    display: "flex",
    padding: "6px",
    borderRadius: "20px",
    backgroundColor: withHexOpacity(Colors.white, 0.12),
    border: `1px solid ${withHexOpacity(Colors.white, 0.12)}`,
    flexDirection: "row",
    alignItems: "center",
    gap: "6px",
    marginBottom: "10px",
    height: "38px",
    marginRight: "10px",
  },
  tagIcon: {
    color: theme.palette.grey["A200"],
  },
  doneIcon: {
    color: Colors.white,
  },
  tagsListTitle: {
    mb: 1.5,
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[700],
  },
};
