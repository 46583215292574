import { Grid, Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type IBlockWithTitle = {
  title: string;
  children?: React.ReactNode;
};

export const BlockWithTitle: React.FC<IBlockWithTitle> = ({
  title,
  children,
}) => {
  return (
    <Grid sx={materialStyles.block}>
      <Grid sx={materialStyles.titleBlock}>
        <Typography sx={materialStyles.title}>{title}</Typography>
      </Grid>
      {children}
    </Grid>
  );
};
