import React, { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { FiltersWrapper } from "./FiltersWrapper";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { materialStyles } from "./styles";
import { TagsList } from "../../lists/tagsList";
import {
  resetPhotoFilters,
  setPhotosFilters,
} from "../../../store/slices/myPhotos";
import { useFilters } from "../../../hooks/useFilters";
import { useSearchTags } from "../../../hooks/useSearchTags";
import _ from "lodash";
import { AutocompleteOptionWithTitle } from "../../items/AutocompleteOptionWithTitle";
import { AppAutocomplete } from "../../inputs/AppAutocomplete";
import { FiltersAutocompleteInput } from "../../inputs/FiltersAutocompleteInput";
import { DISPLAY_USERS_FROM_LENGTH } from "../../../hooks/useSearchPeoples";

type IPhotosFiltersForm = {
  onClose: () => void;
  isOpen: boolean;
};

export const PhotosFiltersForm: React.FC<IPhotosFiltersForm> = ({
  onClose,
  isOpen,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { filters } = useAppSelector((state) => state.myPhotos);

  const { localFilters, onChangeFilters } = useFilters(filters);

  const [tagsSearch, setTagsSearch] = useState("");

  const {
    findedTags,
    loading: loadingSearchTags,
    debouncedSearch: debouncedTagsSearch,
  } = useSearchTags(tagsSearch);

  const onSubmit = useCallback(() => {
    if (!localFilters) {
      return;
    }

    dispatch(setPhotosFilters(localFilters));
    onClose();
  }, [dispatch, localFilters, onClose]);

  const onTagSearchChange = useCallback((event: any) => {
    const { value } = event.target;

    setTagsSearch(value);
  }, []);

  const onResetFilters = useCallback(() => {
    dispatch(resetPhotoFilters());
  }, [dispatch]);

  return (
    <FiltersWrapper
      onResetFilters={onResetFilters}
      disableReset={_.isEmpty(filters.tag)}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Grid sx={materialStyles.filterBlock}>
        <Typography sx={materialStyles.filterTitle}>
          {t("filters.photos.tags")}
        </Typography>
        <AppAutocomplete
          getOptionLabel={(option) => option.title}
          noOptionsText={
            debouncedTagsSearch.length >= DISPLAY_USERS_FROM_LENGTH
              ? t("filters.unable_find_tag")
              : t("filters.to_search_enter_more_than")
          }
          options={findedTags}
          loading={loadingSearchTags}
          sx={materialStyles.filtersAutocomplete}
          renderInput={(params) => (
            <FiltersAutocompleteInput
              {...params}
              placeholder={t("filters.enter_tag_name")}
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
              }}
              onChange={onTagSearchChange}
            />
          )}
          renderOption={(props, option) => (
            <AutocompleteOptionWithTitle
              option={option}
              onOptionClick={() => onChangeFilters(option, "tag")}
              {...props}
            />
          )}
        />
        <TagsList
          disabledCreate
          disableRipple
          tags={localFilters.tag ? [localFilters.tag] : []}
          onRemoveTag={(tag) => onChangeFilters(tag, "tag")}
        />
      </Grid>
    </FiltersWrapper>
  );
};
