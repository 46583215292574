import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { setSupportConversationMessages } from "../store/slices/supportRequestConversation";
import { ISupportRequest } from "../store/slices/supportRequests";
import { getSupportRequestMessages } from "../store/thunks/support";

export const useFetchSupportRequestMessages = () => {
  const { supportRequestId, messages } = useAppSelector(
    (state) => state.supportRequestConversation,
  );
  const dispatch = useAppDispatch();

  const fetchSupportRequestMessages = useCallback(
    async (requestId: ISupportRequest["id"]) => {
      try {
        const messages = await dispatch(getSupportRequestMessages(requestId));

        if (!messages) {
          throw new Error();
        }

        dispatch(setSupportConversationMessages(messages));
      } catch (error) {
        console.error("Error while [fetchSupportRequestMessages]", error);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (supportRequestId) {
      fetchSupportRequestMessages(supportRequestId);
    }
  }, [fetchSupportRequestMessages, supportRequestId]);

  return { messages };
};
