import React, {
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { IPhotoType } from "../../../store/slices/myPhotos";
import { ImageList, Grid } from "@mui/material";
import _ from "lodash";
import { materialStyles } from "./styles";
import moment from "moment";
import { useAppDispatch } from "../../../store";
import { IMaterialStyleItem } from "../../../types";
import {
  addPhotoToFavorites,
  removePhotoFromFavorites,
} from "../../../store/slices/favorites";
import {
  addFavoritesPhoto,
  removeFavoritesPhoto,
} from "../../../store/thunks/favorites";
import { useImageListSelectionByDate } from "../../../hooks/useImageListSelectionByDate";
import { AppImageListItem } from "./components/AppImageListItem";
import { AppImageListDateHeader } from "./components/AppImageListDateHeader";
import { ImageSlidesModal } from "../../modals/imageSlidesModal";
import { initMediaSlider } from "../../../store/slices/slider";

export type IImageListType = "TRASH" | "FAVORITES" | "DEFAULT" | "PUBLIC";

type IImageList = {
  photos?: IPhotoType[];
  enableSelection?: boolean;
  enableOpenPhoto?: boolean;
  enableFavorites?: boolean;
  onSelected?: (ids: number[]) => void;
  containerStyle?: IMaterialStyleItem;
  imageItemStyle?: IMaterialStyleItem;
  type?: IImageListType;
  onUpdatePhotoToFavorites?: (photo: IPhotoType) => void;
  onClosePhotoModal?: () => void;
};

export interface TAppImageListForwardRef {
  clearAllSelections: () => void;
}

export const AppImageList = forwardRef<
  TAppImageListForwardRef,
  React.PropsWithChildren<IImageList>
>(
  (
    {
      photos,
      enableSelection = false,
      enableOpenPhoto = false,
      enableFavorites = true,
      onSelected,
      containerStyle,
      imageItemStyle,
      type = "DEFAULT",
      onUpdatePhotoToFavorites,
      onClosePhotoModal,
    },
    ref,
  ) => {
    const [isOpenMediaSlides, setIsOpenMediaSlides] = useState(false);

    const dispatch = useAppDispatch();

    const sortedImageByDate = useMemo(
      () =>
        _.chain(photos)
          .sortBy((photo) => moment(photo.created_at).toDate())
          .reverse()
          .value(),
      [photos],
    );

    const groupedImageData = useMemo(() => {
      const groupedImage = _.reduce(
        sortedImageByDate,
        (memo, photo) => {
          const dateKey = moment(photo.created_at).format("YYYY-MM-DD");
          if (!memo[dateKey]) {
            memo[dateKey] = [];
          }

          memo[dateKey].push(photo);

          return memo;
        },
        {} as Record<string, IPhotoType[]>,
      );

      return groupedImage;
    }, [sortedImageByDate]);

    const {
      onSelectedImage,
      onSelectedAllImageInDate,
      getIsSelectedDate,
      selectedImages,
      clearAllSelections,
    } = useImageListSelectionByDate(
      groupedImageData,
      enableSelection,
      onSelected,
    );

    const onCloseImageSlides = useCallback(() => {
      setIsOpenMediaSlides(false);
      onClosePhotoModal && onClosePhotoModal();
    }, [onClosePhotoModal]);

    useImperativeHandle(ref, () => ({
      clearAllSelections,
    }));

    const onImageClick = useCallback(
      (photo: IPhotoType) => {
        switch (true) {
          case enableSelection:
            onSelectedImage(photo.id);
            break;
          case enableOpenPhoto:
            dispatch(initMediaSlider({ initialMedia: photo, media: photos }));
            setIsOpenMediaSlides(true);
            break;
        }
      },
      [dispatch, enableOpenPhoto, enableSelection, onSelectedImage, photos],
    );

    const onAddOrRemoveFromFavorites = useCallback(
      async (photo: IPhotoType) => {
        try {
          if (photo.is_favorite || type === "FAVORITES") {
            const memoPhoto = { ...photo, is_favorite: false };
            await dispatch(removeFavoritesPhoto(photo.id));
            dispatch(removePhotoFromFavorites(photo.id));
            onUpdatePhotoToFavorites && onUpdatePhotoToFavorites(memoPhoto);
            return;
          }

          const memoPhoto = { ...photo, is_favorite: true };
          await dispatch(addFavoritesPhoto(photo.id));
          dispatch(addPhotoToFavorites(photo.id));
          onUpdatePhotoToFavorites && onUpdatePhotoToFavorites(memoPhoto);
        } catch (error) {
          console.error("Error while [onAddOrRemoveFromFavorites]", error);
        }
      },
      [dispatch, onUpdatePhotoToFavorites, type],
    );

    return (
      <>
        <Grid sx={containerStyle}>
          {_.map(_.keys(groupedImageData), (date) => (
            <Fragment key={date}>
              <AppImageListDateHeader
                date={date}
                enableSelection={enableSelection}
                onSelectedAllImageInDate={onSelectedAllImageInDate}
                isSelected={getIsSelectedDate(date)}
              />
              <ImageList rowHeight={250} sx={materialStyles.imageList}>
                {_.map(groupedImageData[date], (photo) => (
                  <AppImageListItem
                    key={photo.id}
                    isSelected={_.includes(selectedImages, photo.id)}
                    photo={photo}
                    enableFavorites={enableFavorites}
                    imageListType={type}
                    onAddOrRemoveFromFavorites={onAddOrRemoveFromFavorites}
                    onImageClick={onImageClick}
                    sx={imageItemStyle}
                  />
                ))}
              </ImageList>
            </Fragment>
          ))}
        </Grid>
        <ImageSlidesModal
          isOpen={isOpenMediaSlides}
          onClose={onCloseImageSlides}
        />
      </>
    );
  },
);
