import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  page: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
    },
    gap: "50px",
  },
};
