import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { PrivacyGroup } from "./groups";

interface ISharingSliceInitialState {
  actionId: number | null;
  sharingGroups: PrivacyGroup[];
}

const sharingSliceInitialState: ISharingSliceInitialState = {
  actionId: null,
  sharingGroups: [],
};

const sharingSlice = createSlice({
  name: "sharing",
  initialState: sharingSliceInitialState,
  reducers: {
    setShareActionId(state, action) {
      state.actionId = action.payload;
    },
    setSharingGroups(state, action) {
      state.sharingGroups = action.payload;
    },
    removeSharingUser(state, action) {
      const filteredSharingGroups = _.filter(
        state.sharingGroups,
        (user) => user.id !== action.payload,
      );
      state.sharingGroups = filteredSharingGroups;
    },
    resetSharing(state) {
      state.actionId = sharingSliceInitialState.actionId;
      state.sharingGroups = sharingSliceInitialState.sharingGroups;
    },
  },
});

export default sharingSlice.reducer;
export const {
  setShareActionId,
  setSharingGroups,
  resetSharing,
  removeSharingUser,
} = sharingSlice.actions;
