import React from "react";
import { IUserType } from "../../store/slices/user";
import { Grid, IconButton, Typography } from "@mui/material";
import { UserAvatar } from "../user/UserAvatar";
import CloseIcon from "@mui/icons-material/Close";
import { materialStyles } from "./styles";

type IUserWithRemoveItem = {
  user: IUserType;
  onRemoveClick?: (userId: IUserType["id"]) => void;
};

export const UserWithRemoveItem: React.FC<IUserWithRemoveItem> = ({
  user,
  onRemoveClick,
}) => {
  return (
    <Grid sx={materialStyles.userWithRemoveItem}>
      <UserAvatar type="PHOTO_PAGE" user={user} />
      <Typography sx={materialStyles.itemUsername}>{user.username}</Typography>
      {onRemoveClick ? (
        <IconButton
          onClick={() => onRemoveClick(user.id)}
          sx={materialStyles.removeIconContainer}
        >
          <CloseIcon sx={materialStyles.removeIcon} />
        </IconButton>
      ) : null}
    </Grid>
  );
};
