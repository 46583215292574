import React, { useCallback, useMemo, useState } from "react";
import { IUserType } from "../../../store/slices/user";
import { Button, Divider, Grid, Popover, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { UserAvatar } from "../../user/UserAvatar";
import { useTranslation } from "react-i18next";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { useNavigate } from "react-router-dom";
import { ChangeVisualModeButtons } from "./ChangeVisualModeButtons";
import { useLogout } from "../../../hooks/useLogout";

type IUserHeaderBlock = {
  user: IUserType;
};

export const UserHeaderBlock: React.FC<IUserHeaderBlock> = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const { resetStorage } = useLogout();

  const { t } = useTranslation();

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const onSignIn = useCallback(() => {
    resetStorage();
    setAnchorEl(null);
    navigate("/");
  }, [navigate, resetStorage]);

  const onGoToUserSettings = useCallback(() => {
    navigate("/settings");
  }, [navigate]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Button
        onClick={handleClick}
        sx={materialStyles.userAvatarButton}
        endIcon={<UserAvatar type="DEFAULT" user={user} />}
      >
        <p id="userName">{user.username || "UserName"}</p>
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            sx: materialStyles.popoverContainer,
          },
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid>
          <Grid sx={materialStyles.userInfoBlock}>
            <UserAvatar type="HEADER" user={user} />
            <Grid sx={materialStyles.userNameAndEmailBlock}>
              <Typography sx={materialStyles.userName}>
                {user.username || "UserName"}
              </Typography>
              <Typography sx={materialStyles.userEmail}>
                {user.email}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={materialStyles.popoverDivider} />
          <Grid sx={materialStyles.toggleButtonsInHeaderPopover}>
            <ChangeVisualModeButtons />
          </Grid>
          <Grid display="flex" flexDirection="column" alignItems="flex-start">
            <Button
              onClick={onGoToUserSettings}
              startIcon={<SettingsOutlinedIcon />}
              sx={materialStyles.popoverButton}
            >
              {t("header.account_settings")}
            </Button>
            <Button
              onClick={onSignIn}
              startIcon={<ExitToAppOutlinedIcon />}
              sx={materialStyles.popoverButton}
            >
              {t("header.sign_out")}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};
