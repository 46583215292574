import { createTheme } from "@mui/material";

export const HEADER_HEIGHT = 76;
export const SIDE_MENU_WIDTH = 255;

// xs: Экраны с шириной менее 600 пикселей.
// sm: Экраны с шириной от 600 пикселей и более.
// md: Экраны с шириной от 960 пикселей и более.
// lg: Экраны с шириной от 1280 пикселей и более.
// xl: Экраны с шириной от 1920 пикселей и более.

//Additional colors
export const Colors = {
  white: "#FFFFFF",
  black: "#000000",
};

export const theme = createTheme({
  palette: {
    primary: {
      dark: "#162838",
      main: "#0D99FF",
      contrastText: Colors.white,
      light: "#ADCBFA",
    },
    error: {
      main: "#FF505F",
    },
    background: {
      default: "#242424",
    },
    grey: {
      50: "#3F3F3F",
      100: "#3B3C3D",
      200: "#9CA3AF",
      300: "#E5E7EB",
      400: "#A9A9A9",
      500: "#D8D8D8",
      600: "#333333",
      700: "#BDBDBD",
      800: "#3F3F3F",
      900: "#828282",
      A100: "#E0E0E0",
      A200: "#A7A7A7",
      A400: "#363636",
      A700: "#4F4F4F",
    },
  },
});
