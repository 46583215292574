import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getAlbums } from "../store/thunks/albums";
import {
  resetAlbums,
  setAlbums,
  setAlbumsPage,
  setAlbumsPageSize,
} from "../store/slices/albums";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../constants/api";

export const useFetchAlbums = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();
  const { albums } = useAppSelector((state) => state.albums);

  const fetchAlbums = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        if (!isAuth) {
          return;
        }

        const media = await dispatch(getAlbums(page, pageSize));

        dispatch(setAlbums(media));
      } catch (error) {
        console.error("Error while [fetchAlbums]", error);
      }
    },
    [dispatch, isAuth]
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setAlbumsPage(albums.page + 1));
    dispatch(setAlbumsPageSize(DEFAULT_PAGE_SIZE));
  }, [albums.page, dispatch]);

  const refetchAlbums = useCallback(() => {
    fetchAlbums(albums.page);
  }, [albums.page, fetchAlbums]);

  useEffect(() => {
    fetchAlbums(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);

    return () => {
      dispatch(resetAlbums());
    };
  }, [dispatch, fetchAlbums]);

  useEffect(() => {
    if (albums.page !== DEFAULT_PAGE) {
      fetchAlbums(albums.page, albums.pageSize);
    }
  }, [albums.page, albums.pageSize, fetchAlbums]);

  return {
    albums: albums.data,
    refetchAlbums,
    onUpdatePageOrPageSize,
  };
};
