import { Box, Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type IAutocompleteOptionWithTitle = {
  option: { title: string; [key: string]: string | number | null };
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  onOptionClick: (option: any) => void;
} & React.HTMLAttributes<HTMLLIElement>;

export const AutocompleteOptionWithTitle: React.FC<
  IAutocompleteOptionWithTitle
> = ({ option, onClick, onOptionClick, ...props }) => {
  return (
    <Box
      component="li"
      onClick={(event: any) => {
        if (onClick) {
          onClick(event);
        }
        onOptionClick(option);
      }}
      sx={materialStyles.searchOption}
      {...props}
    >
      <Typography sx={materialStyles.searchUserName}>{option.title}</Typography>
    </Box>
  );
};
