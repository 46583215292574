import { useCallback, useState } from "react";
import { UserLayout } from "../../components/layout/UserLayout";
import { SettingsContentComponent } from "../../components/settings";
import { Grid } from "@mui/material";
import { SettingsList } from "../../components/lists/settingsList/SettingsList";
import { materialStyles } from "./styles";

export type ISettingsContentTypes =
  | "ACCOUNT_INFORMATION"
  | "PRIVACY_GROUPS"
  | "SUPPORT"
  | "EMAIL_NOTIFICATIONS"
  | "DESKTOP_NOTIFICATIONS"
  | "BROWSER_NOTIFICATIONS"
  | "APP_ACCESS"
  | "BLOCKED_USERS";

const DEFAULT_SETTINGS_CONTENT_TYPE = "ACCOUNT_INFORMATION";

export const UserSettings = () => {
  const [settingsContentType, setSettingContentType] =
    useState<ISettingsContentTypes>(DEFAULT_SETTINGS_CONTENT_TYPE);

  const onChangeSettingsType = useCallback((type: ISettingsContentTypes) => {
    setSettingContentType(type);
  }, []);

  return (
    <UserLayout>
      <Grid sx={materialStyles.page}>
        <SettingsList
          type={settingsContentType}
          onChangeSettingsType={onChangeSettingsType}
        />
        <SettingsContentComponent type={settingsContentType} />
        <Grid style={{ flex: 2 }} />
      </Grid>
    </UserLayout>
  );
};
