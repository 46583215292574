import i18next from "i18next";
import _ from "lodash";
import {
  IAnyFilters,
  IAppliedFiltersType,
} from "../components/items/AppliedFilters";
import { IMapFilters } from "../store/slices/map";
import { IPhotosFiltersType } from "../store/slices/myPhotos";
import moment from "moment";

export type IAppliedFilter = {
  value: string;
  key: string;
};

export const parseFiltersForAppliedFilters = (
  filters: IAnyFilters,
  type: IAppliedFiltersType
) => {
  const keys = _.keys(filters);
  const filterKey = _.lowerCase(type);

  const values = _.map(keys, (key) => {
    const filterField = _.get(filters, key);
    const isFilterFieldArray = _.isArray(filterField);
    const isFilterFieldObject = Boolean(filterField && filterField.id);
    const isFilterFieldEmpty = _.isEmpty(filterField);
    const isFilterFieldIsDate = moment(filterField).isValid();

    let value;

    switch (true) {
      case isFilterFieldEmpty:
        value = false;
        break;
      case isFilterFieldArray:
        const titlesFromFilterField = _.map(
          filterField,
          (field) => field.title || field.username
        );
        value = _.join(titlesFromFilterField, ", ");
        break;
      case isFilterFieldObject:
        value = filterField.title;
        break;
      case isFilterFieldIsDate:
        value = moment(filterField).format("DD.MM.YYYY");
        break;
      default:
        value = filterField;
        break;
    }

    if (!value) {
      return undefined;
    }

    return {
      value: `${i18next.t(`filters.${filterKey}.${key}`)} ${value}`,
      key,
    } as IAppliedFilter;
  });

  return _.compact(values);
};

export const parseFiltersParamsForAllowedMediaRequest = (
  filters?: IMapFilters
) => {
  if (!filters) {
    return {};
  }
  const { tags, peoples } = filters;

  const parsedTagsIds = _.chain(tags)
    .map((tag) => tag.id)
    .join(",")
    .value();

  const parsedUserIds = _.chain(peoples)
    .map((user) => user.id)
    .join(",")
    .value();

  const parsedCreatedFrom = filters.created_from ? filters.created_from : null;

  const parsedCreatedTo = filters.created_to ? filters.created_to : null;

  return _.pickBy(
    {
      tags: parsedTagsIds,
      user_id: parsedUserIds,
      created_from: parsedCreatedFrom,
      created_to: parsedCreatedTo,
    },
    (value) => value !== "" && value !== null
  );
};

export const parseFiltersParamsForMediaRequest = (
  filters?: IPhotosFiltersType
) => {
  if (!filters) {
    return {};
  }
  const { tag } = filters;

  const parsedTagId = tag ? tag.id : "";

  return _.pickBy(
    {
      tag: parsedTagId,
    },
    (value) => value !== "" && value !== null
  );
};

export function parseCreateDateToFormatDiff(originalDate: string) {
  const currentDate = moment();

  const date = moment(originalDate);

  if (date.isSame(currentDate, "day")) {
    return date.format("HH:mm");
  } else if (date.isSame(date.clone().subtract(1, "days"), "day")) {
    date.format("HH:mm D MMM");
  } else {
    return date.format("HH:mm D MMM");
  }
}

export function parseTimerTime(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${secs
    .toString()
    .padStart(2, "0")}`;
}
