import { Box, Modal } from "@mui/material";
import React, { useCallback, useState } from "react";
import { materialStyles } from "./styles";
import { ReasonsComplaintContent } from "./components/ReasonsComplaintContent";
import { useAppDispatch, useAppSelector } from "../../../store";
import { resetComplaint } from "../../../store/slices/complaint";
import { ConfirmComplaintContent } from "./components/ConfirmComplaintContent";
import { SuccessComplaintContent } from "./components/SuccessComplaintContent";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";
import { IComplaintReasonType } from "../../../hooks/useComplaintReasons";

export type IUserComplaintModalType =
  | "REPORT_REASONS_FORM"
  | "CONFIRM_REPORT_FORM"
  | "SUCCEESS_COMPLAINR_FORM";

type IUserModalComplaint = {
  isOpen: boolean;
  onClose: () => void;
  initialModalType?: IUserComplaintModalType;
  type: IComplaintReasonType;
};

export const ComplaintModal: React.FC<IUserModalComplaint> = ({
  isOpen,
  onClose,
  initialModalType = "REPORT_REASONS_FORM",
  type,
}) => {
  const [complaintModalType, setComplaintModalType] =
    useState(initialModalType);

  const { loading } = useAppSelector((state) => state.loader);

  const dispatch = useAppDispatch();

  const onChangeComplaintModalType = useCallback(
    (type: IUserComplaintModalType) => {
      setComplaintModalType(type);
    },
    []
  );

  const onCloseModal = useCallback(() => {
    setComplaintModalType(initialModalType);
    dispatch(resetComplaint());
    onClose();
  }, [dispatch, initialModalType, onClose]);

  return (
    <Modal open={isOpen} sx={materialStyles.modal} onClose={onCloseModal}>
      <Box sx={materialStyles.modalBox}>
        {complaintModalType === "REPORT_REASONS_FORM" ? (
          <ReasonsComplaintContent
            onClose={onCloseModal}
            onChangeComplaintModalType={onChangeComplaintModalType}
            type={type}
          />
        ) : null}
        {complaintModalType === "CONFIRM_REPORT_FORM" ? (
          <ConfirmComplaintContent
            onClose={onCloseModal}
            onChangeComplaintModalType={onChangeComplaintModalType}
            type={type}
          />
        ) : null}
        {complaintModalType === "SUCCEESS_COMPLAINR_FORM" ? (
          <SuccessComplaintContent
            onClose={onCloseModal}
            onChangeComplaintModalType={onChangeComplaintModalType}
            type={type}
          />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
