import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Box,
  Tab,
} from "@mui/material";
import { IAuthModalType } from "..";
import { materialStyles, styles } from "./styles";
import { AuthModalHeader } from "./AuthModalHeader";
import { useTranslation } from "react-i18next";
import { SocialButtons } from "./SocialButtons";
import { ChangeAuthTypeBlock } from "./ChangeAuthTypeBlock";
import { useCallback, useMemo, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useFormik } from "formik";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  setEmail,
  setPhone,
  setPhoneParams,
} from "../../../../store/slices/registration";
import {
  sendEmailValidation,
  sendPhoneValidation,
} from "../../../../store/thunks/user";
import {
  getMaskLength,
  plusOnStart,
  validateEmail,
  validatePhone,
} from "../../../../utils/validate";
import { PhoneCodesModal } from "./PhoneCodesModal";
import { ICountriesType } from "../../../../constants/Countries";
import { useToast } from "rc-toastr";

type IRegistrationForm = {
  onChangeAuthModalType: (type: IAuthModalType) => void;
  onClose: () => void;
};

export type IAuthType = "PHONE" | "EMAIL";

type IFormValues = {
  email: string;
  phone: string;
  termsOfUseAgree: boolean;
  rememberMe: boolean;
};

export const RegistrationForm: React.FC<IRegistrationForm> = ({
  onChangeAuthModalType,
  onClose,
}) => {
  const { t } = useTranslation();

  const { regUser } = useAppSelector((state) => state.regUser);

  const [tabValue, setTabValue] = useState<IAuthType>("EMAIL");

  const { toast } = useToast();

  const dispatch = useAppDispatch();

  const onChangeModalType = useCallback(() => {
    onChangeAuthModalType("LOGIN");
  }, [onChangeAuthModalType]);

  const onSubmit = useCallback(
    async (details: IFormValues) => {
      const isCustomPhone = plusOnStart(details.phone);

      const parsedPhone = isCustomPhone
        ? details.phone
        : `${regUser.phoneParams.code}${details.phone}`;

      const isValidPhoneValue = validatePhone(parsedPhone);
      const isValidEmailValue = validateEmail(details.email);

      try {
        switch (true) {
          case Boolean(details.email):
            if (!isValidEmailValue) {
              toast.error(t("auth_modal.you_have_entered_incorrect_email"));
              return;
            }
            dispatch(setEmail(details.email));
            await dispatch(sendEmailValidation(details.email));
            onChangeAuthModalType("CODE_EMAIL");
            return;
          case Boolean(details.phone):
            if (!isValidPhoneValue) {
              toast.error(t("auth_modal.you_have_entered_incorrect_phone"));
              return;
            }
            dispatch(setPhone(parsedPhone));
            await dispatch(sendPhoneValidation(parsedPhone));
            onChangeAuthModalType("CODE_PHONE");
            return;
        }
      } catch (error) {
        console.error("Error while [onSubmit]", error);
      }
    },
    [dispatch, onChangeAuthModalType, regUser.phoneParams.code, t, toast],
  );

  const { values, handleChange, handleSubmit, isValid, errors, setFieldValue } =
    useFormik({
      onSubmit,
      initialValues: {
        email: "",
        phone: "",
        termsOfUseAgree: false,
        rememberMe: false,
      },
      validate: (values) => {
        return _.pickBy({
          email: tabValue === "EMAIL" && !_.trim(values.email),
          phone: tabValue === "PHONE" && !_.trim(values.phone),
          termsOfUseAgree: !Boolean(values.termsOfUseAgree),
        });
      },
    });

  const handleTabChange = useCallback(
    (event: any, value: IAuthType) => {
      setFieldValue("email", "");
      setFieldValue("phone", "");
      setTabValue(value);
    },
    [setFieldValue],
  );

  const onChoosePhoneParams = useCallback(
    (item: ICountriesType) => {
      dispatch(setPhoneParams(item));
    },
    [dispatch],
  );

  const onSocialAuthSuccess = useCallback(
    (isNew: boolean) => {
      if (isNew) {
        onChangeAuthModalType("SET_USER_NICKNAME");
        return;
      }
      onClose();
    },
    [onChangeAuthModalType, onClose],
  );

  const isShowPhoneCodes = useMemo(
    () => !plusOnStart(values.phone),
    [values.phone],
  );

  const phoneMaxLength = useMemo(
    () =>
      isShowPhoneCodes
        ? getMaskLength(regUser.phoneParams.maskPlaceholder)
        : undefined,
    [isShowPhoneCodes, regUser.phoneParams.maskPlaceholder],
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid sx={materialStyles.innerAuthModalBox}>
        <AuthModalHeader title={t("auth_modal.sign_up")} />
        <Typography sx={materialStyles.modalSubtitle}>
          {t("auth_modal.we_very_happy_see_you")}
        </Typography>
        <TabContext value={tabValue}>
          <Box>
            <TabList sx={materialStyles.tabList} onChange={handleTabChange}>
              <Tab
                sx={materialStyles.tabContainer}
                label={t("auth_modal.email")}
                value="EMAIL"
              />
              <Tab
                sx={materialStyles.tabContainer}
                label={t("auth_modal.phone")}
                value="PHONE"
              />
            </TabList>
          </Box>
          <Grid sx={materialStyles.formContainer}>
            <TabPanel sx={materialStyles.tabPanelContainer} value="EMAIL">
              <TextField
                error={Boolean(errors.email)}
                name="email"
                sx={materialStyles.authInput}
                value={values.email}
                onChange={handleChange}
                fullWidth
                label={t("auth_modal.email")}
                placeholder={t("auth_modal.enter_email")}
              />
            </TabPanel>
            <TabPanel sx={materialStyles.tabPanelContainer} value="PHONE">
              <TextField
                error={Boolean(errors.phone)}
                name="phone"
                type="number"
                sx={materialStyles.authInput}
                value={values.phone}
                onChange={handleChange}
                fullWidth
                label={t("auth_modal.phone")}
                placeholder={regUser.phoneParams.maskPlaceholder}
                inputProps={{ maxLength: phoneMaxLength }}
                InputProps={{
                  startAdornment: isShowPhoneCodes ? (
                    <PhoneCodesModal
                      onClickItem={onChoosePhoneParams}
                      phoneParams={regUser.phoneParams}
                    />
                  ) : null,
                }}
              />
            </TabPanel>
            <FormGroup sx={materialStyles.checkBoxGroup}>
              <FormControlLabel
                name="termsOfUseAgree"
                value={values.termsOfUseAgree}
                onChange={handleChange}
                sx={materialStyles.checkBox}
                control={<Checkbox />}
                label={t("auth_modal.terms_of_use_privacy_policy")}
              />
              <FormControlLabel
                name="rememberMe"
                value={values.rememberMe}
                onChange={handleChange}
                sx={materialStyles.checkBox}
                control={<Checkbox />}
                label={t("auth_modal.remember_me_as_member_of_wozere")}
              />
            </FormGroup>
            <Button
              type="submit"
              disabled={!isValid}
              style={styles.signUpButton}
              sx={materialStyles.loginButton}
              variant="contained"
            >
              {t("auth_modal.sign_up")}
            </Button>
            <SocialButtons type="SIGNUP" onAuthSuccess={onSocialAuthSuccess} />
            <ChangeAuthTypeBlock
              onClick={onChangeModalType}
              subText={t("auth_modal.already_have_an_account")}
              actionText={t("auth_modal.log_in_with")}
            />
          </Grid>
        </TabContext>
      </Grid>
    </form>
  );
};
