import { AppDispatch, RootState } from "..";
import axios from "axios";
import { API_URL } from "../api";
import { setLoading } from "../slices/loading";
import { setError } from "../slices/errors";
import { getResponseFromAxiosError } from "../../utils";
import _ from "lodash";
import { checkOrRefreshToken } from "./user";
import { DEFAULT_PAGE_SIZE } from "../../constants/api";

export const addMediaToAlbum = (mediaId: number, albumId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/media/${mediaId}/album/${albumId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [addMediaToAlbum]", error);
    }
  };
};

export const addMediaToAlbumByIds = (ids: number[], albumId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const promises = _.map(ids, (mediaId) =>
        dispatch(addMediaToAlbum(mediaId, albumId)),
      );

      await Promise.all(promises);
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [addMediaToAlbumByIds]", error);
    }
  };
};

export const createAlbum = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user, newAlbum } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/albums`,
        {
          title: newAlbum.title,
          description: newAlbum.description,
          is_public: true,
          cover_id: _.first(newAlbum.imageIds) || null,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      const newAlbumId = data.id;

      await dispatch(addMediaToAlbumByIds(newAlbum.imageIds, newAlbumId));

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [createAlbum]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getAlbums = (page?: number, pageSize?: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/albums`, {
        params: {
          page,
          limit: pageSize,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getAlbums]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getAlbum = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/globe/album/${id}`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getAlbum]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getAlbumPhotos = ({
  id,
  page,
  pageSize,
}: {
  id: number;
  page?: number;
  pageSize?: number;
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/globe/allowed`, {
        params: {
          albums: id,
          page,
          limit: pageSize || DEFAULT_PAGE_SIZE,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getAlbum]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const removeAlbum = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.delete(`${API_URL}/albums/${id}`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [removeAlbum]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getAlbumSharingUsersById = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/albums/${id}/access`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getAlbumSharingUsersById]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const sharingAlbum = (albumId: number, userId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/albums/${albumId}/access/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [sharingAlbum]", error);
    }
  };
};

export const removeSharingAlbum = (albumId: number, userId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.delete(
        `${API_URL}/albums/${albumId}/access/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [removeSharingAlbum]", error);
    }
  };
};

export const sharingAlbumByIds = (albumId: number, userIds: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      const promises = _.map(userIds, (userId) =>
        dispatch(sharingAlbum(albumId, userId)),
      );

      const response = await Promise.all(promises);

      if (_.isEmpty(response)) {
        throw new Error();
      }

      return response;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [sharingAlbumByIds]", error);
    }
  };
};

export const removeSharingAlbumByIds = (albumId: number, userIds: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      const promises = _.map(userIds, (userId) =>
        dispatch(removeSharingAlbum(albumId, userId)),
      );

      const response = await Promise.all(promises);

      if (_.isEmpty(response)) {
        throw new Error();
      }

      return response;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [removeSharingAlbumByIds]", error);
    }
  };
};

export const editAlbum = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user, edit } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const title = _.get(edit, "props.prop1");
      const description = _.get(edit, "props.prop2");

      const { data } = await axios.post(
        `${API_URL}/albums/${id}`,
        {
          title,
          description,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [editAlbum]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
