import { theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  block: {
    border: `1px solid ${theme.palette.grey[800]}`,
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
  },
  titleBlock: {
    backgroundColor: theme.palette.grey[600],
    padding: "4px 16px",
  },
  title: {
    color: theme.palette.grey[700],
    fontSize: "14px",
    lineHeight: "22px",
  },
  blockItem: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    cursor: "pointer",
  },
  arrowIcon: {
    color: theme.palette.grey[700],
  },
  blockItemTitle: {
    color: theme.palette.grey[700],
    fontSize: "14px",
    lineHeight: "22px",
  },
  itemsDivider: {
    borderColor: theme.palette.grey[800],
  },
  blockItemButton: {
    alignItems: "center",
    justifyContent: "center",
  },
  itemButton: {
    textTransform: "none",
    letterSpacing: 0,
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
  },
  blockItemSubtitle: {
    color: theme.palette.grey[900],
    fontSize: "12px",
    lineHeight: "20px",
    textAlign: "left",
  },
  blockItemSwitcher: {
    "& .MuiSwitch-track": {
      backgroundColor: "white",
    },
  },
};
