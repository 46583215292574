import { AppDispatch, RootState } from "..";
import axios from "axios";
import { API_URL } from "../api";
import { setLoading } from "../slices/loading";
import { setError } from "../slices/errors";
import { getResponseFromAxiosError } from "../../utils";
import { checkOrRefreshToken } from "./user";
import _ from "lodash";

export const getPrivacyGroups = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/groups`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getPrivacyGroups]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getPrivacyGroupById = (groupId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/groups/${groupId}`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getPrivacyGroupById]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const createNewPrivacyGroup = (title: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/groups`,
        {
          title,
          is_visible: true,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [createNewPrivacyGroup]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addUserToGroupById = (groupId: number, userId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/groups/${groupId}/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [addUserToGroupById]", error);
    }
  };
};

export const addUsersToGroupByIds = (groupId: number, userIds: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      const promises = _.map(userIds, (userId) =>
        dispatch(addUserToGroupById(groupId, userId)),
      );

      const response = await Promise.all(promises);

      if (_.isEmpty(response)) {
        throw new Error();
      }

      return response;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [addUsersToGroupByIds]", error);
    }
  };
};

export const removeUserFromGroupById = (groupId: number, userId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.delete(
        `${API_URL}/groups/${groupId}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [removeUserFromGroupById]", error);
    }
  };
};

export const removeUsersFromGroupByIds = (
  groupId: number,
  userIds: number[],
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const promises = _.map(userIds, (userId) =>
        dispatch(removeUserFromGroupById(groupId, userId)),
      );

      const response = await Promise.all(promises);

      if (_.isEmpty(response)) {
        throw new Error();
      }

      return response;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [removeUsersFromGroupByIds]", error);
    }
  };
};
