export function getYouTubeVideoIdFromUrl(url?: string) {
  if (!url) {
    return undefined;
  }

  const regex =
    /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
  const match = url.match(regex);
  return match ? match[1] : undefined;
}

export function getYouTubeVideoEmbedFromId(id?: string | null) {
  if (!id) {
    return undefined;
  }

  return `https://www.youtube.com/embed/${id}`;
}

export function getYouTubeVideoCoverFromId(id?: string) {
  if (!id) {
    return undefined;
  }

  return `https://i.ytimg.com/vi/${id}/hqdefault.jpg`;
}

export function getYouTubeVideoUrlFromId(id?: string) {
  if (!id) {
    return undefined;
  }

  return `https://www.youtube.com/watch?v=${id}`;
}
