//TODO: Move to .env file
export const GOOGLE_CLIENT_ID =
  "581918154210-bamt03lejga3cv52j5rstqgsbr8t3ppf.apps.googleusercontent.com";

//TODO: Move to .env file
export const FACEBOOK_CLIENT_ID = "293195787097570";

//TODO: Move to .env file
export const MAP_BOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZGFuaWwtc2FyZ2luIiwiYSI6ImNsb29lcGRodTAwdXoyaXFucTcxNmtnMmwifQ.28b1pLTtEWW5bP1iNSzxaw";

export const MAP_BOX_STYLE_URL =
  "mapbox://styles/danil-sargin/clr3nh6co00es01pd70qw8m3b";

//TODO: Move to .env file
export const GOOGLE_API_KEY = "AIzaSyBcYS6_Goe2B3GR4tqGIgouMXP7Wjh_JGY";

export const INITIAL_MAP_LATITUDE = 46.195688;
export const INITIAL_MAP_LONGITUDE = 3.798001;

export const FILE_IMAGE_APPLE_EXT = ["heic", "heif"];

export const FILE_IMAGE_EXT = ["jpeg", "jpg", "png", "webp", "jfif"];

export const FILE_VIDEO_EXT = ["mp4"];

export const FILE_VIDEO_NO_WEB_EXT = ["mov", "avi"];
