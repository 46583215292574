import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IMaterialStyleItem } from "../../types";
import { Moment } from "moment";

type DatePickerInputProps = {
  label?: string;
  sx?: IMaterialStyleItem;
  value?: Moment | null;
  onChange?: (value: Moment | null) => void;
  disableFuture?: boolean;
};

export const DatePickerInput: React.FC<DatePickerInputProps> = ({
  label,
  sx,
  value,
  onChange,
  disableFuture,
}) => {
  return (
    <DatePicker
      disableFuture={disableFuture}
      label={label}
      sx={sx}
      value={value}
      onChange={onChange}
    />
  );
};
