import React, { createContext, Dispatch, useCallback, useReducer } from "react";
import { AuthModal } from "../modals/authModal";
import { UserModal } from "../modals/userModal";

type TCommonModalsProvider = {
  children: React.ReactNode;
};

type CommonModalsState = {
  isAuthModalOpen: boolean;
  isUserModalOpen: boolean;
};

type CommonModalsAction = {
  type:
    | "SHOW_AUTH_MODAL"
    | "HIDE_AUTH_MODAL"
    | "SHOW_USER_MODAL"
    | "HIDE_USER_MODAL";
};

const initialAuthModalState: CommonModalsState = {
  isAuthModalOpen: false,
  isUserModalOpen: false,
};

export const CommonModalsDispatchContext =
  createContext<Dispatch<CommonModalsAction> | null>(null);

const authModalReducer = (
  state: CommonModalsState,
  action: CommonModalsAction,
) => {
  switch (action.type) {
    case "SHOW_AUTH_MODAL": {
      return { ...state, isAuthModalOpen: true };
    }
    case "HIDE_AUTH_MODAL": {
      return { ...state, isAuthModalOpen: false };
    }
    case "SHOW_USER_MODAL": {
      return { ...state, isUserModalOpen: true };
    }
    case "HIDE_USER_MODAL": {
      return { ...state, isUserModalOpen: false };
    }
    default:
      return state;
  }
};

export const CommonModalsProvider: React.FC<TCommonModalsProvider> = ({
  children,
}) => {
  const [commonModalsState, dispatchCommonModal] = useReducer(
    authModalReducer,
    initialAuthModalState,
  );

  const onCloseAuthModal = useCallback(() => {
    dispatchCommonModal({ type: "HIDE_AUTH_MODAL" });
  }, []);

  const onCloseUserModal = useCallback(() => {
    dispatchCommonModal({ type: "HIDE_USER_MODAL" });
  }, []);

  return (
    <CommonModalsDispatchContext.Provider value={dispatchCommonModal}>
      {children}
      <AuthModal
        isOpen={commonModalsState.isAuthModalOpen}
        onClose={onCloseAuthModal}
      />
      <UserModal
        isOpen={commonModalsState.isUserModalOpen}
        onClose={onCloseUserModal}
      />
    </CommonModalsDispatchContext.Provider>
  );
};
