import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  formContainer: {
    width: "100%",
    p: 2,
  },
  videoEditorContainer: {
    maxWidth: "100%",
    minWidth: "100%",
    maxHeight: "500px",
    backgroundColor: "#101010",
    marginTop: 1,
  },
};
