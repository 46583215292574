import { ButtonBase, Grid, Typography } from "@mui/material";
import { IUserType } from "../../../store/slices/user";
import React, { useEffect, useRef } from "react";
import _ from "lodash";
import { UserAvatar } from "../../user/UserAvatar";
import { materialStyles } from "./styles";

export type IPeoplesList = {
  title?: string;
  peoples: IUserType[];
  onClick?: (id: number) => void;
  listEmptyText?: string;
  onEndReached?: () => void;
};

export const PeoplesList: React.FC<IPeoplesList> = ({
  title,
  peoples,
  onClick,
  listEmptyText,
  onEndReached,
}) => {
  const listRef = useRef<any>(null);

  useEffect(() => {
    const gridElement = listRef.current;

    const handleScroll = () => {
      if (
        Math.round(gridElement.scrollLeft + gridElement.clientWidth) >=
          gridElement.scrollWidth ||
        Math.floor(gridElement.scrollLeft + gridElement.clientWidth) >=
          gridElement.scrollWidth
      ) {
        onEndReached && onEndReached();
      }
    };

    gridElement.addEventListener("scroll", handleScroll);

    return () => {
      gridElement.removeEventListener("scroll", handleScroll);
    };
  }, [onEndReached]);

  return (
    <Grid sx={materialStyles.peoplesListWithTitleContainer}>
      {title ? (
        <Grid>
          <Typography sx={materialStyles.listTitle}>{title}</Typography>
        </Grid>
      ) : null}
      <Grid ref={listRef} sx={materialStyles.peoplesListContainer}>
        {_.map(peoples, (people) => (
          <ButtonBase
            key={people.id}
            sx={materialStyles.peopleConteiner}
            onClick={() => onClick && onClick(people.id)}
          >
            <Grid sx={materialStyles.avatarContainer}>
              <UserAvatar user={people} type="IN_PEOPLE_LIST" />
            </Grid>
            <Typography sx={materialStyles.avatarUsername}>
              {people.username}
            </Typography>
          </ButtonBase>
        ))}
        {_.isEmpty(peoples) && listEmptyText ? (
          <Typography sx={materialStyles.listEmptyText}>
            {listEmptyText}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};
