import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getMyFavoritesPhoto } from "../store/thunks/favorites";
import {
  resetFavorites,
  setFavoritePhotos,
  setMyFavoritesPage,
  setMyFavoritesPageSize,
} from "../store/slices/favorites";
import {
  DEFAULT_GLOBE_PAGE_SIZE,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "../constants/api";

export const useFetchFavorites = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { favoritePhotos } = useAppSelector((state) => state.favorites);
  const { mode } = useAppSelector((state) => state.visualMode);

  const fetchFavorites = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        if (!isAuth) {
          return;
        }

        const media = await dispatch(getMyFavoritesPhoto(page, pageSize));

        dispatch(setFavoritePhotos(media));
      } catch (error) {
        console.error("Error while [fetchFavorites]", error);
      }
    },
    [dispatch, isAuth]
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setMyFavoritesPage(favoritePhotos.page + 1));
    dispatch(setMyFavoritesPageSize(DEFAULT_PAGE_SIZE));
  }, [favoritePhotos.page, dispatch]);

  const refetchMyFavorites = useCallback(() => {
    fetchFavorites(favoritePhotos.page);
  }, [favoritePhotos.page, fetchFavorites]);

  useEffect(() => {
    const defaultPageSize = {
      GRID: DEFAULT_PAGE_SIZE,
      GLOBE: DEFAULT_GLOBE_PAGE_SIZE,
    }[mode];

    fetchFavorites(DEFAULT_PAGE, defaultPageSize);

    return () => {
      dispatch(resetFavorites());
    };
  }, [dispatch, mode, fetchFavorites]);

  useEffect(() => {
    if (favoritePhotos.page !== DEFAULT_PAGE) {
      fetchFavorites(favoritePhotos.page, favoritePhotos.pageSize);
    }
  }, [favoritePhotos.page, favoritePhotos.pageSize, fetchFavorites]);

  return {
    favoritePhotos: favoritePhotos.data,
    onUpdatePageOrPageSize,
    refetchMyFavorites,
  };
};
