import { createSlice } from "@reduxjs/toolkit";
import { PrivacyGroup } from "./groups";
import { IUserType } from "./user";
import _ from "lodash";

interface IUpdateGroupSliceInitialState {
  groupId: PrivacyGroup["id"] | null;
  group: PrivacyGroup | null;
  users: IUserType[];
}

const updateGroupSliceInitialState: IUpdateGroupSliceInitialState = {
  groupId: null,
  group: null,
  users: [],
};

const updateGroupSlice = createSlice({
  name: "updateGroup",
  initialState: updateGroupSliceInitialState,
  reducers: {
    setGroupIdForUpdate(state, action) {
      state.groupId = action.payload;
    },
    setGroupForUpdate(state, action) {
      state.group = action.payload;
    },
    setGroupUsersForUpdate(state, action) {
      state.users = action.payload;
    },
    removeUserFromGroup(state, action) {
      const filteredSharingUsers = _.filter(
        state.users,
        (user) => user.id !== action.payload,
      );
      state.users = filteredSharingUsers;
    },
    resetUpdateGroupSlice(state) {
      state.groupId = updateGroupSliceInitialState.groupId;
      state.group = updateGroupSliceInitialState.group;
      state.users = updateGroupSliceInitialState.users;
    },
  },
});

export default updateGroupSlice.reducer;
export const {
  setGroupIdForUpdate,
  setGroupForUpdate,
  setGroupUsersForUpdate,
  removeUserFromGroup,
  resetUpdateGroupSlice,
} = updateGroupSlice.actions;
