import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  innerModalContainer: {
    padding: "24px 19px 31px 19px",
    flex: 1,
  },
  shareModalTitle: {
    fontSize: "20px",
    color: Colors.white,
    lineHeight: "28px",
    fontWeight: 700,
    width: "100%",
  },
  searchUserInput: {
    border: `1px solid ${theme.palette.grey[200]} !important`,
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    color: Colors.white,

    marginTop: "24px",
    marginBottom: "36px",

    "& input": {
      color: Colors.white,
    },

    "& fieldset": {
      border: "none",
    },

    "& ::placeholder": {
      color: theme.palette.grey[200],
      opacity: 1,
    },
  },
  shareModalSubtitle: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[700],
    marginBottom: "8px",
  },
  userWithGeneralAccess: {
    marginTop: "26px",
  },
  confirmButton: {
    height: "50px",
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: Colors.white,
      backgroundColor: theme.palette.grey[400],
    },
  },
  shareModalGroupsList: {
    marginTop: "24px",
    maxHeight: "300px",
    overflow: "auto",
  },
  checkBoxDivider: {
    borderColor: theme.palette.grey[800],
    mt: 1,
    mb: 1,
  },
  sharingButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    gap: 1,
    marginTop: "36px",
  },
  copyLinkButton: {
    flex: 1,
    textDecoration: "none",
    textTransform: "none",
    letterSpacing: 0,
  },
};
