import React, { useCallback, useMemo, useState } from "react";
import { IUserAlbumModalType } from "..";
import { Grid, IconButton, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { FullSizeModalHeader } from "../../../header/FullSizeModalHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppImageList } from "../../../lists/imageList/AppImageList";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { removeAlbum } from "../../../../store/thunks/albums";
import { useTranslation } from "react-i18next";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import { removeAlbumFromStorage } from "../../../../store/slices/albums";
import { ComplaintModal } from "../../complaintModal";
import { setActionId } from "../../../../store/slices/complaint";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import { EditModal } from "../../editModal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { setActionId as setEditActionId } from "../../../../store/slices/edit";
import { updateAlbumPhotoInStorage } from "../../../../store/slices/userAlbum";
import { IPhotoType } from "../../../../store/slices/myPhotos";
import { useFetchUserAlbumById } from "../../../../hooks/useFetchUserAlbumById";
import { useFetchUserAlbumPhotosById } from "../../../../hooks/useFetchUserAlbumPhotosById";
import { WithHandleScrollGrid } from "../../../helpers/WithHandleScrollGrid";
import { ConfirmModal } from "../../confirmModal";

type IAlbumMainContent = {
  onClose: () => void;
  onChangeUserAlbumModalType: (type: IUserAlbumModalType) => void;
};

export const AlbumMainContent: React.FC<IAlbumMainContent> = ({
  onClose,
  onChangeUserAlbumModalType,
}) => {
  const { t } = useTranslation();
  const { id } = useAppSelector((state) => state.userAlbum);
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [isComplaintModalOpen, setIsComplaintOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const { album } = useFetchUserAlbumById({ id, isEnable: true });

  const isMyAlbum = useMemo(
    () => user?.id === album?.user_id,
    [album?.user_id, user?.id],
  );

  const { albumPhotos, onUpdatePageOrPageSize, refetchUserAlbumPhotos } =
    useFetchUserAlbumPhotosById({ id, isEnable: Boolean(album) });

  const onOpenRemoveModal = useCallback(() => {
    setIsRemoveModalOpen(true);
  }, []);

  const onCloseRemoveModal = useCallback(() => {
    setIsRemoveModalOpen(false);
  }, []);

  const onRemoveAlbum = useCallback(async () => {
    try {
      if (!id) {
        return;
      }

      await dispatch(removeAlbum(id));
      dispatch(removeAlbumFromStorage(id));
      onClose();
    } catch (error) {
      console.error("Error while [onRemoveAlbum]", error);
    }
  }, [dispatch, id, onClose]);

  const onCompaintModalOpen = useCallback(() => {
    if (!album) {
      return;
    }
    dispatch(setActionId(album.id));
    setIsComplaintOpen(true);
  }, [dispatch, album]);

  const onComplaintModalClose = useCallback(() => {
    setIsComplaintOpen(false);
  }, []);

  const onEditModalOpen = useCallback(() => {
    if (!album) {
      return;
    }
    dispatch(setEditActionId(album.id));
    setIsEditModalOpen(true);
  }, [album, dispatch]);

  const onEditModalClose = useCallback(() => {
    setIsEditModalOpen(false);
  }, []);

  const onUpdatePhotoToFavorites = useCallback(
    (photo: IPhotoType) => {
      dispatch(updateAlbumPhotoInStorage(photo));
    },
    [dispatch],
  );

  return (
    <>
      <Grid sx={materialStyles.innerModalForm}>
        <FullSizeModalHeader
          onIconClick={onClose}
          Icon={<ArrowBackIcon sx={materialStyles.headerIcon} />}
        >
          <Grid sx={materialStyles.headerBlock}>
            {!isMyAlbum ? (
              <IconButton onClick={onCompaintModalOpen}>
                <ReportGmailerrorredOutlinedIcon
                  sx={materialStyles.headerIcon}
                />
              </IconButton>
            ) : null}
            {isMyAlbum ? (
              <IconButton onClick={onEditModalOpen}>
                <EditOutlinedIcon sx={materialStyles.headerIcon} />
              </IconButton>
            ) : null}
            {isMyAlbum ? (
              <IconButton onClick={onOpenRemoveModal}>
                <DeleteOutlineOutlined sx={materialStyles.headerIcon} />
              </IconButton>
            ) : null}
          </Grid>
        </FullSizeModalHeader>
        {album ? (
          <WithHandleScrollGrid
            onEndReached={onUpdatePageOrPageSize}
            sx={materialStyles.mainContentContainer}
          >
            <Grid sx={materialStyles.titleAndDescriptionBlock}>
              {album.title ? (
                <Typography sx={materialStyles.albumTitle}>
                  {album.title}
                </Typography>
              ) : null}
              <Typography sx={materialStyles.albumItemsCount}>
                {t("albums.value_items", { items: album.media_count })}
              </Typography>
              {album.description ? (
                <Typography sx={materialStyles.albumDescription}>
                  {album.description}
                </Typography>
              ) : null}
            </Grid>
            {albumPhotos ? (
              <AppImageList
                onUpdatePhotoToFavorites={onUpdatePhotoToFavorites}
                photos={albumPhotos}
                enableOpenPhoto
                onClosePhotoModal={refetchUserAlbumPhotos}
              />
            ) : null}
          </WithHandleScrollGrid>
        ) : null}
      </Grid>
      <ComplaintModal
        isOpen={isComplaintModalOpen}
        onClose={onComplaintModalClose}
        type="ALBUM"
      />
      <ConfirmModal
        isOpen={isRemoveModalOpen}
        onClose={onCloseRemoveModal}
        onConfirm={onRemoveAlbum}
        confirmText={t("albums.remove_album")}
      />
      <EditModal
        isOpen={isEditModalOpen}
        onClose={onEditModalClose}
        type="ALBUM"
      />
    </>
  );
};
