import {
  ButtonBase,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import React, { useState, useMemo, useCallback } from "react";
import { materialStyles, styles } from "./styles";
import { Countries, ICountriesType } from "../../../../constants/Countries";
import Flags from "../../../../assets/Flags";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import CaretDownIcon from "../../../../assets/images/icons/caret-down-icon.svg";

type IPhoneCodesModal = {
  phoneParams: ICountriesType;
  onClickItem?: (item: ICountriesType) => void;
};

export const PhoneCodesModal: React.FC<IPhoneCodesModal> = ({
  phoneParams,
  onClickItem,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const sortedCountries = useMemo(
    () =>
      _.sortBy(Countries, (item) =>
        item.countryCode === "US" || item.countryCode === "UA"
          ? ""
          : t(item.id),
      ),
    [t],
  );

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClick = useCallback(
    (item: ICountriesType) => {
      handleClose();
      onClickItem && onClickItem(item);
    },
    [handleClose, onClickItem],
  );

  return (
    <>
      <IconButton onClick={handleOpen}>
        <img
          style={styles.countryFlag}
          src={Flags[phoneParams.iconName]}
          alt={Flags[phoneParams.iconName]}
        />
        <img src={CaretDownIcon} alt="CaretDownIcon" />
        <Typography sx={materialStyles.phoneCode}>
          {phoneParams.code}
        </Typography>
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: materialStyles.popoverContainer,
          },
        }}
      >
        <Grid sx={materialStyles.countriesItems}>
          {_.map(sortedCountries, (item) => (
            <ButtonBase
              key={item.id}
              sx={materialStyles.countriesItem}
              onClick={() => onClick(item)}
            >
              <img
                style={styles.countryFlag}
                src={Flags[item.iconName]}
                alt={Flags[item.iconName]}
              />
              <Typography sx={materialStyles.countriesItemTitle}>
                {t(item.id)} <span id="phone-code">{item.code}</span>
              </Typography>
            </ButtonBase>
          ))}
        </Grid>
      </Popover>
    </>
  );
};
