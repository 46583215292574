import { Colors, theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  createAlbumButton: {
    color: Colors.white,
    borderRadius: "28px",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
};
