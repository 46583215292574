import { createSlice } from "@reduxjs/toolkit";

interface INewAlbumliceInitialState {
  title: string | null;
  description: string | null;
  imageIds: number[];
}

const newAlbumSliceInitialState: INewAlbumliceInitialState = {
  title: null,
  description: null,
  imageIds: [],
};

const newAlbumSlice = createSlice({
  name: "newAlbum",
  initialState: newAlbumSliceInitialState,
  reducers: {
    setAlbumTitle(state, action) {
      state.title = action.payload;
    },
    setAlbumDescription(state, action) {
      state.description = action.payload;
    },
    setAlbumImageIds(state, action) {
      state.imageIds = action.payload;
    },
    resetNewAlbum(state) {
      state.title = newAlbumSliceInitialState.title;
      state.description = newAlbumSliceInitialState.description;
      state.imageIds = newAlbumSliceInitialState.imageIds;
    },
  },
});

export default newAlbumSlice.reducer;
export const {
  setAlbumTitle,
  setAlbumDescription,
  setAlbumImageIds,
  resetNewAlbum,
} = newAlbumSlice.actions;
