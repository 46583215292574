import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";
import { withHexOpacity } from "../../../../utils";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0 16px",
    padding: "12px",
    borderRadius: "6px",
  },
  itemActiveContainer: {
    backgroundColor: withHexOpacity(theme.palette.primary.main, 0.3),
  },
  itemContainerInfo: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    marginLeft: "16px",
    overflow: "hidden",
  },
  itemInnerContainerInfo: {
    overflow: "hidden",
  },
  itemUsername: {
    color: Colors.white,
    fontSize: "14px",
    lineHeight: "25px",
    fontWeight: "700",
    textAlign: "left",
  },
  itemLastMessage: {
    color: Colors.white,
    fontSize: "12px",
    lineHeight: "19px",
    fontWeight: "700",
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  itemLastMessageContainer: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  itemLastMessageTime: {
    color: withHexOpacity(Colors.white, 0.5),
    fontSize: "14px",
    lineHeight: "25px",
    fontWeight: "700",
  },
  chatIsNotReadIndicator: {
    width: "5px",
    aspectRatio: 1,
    borderRadius: "50%",
    backgroundColor: theme.palette.error.main,
  },
};
