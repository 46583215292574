import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { getPrivacyGroups } from "../store/thunks/groups";
import { PrivacyGroup, setGroups } from "../store/slices/groups";
import { useAuth } from "./useAuth";
import { sortedGroupsByType } from "../utils";

export const ALL_GROUP: PrivacyGroup = {
  id: 0,
  title: "All",
  is_custom: false,
  isAll: true,
  is_editable: false,
  type: 0,
};

export const usePrivacyGroups = () => {
  const dispatch = useAppDispatch();
  const { groups } = useAppSelector((state) => state.groups);
  const isAuth = useAuth();

  const fetchPrivacyGroups = useCallback(async () => {
    try {
      if (!isAuth) {
        return;
      }

      const data = await dispatch(getPrivacyGroups());

      if (!data) {
        throw new Error();
      }

      dispatch(setGroups(data));
    } catch (error: any) {
      console.error("Error while [fetchPrivacyGroups]", error);
    }
  }, [dispatch, isAuth]);

  useEffect(() => {
    fetchPrivacyGroups();
  }, [fetchPrivacyGroups]);

  return {
    groups: sortedGroupsByType(groups),
  };
};
