import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";

export const useSideMenuItems = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const isAuth = useAuth();

  const goToPhotos = useCallback(() => {
    navigate("/photos");
  }, [navigate]);

  const goToMap = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const goToFavorites = useCallback(() => {
    navigate("/favorites");
  }, [navigate]);

  const goToAlbums = useCallback(() => {
    navigate("/albums");
  }, [navigate]);

  const goToTrash = useCallback(() => {
    navigate("/trash");
  }, [navigate]);

  const goToExplore = useCallback(() => {
    navigate("/explore");
  }, [navigate]);

  const goToMessages = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const mainMenuItems = useMemo(
    () =>
      _.compact([
        {
          url: "/",
          title: t("common.map"),
          icon: "MapRoundedIcon",
          onClick: goToMap,
        },
        {
          url: "/explore",
          title: t("common.explore"),
          icon: "SearchRoundedIcon",
          onClick: goToExplore,
        },
        isAuth && {
          url: "/messages",
          title: t("common.messages"),
          icon: "ForumOutlinedIcon",
          onClick: goToMessages,
        },
      ]),
    [goToExplore, goToMap, goToMessages, isAuth, t],
  );

  const libraryMenuItems = useMemo(
    () => [
      {
        url: "/photos",
        title: t("common.photos"),
        icon: "PhotoLibraryRoundedIcon",
        onClick: goToPhotos,
      },
      {
        url: "/favorites",
        title: t("common.favorites"),
        icon: "FavoriteOutlinedIcon",
        onClick: goToFavorites,
      },
      {
        url: "/albums",
        title: t("common.albums"),
        icon: "PhotoAlbumIcon",
        onClick: goToAlbums,
      },
      {
        url: "/trash",
        title: t("common.trash"),
        icon: "DeleteOutlineOutlinedIcon",
        onClick: goToTrash,
      },
    ],
    [goToAlbums, goToFavorites, goToPhotos, goToTrash, t],
  );

  return {
    mainMenuItems,
    libraryMenuItems,
  };
};
