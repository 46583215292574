import { Colors, theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  tabContainer: {
    color: theme.palette.grey[700],
    textTransform: "none",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    flex: 1,
    maxWidth: "none",

    borderTop: `1px solid ${theme.palette.grey[600]}`,
    borderBottom: `1px solid ${theme.palette.grey[600]}`,

    "&.Mui-selected": {
      fontWeight: "600",

      "& #counter": {
        color: Colors.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  tabCounter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    p: "2px",
    borderRadius: "16px",
    backgroundColor: theme.palette.grey[700],

    color: theme.palette.background.default,

    aspectRatio: 1,

    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabPanelContainer: {
    padding: 0,
  },
  tabList: {
    marginTop: "24px",
  },
};
