import { createSlice } from "@reduxjs/toolkit";

interface ISelectAvatarSliceInitialState {
  url: string | null;
  photoId: number | null;
}

const selectAvatarSliceInitialState: ISelectAvatarSliceInitialState = {
  url: null,
  photoId: null,
};

const selectAvatarSlice = createSlice({
  name: "selectAvatar",
  initialState: selectAvatarSliceInitialState,
  reducers: {
    setPhotoId(state, action) {
      state.photoId = action.payload;
    },
    setPhotoUrl(state, action) {
      state.url = action.payload;
    },
    resetSelectAvatarProps(state) {
      state.url = selectAvatarSliceInitialState.url;
      state.photoId = selectAvatarSliceInitialState.photoId;
    },
  },
});

export default selectAvatarSlice.reducer;
export const { setPhotoUrl, setPhotoId, resetSelectAvatarProps } =
  selectAvatarSlice.actions;
