import { AppDispatch, RootState } from "..";
import axios from "axios";
import { API_URL } from "../api";
import { setLoading } from "../slices/loading";
import { setError } from "../slices/errors";
import { getResponseFromAxiosError } from "../../utils";
import { checkOrRefreshToken } from "./user";
import _ from "lodash";
import { DEFAULT_PAGE_SIZE } from "../../constants/api";

export const getMySubscriptions = (page?: number, pageSize?: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/subscriptions`, {
        params: {
          page,
          limit: pageSize,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return {
        list: data.list,
        totalElements: data.count,
      };
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [getMySubscriptions]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getMySubscribers = (page?: number, pageSize?: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/subscriptions/subscribers`, {
        params: {
          page,
          limit: pageSize || DEFAULT_PAGE_SIZE,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return {
        list: data.list,
        totalElements: data.count,
      };
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getMySubscribers]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getPeoplesWithPagination = (page?: number, pageSize?: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(
        `${API_URL}/search/people`,

        {
          params: {
            page,
            limit: pageSize || DEFAULT_PAGE_SIZE,
          },
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        }
      );

      if (!data) {
        throw new Error();
      }

      return {
        list: data.list,
        totalElements: data.count,
      };
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [getPeoples]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getPeoples = (search?: string, isUseLoading?: boolean) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (isUseLoading) dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(
        `${API_URL}/search/people?q=${_.trim(search)}&limit=500`,
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        }
      );

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getPeoples]", error);
    } finally {
      if (isUseLoading) dispatch(setLoading(false));
    }
  };
};

export const subscribeOnUser = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/subscriptions/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        }
      );

      if (!data) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [subscribeOnUser]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const unsubscribeOnUser = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.delete(`${API_URL}/subscriptions/${id}`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [unsubscribeOnUser]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
