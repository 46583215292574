import { Button, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import { getFileExtFromDroppedFile } from "../../../../utils";
import UploadCloud from "../../../../assets/images/icons/upload-cloud.svg";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { ISelectAvatarPhotoModalType } from "..";
import heic2any from "heic2any";
import { setLoading } from "../../../../store/slices/loading";
import { setPhotoUrl } from "../../../../store/slices/selectAvatar";

type IUploadNewFileForm = {
  onChangeSelectModalType: (type: ISelectAvatarPhotoModalType) => void;
};

export const UploadNewAvatarForm: React.FC<IUploadNewFileForm> = ({
  onChangeSelectModalType,
}) => {
  const { t } = useTranslation();

  const { url } = useAppSelector((state) => state.selectAvatar);
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const droppedFile = _.first(acceptedFiles);

        if (!droppedFile) {
          return;
        }

        dispatch(setLoading(true));

        const ext = getFileExtFromDroppedFile(droppedFile);

        let fileUrl;

        if (_.includes(["heic", "heif"], ext)) {
          const outputBlob = (await heic2any({
            blob: droppedFile,
            toType: "image/jpeg",
            quality: 1,
          })) as Blob;
          fileUrl = URL.createObjectURL(outputBlob);
        } else {
          fileUrl = URL.createObjectURL(droppedFile);
        }

        dispatch(setPhotoUrl(fileUrl));
      } catch (error) {
        console.error("Error while [onDrop]", error);
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/heic",
      "image/jfif",
    ],
  });

  useEffect(() => {
    if (url) {
      onChangeSelectModalType("SELECT_NEW_AVATAR");
    }
  }, [url, onChangeSelectModalType]);

  return (
    <Grid sx={materialStyles.innerUploadModalBox}>
      <Grid sx={materialStyles.uploadPhotoContent}>
        <Grid sx={materialStyles.uploadPhotoDropZone} {...getRootProps()}>
          <input {...getInputProps()} />
          <img src={UploadCloud} alt="upload-icon" />
          <Typography sx={materialStyles.uploadPhotoSubtitle}>
            {t("edit_user.upload_new_avatar")}
          </Typography>
          <Button sx={materialStyles.uploadButton} variant="contained">
            {t("upload_photo.browse")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
