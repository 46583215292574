import axios from "axios";
import { AppDispatch, RootState } from "..";
import { setLoading } from "../slices/loading";
import { checkOrRefreshToken } from "./user";
import { API_URL } from "../api";
import { ERROR_STATUS_CODES, setError } from "../slices/errors";
import { getResponseFromAxiosError } from "../../utils";
import { ChatWithUserNotFoundError } from "../classes";

export const getChats = (page?: number, pageSize?: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/chats`, {
        params: {
          page,
          limit: pageSize,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getChats]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getChat = (userId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/chats/${userId}`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      const errorResponse = getResponseFromAxiosError(error);

      if (errorResponse.statusCode === ERROR_STATUS_CODES.NOT_FOUND) {
        throw new ChatWithUserNotFoundError();
      }

      dispatch(setError(errorResponse));
      console.error("Error while [getChat]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
