import React, { useCallback } from "react";
import { IUserComplaintModalType } from "..";
import { Grid, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import {
  IComplaintReasonType,
  useComplaintReasons,
} from "../../../../hooks/useComplaintReasons";
import _ from "lodash";
import { ButtonWithIcon } from "../../../buttons/ButtonWithIcon";
import { useAppDispatch } from "../../../../store";
import { setMessage } from "../../../../store/slices/complaint";

type IReasonsComplaintContent = {
  onClose: () => void;
  onChangeComplaintModalType: (type: IUserComplaintModalType) => void;
  type: IComplaintReasonType;
};

export const ReasonsComplaintContent: React.FC<IReasonsComplaintContent> = ({
  onClose,
  onChangeComplaintModalType,
  type,
}) => {
  const { t } = useTranslation();
  const complaintsReason = useComplaintReasons(type);
  const dispatch = useAppDispatch();

  const onGoToConfrmScreen = useCallback(
    (message: string) => {
      dispatch(setMessage(message));
      onChangeComplaintModalType("CONFIRM_REPORT_FORM");
    },
    [dispatch, onChangeComplaintModalType]
  );

  return (
    <Grid sx={materialStyles.innerModal}>
      <Typography sx={materialStyles.modalHeaderTitle}>
        {t("complaint.report")}
      </Typography>
      <Typography sx={materialStyles.headerSubtitle}>
        {t("complaint.why_are_you_reporting_this_user")}
      </Typography>
      <Grid sx={materialStyles.reasonsBlock}>
        {_.map(complaintsReason, (reason) => (
          <ButtonWithIcon
            key={reason.id}
            title={reason.message}
            actionType="ARROW"
            onClick={() => onGoToConfrmScreen(reason.message)}
          />
        ))}
      </Grid>
    </Grid>
  );
};
