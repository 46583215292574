import { theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  groupsListContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    overflow: "auto",
  },
  groupsListTitle: {
    mb: 1.5,
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[700],
  },
};
