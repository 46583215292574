import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";
import moment from "moment";
import CustomCheckboxIcon from "../../../../assets/images/icons/custom-checkbox.svg";
import CustomActiveCheckboxIcon from "../../../../assets/images/icons/custom-checkbox-active.svg";

type IAppImageListDateHeader = {
  date: string;
  enableSelection?: boolean;
  onSelectedAllImageInDate?: (date: string) => void;
  isSelected?: boolean;
};

export const AppImageListDateHeader: React.FC<IAppImageListDateHeader> = ({
  date,
  enableSelection = false,
  onSelectedAllImageInDate,
  isSelected,
}) => {
  return (
    <Grid sx={materialStyles.imageListDateContainer}>
      {enableSelection ? (
        <IconButton
          onClick={() =>
            onSelectedAllImageInDate && onSelectedAllImageInDate(date)
          }
          sx={materialStyles.checkBoxButtonInDateContainer}
        >
          {!isSelected ? (
            <img src={CustomCheckboxIcon} alt="CustomCheckboxIcon" />
          ) : (
            <img
              src={CustomActiveCheckboxIcon}
              alt="CustomActiveCheckboxIcon"
            />
          )}
        </IconButton>
      ) : null}
      <Typography sx={materialStyles.imageListDate}>
        {moment(date).format("dddd, D MMMM")}
      </Typography>
    </Grid>
  );
};
