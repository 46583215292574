import { createSlice } from "@reduxjs/toolkit";
import { IPhotoType } from "./myPhotos";
import _ from "lodash";

interface ISliderSliceInitialState {
  initialMedia: IPhotoType | null;
  initialMediaIndex: number;
  isLastInitialMedia: boolean;
  isFirstInitialMedia: boolean;
  media: IPhotoType[];
}

const sliderSliceInitialState: ISliderSliceInitialState = {
  initialMedia: null,
  initialMediaIndex: 0,
  isLastInitialMedia: false,
  isFirstInitialMedia: false,
  media: [],
};

const sliderSlice = createSlice({
  name: "slider",
  initialState: sliderSliceInitialState,
  reducers: {
    initMediaSlider(state, action) {
      const initialMedia = action.payload.initialMedia;
      const media = action.payload.media;
      const initialMediaIndex = _.findIndex(media, { id: initialMedia.id });
      const isLastInitialMedia = initialMediaIndex === _.size(media) - 1;
      const isFirstInitialMedia = initialMediaIndex === 0;

      state.initialMedia = initialMedia;
      state.media = media;
      state.initialMediaIndex = initialMediaIndex;
      state.isLastInitialMedia = isLastInitialMedia;
      state.isFirstInitialMedia = isFirstInitialMedia;
    },
    resetMediaSlider(state) {
      state.initialMedia = sliderSliceInitialState.initialMedia;
      state.media = sliderSliceInitialState.media;
      state.initialMediaIndex = sliderSliceInitialState.initialMediaIndex;
      state.isLastInitialMedia = sliderSliceInitialState.isLastInitialMedia;
      state.isFirstInitialMedia = sliderSliceInitialState.isFirstInitialMedia;
    },
    removeMediaFromSlider(state, action) {
      const filteredMedia = _.filter(
        state.media,
        (media) => media.id !== action.payload,
      );

      state.media = filteredMedia;
    },
  },
});

export default sliderSlice.reducer;
export const { initMediaSlider, resetMediaSlider, removeMediaFromSlider } =
  sliderSlice.actions;
