import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ISettingsContentTypes } from "../pages/UserSettings";

type ISettingsItems = {
  type: ISettingsContentTypes;
  title: string;
};

export const useSettingsItems = () => {
  const { t } = useTranslation();

  const items: ISettingsItems[] = useMemo(
    () => [
      {
        type: "ACCOUNT_INFORMATION",
        title: t("settings.account_information"),
      },
      {
        type: "PRIVACY_GROUPS",
        title: t("settings.privacy_groups"),
      },
      {
        type: "SUPPORT",
        title: t("settings.support"),
      },
    ],
    [t],
  );

  return { items };
};
