import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {
  successIcon: {
    marginBottom: "24px",
  },
};

export const materialStyles: IMaterialStyleType = {
  innerModal: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "19px",
  },
  modalHeaderTitle: {
    color: Colors.white,
    fontSize: "20px",
    lineHeight: "22px",
    fontWeight: 700,
    marginBottom: "24px",
  },
  confirmModalHeaderTitle: {
    color: Colors.white,
    fontSize: "20px",
    lineHeight: "22px",
    fontWeight: 700,
    marginBottom: "24px",
    textAlign: "center",
  },
  headerSubtitle: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[700],
    fontWeight: 700,
  },
  reasonsBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    marginTop: "14px",
  },
  confirmContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  confirmButton: {
    height: "48px",
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",
    marginTop: "24px",

    width: "100%",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
  cancelButton: {
    textTransform: "none",
    marginTop: "8px",
  },
  closeButton: {
    height: "48px",
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",
    marginTop: "24px",

    width: {
      xs: `auto`,
      sm: `auto`,
      md: "420px",
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
};
