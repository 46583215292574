import React, { useCallback, useContext, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { FiltersWrapper } from "./FiltersWrapper";
import { Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { materialStyles } from "./styles";
import { TagsList } from "../../lists/tagsList";
import { useFilters } from "../../../hooks/useFilters";
import {
  resetMapPhotoFilters,
  setMapPhotosFilters,
} from "../../../store/slices/map";
import { AppAutocomplete } from "../../inputs/AppAutocomplete";
import {
  useSearchPeoples,
  DISPLAY_USERS_FROM_LENGTH,
} from "../../../hooks/useSearchPeoples";
import { FiltersAutocompleteInput } from "../../inputs/FiltersAutocompleteInput";
import { UserAutocompleteOption } from "../../items/UserAutocompleteOption";
import { AvatarsList } from "../../lists/avatarsList";
import { useSearchTags } from "../../../hooks/useSearchTags";
import { AutocompleteOptionWithTitle } from "../../items/AutocompleteOptionWithTitle";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import { DatePickerInput } from "../../inputs/DatePickerInput";
import moment from "moment";
import { CommonModalsDispatchContext } from "../../providers/CommonModalsProvider";
import { setUserId } from "../../../store/slices/userProfile";

type IGlobeFiltersForm = {
  onClose: () => void;
  isOpen: boolean;
};

export const MapFiltersForm: React.FC<IGlobeFiltersForm> = ({
  onClose,
  isOpen,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { filters } = useAppSelector((state) => state.map);

  const dispatchCommonModals = useContext(CommonModalsDispatchContext);

  const { localFilters, onChangeFilters, onResetFilter } = useFilters(filters);

  const [peoplesSearch, setPeoplseSearch] = useState("");
  const [tagsSearch, setTagsSearch] = useState("");

  const isDisabledFilters = useMemo(
    () =>
      _.isEmpty(filters.peoples) &&
      _.isEmpty(filters.tags) &&
      !filters.created_from &&
      !filters.created_to,
    [filters.created_from, filters.created_to, filters.peoples, filters.tags],
  );

  const { findedUsers, loading, debouncedSearch } =
    useSearchPeoples(peoplesSearch);

  const {
    findedTags,
    loading: loadingSearchTags,
    debouncedSearch: debouncedTagsSearch,
  } = useSearchTags(tagsSearch);

  const onSubmit = useCallback(() => {
    if (!localFilters) {
      return;
    }
    dispatch(setMapPhotosFilters(localFilters));
    onClose();
  }, [dispatch, localFilters, onClose]);

  const onUserSearchChange = useCallback((event: any) => {
    const { value } = event.target;

    setPeoplseSearch(value);
  }, []);

  const onTagSearchChange = useCallback((event: any) => {
    const { value } = event.target;

    setTagsSearch(value);
  }, []);

  const onResetFilters = useCallback(() => {
    dispatch(resetMapPhotoFilters());
  }, [dispatch]);

  const onClickUserFromFilter = useCallback(
    (userId: number) => {
      dispatch(setUserId(userId));
      dispatchCommonModals && dispatchCommonModals({ type: "SHOW_USER_MODAL" });
    },
    [dispatch, dispatchCommonModals],
  );

  return (
    <FiltersWrapper
      onResetFilters={onResetFilters}
      onClose={onClose}
      onSubmit={onSubmit}
      disableReset={isDisabledFilters}
    >
      <Grid sx={materialStyles.filterBlock}>
        <Typography sx={materialStyles.filterTitle}>
          {t("filters.peoples")}
        </Typography>
        <AppAutocomplete
          getOptionLabel={(option) => option.username}
          noOptionsText={
            debouncedSearch.length >= DISPLAY_USERS_FROM_LENGTH
              ? t("filters.unable_find_user")
              : t("filters.to_search_enter_more_than")
          }
          options={findedUsers}
          loading={loading}
          sx={materialStyles.filtersAutocomplete}
          renderInput={(params) => (
            <FiltersAutocompleteInput
              {...params}
              placeholder={t("filters.enter_username")}
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
              }}
              onChange={onUserSearchChange}
            />
          )}
          renderOption={(props, option) => (
            <UserAutocompleteOption
              user={option}
              onOptionClick={() => onChangeFilters(option, "peoples")}
              {...props}
            />
          )}
        />
        <AvatarsList
          onClickUser={onClickUserFromFilter}
          users={localFilters.peoples}
        >
          {!_.isEmpty(localFilters.peoples) ? (
            <IconButton onClick={() => onResetFilter("peoples")}>
              <CloseIcon sx={materialStyles.clearUserFilterIcon} />
            </IconButton>
          ) : null}
        </AvatarsList>
      </Grid>
      <Grid sx={materialStyles.filterBlock}>
        <Typography sx={materialStyles.filterTitle}>
          {t("filters.photos.tags")}
        </Typography>
        <AppAutocomplete
          getOptionLabel={(option) => option.title}
          noOptionsText={
            debouncedTagsSearch.length >= DISPLAY_USERS_FROM_LENGTH
              ? t("filters.unable_find_tag")
              : t("filters.to_search_enter_more_than")
          }
          options={findedTags}
          loading={loadingSearchTags}
          sx={materialStyles.filtersAutocomplete}
          renderInput={(params) => (
            <FiltersAutocompleteInput
              {...params}
              placeholder={t("filters.enter_tag_name")}
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
              }}
              onChange={onTagSearchChange}
            />
          )}
          renderOption={(props, option) => (
            <AutocompleteOptionWithTitle
              option={option}
              onOptionClick={() => onChangeFilters(option, "tags")}
              {...props}
            />
          )}
        />
        <TagsList
          disabledCreate
          disableRipple
          tags={localFilters.tags}
          onRemoveTag={(tag) => onChangeFilters(tag, "tags")}
        />
      </Grid>
      <Grid sx={materialStyles.filterBlock}>
        <Grid sx={materialStyles.row}>
          <Grid sx={materialStyles.datePickerContainer}>
            <Typography sx={materialStyles.filterTitle}>
              {t("filters.created_from")}
            </Typography>
            <DatePickerInput
              sx={materialStyles.datePickerInput}
              onChange={(value) => onChangeFilters(value, "created_from")}
              disableFuture
              value={
                localFilters.created_from
                  ? moment(localFilters.created_from)
                  : null
              }
            />
          </Grid>
          <Grid sx={materialStyles.datePickerContainer}>
            <Typography sx={materialStyles.filterTitle}>
              {t("filters.created_to")}
            </Typography>
            <DatePickerInput
              sx={materialStyles.datePickerInput}
              onChange={(value) => onChangeFilters(value, "created_to")}
              disableFuture
              value={
                localFilters.created_to ? moment(localFilters.created_to) : null
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </FiltersWrapper>
  );
};
