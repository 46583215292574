import { materialStyles, styles } from "./styles";
import { Grid } from "@mui/material";

type IChangeAuthTypeBlock = {
  subText: string;
  actionText: string;
  onClick?: () => void;
};

export const ChangeAuthTypeBlock: React.FC<IChangeAuthTypeBlock> = ({
  subText,
  actionText,
  onClick,
}) => {
  return (
    <Grid sx={materialStyles.dontHaveBlock}>
      <p style={styles.dontHaveText}>{subText}</p>
      <p onClick={onClick} style={styles.signUpText}>
        {actionText}
      </p>
    </Grid>
  );
};
