import React, { useCallback } from "react";
import { IUserEditModalType } from "..";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { styles } from "../styles";
import { useFormik } from "formik";
import _ from "lodash";
import { useAppDispatch } from "../../../../store";
import { setNewUserEmail } from "../../../../store/slices/userEdit";
import { validateEmail } from "../../../../utils/validate";
import { useToast } from "rc-toastr";

type IEmailEditForm = {
  onClose: () => void;
  onChangeModalType: (type: IUserEditModalType) => void;
};

type IEmailEditFormValues = {
  email: string;
};

export const EmailEditForm: React.FC<IEmailEditForm> = ({
  onClose,
  onChangeModalType,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { toast } = useToast();

  const onSubmit = useCallback(
    (details: IEmailEditFormValues) => {
      const isValidEmail = validateEmail(details.email);
      if (!isValidEmail) {
        toast.error(t("edit_user.email_error"));
        return;
      }
      dispatch(setNewUserEmail(details.email));
      onChangeModalType("CONFIRM_MODAL");
    },
    [dispatch, onChangeModalType, t, toast]
  );

  const { values, errors, handleChange, handleSubmit, isValid } = useFormik({
    onSubmit,
    initialValues: { email: "" },
    validate: (values) => {
      return _.pickBy({
        email: !_.trim(values.email) || !validateEmail(values.email),
      });
    },
  });

  return (
    <Grid sx={materialStyles.innerModalContainer}>
      <Typography sx={materialStyles.editModalTitle}>
        {t("edit_user.edit_email")}
      </Typography>
      <form onSubmit={handleSubmit} style={styles.formContainer}>
        <TextField
          name="email"
          error={Boolean(errors.email)}
          value={values.email}
          onChange={handleChange}
          sx={materialStyles.editInput}
          fullWidth
          label={t("edit_user.new_email")}
          placeholder={t("edit_user.enter_new_email")}
        />
        <Button
          disabled={!isValid}
          variant="contained"
          sx={materialStyles.button}
          type="submit"
        >
          {t("edit.edit")}
        </Button>
      </form>
    </Grid>
  );
};
