import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  List,
  ListItemButton,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles } from "./styles";
import { useSideMenuItems } from "../../hooks/useSideMenuItems";
import _ from "lodash";
import PhotoLibraryRoundedIcon from "@mui/icons-material/PhotoLibraryRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import MapRoundedIcon from "@mui/icons-material/MapRounded";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import PhotoAlbumIcon from "@mui/icons-material/PhotoAlbum";
import MoveToInboxOutlinedIcon from "@mui/icons-material/MoveToInboxOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "react-i18next";
import { ServerInfo } from "./components/ServerInfo";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store";
import { StorageInfo } from "./components/StorageInfo";
import { useAuth } from "../../hooks/useAuth";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";

type ISideMenuType = {};

const SUPPORT_EMAIL = "support@wozere.me";

export const SideMenu: React.FC<ISideMenuType> = () => {
  const { mainMenuItems, libraryMenuItems } = useSideMenuItems();
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.user);

  const location = useLocation();

  const isAuth = useAuth();

  const menuIcon = useCallback((iconName: string) => {
    switch (iconName) {
      case "PhotoLibraryRoundedIcon":
        return <PhotoLibraryRoundedIcon sx={materialStyles.sideMenuIcon} />;
      case "SearchRoundedIcon":
        return <SearchRoundedIcon sx={materialStyles.sideMenuIcon} />;
      case "MapRoundedIcon":
        return <MapRoundedIcon sx={materialStyles.sideMenuIcon} />;
      case "ForumOutlinedIcon":
        return <ForumOutlinedIcon sx={materialStyles.sideMenuIcon} />;
      case "PeopleOutlineRoundedIcon":
        return <PeopleOutlineRoundedIcon sx={materialStyles.sideMenuIcon} />;
      case "FavoriteOutlinedIcon":
        return <FavoriteOutlinedIcon sx={materialStyles.sideMenuIcon} />;
      case "PhotoAlbumIcon":
        return <PhotoAlbumIcon sx={materialStyles.sideMenuIcon} />;
      case "MoveToInboxOutlinedIcon":
        return <MoveToInboxOutlinedIcon sx={materialStyles.sideMenuIcon} />;
      case "DeleteOutlineOutlinedIcon":
        return <DeleteOutlineOutlinedIcon sx={materialStyles.sideMenuIcon} />;
      default:
        return null;
    }
  }, []);

  const goToSupport = useCallback(() => {
    window.open(`mailto:${SUPPORT_EMAIL}`);
  }, []);

  return (
    <Grid sx={materialStyles.sideMenuContainer}>
      <Grid sx={materialStyles.sideMenuItemsContainer}>
        <List sx={materialStyles.sideMenuMainList}>
          {_.map(mainMenuItems, (menuItem) => (
            <ListItemButton
              key={menuItem.title}
              onClick={menuItem.onClick}
              sx={[
                materialStyles.sideMenuButton,
                menuItem.url === location.pathname &&
                  materialStyles.sideMenuActiveButton,
              ]}
            >
              {menuIcon(menuItem.icon)}
              <Typography sx={materialStyles.sideMenuTitle}>
                {menuItem.title}
              </Typography>
            </ListItemButton>
          ))}
        </List>
        {user ? (
          <Typography sx={materialStyles.sideMenuSubTitle}>
            {t("common.library")}
          </Typography>
        ) : null}
        {user ? (
          <List>
            {_.map(libraryMenuItems, (menuItem) => (
              <ListItemButton
                key={menuItem.title}
                onClick={menuItem.onClick}
                sx={[
                  materialStyles.sideMenuButton,
                  menuItem.url === location.pathname &&
                    materialStyles.sideMenuActiveButton,
                ]}
              >
                {menuIcon(menuItem.icon)}
                <Typography sx={materialStyles.sideMenuTitle}>
                  {menuItem.title}
                </Typography>
              </ListItemButton>
            ))}
          </List>
        ) : null}
      </Grid>
      {isAuth ? (
        <>
          <StorageInfo />
          <Divider sx={materialStyles.divider} />
          <ServerInfo />
        </>
      ) : null}
      {!isAuth ? (
        <Box sx={materialStyles.supportContainer}>
          <Typography sx={materialStyles.supportTitle}>
            {t("common.to_contact_support")}
          </Typography>
          <ButtonBase onClick={goToSupport}>
            <Typography sx={materialStyles.supportEmail}>
              {SUPPORT_EMAIL}
            </Typography>
          </ButtonBase>
        </Box>
      ) : null}
    </Grid>
  );
};
