import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  blockTitle: {
    fontSize: "12px",
    fontWeight: "400",
    color: theme.palette.grey[900],
  },
  blockTextContent: {
    fontSize: "16px",
    lineHeight: "24px",
    color: Colors.white,
    marginTop: "6px",
    wordBreak: "break-all",
  },
  itemsDivider: {
    borderColor: theme.palette.grey[700],
  },
};
