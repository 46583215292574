import { Grid, Typography, Switch, ButtonBase } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";
import _ from "lodash";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "../../../assets/images/icons/edit-icon.svg";

type IBlockWithTitleItem = {
  type?: "ARROW" | "EDIT" | "SWITCHER" | "DEFAULT";
  title: string;
  subtitle?: string;
  value?: string;
  onClick?: () => void;
};

export const BlockWithTitleItem: React.FC<IBlockWithTitleItem> = ({
  type = "DEFAULT",
  title,
  subtitle,
  value,
  onClick,
}) => {
  return (
    <ButtonBase
      disableRipple={_.includes(["SWITCHER", "DEFAULT"], type)}
      disabled={_.includes(["SWITCHER", "DEFAULT"], type)}
      onClick={onClick}
      sx={materialStyles.blockItem}
    >
      <Grid>
        <Typography sx={materialStyles.blockItemTitle}>{title}</Typography>
        {subtitle ? (
          <Typography sx={materialStyles.blockItemSubtitle}>
            {subtitle}
          </Typography>
        ) : null}
      </Grid>
      {_.includes(["ARROW", "EDIT"], type) ? (
        <>
          {type === "ARROW" ? (
            <ArrowForwardIosIcon sx={materialStyles.arrowIcon} />
          ) : null}
          {type === "EDIT" ? <img src={EditIcon} alt="edit button" /> : null}
        </>
      ) : null}
      {type === "SWITCHER" ? (
        <Switch sx={materialStyles.blockItemSwitcher} value={value} />
      ) : null}
    </ButtonBase>
  );
};
