import React, { useCallback, useEffect } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { materialStyles } from "./styles";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setVisualMode } from "../../../store/slices/visualMode";

type IChangeVisualModeButtons = {};

export const ChangeVisualModeButtons: React.FC<
  IChangeVisualModeButtons
> = () => {
  const { t } = useTranslation();
  const { mode } = useAppSelector((state) => state.visualMode);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const defaultVisualMode = localStorage.getItem("visualMode");

    if (defaultVisualMode) {
      dispatch(setVisualMode(defaultVisualMode));
    }
  }, [dispatch]);

  const onChangeMode = useCallback(
    (event: any) => {
      const { value } = event.target;

      if (!value) {
        return;
      }

      localStorage.setItem("visualMode", value);

      dispatch(setVisualMode(value));
    },
    [dispatch],
  );

  return (
    <ToggleButtonGroup
      onClick={onChangeMode}
      value={mode}
      sx={materialStyles.toggleButtonGroup}
    >
      <ToggleButton sx={materialStyles.toggleButton} value="GRID">
        {t("header.grid")}
      </ToggleButton>
      <ToggleButton sx={materialStyles.toggleButton} value="GLOBE">
        {t("header.sphere")}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
