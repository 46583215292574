import {
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Slide,
  styled,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { MediaType, removePhoto } from "../../../store/slices/myPhotos";
import { materialStyles } from "./styles";
import { SlideItem } from "./components/SlideItem";
import { SLIDE_SPEED, useImageSlider } from "../../../hooks/useImageSlider";
import LeftIcon from "../../../assets/images/icons/chevron-left.svg";
import RightIcon from "../../../assets/images/icons/chevron-right.svg";
import { Image } from "../../images";
import { FullSizeModalHeader } from "../../header/FullSizeModalHeader";
import { useAuth } from "../../../hooks/useAuth";
import { FavoritesButton } from "../../buttons/FavoritesButton";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import { AdditionalMenuButton } from "../../buttons/AdditionalMenuButton";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import { ShareModal } from "../shareModal";
import { ComplaintModal } from "../complaintModal";
import { EditModal } from "../editModal";
import { SelectAvatarPhotoModal } from "../selectAvatarPhotoModal";
import { useTranslation } from "react-i18next";
import { setPhotoId, setPhotoUrl } from "../../../store/slices/selectAvatar";
import { setActionId } from "../../../store/slices/complaint";
import { setShareActionId } from "../../../store/slices/sharing";
import { setActionId as setEditActionId } from "../../../store/slices/edit";
import {
  changeMediaAccess,
  removeMediaById,
  removeSharingMediaByGroupId,
  removeSharingMediaByGroupIds,
  sharingMediaByGroupId,
} from "../../../store/thunks/media";
import { removePhotoFromAlbum } from "../../../store/slices/userAlbum";
import { PhotoInfoDrawer } from "./components/PhotoInfoDrawer";
import { PhotoCommentsDrawer } from "./components/PhotoCommentsDrawer";
import {
  addFavoritesPhoto,
  removeFavoritesPhoto,
} from "../../../store/thunks/favorites";
import {
  addSharingGroup,
  changeMediaSharingAccess,
  removeSharingGroup,
  resetUserPhoto,
  setIsFavorites,
} from "../../../store/slices/userPhoto";
import { useLocalProccessSocketEmotions } from "../../../hooks/useLocalProccessSocketEmotions";
import { useUserPhoto } from "../../../hooks/useUserPhoto";
import { useSwipeEffect } from "../../../hooks/useSwipeEffect";
import { IEmotionsKeys } from "../../../hooks/useEmotions";
import DownloadIcon from "@mui/icons-material/Download";
import { Picture } from "./components/Picture";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { ALL_GROUP, usePrivacyGroups } from "../../../hooks/usePrivacyGroups";
import { GroupsList } from "../../lists/groupsList";
import { PrivacyGroup } from "../../../store/slices/groups";
import { removePhotoFromMap } from "../../../store/slices/map";
import {
  removePhotoFromExplore,
  removeSubscription,
  setSubscription,
} from "../../../store/slices/explore";
import { UserAvatarWithActions } from "../../user/UserAvatarWithActions";
import {
  subscribeOnUser,
  unsubscribeOnUser,
} from "../../../store/thunks/explore";
import { IUserType } from "../../../store/slices/user";
import { Video } from "../../common/Video";
import CloseIcon from "@mui/icons-material/Close";
import {
  removeMediaFromSlider,
  resetMediaSlider,
} from "../../../store/slices/slider";
import { ConfirmModal } from "../confirmModal";
import { YouTubeIFrame } from "../../common/YouTubeIFrame";

type ImageSlidesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const DivForSwipe = styled("div")({});

export interface IImageSlidesModalForwardRef {
  showPhotoComments: () => void;
  showPhotoInfo: () => void;
}

export const ImageSlidesModal = forwardRef<
  IImageSlidesModalForwardRef,
  React.PropsWithChildren<ImageSlidesModalProps>
>(({ isOpen, onClose: onCloseFromProps }, ref) => {
  const { t } = useTranslation();

  const { media } = useAppSelector((state) => state.slider);

  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);
  const { album } = useAppSelector((state) => state.userAlbum);
  const { photo, sharingGroups } = useAppSelector((state) => state.userPhoto);

  const { groups } = usePrivacyGroups();

  const { slidesState, onArrowClick, onLoadImage, dispatchSlides } =
    useImageSlider();

  const { getUserPhoto, isLoading } = useUserPhoto();

  const {
    ref: swipeRef,
    handleTouchEnd,
    handleTouchStart,
  } = useSwipeEffect({
    onSwipeLeft: () => onArrowClick("left"),
    onSwipeRight: () => onArrowClick("right"),
  });

  const isAuth = useAuth();

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isComplaintModalOpen, setIsComplaintOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSelectAvatarModalOpen, setIsSelectAvatarModalOpen] = useState(false);
  const [isRemoveConfirmModalOpen, setIsRemoveConfirmModalOpen] =
    useState(false);

  const [isOpenPhotoInfo, setIsOpenPhotoInfo] = useState(false);
  const [isOpenPhotoComments, setIsOpenPhotoComments] = useState(false);

  const selectedGroups = useMemo(
    () => _.compact([Boolean(photo?.is_public) && ALL_GROUP, ...sharingGroups]),
    [photo?.is_public, sharingGroups],
  );

  const { localEmotions, onSendEmotion } = useLocalProccessSocketEmotions({
    id: _.get(photo, "id", null),
    emotions: _.get(photo, "emotions", undefined),
    emotionsOwn: _.chain(photo)
      .get("emotionsOwn")
      .first()
      .value() as IEmotionsKeys,
  });

  const isMyPhoto = useMemo(
    () => isAuth && user?.id === _.get(slidesState, "photo.user.id", 0),
    [isAuth, user?.id, slidesState],
  );

  const isShowArrows = useMemo(() => _.size(media) !== 1, [media]);

  const onOpenInfoPhoto = useCallback(() => {
    setIsOpenPhotoInfo(true);
  }, []);

  const onCloseInfoPhoto = useCallback(() => {
    setIsOpenPhotoInfo(false);
  }, []);

  const onOpenPhotoComments = useCallback(() => {
    setIsOpenPhotoComments(true);
  }, []);

  const onClosePhotoComments = useCallback(() => {
    setIsOpenPhotoComments(false);
  }, []);

  useImperativeHandle(ref, () => ({
    showPhotoComments: onOpenPhotoComments,
    showPhotoInfo: onOpenInfoPhoto,
  }));

  const onComplaintModalClose = useCallback(() => {
    setIsComplaintOpen(false);
  }, []);

  const onShareModalClose = useCallback(() => {
    setIsShareModalOpen(false);
  }, []);

  const onCloseEditModal = useCallback(() => {
    setIsEditModalOpen(false);
  }, []);

  const onCloseSelectAvatarModal = useCallback(() => {
    setIsSelectAvatarModalOpen(false);
  }, []);

  const onOpenSelectAvatarModal = useCallback(() => {
    if (!slidesState.photo) {
      return;
    }

    const photo = slidesState.photo;

    dispatch(setPhotoId(photo.id));
    dispatch(setPhotoUrl(photo.preview));
    setIsSelectAvatarModalOpen(true);
  }, [dispatch, slidesState.photo]);

  const onCompaintModalOpen = useCallback(() => {
    if (!slidesState.photo) {
      return;
    }

    const photo = slidesState.photo;

    dispatch(setActionId(photo.id));
    setIsComplaintOpen(true);
  }, [dispatch, slidesState.photo]);

  const onShareModalOpen = useCallback(() => {
    if (!slidesState.photo) {
      return;
    }

    const photo = slidesState.photo;

    dispatch(setShareActionId(photo.id));
    setIsShareModalOpen(true);
  }, [dispatch, slidesState.photo]);

  const onOpenEditModal = useCallback(() => {
    if (!slidesState.photo) {
      return;
    }

    const photo = slidesState.photo;

    dispatch(setEditActionId(photo.id));
    setIsEditModalOpen(true);
  }, [dispatch, slidesState.photo]);

  const onClose = useCallback(() => {
    onCloseFromProps && onCloseFromProps();
    onClosePhotoComments();
    onCloseInfoPhoto();
    dispatch(resetUserPhoto());
    dispatch(resetMediaSlider());
  }, [dispatch, onCloseFromProps, onCloseInfoPhoto, onClosePhotoComments]);

  const onRemovePhoto = useCallback(async () => {
    try {
      if (!slidesState.photo) {
        return;
      }

      const photo = slidesState.photo;
      await dispatch(removeMediaById(photo.id));

      dispatch(removePhoto(photo.id));
      dispatch(removePhotoFromMap(photo.id));
      dispatch(removePhotoFromExplore(photo.id));

      if (album) {
        dispatch(removePhotoFromAlbum(photo.id));
      }

      dispatch(removeMediaFromSlider(photo.id));

      if (slidesState.slideLeftDisable && slidesState.slideRightDisable) {
        onClose();
        return;
      }

      if (slidesState.slideRightDisable) {
        onArrowClick("left");
        return;
      }

      onArrowClick("right", true);
    } catch (error) {
      console.error("Error while [onRemovePhoto]", error);
    }
  }, [
    album,
    dispatch,
    onArrowClick,
    onClose,
    slidesState.photo,
    slidesState.slideLeftDisable,
    slidesState.slideRightDisable,
  ]);

  const openOriginalPhoto = useCallback(() => {
    if (!slidesState.photo) {
      return;
    }

    if (slidesState.photo.type === MediaType.VIDEO) {
      if (_.get(photo, "files[0].hd")) {
        window.open(_.get(photo, "files[0].hd"));
        return;
      }
      return;
    }
    window.open(
      photo?.original || slidesState.photo.full || slidesState.photo.preview,
    );
  }, [photo, slidesState.photo]);

  const onOpenConfirmRemoveModal = useCallback(() => {
    setIsRemoveConfirmModalOpen(true);
  }, []);
  const onCloseConfirmRemoveModal = useCallback(() => {
    setIsRemoveConfirmModalOpen(false);
  }, []);

  const menuActions = useMemo(
    () =>
      _.compact([
        isMyPhoto &&
          slidesState.photo?.type === MediaType.PHOTO && {
            title: t("photos.select_avatar"),
            onPress: onOpenSelectAvatarModal,
          },
        !isMyPhoto &&
          isAuth && {
            title: t("photos.complaint_photo"),
            onPress: onCompaintModalOpen,
          },
        {
          title: t("photos.share_media"),
          onPress: onShareModalOpen,
        },
        isMyPhoto && {
          title: t("photos.edit_media"),
          onPress: onOpenEditModal,
        },
        isMyPhoto && {
          title: t("photos.remove_media"),
          onPress: onOpenConfirmRemoveModal,
        },
        {
          title: t("photos.download_media"),
          onPress: openOriginalPhoto,
        },
      ]),
    [
      isAuth,
      isMyPhoto,
      onCompaintModalOpen,
      onOpenConfirmRemoveModal,
      onOpenEditModal,
      onOpenSelectAvatarModal,
      onShareModalOpen,
      openOriginalPhoto,
      slidesState.photo?.type,
      t,
    ],
  );

  const onAddOrRemoveFavoritePhoto = useCallback(async () => {
    if (!slidesState.photo) {
      return;
    }

    const photo = slidesState.photo;

    try {
      if (photo.is_favorite) {
        await dispatch(removeFavoritesPhoto(photo.id));
        dispatch(setIsFavorites(false));
        return;
      }

      await dispatch(addFavoritesPhoto(photo.id));
      dispatch(setIsFavorites(true));
    } catch (error) {
      console.error("Error while [onAddOrRemoveFavoritePhoto]", error);
    }
  }, [dispatch, slidesState.photo]);

  useEffect(() => {
    if (isOpen && slidesState.photo) {
      getUserPhoto(slidesState.photo.id);
    }
  }, [getUserPhoto, isOpen, slidesState.photo]);

  const onAddOrRemoveGroup = useCallback(
    async (group: PrivacyGroup, isSelected?: boolean) => {
      if (!photo) {
        return;
      }

      try {
        switch (true) {
          case group.isAll:
            if (isSelected) {
              break;
            }
            await dispatch(changeMediaAccess(photo.id, true));
            dispatch(changeMediaSharingAccess(true));
            const allSharingGroupsIds = _.map(sharingGroups, (item) => item.id);
            if (!_.isEmpty(allSharingGroupsIds)) {
              await dispatch(
                removeSharingMediaByGroupIds(photo.id, allSharingGroupsIds),
              );
            }
            dispatch(removeSharingGroup(allSharingGroupsIds));
            break;
          case isSelected:
            await dispatch(removeSharingMediaByGroupId(photo.id, group.id));
            dispatch(removeSharingGroup([group.id]));
            break;
          default:
            if (photo.is_public) {
              await dispatch(changeMediaAccess(photo.id, false));
              dispatch(changeMediaSharingAccess(false));
            }
            await dispatch(sharingMediaByGroupId(photo.id, group.id));
            dispatch(addSharingGroup([group]));
            break;
        }
      } catch (error) {
        console.error("Error while [onAddOrRemoveSharingGroup]", error);
      }
    },
    [dispatch, photo, sharingGroups],
  );

  const onSubscribeOrUnsubscribeToUser = useCallback(
    async (action: boolean, userProfile: IUserType) => {
      try {
        if (!action) {
          await dispatch(unsubscribeOnUser(userProfile.id));
          dispatch(removeSubscription(userProfile.id));
        } else {
          await dispatch(subscribeOnUser(userProfile.id));
          dispatch(setSubscription(userProfile));
        }

        dispatchSlides({
          type: "SUBSCRIBE_OR_UNSUBSCRIBE_TO_USER",
          value: action,
        });
      } catch (error) {
        console.error("Error while onSubscribeOrUnsubscribeToUser", error);
      }
    },
    [dispatch, dispatchSlides],
  );

  return (
    <>
      <Modal sx={materialStyles.modal} open={isOpen} onClose={onClose}>
        <Box sx={materialStyles.modalContainer}>
          <FullSizeModalHeader
            TitleComponent={
              <UserAvatarWithActions
                user={_.get(slidesState, "photo.user")}
                isMyProfile={isMyPhoto}
                onSubscribeOrUnsubscribe={onSubscribeOrUnsubscribeToUser}
              />
            }
            gradientBackground
            Icon={
              slidesState.photo?.type === MediaType.VIDEO ||
              slidesState.photo?.type === MediaType.YOUTUBE_VIDEO ? (
                <CloseIcon sx={materialStyles.headerIcon} />
              ) : null
            }
            iconPosition="right"
            onIconClick={onClose}
          >
            <Grid sx={materialStyles.iconBlocks}>
              {isAuth ? (
                <FavoritesButton
                  isFavorite={
                    photo ? _.get(photo, "is_favorite", false) : false
                  }
                  onAddOrRemoveFavoritePhoto={onAddOrRemoveFavoritePhoto}
                  photoEmotions={localEmotions.emotions}
                  emotionsOwn={localEmotions.emotionsOwn}
                  onSendEmotion={onSendEmotion}
                />
              ) : null}
              <IconButton
                onClick={openOriginalPhoto}
                sx={materialStyles.hideOnMobile}
              >
                <DownloadIcon sx={materialStyles.headerIcon} />
              </IconButton>
              {isMyPhoto && slidesState.photo?.type === MediaType.PHOTO ? (
                <IconButton
                  sx={materialStyles.hideOnMobile}
                  onClick={onOpenSelectAvatarModal}
                >
                  <AccountCircleOutlinedIcon sx={materialStyles.headerIcon} />
                </IconButton>
              ) : null}
              {!isMyPhoto && isAuth ? (
                <IconButton
                  sx={materialStyles.hideOnMobile}
                  onClick={onCompaintModalOpen}
                >
                  <ReportGmailerrorredOutlinedIcon
                    sx={materialStyles.headerIcon}
                  />
                </IconButton>
              ) : null}
              <IconButton
                sx={materialStyles.hideOnMobile}
                onClick={onShareModalOpen}
              >
                <ShareOutlinedIcon sx={materialStyles.headerIcon} />
              </IconButton>
              {isMyPhoto ? (
                <IconButton
                  sx={materialStyles.hideOnMobile}
                  onClick={onOpenEditModal}
                >
                  <EditOutlinedIcon sx={materialStyles.headerIcon} />
                </IconButton>
              ) : null}
              <IconButton onClick={onOpenInfoPhoto}>
                <InfoOutlinedIcon sx={materialStyles.headerIcon} />
              </IconButton>
              {isAuth ? (
                <IconButton onClick={onOpenPhotoComments}>
                  <ChatBubbleOutlineIcon sx={materialStyles.headerIcon} />
                </IconButton>
              ) : null}
              {isMyPhoto ? (
                <IconButton
                  sx={materialStyles.hideOnMobile}
                  onClick={onOpenConfirmRemoveModal}
                >
                  <DeleteOutlineOutlined sx={materialStyles.headerIcon} />
                </IconButton>
              ) : null}
              {!_.isEmpty(menuActions) ? (
                <Grid sx={materialStyles.showOnMobile}>
                  <AdditionalMenuButton menuItems={menuActions} />
                </Grid>
              ) : null}
            </Grid>
          </FullSizeModalHeader>
          <DivForSwipe
            ref={swipeRef}
            onTouchEnd={handleTouchEnd}
            onTouchStart={handleTouchStart}
            sx={materialStyles.slides}
          >
            {isShowArrows ? (
              <ButtonBase
                disabled={slidesState.slideLeftDisable}
                sx={[
                  materialStyles.slideButton,
                  materialStyles.hideOnMobile,
                  slidesState.slideLeftDisable &&
                    materialStyles.disabledSlideButton,
                ]}
                onClick={() => onArrowClick("left")}
              >
                <Image src={LeftIcon} />
              </ButtonBase>
            ) : null}
            {slidesState.loading ? <CircularProgress /> : null}
            <Slide
              timeout={SLIDE_SPEED}
              in={slidesState.slideIn}
              direction={slidesState.slideDirection}
            >
              <Picture sx={materialStyles.slidePicture}>
                {slidesState.photo?.type === MediaType.PHOTO ? (
                  <SlideItem
                    discription={
                      slidesState.photo.description || photo?.description
                    }
                    onClose={onClose}
                    onLoad={onLoadImage}
                    sx={materialStyles.slideItem}
                    src={slidesState.photo?.full || slidesState.photo?.preview}
                  />
                ) : null}
                {slidesState.photo?.type === MediaType.VIDEO ? (
                  <Video
                    autoPlay={false}
                    muted
                    onLoadedData={onLoadImage}
                    sx={materialStyles.slideItem}
                    src={
                      _.get(photo, "files[0].hd") ||
                      _.get(photo, "files[0].original")
                    }
                    controls
                    playsInline
                  />
                ) : null}
                {slidesState.photo?.type === MediaType.YOUTUBE_VIDEO ? (
                  <YouTubeIFrame
                    sx={[
                      materialStyles.iframeSlideItem,
                      materialStyles.slideItem,
                    ]}
                    videoId={photo?.original}
                    onLoad={onLoadImage}
                  />
                ) : null}
              </Picture>
            </Slide>
            {isShowArrows ? (
              <ButtonBase
                disabled={slidesState.slideRightDisable}
                sx={[
                  materialStyles.slideButton,
                  materialStyles.hideOnMobile,
                  slidesState.slideRightDisable &&
                    materialStyles.disabledSlideButton,
                ]}
                onClick={() => onArrowClick("right")}
              >
                <Image src={RightIcon} />
              </ButtonBase>
            ) : null}
            {isShowArrows ? (
              <Grid sx={materialStyles.showOnMobile}>
                <ButtonBase
                  disabled={slidesState.slideLeftDisable}
                  sx={[
                    materialStyles.slideButton,
                    slidesState.slideLeftDisable &&
                      materialStyles.disabledSlideButton,
                  ]}
                  onClick={() => onArrowClick("left")}
                >
                  <Image src={LeftIcon} />
                </ButtonBase>
                <ButtonBase
                  disabled={slidesState.slideRightDisable}
                  sx={[
                    materialStyles.slideButton,
                    slidesState.slideRightDisable &&
                      materialStyles.disabledSlideButton,
                  ]}
                  onClick={() => onArrowClick("right")}
                >
                  <Image src={RightIcon} />
                </ButtonBase>
              </Grid>
            ) : null}
          </DivForSwipe>
          {isMyPhoto ? (
            <GroupsList
              sx={materialStyles.groupsList}
              listContainerSx={materialStyles.groupsListContainer}
              listItemSx={materialStyles.groupsListItem}
              groups={groups}
              selectedGroups={selectedGroups}
              onGroupClick={onAddOrRemoveGroup}
            />
          ) : null}
        </Box>
      </Modal>
      <PhotoCommentsDrawer
        isLoading={isLoading}
        isOpen={isOpenPhotoComments}
        onClose={onClosePhotoComments}
        photoId={slidesState.photo?.id}
      />
      <PhotoInfoDrawer
        isLoading={isLoading}
        isOpen={isOpenPhotoInfo}
        onClose={onCloseInfoPhoto}
        photoId={slidesState.photo?.id}
      />
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={onShareModalClose}
        type="PHOTO"
      />
      <ComplaintModal
        isOpen={isComplaintModalOpen}
        onClose={onComplaintModalClose}
        type="PHOTO"
      />
      <EditModal
        isOpen={isEditModalOpen}
        onClose={onCloseEditModal}
        type="PHOTO"
      />
      <SelectAvatarPhotoModal
        isOpen={isSelectAvatarModalOpen}
        onClose={onCloseSelectAvatarModal}
      />
      <ConfirmModal
        onClose={onCloseConfirmRemoveModal}
        isOpen={isRemoveConfirmModalOpen}
        onConfirm={onRemovePhoto}
        confirmText={t("photos.are_you_sure_you_want_delete_photo")}
      />
    </>
  );
});
