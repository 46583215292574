import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {
  successIcon: {
    marginBottom: "24px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
};

export const materialStyles: IMaterialStyleType = {
  innerModalContainer: {
    padding: "24px 19px 31px 19px",
    flex: 1,
  },
  editModalTitle: {
    fontSize: "20px",
    color: Colors.white,
    lineHeight: "28px",
    fontWeight: 700,
    width: "100%",
  },
  editInput: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: "6px",
    color: Colors.white,
    marginTop: "24px",

    width: {
      xs: `auto`,
      sm: `auto`,
      md: "420px",
    },

    "& .MuiInputBase-root": {
      color: Colors.white,
    },

    "& .Mui-error": {
      border: "1px solid #FF505F",
    },

    "& input": {
      color: Colors.white,
    },

    "& fieldset": {
      border: "none",
    },

    "& ::placeholder": {
      color: Colors.white,
      opacity: 0.6,
    },

    "& .MuiFormLabel-root": {
      color: Colors.white,
      opacity: 0.6,
      backgroundColor: theme.palette.background.default,

      "&.Mui-error": {
        border: "none",
        color: theme.palette.error.main,
        opacity: 1,
      },
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: Colors.white,
      opacity: 0.6,

      "&.Mui-error": {
        border: "none",
        color: theme.palette.error.main,
        opacity: 1,
      },
    },
  },
  button: {
    height: "50px",
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",
    marginTop: "36px",

    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: Colors.white,
      backgroundColor: theme.palette.grey[400],
    },
  },
  confirmButton: {
    height: "50px",
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",

    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: Colors.white,
      backgroundColor: theme.palette.grey[400],
    },
  },
  confirmModalHeaderTitle: {
    color: Colors.white,
    fontSize: "20px",
    lineHeight: "22px",
    fontWeight: 700,
    marginBottom: "24px",
    textAlign: "center",
  },
  closeButton: {
    height: "48px",
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",
    marginTop: "24px",

    width: {
      xs: `100%`,
      sm: `100%`,
      md: "420px",
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
  confirmContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
};
