import React, { useCallback, useContext, useMemo, useState } from "react";
import { UserAvatar } from "./UserAvatar";
import { IUserType } from "../../store/slices/user";
import { ButtonBase, Grid, Popover, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { CommonModalsDispatchContext } from "../providers/CommonModalsProvider";
import { useAuth } from "../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../store";
import { setUserId } from "../../store/slices/userProfile";
import { getChat } from "../../store/thunks/chats";
import { getDraftChatInstance } from "../../utils";
import { addDraftChatMessage } from "../../store/slices/messages";
import { setChatUser } from "../../store/slices/chat";
import { useNavigate } from "react-router-dom";
import { ChatWithUserNotFoundError } from "../../store/classes";

export type TActionItem = {
  title: string;
  onPress?: () => void;
};

type TUserAvatarWithActions = {
  user?: IUserType;
  isMyProfile?: boolean;
  onSubscribeOrUnsubscribe?: (action: boolean, user: IUserType) => void;
};

export const UserAvatarWithActions: React.FC<TUserAvatarWithActions> = ({
  user,
  isMyProfile,
  onSubscribeOrUnsubscribe,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { user: myUserProfile } = useAppSelector((state) => state.user);

  const dispatchCommonModals = useContext(CommonModalsDispatchContext);

  const navigate = useNavigate();

  const isAuth = useAuth();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClickItem = useCallback(
    (item: TActionItem) => {
      handleClose();
      item.onPress && item.onPress();
    },
    [handleClose],
  );

  const goToProfile = useCallback(() => {
    if (!user) {
      return;
    }

    if (!isAuth) {
      dispatchCommonModals && dispatchCommonModals({ type: "SHOW_AUTH_MODAL" });
      return;
    }

    dispatch(setUserId(user.id));
    dispatchCommonModals && dispatchCommonModals({ type: "SHOW_USER_MODAL" });
  }, [dispatch, dispatchCommonModals, isAuth, user]);

  const onGoToChatWithUser = useCallback(async () => {
    try {
      if (!user) {
        return;
      }

      const data = await dispatch(getChat(user.id));

      if (!data) {
        throw new Error();
      }

      if (_.isEmpty(data)) {
        const draftChatInstance = getDraftChatInstance(user, myUserProfile);
        dispatch(addDraftChatMessage(draftChatInstance));
      }

      dispatch(setChatUser(user));

      navigate("/messages");
    } catch (error: any) {
      if (error instanceof ChatWithUserNotFoundError) {
        const draftChatInstance = getDraftChatInstance(user, myUserProfile);
        dispatch(addDraftChatMessage(draftChatInstance));
        dispatch(setChatUser(draftChatInstance.user));

        navigate("/messages");
        return;
      }
      console.error("Error while [onGoToChatWithUser]", error);
    }
  }, [dispatch, myUserProfile, navigate, user]);

  const actionItems: TActionItem[] = useMemo(
    () =>
      _.compact([
        { title: t("user_profile.go_to_user_profile"), onPress: goToProfile },
        onSubscribeOrUnsubscribe && {
          title: user?.is_subscribed
            ? t("user_profile.subscribe")
            : t("user_profile.unsubscribe"),
          onPress: () =>
            user
              ? user?.is_subscribed
                ? onSubscribeOrUnsubscribe(false, user)
                : onSubscribeOrUnsubscribe(true, user)
              : undefined,
        },
        {
          title: t("user_profile.send_message"),
          onPress: onGoToChatWithUser,
        },
      ]),
    [goToProfile, onGoToChatWithUser, onSubscribeOrUnsubscribe, t, user],
  );

  return (
    <>
      <ButtonBase
        onClick={handleOpen}
        sx={materialStyles.userAvatarWithActionContainer}
        disabled={isMyProfile}
      >
        <UserAvatar type="DEFAULT" user={user} />
        <Typography sx={materialStyles.userAvatarWithActionName}>
          {user?.username}
        </Typography>
      </ButtonBase>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: materialStyles.popoverContainer,
          },
        }}
      >
        <Grid sx={materialStyles.actionItems}>
          {_.map(actionItems, (item) => (
            <ButtonBase
              key={item.title}
              sx={materialStyles.actionItem}
              onClick={() => onClickItem(item)}
            >
              <Typography sx={materialStyles.actionItemTitle}>
                {item.title}
              </Typography>
            </ButtonBase>
          ))}
        </Grid>
      </Popover>
    </>
  );
};
