import { Grid, Typography } from "@mui/material";
import { IUserType } from "../../store/slices/user";
import { materialStyles, styles } from "./styles";
import { useMemo } from "react";
import { getUserAttributesForAvatar } from "../../utils";

type IUserAvatar = {
  user?: IUserType | null;
  type?: "DEFAULT" | "HEADER" | "PHOTO_PAGE" | "IN_PEOPLE_LIST" | "AVATAR_LIST";
  editEnable?: boolean;
};

export const UserAvatar: React.FC<IUserAvatar> = ({
  user,
  type,
  editEnable = false,
}) => {
  const avatarStyle = useMemo(() => {
    switch (type) {
      case "DEFAULT":
        return materialStyles.defaultUserAvatar;
      case "HEADER":
        return materialStyles.headerUserAvatar;
      case "PHOTO_PAGE":
        return materialStyles.photoPageUserAvatar;
      case "IN_PEOPLE_LIST":
        return materialStyles.inPeopleListUserAvatar;
      case "AVATAR_LIST":
        return materialStyles.photoAvatarsListsUserAvatar;
      default:
        return undefined;
    }
  }, [type]);

  const lettersStyles = useMemo(() => {
    switch (type) {
      case "DEFAULT":
        return materialStyles.defaultLettersUserAvatar;
      case "HEADER":
        return materialStyles.headerLettersUserAvatar;
      case "PHOTO_PAGE":
        return materialStyles.photoPageLettersUserAvatar;
      case "IN_PEOPLE_LIST":
        return materialStyles.inPeopleListLettersUserAvatar;
      case "AVATAR_LIST":
        return materialStyles.inAvatarsListLettersUserAvatar;
      default:
        return undefined;
    }
  }, [type]);

  return (
    <Grid sx={avatarStyle}>
      {user && user.avatar ? (
        <img
          style={styles.userImageAvatar}
          src={user.avatar}
          alt="user-avatar"
        />
      ) : (
        <Typography sx={lettersStyles}>
          {getUserAttributesForAvatar((user && user.username) || "UserName")}
        </Typography>
      )}
    </Grid>
  );
};
