import React from "react";
import VerificationInput from "react-verification-input";
import "./styles.css";
import clsx from "clsx";

type ICodeInput = {
  value?: string;
  onChange: (value: string) => void;
  isError?: boolean;
};

export const CodeInput: React.FC<ICodeInput> = ({
  onChange,
  value,
  isError,
}) => {
  return (
    <VerificationInput
      length={6}
      onChange={onChange}
      value={value}
      classNames={{
        container: "container",
        character: clsx("character", isError && "character-error"),
        characterInactive: "character--inactive",
        characterSelected: "character--selected",
        characterFilled: "character--filled",
      }}
    />
  );
};
