import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { materialStyles } from "./styles";
import { useFormik } from "formik";
import { SearchUserInput } from "../../../header/components/SearchUserInput";
import { IUserType } from "../../../../store/slices/user";
import _ from "lodash";
import { UserWithRemoveItem } from "../../../items/UserWithRemoveItem";
import { useToast } from "rc-toastr";
import { useAppDispatch } from "../../../../store";
import {
  addUsersToGroupByIds,
  createNewPrivacyGroup,
} from "../../../../store/thunks/groups";
import { addNewGroup } from "../../../../store/slices/groups";

type ICreatePrivacyGroupForm = {
  onClose: () => void;
};

type IFormValues = {
  title: string;
};

export const CreatePrivacyGroupForm: React.FC<ICreatePrivacyGroupForm> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const { toast } = useToast();

  const dispatch = useAppDispatch();

  const [localAddedUsersToGroup, setAddedUsersToGroup] = useState<IUserType[]>(
    [],
  );

  const onSubmit = useCallback(
    async (details: IFormValues) => {
      try {
        const response = await dispatch(createNewPrivacyGroup(details.title));

        if (!response) {
          throw new Error();
        }

        if (!_.isEmpty(localAddedUsersToGroup)) {
          await dispatch(
            addUsersToGroupByIds(
              response.id,
              _.map(localAddedUsersToGroup, (user) => user.id),
            ),
          );
        }

        dispatch(addNewGroup(response));

        onClose();
      } catch (error) {
        console.error("Error  while [onSubmit]", error);
      }
    },
    [dispatch, localAddedUsersToGroup, onClose],
  );

  const { values, errors, handleSubmit, isValid, handleChange } = useFormik({
    onSubmit,
    initialValues: {
      title: "",
    },
    validate: (values) => {
      return _.pickBy({
        title: !_.trim(values.title),
      });
    },
  });

  const onAddUserToLocalState = useCallback(
    (user: IUserType) => {
      const isUserAlreadyAddedLocal = _.find(localAddedUsersToGroup, {
        id: user.id,
      });

      if (isUserAlreadyAddedLocal) {
        toast.error(t("sharing.user_already_added"));
        return;
      }

      setAddedUsersToGroup([...localAddedUsersToGroup, user]);
    },
    [localAddedUsersToGroup, t, toast],
  );

  const onRemoveUserFromLocalState = useCallback(
    (userId: IUserType["id"]) => {
      const filteredLocalUser = _.filter(
        localAddedUsersToGroup,
        (user) => user.id !== userId,
      );
      setAddedUsersToGroup(filteredLocalUser);
    },
    [localAddedUsersToGroup],
  );

  return (
    <Grid sx={materialStyles.modalInnerBlock}>
      <form onSubmit={handleSubmit}>
        <Grid sx={materialStyles.modalHeaderBlock}>
          <Typography sx={materialStyles.modalHeaderTitle}>
            {t("settings.create_group")}
          </Typography>
          <Typography sx={materialStyles.modalHeaderSubtitle}>
            {t("settings.private_groups_sharing_photos")}
          </Typography>
        </Grid>
        <Grid sx={materialStyles.modalForm}>
          <TextField
            error={Boolean(errors.title)}
            name="title"
            value={values.title}
            onChange={handleChange}
            sx={materialStyles.input}
            fullWidth
            label={t("upload_photo.group_title")}
            placeholder={t("upload_photo.enter_group_title")}
          />
          <SearchUserInput
            onChangeValue={onAddUserToLocalState}
            customTextInputSx={materialStyles.searchUserInput}
          />
          <Grid sx={materialStyles.selectedLocalUser}>
            {_.map(localAddedUsersToGroup, (user) => (
              <UserWithRemoveItem
                user={user}
                onRemoveClick={onRemoveUserFromLocalState}
              />
            ))}
          </Grid>
          <Button
            disabled={!isValid}
            type="submit"
            sx={materialStyles.saveButton}
            variant="contained"
          >
            {t("common.create")}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};
