import { Box, Modal } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";
import { ConfirmDeleteAccountContent } from "./components/ConfirmDeleteAccountContent";

type IDeleteAccountModal = {
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteAccountModal: React.FC<IDeleteAccountModal> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose} sx={materialStyles.modal}>
      <Box sx={materialStyles.modalBox}>
        <ConfirmDeleteAccountContent onClose={onClose} />
      </Box>
    </Modal>
  );
};
