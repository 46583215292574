import { IMaterialStyleType, ICssStyleType } from "../../types";
import { Colors, theme, HEADER_HEIGHT } from "../../theme";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  sideMenuContainer: {
    position: "relative",
    width: "256px",
    height: "100vh",
    maxHeight: "-webkit-fill-available",
    backgroundColor: theme.palette.background.default,
    zIndex: 20,
    display: "flex",
    borderRight: `1px solid ${theme.palette.grey[50]}`,
    flexDirection: "column",
    paddingBottom: "24px",
  },
  sideMenuItemsContainer: {
    flex: 1,
    overflowY: "auto",
  },
  sideMenuMainList: {
    marginTop: {
      xs: `${HEADER_HEIGHT + 4}px`,
      sm: `${HEADER_HEIGHT + 4}px`,
      md: `${HEADER_HEIGHT + 4}px`,
    },
  },
  sideMenuButton: {
    color: Colors.white,
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  sideMenuActiveButton: {
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  sideMenuTitle: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    marginLeft: "16px",

    display: "flex",
  },
  sideMenuIcon: {
    width: "24px",
    height: "24px",
  },
  sideMenuSubTitle: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
    color: theme.palette.grey[300],
    textTransform: "uppercase",
    padding: "24px",
    textAlign: "left",
  },
  divider: {
    borderColor: theme.palette.grey[50],
    marginTop: "16px",
    marginBottom: "16px",
  },
  supportContainer: {
    p: 1,
  },
  supportTitle: {
    color: theme.palette.grey[700],
  },
  supportEmail: {
    color: theme.palette.primary.main,
  },
};
