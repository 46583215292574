import { Box, Modal } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";
import { materialStyles } from "./styles";
import { SupportConversationForm } from "./components/SupportConversationForm";
import { resetSupportConversation } from "../../../store/slices/supportRequestConversation";
import { useSocketSupportConversationHandle } from "../../../hooks/useSocketSupportConversationHandle";

type TSupportConversationModal = {
  isOpen: boolean;
  onClose: () => void;
  type?: "CONVERSATION_FORM";
};

export const SupportConversationModal: React.FC<TSupportConversationModal> = ({
  isOpen,
  onClose: onCloseFromProps,
  type = "CONVERSATION_FORM",
}) => {
  const { loading } = useAppSelector((state) => state.loader);
  const { supportRequestId } = useAppSelector(
    (state) => state.supportRequestConversation,
  );
  const dispatch = useAppDispatch();

  const { onSocketJoin, onSocketLeave } =
    useSocketSupportConversationHandle(supportRequestId);

  const onClose = useCallback(() => {
    onSocketLeave();
    dispatch(resetSupportConversation());
    onCloseFromProps();
  }, [dispatch, onCloseFromProps, onSocketLeave]);

  useEffect(() => {
    if (isOpen) {
      onSocketJoin();
    }
  }, [isOpen, onSocketJoin]);

  return (
    <Modal open={isOpen} onClose={onClose} sx={materialStyles.modal}>
      <Box sx={materialStyles.modalBox}>
        {type === "CONVERSATION_FORM" ? <SupportConversationForm /> : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
