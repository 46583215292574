import { theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  messagesPage: {
    display: "flex",
    flex: 1,
    margin: {
      xs: "none",
      sm: "none",
      md: `-39px 0px 0px -29px`,
    },
    padding: {
      xs: "none",
      sm: "none",
      md: "0 29px 0 29px",
    },
    height: "100%",
  },
  messagesListContainer: {
    display: "flex",
    flexDirection: "column",
    borderLeft: `1px solid ${theme.palette.grey[50]}`,
    borderRight: `1px solid ${theme.palette.grey[50]}`,
    flex: 1,
    overflow: "hidden",
  },
  messagesActiveChat: {
    flex: 2.5,
    display: "flex",
  },
};
