import React, { useCallback, useMemo, useState } from "react";
import { IAlbumType } from "../../../store/slices/albums";
import { Grid } from "@mui/material";
import _ from "lodash";
import { AppAlbumListItem } from "./AppAlbumListItem";
import { materialStyles } from "./styles";
import moment from "moment";
import { UserAlbumModal } from "../../modals/userAlbumModal";
import { useAppDispatch } from "../../../store";
import { setUserAlbumId } from "../../../store/slices/userAlbum";

type IAppAlbumList = {
  albums?: IAlbumType[];
  onItemClick?: (id: number) => void;
  enableAlbumOpen?: boolean;
  onCloseAlbumModal?: () => void;
};

export const AppAlbumList: React.FC<IAppAlbumList> = ({
  albums,
  onItemClick,
  onCloseAlbumModal,
  enableAlbumOpen = true,
}) => {
  const dispatch = useAppDispatch();

  const sortedAlbumsArray = useMemo(
    () =>
      _.chain(albums)
        .sortBy((album) => moment(album.created_at).toDate())
        .reverse()
        .value(),
    [albums]
  );

  const [isAlbumModal, setIsAlbumModal] = useState(false);

  const onAlbumModalClose = useCallback(() => {
    setIsAlbumModal(false);

    onCloseAlbumModal && onCloseAlbumModal();
  }, [onCloseAlbumModal]);

  const onAlbumModalOpen = useCallback(
    (id: number) => {
      dispatch(setUserAlbumId(id));
      setIsAlbumModal(true);
    },
    [dispatch]
  );

  const onAlbumClick = useCallback(
    (id: number) => {
      enableAlbumOpen && onAlbumModalOpen(id);
      onItemClick && onItemClick(id);
    },
    [enableAlbumOpen, onAlbumModalOpen, onItemClick]
  );

  if (!albums || _.isEmpty(albums)) {
    return null;
  }

  return (
    <>
      <Grid sx={materialStyles.albumListContainer}>
        {_.map(sortedAlbumsArray, (album) => (
          <AppAlbumListItem
            key={album.id}
            album={album}
            onClick={onAlbumClick}
          />
        ))}
      </Grid>
      <UserAlbumModal isOpen={isAlbumModal} onClose={onAlbumModalClose} />
    </>
  );
};
