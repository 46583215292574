import React from "react";
import { Grid, CircularProgress, Backdrop } from "@mui/material";
import { materialStyles } from "./styles";

type ILoaderWithBackdrop = {
  isShow: boolean;
};

export const LoaderWithBackdrop: React.FC<ILoaderWithBackdrop> = ({
  isShow,
}) => {
  return isShow ? (
    <Grid sx={materialStyles.loaderWithBackdrop}>
      <CircularProgress />
      <Backdrop open={isShow} />
    </Grid>
  ) : null;
};
