import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import myPhotosReducer from "./slices/myPhotos";
import loaderReducer from "./slices/loading";
import errorsReducer from "./slices/errors";
import registrationReducer from "./slices/registration";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import uploadFile from "./slices/uploadFile";
import visualMode from "./slices/visualMode";
import newAlbum from "./slices/newAlbum";
import albums from "./slices/albums";
import userAlbum from "./slices/userAlbum";
import userPhoto from "./slices/userPhoto";
import explore from "./slices/explore";
import userProfile from "./slices/userProfile";
import complaint from "./slices/complaint";
import sharing from "./slices/sharing";
import edit from "./slices/edit";
import map from "./slices/map";
import trash from "./slices/trash";
import favorites from "./slices/favorites";
import selectAvatar from "./slices/selectAvatar";
import userEdit from "./slices/userEdit";
import system from "./slices/system";
import tags from "./slices/tags";
import socket from "./slices/socket";
import groups from "./slices/groups";
import updateGroup from "./slices/updateGroup";
import messages from "./slices/messages";
import chat from "./slices/chat";
import supportRequests from "./slices/supportRequests";
import supportRequestConversation from "./slices/supportRequestConversation";
import videoEditor from "./slices/videoEditor";
import slider from "./slices/slider";
import uploaderStack from "./slices/uploaderStack";

const mainReducer = combineReducers({
  user: userReducer,
  myPhotos: myPhotosReducer,
  loader: loaderReducer,
  errors: errorsReducer,
  regUser: registrationReducer,
  uploadFile: uploadFile,
  visualMode: visualMode,
  newAlbum: newAlbum,
  albums: albums,
  userAlbum: userAlbum,
  userPhoto: userPhoto,
  explore: explore,
  userProfile: userProfile,
  complaint: complaint,
  sharing: sharing,
  edit: edit,
  map: map,
  trash: trash,
  favorites: favorites,
  selectAvatar: selectAvatar,
  userEdit: userEdit,
  system: system,
  tags: tags,
  socket: socket,
  groups: groups,
  updateGroup: updateGroup,
  messages: messages,
  chat: chat,
  supportRequests: supportRequests,
  supportRequestConversation: supportRequestConversation,
  videoEditor: videoEditor,
  slider: slider,
  uploaderStack: uploaderStack,
});

export const store = configureStore({
  reducer: mainReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
