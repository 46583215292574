import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  innerModalForm: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },
  headerIcon: {
    color: Colors.white,
    width: "24px",
    height: "24px",
  },
  headerBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "36px",
  },
  titleAndDescriptionBlock: {
    marginTop: "30px",
    marginBottom: "80px",
  },
  albumTitle: {
    fontSize: "48px",
    lineHeight: "56px",
    color: theme.palette.grey[700],
    fontWeight: 700,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  albumDescription: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.grey[700],
    marginTop: "48px",
    wordBreak: "break-word",
  },
  albumItemsCount: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.grey[700],
    marginTop: "48px",
  },
  mainContentContainer: {
    display: "flex",
    flex: 1,
    overflow: "auto",
    flexDirection: "column",
    paddingLeft: {
      xs: "20px",
      sm: "20px",
      md: "170px",
    },
    paddingRight: {
      xs: "20px",
      sm: "20px",
      md: "170px",
    },
  },
  addShareButton: {
    backgroundColor: theme.palette.primary.main,

    width: "36px",
    height: "36px",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  avatarListContainer: {
    width: "100%",
    overflow: "hidden",
    marginTop: "48px",
  },
};
