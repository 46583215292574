import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { materialStyles, styles } from "./styles";
import { IEditModalContentType, IEditModalType } from "..";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setProps } from "../../../../store/slices/edit";
import { useToast } from "rc-toastr";

type IEditFormValues = {
  prop1: "";
  prop2: "";
};

type IEditModalForm = {
  type: IEditModalContentType;
  onClose: () => void;
  onChangeModalType: (type: IEditModalType) => void;
};

export const EditModalForm: React.FC<IEditModalForm> = ({
  type,
  onClose,
  onChangeModalType,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { album } = useAppSelector((state) => state.userAlbum);
  const { photo } = useAppSelector((state) => state.userPhoto);

  const { toast } = useToast();

  //TODO: Reuse empty values
  const formInitialValues = useMemo(() => {
    switch (type) {
      case "ALBUM":
        return {
          prop1: album?.title || "",
          prop2: album?.description || "",
        };
      case "PHOTO":
        return {
          prop1: photo?.description || "",
          prop2: "",
        };
      default:
        return {
          prop1: "",
          prop2: "",
        };
    }
  }, [album?.description, album?.title, photo?.description, type]);

  const onSubmit = useCallback(
    (details: IEditFormValues) => {
      switch (type) {
        case "PHOTO": {
          if (details.prop1 === formInitialValues.prop1) {
            toast.error(t("edit.edit_values_is_equal"));
            return;
          }
          break;
        }
        case "ALBUM": {
          if (
            details.prop1 === formInitialValues.prop1 &&
            details.prop2 === formInitialValues.prop2
          ) {
            toast.error(t("edit.edit_values_is_equal"));
            return;
          }
          break;
        }
      }
      dispatch(setProps(details));
      onChangeModalType("CONFIRM_MODAL");
    },
    [
      dispatch,
      formInitialValues.prop1,
      formInitialValues.prop2,
      onChangeModalType,
      t,
      toast,
      type,
    ]
  );

  const { values, handleSubmit, handleChange, errors, isValid } = useFormik({
    onSubmit,
    initialValues: formInitialValues as IEditFormValues,
    validate: (values) => {
      return _.pickBy({
        prop1:
          _.includes(["PHOTO", "ALBUM"], type) &&
          !_.trim(values.prop1) &&
          formInitialValues.prop1 &&
          formInitialValues.prop1 === values.prop1,
        prop2:
          _.includes(["ALBUM"], type) &&
          !_.trim(values.prop2) &&
          formInitialValues.prop2 &&
          formInitialValues.prop2 === values.prop2,
      });
    },
  });
  return (
    <Grid sx={materialStyles.innerModalContainer}>
      <Typography sx={materialStyles.editModalTitle}>
        {
          {
            ALBUM: t("edit.edit_album_modal_title"),
            PHOTO: t("edit.edit_photo_modal_title"),
          }[type]
        }
      </Typography>
      <form style={styles.formContainer} onSubmit={handleSubmit}>
        {type === "PHOTO" ? (
          <TextField
            name="prop1"
            multiline
            maxRows={5}
            minRows={3}
            error={Boolean(errors.prop1)}
            value={values.prop1}
            onChange={handleChange}
            sx={materialStyles.editInput}
            fullWidth
            label={t("edit.photo_description")}
            placeholder={t("edit.enter_photo_description")}
          />
        ) : null}
        {type === "ALBUM" ? (
          <TextField
            name="prop1"
            error={Boolean(errors.prop1)}
            value={values.prop1}
            onChange={handleChange}
            sx={materialStyles.editInput}
            inputProps={{ maxLength: 100 }}
            fullWidth
            label={t("edit.album_title")}
            placeholder={t("edit.enter_album_title")}
          />
        ) : null}
        {type === "ALBUM" ? (
          <TextField
            name="prop2"
            multiline
            maxRows={5}
            minRows={3}
            error={Boolean(errors.prop2)}
            value={values.prop2}
            onChange={handleChange}
            sx={materialStyles.editInput}
            fullWidth
            label={t("edit.album_description")}
            placeholder={t("edit.enter_album_description")}
          />
        ) : null}
        <Button
          disabled={!isValid}
          variant="contained"
          sx={materialStyles.button}
          type="submit"
        >
          {t("edit.edit")}
        </Button>
      </form>
    </Grid>
  );
};
