import React, { useCallback } from "react";
import { IUserEditModalType } from "..";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import _ from "lodash";
import { useAppDispatch } from "../../../../store";
import { setNewUserName } from "../../../../store/slices/userEdit";

type IUserNameEditForm = {
  onClose: () => void;
  onChangeModalType: (type: IUserEditModalType) => void;
};

type IUsernameEditFormValues = {
  username: string;
};

export const UserNameEditForm: React.FC<IUserNameEditForm> = ({
  onClose,
  onChangeModalType,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    (details: IUsernameEditFormValues) => {
      dispatch(setNewUserName(details.username));
      onChangeModalType("CONFIRM_MODAL");
    },
    [dispatch, onChangeModalType],
  );

  const { values, errors, handleChange, handleSubmit, isValid } = useFormik({
    onSubmit,
    initialValues: { username: "" },
    validate: (values) => {
      return _.pickBy({
        username: !_.trim(values.username),
      });
    },
  });

  return (
    <Grid sx={materialStyles.innerModalContainer}>
      <form onSubmit={handleSubmit}>
        <Typography sx={materialStyles.editModalTitle}>
          {t("edit_user.edit_username")}
        </Typography>
        <TextField
          name="username"
          error={Boolean(errors.username)}
          value={values.username}
          onChange={handleChange}
          sx={materialStyles.editInput}
          fullWidth
          label={t("edit_user.new_username")}
          placeholder={t("edit_user.enter_username")}
        />
        <Button
          disabled={!isValid}
          variant="contained"
          sx={materialStyles.button}
          type="submit"
        >
          {t("edit.edit")}
        </Button>
      </form>
    </Grid>
  );
};
