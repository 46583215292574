import React, { useCallback, useRef, useState } from "react";
import { IPhotoType } from "../../../store/slices/myPhotos";
import { Grid } from "@mui/material";
import _ from "lodash";
import { PostItem } from "./components/PostItem";
import { materialStyles } from "./styles";
import { useAppDispatch } from "../../../store";
import { setUserPhotoId } from "../../../store/slices/userPhoto";
import { UserModal } from "../../modals/userModal";
import { useAuth } from "../../../hooks/useAuth";
import { useToast } from "rc-toastr";
import { useTranslation } from "react-i18next";
import { IUserType } from "../../../store/slices/user";
import { setUserId } from "../../../store/slices/userProfile";
import {
  IImageSlidesModalForwardRef,
  ImageSlidesModal,
} from "../../modals/imageSlidesModal";
import { IExploreTypeTab } from "../../../pages/Explore";
import { initMediaSlider } from "../../../store/slices/slider";

type IPostsLists = {
  photos?: IPhotoType[];
  onClosePhotoModal?: () => void;
  onCloseUserModal?: () => void;
  type: IExploreTypeTab;
};

export const PostsLists: React.FC<IPostsLists> = ({
  photos,
  onClosePhotoModal,
  onCloseUserModal: onCloseUserModalFromProps,
  type,
}) => {
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const imageSlidesRef = useRef<IImageSlidesModalForwardRef>(null);

  const [isOpenMediaSlides, setIsOpenMediaSlides] = useState(false);

  const scrollViewRef = useRef<any>(null);

  const dispatch = useAppDispatch();

  const isAuth = useAuth();

  const { toast } = useToast();

  const { t } = useTranslation();

  const onOpenUserModal = useCallback(
    (user?: IUserType) => {
      if (!user) {
        return;
      }

      if (!isAuth) {
        toast.warning(
          t("common.this_functionality_available_registered_users"),
        );
        return;
      }

      dispatch(setUserId(user.id));
      setIsUserModalOpen(true);
    },
    [dispatch, isAuth, t, toast],
  );

  const onCloseUserModal = useCallback(() => {
    onCloseUserModalFromProps && onCloseUserModalFromProps();
    setIsUserModalOpen(false);
  }, [onCloseUserModalFromProps]);

  const onPhotoClick = useCallback(
    (photo: IPhotoType) => {
      dispatch(initMediaSlider({ initialMedia: photo, media: photos }));
      setIsOpenMediaSlides(true);
      dispatch(setUserPhotoId(photo.id));
    },
    [dispatch, photos],
  );

  const onPhotoCommentsClick = useCallback(
    (photo: IPhotoType) => {
      dispatch(initMediaSlider({ initialMedia: photo, media: photos }));
      setIsOpenMediaSlides(true);
      dispatch(setUserPhotoId(photo.id));
      imageSlidesRef.current?.showPhotoComments();
    },
    [dispatch, photos],
  );

  const onPhotoMapClick = useCallback(
    (photo: IPhotoType) => {
      dispatch(initMediaSlider({ initialMedia: photo, media: photos }));
      setIsOpenMediaSlides(true);
      dispatch(setUserPhotoId(photo.id));
      imageSlidesRef.current?.showPhotoInfo();
    },
    [dispatch, photos],
  );

  const onCloseImageSlides = useCallback(() => {
    setIsOpenMediaSlides(false);
    onClosePhotoModal && onClosePhotoModal();
  }, [onClosePhotoModal]);

  if (!photos) {
    return null;
  }

  return (
    <>
      <Grid ref={scrollViewRef} sx={materialStyles.postsContainer}>
        {_.map(photos, (photo) => (
          <PostItem
            key={photo.id}
            photo={photo}
            onPhotoClick={onPhotoClick}
            onUserProfileClick={onOpenUserModal}
            onPhotoCommentsClick={onPhotoCommentsClick}
            onPhotoMapClick={onPhotoMapClick}
            type={type}
          />
        ))}
      </Grid>
      <ImageSlidesModal
        ref={imageSlidesRef}
        isOpen={isOpenMediaSlides}
        onClose={onCloseImageSlides}
      />
      <UserModal isOpen={isUserModalOpen} onClose={onCloseUserModal} />
    </>
  );
};
