import { Button, Grid, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../store";
import { deleteAccount } from "../../../../store/thunks/user";
import { useLogout } from "../../../../hooks/useLogout";
import { useNavigate } from "react-router-dom";

type IConfirmDeleteAccountContent = {
  onClose: () => void;
};

export const ConfirmDeleteAccountContent: React.FC<
  IConfirmDeleteAccountContent
> = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { resetStorage } = useLogout();

  const onConfirm = useCallback(async () => {
    try {
      await dispatch(deleteAccount());
      resetStorage();
      navigate("/");
      onClose();
    } catch (error) {
      console.error("Error while onConfirm", error);
    }
  }, [dispatch, navigate, onClose, resetStorage]);

  return (
    <Grid sx={materialStyles.innerModalContainer}>
      <Typography sx={materialStyles.confirmModalHeaderTitle}>
        {t("settings.confirm_delete_account")}
      </Typography>
      <Button
        variant="contained"
        sx={materialStyles.confirmButton}
        onClick={onConfirm}
      >
        {t("common.confirm")}
      </Button>
    </Grid>
  );
};
