import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  innerModalForm: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },
  headerIcon: {
    color: Colors.white,
  },
  headerBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  mainBlock: {
    display: "flex",
    flex: 1,
    paddingLeft: {
      xs: "20px",
      sm: "20px",
      md: "170px",
    },
    paddingRight: {
      xs: "20px",
      sm: "20px",
      md: "170px",
    },
    flexDirection: "column",
    overflow: "auto",
    paddingTop: "24px",
  },
  tabPanelContainer: {
    padding: "24px 0 0",
  },
  userProfileInfoBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "36px",
  },
  userProfileName: {
    marginTop: "24px",
    marginBottom: "8px",
    color: Colors.white,
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 600,
  },
  userProfileEmail: {
    color: theme.palette.grey[700],
    fontSize: "14px",
    lineHeight: "20px",
  },
  subscribeButton: {
    textTransform: "none",
    letterSpacing: 0,
    color: Colors.white,
    backgroundColor: theme.palette.primary.main,
    width: "200px",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: Colors.white,
      backgroundColor: theme.palette.grey[400],
    },
  },
  unsubscribeButton: {
    backgroundColor: theme.palette.error.main,

    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  tabContainer: {
    color: Colors.white,
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "22px",
    minWidth: "155px",
  },
  headerImage: {
    height: "140px",
    backgroundColor: theme.palette.grey[600],
    width: "100%",
    borderRadius: "20px",
  },
  userSocialInfo: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: "12px",
  },
  userSocialInfoText: {
    color: theme.palette.grey[900],
    fontSize: "14px",
    lineHeightStep: "22px",
  },
  userSocialInfoDot: {
    backgroundColor: theme.palette.grey[900],
    width: "3px",
    height: "3px",
    borderRadius: "50%",
  },
};
