import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getMySubscriptions } from "../store/thunks/explore";
import {
  resetExploreSubscriptions,
  setExploreSubscriptionsPage,
  setExploreSubscriptionsPageSize,
  setExploreSubscriptionsTotalElements,
  setSubscriptions,
} from "../store/slices/explore";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../constants/api";

export const useFetchSubscriptions = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { subscriptions } = useAppSelector((state) => state.explore);

  const fetchSubscriptions = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        if (!isAuth) {
          return;
        }

        const response = await dispatch(getMySubscriptions(page, pageSize));

        dispatch(setSubscriptions(response?.list));
        dispatch(setExploreSubscriptionsTotalElements(response?.totalElements));
      } catch (error) {
        console.error("Error while [fetchSubscriptions]", error);
      }
    },
    [dispatch, isAuth]
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setExploreSubscriptionsPage(subscriptions.page + 1));
    dispatch(setExploreSubscriptionsPageSize(DEFAULT_PAGE_SIZE));
  }, [subscriptions.page, dispatch]);

  const refetchSubscriptions = useCallback(() => {
    fetchSubscriptions(subscriptions.page);
  }, [fetchSubscriptions, subscriptions.page]);

  useEffect(() => {
    fetchSubscriptions();

    return () => {
      dispatch(resetExploreSubscriptions());
    };
  }, [dispatch, fetchSubscriptions, isAuth]);

  useEffect(() => {
    if (subscriptions.page !== DEFAULT_PAGE) {
      fetchSubscriptions(subscriptions.page, subscriptions.pageSize);
    }
  }, [subscriptions.page, subscriptions.pageSize, fetchSubscriptions]);

  return {
    subscriptions: subscriptions.data,
    totalElements: subscriptions.totalElements,
    refetchSubscriptions,
    onUpdatePageOrPageSize,
  };
};
