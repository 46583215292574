import { IFlags } from "../assets/Flags";

export type ICountriesType = {
  id: string;
  countryCode: string;
  iconName: IFlags;
  code: string;
  mask: string;
  maskPlaceholder: string;
};

export const Countries: ICountriesType[] = [
  {
    id: "countries.usa",
    countryCode: "US",
    iconName: "USAFlag",
    code: "+1",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.ukraine",
    countryCode: "UA",
    iconName: "UkraineFlag",
    code: "+380",
    mask: "([99]) [999] [99] [99]",
    maskPlaceholder: "(XX) XXX XX XX",
  },
  {
    id: "countries.france",
    countryCode: "FR",
    iconName: "FranceFlag",
    code: "+33",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.uk",
    countryCode: "GB",
    iconName: "UnitedKingdomFlag",
    code: "+44",
    mask: "[99] [9999] [9999]",
    maskPlaceholder: "XX XXXX XXXX",
  },
  {
    id: "countries.germany",
    countryCode: "DE",
    iconName: "GermanyFlag",
    code: "+49",
    mask: "([999]) [9999] [999999]",
    maskPlaceholder: "(XXX) XXXX XXXX",
  },
  {
    id: "countries.china",
    countryCode: "CN",
    iconName: "ChinaFlag",
    code: "+86",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.saint_helena_and_ascension_island",
    countryCode: "SH",
    iconName: "UnitedKingdomFlag",
    code: "+247",
    mask: "[9999]",
    maskPlaceholder: "XXXX",
  },
  {
    id: "countries.andorra",
    countryCode: "AD",
    iconName: "AndorraFlag",
    code: "+376",
    mask: "[999] [999]",
    maskPlaceholder: "XXX XXX",
  },
  {
    id: "countries.uae",
    countryCode: "AE",
    iconName: "OAEFlag",
    code: "+971",
    mask: "[9] [999] [99999999999]",
    maskPlaceholder: "X XXX XXXX",
  },
  {
    id: "countries.afghanistan",
    countryCode: "AF",
    iconName: "AfghanistanFlag",
    code: "+93",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.antiguaandbarbuda",
    countryCode: "AG",
    iconName: "AABFlag",
    code: "+1 (268)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.angulia",
    countryCode: "AI",
    iconName: "AnguliaFlag",
    code: "+1 (264)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.albania",
    countryCode: "AL",
    iconName: "AlbaniaFlag",
    code: "+355",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.armenia",
    countryCode: "AM",
    iconName: "ArmeniaFlag",
    code: "+374",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.antilles",
    countryCode: "AN",
    iconName: "AntillesFlag",
    code: "+599",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.angola",
    countryCode: "AO",
    iconName: "AngolaFlag",
    code: "+244",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.argentina",
    countryCode: "AR",
    iconName: "ArgentinaFlag",
    code: "+54",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.americansamoa",
    countryCode: "AS",
    iconName: "AmericanSamoaFlag",
    code: "+1 (684)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.austria",
    countryCode: "AT",
    iconName: "AustriaFlag",
    code: "+43",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.australia",
    countryCode: "AU",
    iconName: "AustraliaFlag",
    code: "+61",
    mask: "([9]) [9999] [9999]",
    maskPlaceholder: "(X) XXXX XXXX",
  },
  {
    id: "countries.aruba",
    countryCode: "AW",
    iconName: "ArubaFlag",
    code: "+297",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.azerbaijan",
    countryCode: "AZ",
    iconName: "AzerbaijanFlag",
    code: "+994",
    mask: "[99] [999] [99] [99]",
    maskPlaceholder: "XX XXX XX XX",
  },
  {
    id: "countries.aland_islands",
    countryCode: "AX",
    iconName: "AlandIslandsFlag",
    code: "+358",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.algeria",
    countryCode: "DZ",
    iconName: "AlgeriaFlag",
    code: "+213",
    mask: "([999]) [99] [99] [99]",
    maskPlaceholder: "(XXX) XX XX XX",
  },
  {
    id: "countries.bosnia",
    countryCode: "BA",
    iconName: "BAHFlag",
    code: "+387",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.barbados",
    countryCode: "BB",
    iconName: "BarbadosFlag",
    code: "+1 (246)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.bangladesh",
    countryCode: "BD",
    iconName: "BangladeshFlag",
    code: "+880",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.belgium",
    countryCode: "BE",
    iconName: "BelgiumFlag",
    code: "+32",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.burkinafaso",
    countryCode: "BF",
    iconName: "BurkinaFasoFlag",
    code: "+226",
    mask: "[99] [99] [9999]",
    maskPlaceholder: "XX XX XXXX",
  },
  {
    id: "countries.bulgaria",
    countryCode: "BG",
    iconName: "BulgariaFlag",
    code: "+359",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.bahrain",
    countryCode: "BH",
    iconName: "BahrainFlag",
    code: "+973",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.burundi",
    countryCode: "BI",
    iconName: "BurundiFlag",
    code: "+257",
    mask: "[99] [99] [9999]",
    maskPlaceholder: "XX XX XXXX",
  },
  {
    id: "countries.benin",
    countryCode: "BJ",
    iconName: "BeninFlag",
    code: "+229",
    mask: "[99] [99] [9999]",
    maskPlaceholder: "XX XX XXXX",
  },
  {
    id: "countries.bermuda",
    countryCode: "BM",
    iconName: "BermudaFlag",
    code: "+1 (441)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.brunei",
    countryCode: "BN",
    iconName: "BruneiFlag",
    code: "+673",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.bolivia",
    countryCode: "BO",
    iconName: "BoliviaFlag",
    code: "+591",
    mask: "[9] [999] [9999]",
    maskPlaceholder: "X XXX XXXX",
  },
  {
    id: "countries.brazil",
    countryCode: "BR",
    iconName: "BrazyliyaFlag",
    code: "+55",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.bahamas",
    countryCode: "BS",
    iconName: "BahamasFlag",
    code: "+1 (242)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.butane",
    countryCode: "BT",
    iconName: "ButaneFlag",
    code: "+975",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.botswana",
    countryCode: "BW",
    iconName: "BotswanaFlag",
    code: "+267",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.belarus",
    countryCode: "BY",
    iconName: "BelarusFlag",
    code: "+375",
    mask: "([99]) [999] [99] [99]",
    maskPlaceholder: "(XX) XXX XX XX",
  },
  {
    id: "countries.belize",
    countryCode: "BZ",
    iconName: "BelizeFlag",
    code: "+501",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.canada",
    countryCode: "CA",
    iconName: "CanadaFlag",
    code: "+1",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.congodemocraticrepublic",
    countryCode: "CD",
    iconName: "ZaireFlag",
    code: "+243",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.car",
    countryCode: "CF",
    iconName: "CARFlag",
    code: "+236",
    mask: "[99] [99] [9999]",
    maskPlaceholder: "XX XX XXXX",
  },
  {
    id: "countries.congo",
    countryCode: "CG",
    iconName: "CongoFlag",
    code: "+242",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.switzerland",
    countryCode: "CH",
    iconName: "SwitzerlandFlag",
    code: "+41",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.cote_dlvoire",
    countryCode: "CI",
    iconName: "CotedlvoireFlag",
    code: "+225",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.cookislands",
    countryCode: "CK",
    iconName: "CookIslandsFlag",
    code: "+682",
    mask: "[99] [999]",
    maskPlaceholder: "XX XXX",
  },
  {
    id: "countries.chile",
    countryCode: "CL",
    iconName: "ChileFlag",
    code: "+56",
    mask: "[9] [9999] [9999]",
    maskPlaceholder: "X XXXX XXXX",
  },
  {
    id: "countries.cameroon",
    countryCode: "CM",
    iconName: "CameroonFlag",
    code: "+237",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.colombia",
    countryCode: "CO",
    iconName: "ColombiaFlag",
    code: "+57",
    mask: "([999]) [9999] [9999]",
    maskPlaceholder: "(XXX) XXXX XXXX",
  },
  {
    id: "countries.costarica",
    countryCode: "CR",
    iconName: "CostaRicaFlag",
    code: "+506",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.cuba",
    countryCode: "CU",
    iconName: "CubaFlag",
    code: "+53",
    mask: "[9] [999] [9999]",
    maskPlaceholder: "X XXX XXXX",
  },
  {
    id: "countries.capeverde",
    countryCode: "CV",
    iconName: "CapeVerdeFlag",
    code: "+238",
    mask: "([999]) [99] [99]",
    maskPlaceholder: "(XXX) XX XX",
  },
  {
    id: "countries.curacao",
    countryCode: "CW",
    iconName: "CuracaoFlag",
    code: "+599",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.cyprus",
    countryCode: "CY",
    iconName: "CyprusFlag",
    code: "+357",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.czechrepublic",
    countryCode: "CZ",
    iconName: "CzechRepublicFlag",
    code: "+420",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.djibouti",
    countryCode: "DJ",
    iconName: "DjiboutiFlag",
    code: "+253",
    mask: "[99] [99] [99] [99]",
    maskPlaceholder: "XX XX XX XX",
  },
  {
    id: "countries.danmark",
    countryCode: "DK",
    iconName: "DanmarkFlag",
    code: "+45",
    mask: "[99] [99] [99] [99]",
    maskPlaceholder: "XX XX XX XX",
  },
  {
    id: "countries.dominica",
    countryCode: "DM",
    iconName: "DominicaFlag",
    code: "+1 (767)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.dominicanrepublic",
    countryCode: "DO",
    iconName: "DominicanRepublicFlag",
    code: "+1 (809)",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.ecuador",
    countryCode: "EC",
    iconName: "EcuadorFlag",
    code: "+593",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.estonia",
    countryCode: "EE",
    iconName: "EstoniaFlag",
    code: "+372",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.egypt",
    countryCode: "EG",
    iconName: "EgyptFlag",
    code: "+20",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.eritrea",
    countryCode: "ER",
    iconName: "EritreaFlag",
    code: "+291",
    mask: "[9] [999] [999]",
    maskPlaceholder: "X XXX XXX",
  },
  {
    id: "countries.ethiopia",
    countryCode: "ET",
    iconName: "EthiopiaFlag",
    code: "+251",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.spain",
    countryCode: "ES",
    iconName: "SpainFlag",
    code: "+34",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.finland",
    countryCode: "FI",
    iconName: "FinlandFlag",
    code: "+358",
    mask: "([999]) [999] [99] [99]",
    maskPlaceholder: "(XXX) XXX XX XX",
  },
  {
    id: "countries.fiji",
    countryCode: "FJ",
    iconName: "FijiFlag",
    code: "+679",
    mask: "[99] [99999]",
    maskPlaceholder: "XX XXXXX",
  },
  {
    id: "countries.falklands",
    countryCode: "FK",
    iconName: "FalklandIslandsFlag",
    code: "+500",
    mask: "[99999]",
    maskPlaceholder: "XXXXX",
  },
  {
    id: "countries.micronesia",
    countryCode: "FM",
    iconName: "MicronesiaFlag",
    code: "+691",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.faroeislands",
    countryCode: "FO",
    iconName: "FaroeIslandsFlag",
    code: "+298",
    mask: "[999] [999]",
    maskPlaceholder: "XXX XXX",
  },
  {
    id: "countries.pierre_and_miquelon",
    countryCode: "PM",
    iconName: "FranceFlag",
    code: "+508",
    mask: "[99] [9999]",
    maskPlaceholder: "XX XXXX",
  },
  {
    id: "countries.french_antilles",
    countryCode: "MF",
    iconName: "FranceFlag",
    code: "+590",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.gabon",
    countryCode: "GA",
    iconName: "GabonFlag",
    code: "+241",
    mask: "[9] [99] [99] [99]",
    maskPlaceholder: "X XX XX XX",
  },
  {
    id: "countries.grenada",
    countryCode: "GD",
    iconName: "GrenadaFlag",
    code: "+1 (473)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.georgia",
    countryCode: "GE",
    iconName: "GeorgiaFlag",
    code: "+995",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.guiana_french",
    countryCode: "GF",
    iconName: "FranceFlag",
    code: "+594",
    mask: "[99999] [9999]",
    maskPlaceholder: "XXXXX XXXX",
  },
  {
    id: "countries.ghana",
    countryCode: "GH",
    iconName: "GhanaFlag",
    code: "+233",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.gibraltar",
    countryCode: "GI",
    iconName: "GibraltarFlag",
    code: "+350",
    mask: "[999] [99999]",
    maskPlaceholder: "XXX XXXXX",
  },
  {
    id: "countries.greenland",
    countryCode: "GL",
    iconName: "GreenlandFlag",
    code: "+299",
    mask: "[99] [99] [99]",
    maskPlaceholder: "XX XX XX",
  },
  {
    id: "countries.gambia",
    countryCode: "GM",
    iconName: "GambiaFlag",
    code: "+220",
    mask: "([999]) [99] [99]",
    maskPlaceholder: "(XXX) XX XX",
  },
  {
    id: "countries.guinea",
    countryCode: "GN",
    iconName: "GuineaFlag",
    code: "+224",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.equatorialguinea",
    countryCode: "GQ",
    iconName: "EquatorialGuineaFlag",
    code: "+240",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.greece",
    countryCode: "GR",
    iconName: "GreeceFlag",
    code: "+30",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.guatemala",
    countryCode: "GT",
    iconName: "GuatemalaFlag",
    code: "+502",
    mask: "[9] [999] [9999]",
    maskPlaceholder: "X XXX XXXX",
  },
  {
    id: "countries.guam",
    countryCode: "GU",
    iconName: "GuamFlag",
    code: "+1 (671)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.guineabissau",
    countryCode: "GW",
    iconName: "GuineaBissauFlag",
    code: "+245",
    mask: "[9] [999999]",
    maskPlaceholder: "X XXXXXX",
  },
  {
    id: "countries.guyana",
    countryCode: "GY",
    iconName: "GuyanaFlag",
    code: "+592",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.hongkong",
    countryCode: "HK",
    iconName: "HongKongFlag",
    code: "+852",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.honduras",
    countryCode: "HN",
    iconName: "HondurasFlag",
    code: "+504",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.croatia",
    countryCode: "HR",
    iconName: "CroatiaFlag",
    code: "+385",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.haiti",
    countryCode: "HT",
    iconName: "HaitiFlag",
    code: "+509",
    mask: "[99] [99] [9999]",
    maskPlaceholder: "XX XX XXXX",
  },
  {
    id: "countries.hungary",
    countryCode: "HU",
    iconName: "HungaryFlag",
    code: "+36",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.indonesia",
    countryCode: "ID",
    iconName: "IndonesiaFlag",
    code: "+62",
    mask: "(8[99]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.irish_republic",
    countryCode: "IE",
    iconName: "IrishRepublicFlag",
    code: "+353",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.israel",
    countryCode: "IL",
    iconName: "IsraelFlag",
    code: "+972",
    mask: "[9] [999] [99999999999]",
    maskPlaceholder: "X XXX XXXX",
  },
  {
    id: "countries.india",
    countryCode: "IN",
    iconName: "IndiaFlag",
    code: "+91",
    mask: "([9999]) [999] [999]",
    maskPlaceholder: "(XXXX) XXX XXX",
  },
  {
    id: "countries.diego_garcia",
    countryCode: "IO",
    iconName: "DiegoGarciaFlag",
    code: "+246",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.iraq",
    countryCode: "IQ",
    iconName: "IraqFlag",
    code: "+964",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.iran",
    countryCode: "IR",
    iconName: "IranFlag",
    code: "+98",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.iceland",
    countryCode: "IS",
    iconName: "IcelandFlag",
    code: "+354",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.italy",
    countryCode: "IT",
    iconName: "ItalyFlag",
    code: "+39",
    mask: "([999]) [9999] [999]",
    maskPlaceholder: "(XXX) XXXX XXX",
  },
  {
    id: "countries.jamaica",
    countryCode: "JM",
    iconName: "JamaicaFlag",
    code: "+1 (876)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.jordan",
    countryCode: "JO",
    iconName: "JordanFlag",
    code: "+962",
    mask: "[9] [9999] [9999]",
    maskPlaceholder: "X XXXX XXXX",
  },
  {
    id: "countries.japan",
    countryCode: "JP",
    iconName: "JapanFlag",
    code: "+81",
    mask: "[99] [9999] [9999]",
    maskPlaceholder: "XX XXXX XXXX",
  },
  {
    id: "countries.kenya",
    countryCode: "KE",
    iconName: "KenyaFlag",
    code: "+254",
    mask: "[999] [999999]",
    maskPlaceholder: "XXX XXXXXX",
  },
  {
    id: "countries.kyrgyzstan",
    countryCode: "KG",
    iconName: "KyrgyzstanFlag",
    code: "+996",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.cambodia",
    countryCode: "KH",
    iconName: "CambodiaFlag",
    code: "+855",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.kiribati_republic",
    countryCode: "KI",
    iconName: "KiribatiRepublicFlag",
    code: "+686",
    mask: "[99] [999]",
    maskPlaceholder: "XX XXX",
  },
  {
    id: "countries.comoros_and_mayotte_island",
    countryCode: "KM",
    iconName: "ComorosAndMayotteFlag",
    code: "+269",
    mask: "[99] [99999]",
    maskPlaceholder: "XX XXXXX",
  },
  {
    id: "countries.kitts_and_nevis",
    countryCode: "KN",
    iconName: "KittsAndNevisFlag",
    code: "+1 (869)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.northkorea",
    countryCode: "KP",
    iconName: "NorthKoreaFlag",
    code: "+850",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.southkorea",
    countryCode: "KR",
    iconName: "SouthKoreaFlag",
    code: "+82",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.kuwait",
    countryCode: "KW",
    iconName: "KuwaitFlag",
    code: "+965",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.caymanislands",
    countryCode: "KY",
    iconName: "CaymanIslandsFlag",
    code: "+1 (345)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.kazakhstan",
    countryCode: "KZ",
    iconName: "KazakhstanFlag",
    code: "+7",
    mask: "(7[99]) [999] [99] [99]",
    maskPlaceholder: "(XXX) XXX XX XX",
  },
  {
    id: "countries.laos",
    countryCode: "LA",
    iconName: "LaosFlag",
    code: "+856",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.lebanon",
    countryCode: "LB",
    iconName: "LebanonFlag",
    code: "+961",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.lucia",
    countryCode: "LC",
    iconName: "LuciaFlag",
    code: "+1 (758)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.liechtenstein",
    countryCode: "LI",
    iconName: "LiechtensteinFlag",
    code: "+423",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.srilanka",
    countryCode: "LK",
    iconName: "SriLankaFlag",
    code: "+94",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.liberia",
    countryCode: "LR",
    iconName: "LiberiaFlag",
    code: "+231",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.lesotho",
    countryCode: "LS",
    iconName: "LesothoFlag",
    code: "+266",
    mask: "[9] [999] [9999]",
    maskPlaceholder: "X XXX XXXX",
  },
  {
    id: "countries.lithuania",
    countryCode: "LT",
    iconName: "LithuaniaFlag",
    code: "+370",
    mask: "([999]) [99] [999]",
    maskPlaceholder: "(XXX) XX XXX",
  },
  {
    id: "countries.luxembourg",
    countryCode: "LU",
    iconName: "LuxembourgFlag",
    code: "+352",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.latvia",
    countryCode: "LV",
    iconName: "LatviaFlag",
    code: "+371",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.libya",
    countryCode: "LY",
    iconName: "LibyaFlag",
    code: "+218",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.marocco",
    countryCode: "MA",
    iconName: "MaroccoFlag",
    code: "+212",
    mask: "[99] [9999] [999]",
    maskPlaceholder: "XX XXXX XXX",
  },
  {
    id: "countries.monaco",
    countryCode: "MC",
    iconName: "MonacoFlag",
    code: "+377",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.moldova",
    countryCode: "MD",
    iconName: "MoldovaFlag",
    code: "+373",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.montenegro",
    countryCode: "ME",
    iconName: "MontenegroFlag",
    code: "+382",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.madagascar",
    countryCode: "MG",
    iconName: "MadagascarFlag",
    code: "+261",
    mask: "[99] [99] [99999]",
    maskPlaceholder: "XX XX XXXXX",
  },
  {
    id: "countries.marshallislands",
    countryCode: "MH",
    iconName: "MarshallIslandsFlag",
    code: "+692",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.macedonia",
    countryCode: "MK",
    iconName: "MacedoniaFlag",
    code: "+389",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.mali",
    countryCode: "ML",
    iconName: "MaliFlag",
    code: "+223",
    mask: "[99] [99] [9999]",
    maskPlaceholder: "XX XX XXXX",
  },
  {
    id: "countries.burma",
    countryCode: "MM",
    iconName: "MyanmarFlag",
    code: "+95",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.mongolia",
    countryCode: "MN",
    iconName: "MongoliaFlag",
    code: "+976",
    mask: "[99] [99] [9999]",
    maskPlaceholder: "XX XX XXXX",
  },
  {
    id: "countries.macao",
    countryCode: "MO",
    iconName: "MacaoFlag",
    code: "+853",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.commonwealth_of_the_northern_mariana_islands",
    countryCode: "MP",
    iconName: "CommonwealthOfTheNorhernMarianaIslandsFlag",
    code: "+1 (670)",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.martinique",
    countryCode: "MQ",
    iconName: "MartiniqueFlag",
    code: "+596",
    mask: "([999]) [99] [99] [99]",
    maskPlaceholder: "(XXX) XX XX XX",
  },
  {
    id: "countries.mauritania",
    countryCode: "MR",
    iconName: "MauritaniaFlag",
    code: "+222",
    mask: "[99] [99] [9999]",
    maskPlaceholder: "XX XX XXXX",
  },
  {
    id: "countries.montserrat",
    countryCode: "MS",
    iconName: "MontserratFlag",
    code: "+1 (664)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.malta",
    countryCode: "MT",
    iconName: "MaltaFlag",
    code: "+356",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.mauritius",
    countryCode: "MU",
    iconName: "MauritiusFlag",
    code: "+230",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.maldives",
    countryCode: "MV",
    iconName: "MaldivesFlag",
    code: "+960",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.malawi",
    countryCode: "MW",
    iconName: "MalawiFlag",
    code: "+265",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.mexico",
    countryCode: "MX",
    iconName: "MexicoFlag",
    code: "+52",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.malaysia",
    countryCode: "MY",
    iconName: "MalaysiaFlag",
    code: "+60",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.mozambique",
    countryCode: "MZ",
    iconName: "MozambiqueFlag",
    code: "+258",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.namibia",
    countryCode: "NA",
    iconName: "NamibiaFlag",
    code: "+264",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.newcaledonia",
    countryCode: "NC",
    iconName: "NewCaledoniaFlag",
    code: "+687",
    mask: "[99] [9999]",
    maskPlaceholder: "XX XXXX",
  },
  {
    id: "countries.niger",
    countryCode: "NE",
    iconName: "NigerFlag",
    code: "+227",
    mask: "[99] [99] [9999]",
    maskPlaceholder: "XX XX XXXX",
  },
  {
    id: "countries.norfolk_island",
    countryCode: "NF",
    iconName: "NorfolkIslandFlag",
    code: "+672",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.nigeria",
    countryCode: "NG",
    iconName: "NigeriaFlag",
    code: "+234",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.nicaragua",
    countryCode: "NI",
    iconName: "NicaraguaFlag",
    code: "+505",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.netherlands",
    countryCode: "NL",
    iconName: "NetherlandsFlag",
    code: "+31",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.norway",
    countryCode: "NO",
    iconName: "NorwayFlag",
    code: "+47",
    mask: "([999]) [99] [999]",
    maskPlaceholder: "(XXX) XX XXX",
  },
  {
    id: "countries.nepal",
    countryCode: "NP",
    iconName: "NepalFlag",
    code: "+977",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.nauru",
    countryCode: "NR",
    iconName: "NauruFlag",
    code: "+674",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.niue",
    countryCode: "NU",
    iconName: "NiueFlag",
    code: "+683",
    mask: "[9999]",
    maskPlaceholder: "XXXX",
  },
  {
    id: "countries.newzealand",
    countryCode: "NZ",
    iconName: "NewZealandFlag",
    code: "+64",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.oman",
    countryCode: "OM",
    iconName: "OmanFlag",
    code: "+968",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.panama",
    countryCode: "PA",
    iconName: "PanamaFlag",
    code: "+507",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.peru",
    countryCode: "PE",
    iconName: "PeruFlag",
    code: "+51",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.frenchpolynesia",
    countryCode: "PF",
    iconName: "FrenchPolynesiaFlag",
    code: "+689",
    mask: "[99] [99] [99]",
    maskPlaceholder: "XX XX XX",
  },
  {
    id: "countries.papuanewguinea",
    countryCode: "PG",
    iconName: "PapuaNewGuineaFlag",
    code: "+675",
    mask: "([999]) [99] [999]",
    maskPlaceholder: "(XXX) XX XXX",
  },
  {
    id: "countries.philippines",
    countryCode: "PH",
    iconName: "PhilippinesFlag",
    code: "+63",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.pakistan",
    countryCode: "PK",
    iconName: "PakistanFlag",
    code: "+92",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.poland",
    countryCode: "PL",
    iconName: "PolandFlag",
    code: "+48",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.palestine",
    countryCode: "PS",
    iconName: "PalestinFlag",
    code: "+970",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.portugal",
    countryCode: "PT",
    iconName: "PortugalFlag",
    code: "+351",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.palay",
    countryCode: "PW",
    iconName: "PalayFlag",
    code: "+680",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.paraguay",
    countryCode: "PY",
    iconName: "ParaguayFlag",
    code: "+595",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.qatar",
    countryCode: "QA",
    iconName: "QatarFlag",
    code: "+974",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.reunion",
    countryCode: "RE",
    iconName: "FranceFlag",
    code: "+262",
    mask: "[99999] [9999]",
    maskPlaceholder: "XXXXX XXXX",
  },
  {
    id: "countries.romania",
    countryCode: "RO",
    iconName: "RomaniaFlag",
    code: "+40",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.srbija",
    countryCode: "RS",
    iconName: "SrbijaFlag",
    code: "+381",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.russia",
    countryCode: "RU",
    iconName: "RussiaFlag",
    code: "+7",
    mask: "([999]) [999] [99] [99]",
    maskPlaceholder: "(XXX) XXX XX XX",
  },
  {
    id: "countries.rwandese_republic",
    countryCode: "RW",
    iconName: "RwandeseRepublicFlag",
    code: "+250",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.saudiarabia",
    countryCode: "SA",
    iconName: "SaudiArabiaFlag",
    code: "+966",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.solomonislands",
    countryCode: "SB",
    iconName: "SolomonIslandsFlag",
    code: "+677",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.seychelles",
    countryCode: "SC",
    iconName: "SeychellesFlag",
    code: "+248",
    mask: "[9] [999] [999]",
    maskPlaceholder: "X XXX XXX",
  },
  {
    id: "countries.sudan",
    countryCode: "SD",
    iconName: "SudanFlag",
    code: "+249",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.sweden",
    countryCode: "SE",
    iconName: "SwedenFlag",
    code: "+46",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.singapore",
    countryCode: "SG",
    iconName: "SingaporeFlag",
    code: "+65",
    mask: "[9999] [9999]",
    maskPlaceholder: "XXXX XXXX",
  },
  {
    id: "countries.st_helena",
    countryCode: "SH",
    iconName: "StHelenaFlag",
    code: "+290",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.slovenia",
    countryCode: "SI",
    iconName: "SloveniaFlag",
    code: "+386",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.slovakia",
    countryCode: "SK",
    iconName: "SlovakiaFlag",
    code: "+421",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.sierraleone",
    countryCode: "SL",
    iconName: "SierraLeoneFlag",
    code: "+232",
    mask: "[99] [999999]",
    maskPlaceholder: "XX XXXXXX",
  },
  {
    id: "countries.sanmarino",
    countryCode: "SM",
    iconName: "SanMarinoFlag",
    code: "+378",
    mask: "[9999] [999999]",
    maskPlaceholder: "XXXX XXXXXX",
  },
  {
    id: "countries.senegal",
    countryCode: "SN",
    iconName: "SenegalFlag",
    code: "+221",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.somalia",
    countryCode: "SO",
    iconName: "SomaliaFlag",
    code: "+252",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.suriname",
    countryCode: "SR",
    iconName: "SurinameFlag",
    code: "+597",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.southsudan",
    countryCode: "SS",
    iconName: "SouthSudanFlag",
    code: "+211",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.saotomeandprincipe",
    countryCode: "ST",
    iconName: "SaoTomeAndPrincipeFlag",
    code: "+239",
    mask: "[99] [99999]",
    maskPlaceholder: "XX XXXXX",
  },
  {
    id: "countries.elsalvador",
    countryCode: "SV",
    iconName: "ElSalvadorFlag",
    code: "+503",
    mask: "[99] [99] [9999]",
    maskPlaceholder: "XX XX XXXX",
  },
  {
    id: "countries.sintmaarten",
    countryCode: "SX",
    iconName: "SintMaartenFlag",
    code: "+1 (721)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.syria",
    countryCode: "SY",
    iconName: "SyriaFlag",
    code: "+963",
    mask: "[99] [9999] [999]",
    maskPlaceholder: "XX XXXX XXX",
  },
  {
    id: "countries.swaziland",
    countryCode: "SZ",
    iconName: "SwazilandFlag",
    code: "+268",
    mask: "[99] [99] [9999]",
    maskPlaceholder: "XX XX XXXX",
  },
  {
    id: "countries.turksandcaicos",
    countryCode: "TC",
    iconName: "TurksAndCaicosIslandsFlag",
    code: "+1 (649)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.chad",
    countryCode: "TD",
    iconName: "ChadFlag",
    code: "+235",
    mask: "[99] [99] [99] [99]",
    maskPlaceholder: "XX XX XX XX",
  },
  {
    id: "countries.togolese_republic",
    countryCode: "TG",
    iconName: "TogoleseRepublicFlag",
    code: "+228",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.thailand",
    countryCode: "TH",
    iconName: "ThailandFlag",
    code: "+66",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.tadjikistan",
    countryCode: "TJ",
    iconName: "TadjikistanFlag",
    code: "+992",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.tokelau",
    countryCode: "TK",
    iconName: "TokelauFlag",
    code: "+690",
    mask: "[9999]",
    maskPlaceholder: "XXXX",
  },
  {
    id: "countries.turkmenistan",
    countryCode: "TM",
    iconName: "TurkmenistanFlag",
    code: "+993",
    mask: "[9] [999] [9999]",
    maskPlaceholder: "X XXX XXXX",
  },
  {
    id: "countries.tunisia",
    countryCode: "TN",
    iconName: "TunisiaFlag",
    code: "+216",
    mask: "[99] [999] [999]",
    maskPlaceholder: "XX XXX XXX",
  },
  {
    id: "countries.tonga",
    countryCode: "TO",
    iconName: "TongaFlag",
    code: "+676",
    mask: "[99999]",
    maskPlaceholder: "XXXXX",
  },
  {
    id: "countries.turkey",
    countryCode: "TR",
    iconName: "TurkeyFlag",
    code: "+90",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.trinidadandtobago",
    countryCode: "TT",
    iconName: "TrinidadAndTobagoFlag",
    code: "+1 (868)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.tuvalu",
    countryCode: "TV",
    iconName: "TuvaluFlag",
    code: "+688",
    mask: "[999999]",
    maskPlaceholder: "XXXXXX",
  },
  {
    id: "countries.taiwan",
    countryCode: "TW",
    iconName: "TaiwanFlag",
    code: "+886",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.tanzania",
    countryCode: "TZ",
    iconName: "TanzaniaFlag",
    code: "+255",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.uganda",
    countryCode: "UG",
    iconName: "UgandaFlag",
    code: "+256",
    mask: "([999]) [999] [999]",
    maskPlaceholder: "(XXX) XXX XXX",
  },
  {
    id: "countries.uruguay",
    countryCode: "UY",
    iconName: "UruguayFlag",
    code: "+598",
    mask: "[9] [999] [99] [99]",
    maskPlaceholder: "X XXX XX XX",
  },
  {
    id: "countries.uzbekistan",
    countryCode: "UZ",
    iconName: "UzbekistanFlag",
    code: "+998",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.vatican",
    countryCode: "VA",
    iconName: "VaticanFlag",
    code: "+37",
    mask: "[9] [999] [99999]",
    maskPlaceholder: "X XXX XXXXX",
  },
  {
    id: "countries.st_vincent_and_the_grenadines",
    countryCode: "VC",
    iconName: "StVincentAndTheGrenadinesFlag",
    code: "+1 (784)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.venezuela",
    countryCode: "VE",
    iconName: "VenezuelaFlag",
    code: "+58",
    mask: "([999]) [999] [9999]",
    maskPlaceholder: "(XXX) XXX XXXX",
  },
  {
    id: "countries.britishvirginislands",
    countryCode: "VG",
    iconName: "BritishVirginIslandsFlag",
    code: "+1 (284)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.virginislands",
    countryCode: "VI",
    iconName: "UsVirginIslandsFlag",
    code: "+1 (340)",
    mask: "[999] [9999]",
    maskPlaceholder: "XXX XXXX",
  },
  {
    id: "countries.vietnam",
    countryCode: "VN",
    iconName: "VietnamFlag",
    code: "+84",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.vanuatu",
    countryCode: "VU",
    iconName: "VanuatuFlag",
    code: "+678",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.wallisandfutuna",
    countryCode: "WF",
    iconName: "FranceFlag",
    code: "+681",
    mask: "[99] [9999]",
    maskPlaceholder: "XX XXXX",
  },
  {
    id: "countries.western_samoa",
    countryCode: "WS",
    iconName: "WesternSamoaFlag",
    code: "+685",
    mask: "[99] [9999]",
    maskPlaceholder: "XX XXXX",
  },
  {
    id: "countries.north_yemen",
    countryCode: "YE",
    iconName: "NorthYemenFlag",
    code: "+967",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
  {
    id: "countries.republic_of_south_africa",
    countryCode: "ZA",
    iconName: "RepublicOfSouthAfricaFlag",
    code: "+27",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.zambia",
    countryCode: "ZM",
    iconName: "ZambiaFlag",
    code: "+260",
    mask: "[99] [999] [9999]",
    maskPlaceholder: "XX XXX XXXX",
  },
  {
    id: "countries.zimbabwe",
    countryCode: "ZW",
    iconName: "ZimbabweFlag",
    code: "+263",
    mask: "[9] [999999]",
    maskPlaceholder: "X XXXXXX",
  },
  {
    id: "countries.puertorico",
    countryCode: "PR",
    iconName: "PuertoRicoFlag",
    code: "+1",
    mask: "[99999999999999999]",
    maskPlaceholder: " ",
  },
];
