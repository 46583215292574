import { Button, Grid, TextField } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { CommentItem } from "./components/CommentItem";
import { IMediaComment } from "../../../store/slices/userPhoto";
import { materialStyles } from "./styles";
import { UserAvatar } from "../../user/UserAvatar";
import { useAppSelector } from "../../../store";
import { useTranslation } from "react-i18next";

type ICommentsList = {
  comments: IMediaComment[];
  onSendComment?: (text: string) => void;
};

export const CommentsList: React.FC<ICommentsList> = ({
  comments,
  onSendComment: onSendCommentFromProps,
}) => {
  const { user } = useAppSelector((state) => state.user);
  const [newComment, setNewComment] = useState("");
  const { t } = useTranslation();

  const sortedComments = useMemo(
    () => _.orderBy(comments, ["created_at"], ["asc"]),
    [comments]
  );

  const isDisabledSend = useMemo(() => _.trim(newComment) === "", [newComment]);

  const onChangeNewComment = useCallback((event: any) => {
    const { value } = event.target;

    setNewComment(value);
  }, []);

  const onSendComment = useCallback(
    (text: string) => {
      setNewComment("");
      onSendCommentFromProps && onSendCommentFromProps(text);
    },
    [onSendCommentFromProps]
  );

  return (
    <Grid sx={materialStyles.commentsListWithSendInput}>
      <Grid sx={materialStyles.commentsList}>
        {_.map(sortedComments, (comment) => (
          <CommentItem key={comment.id} comment={comment} />
        ))}
      </Grid>
      <Grid sx={materialStyles.sendInputContainer}>
        <UserAvatar type="AVATAR_LIST" user={user} />
        <TextField
          fullWidth
          multiline
          maxRows={4}
          placeholder={t("photos.add_comment")}
          sx={materialStyles.sendInput}
          value={newComment}
          onChange={onChangeNewComment}
          InputProps={{
            endAdornment: onSendComment ? (
              <Button
                disabled={isDisabledSend}
                onClick={() => onSendComment(_.trim(newComment))}
                sx={materialStyles.sendButton}
              >
                {t("photos.post")}
              </Button>
            ) : null,
          }}
        />
      </Grid>
    </Grid>
  );
};
