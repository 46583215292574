import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getMedia } from "../store/thunks/media";
import {
  resetMyPhotos,
  setMyPhotos,
  setMyPhotosPage,
  setMyPhotosPageSize,
} from "../store/slices/myPhotos";
import {
  DEFAULT_GLOBE_PAGE_SIZE,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "../constants/api";

export const useFetchPhotos = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { photos, filters } = useAppSelector((state) => state.myPhotos);
  const { mode } = useAppSelector((state) => state.visualMode);

  const fetchPhotos = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        if (!isAuth) {
          return;
        }

        const media = await dispatch(getMedia(page, pageSize, filters));

        dispatch(setMyPhotos(media));
      } catch (error) {
        console.error("Error while [fetchPhotos]", error);
      }
    },
    [dispatch, filters, isAuth]
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setMyPhotosPage(photos.page + 1));
    dispatch(setMyPhotosPageSize(DEFAULT_PAGE_SIZE));
  }, [photos.page, dispatch]);

  const refetchMyPhotos = useCallback(() => {
    fetchPhotos(photos.page);
  }, [fetchPhotos, photos.page]);

  useEffect(() => {
    const defaultPageSize = {
      GRID: DEFAULT_PAGE_SIZE,
      GLOBE: DEFAULT_GLOBE_PAGE_SIZE,
    }[mode];

    fetchPhotos(DEFAULT_PAGE, defaultPageSize);

    return () => {
      dispatch(resetMyPhotos());
    };
  }, [dispatch, mode, fetchPhotos]);

  useEffect(() => {
    if (photos.page !== DEFAULT_PAGE) {
      fetchPhotos(photos.page, photos.pageSize);
    }
  }, [photos.page, photos.pageSize, fetchPhotos]);

  return {
    photos: photos.data,
    onUpdatePageOrPageSize,
    refetchMyPhotos,
  };
};
