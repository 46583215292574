import { useCallback, useEffect, useMemo } from "react";
import { socket } from "../socket";
import { useAppDispatch, useAppSelector } from "../store";
import { Message, updateChatInMessages } from "../store/slices/messages";

type ChatMessageSocketEvent = {
  id: number;
  user_id: number;
  sender_id: number;
};

export const useSocketChatHandle = (chat: Message) => {
  const { isConnected } = useAppSelector((state) => state.socket);
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const userId = useMemo(() => {
    if (!user) {
      return;
    }

    if (chat.sender_id === user.id) {
      return chat.user_id;
    }

    if (chat.user_id === user.id) {
      return chat.sender_id;
    }
  }, [chat.sender_id, chat.user_id, user]);

  const onSocketJoin = useCallback(() => {
    if (isConnected) {
      socket.emit("chat:join", userId);
    }
  }, [userId, isConnected]);

  const onSocketLeave = useCallback(() => {
    if (isConnected) {
      socket.emit("chat:leave", userId);
    }
  }, [userId, isConnected]);

  const handleChatSend = useCallback(
    (message: Message) => {
      if (message.sender_id === userId || message.user_id === userId) {
        dispatch(
          updateChatInMessages({
            id: chat.id,
            message,
          }),
        );
      }
    },
    [chat, dispatch, userId],
  );

  const handleChatRead = useCallback(
    (messageEvent: ChatMessageSocketEvent) => {
      if (chat.id === messageEvent.id && !chat.is_read) {
        dispatch(
          updateChatInMessages({
            id: messageEvent.id,
            message: {
              ...chat,
              is_read: true,
            },
          }),
        );
      }
    },
    [chat, dispatch],
  );

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    socket.on("chat:send", handleChatSend);
    socket.on("chat:read", handleChatRead);

    return () => {
      if (isConnected) {
        socket.off("chat:send", handleChatSend);
        socket.off("chat:read", handleChatRead);
      }
    };
  }, [handleChatRead, handleChatSend, isConnected]);

  return {
    onSocketJoin,
    onSocketLeave,
  };
};
