import { Box, Button, IconButton } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useTranslation } from "react-i18next";
import { TVideoEditor } from "../../../hooks/useVideoEditorReducer";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";

type VideoEditorToolBar = {
  videoEditor: TVideoEditor;
  onSaveVideo?: () => void;
  onResetEditor?: () => void;
  onPlayOrStopVideo?: () => void;
  onMuteOrUnMuteVideo?: () => void;
};

export const Toolbar: React.FC<VideoEditorToolBar> = ({
  videoEditor,
  onSaveVideo,
  onPlayOrStopVideo,
  onMuteOrUnMuteVideo,
  onResetEditor,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={materialStyles.toobarContainer}>
      <Box>
        <IconButton
          onClick={onMuteOrUnMuteVideo}
          sx={materialStyles.toolbarButton}
        >
          {videoEditor.isMuted ? <VolumeMuteIcon /> : <VolumeUpIcon />}
        </IconButton>
        <IconButton
          onClick={onPlayOrStopVideo}
          sx={materialStyles.toolbarButton}
        >
          {videoEditor.isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <IconButton onClick={onResetEditor} sx={materialStyles.toolbarButton}>
          <RestartAltIcon />
        </IconButton>
      </Box>
      <Button onClick={onSaveVideo} sx={materialStyles.saveButton}>
        {t("common.save")}
      </Button>
    </Box>
  );
};
