import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { getChat } from "../store/thunks/chats";
import { setChatMessages } from "../store/slices/chat";

export const useFetchChat = () => {
  const { messages, user } = useAppSelector((state) => state.chat);
  const dispatch = useAppDispatch();

  const fetchChat = useCallback(
    async (userId: number) => {
      try {
        const messages = await dispatch(getChat(userId));

        if (!messages) {
          throw new Error();
        }

        dispatch(setChatMessages(messages));
      } catch (error) {
        console.error("Error while [fetchChat]", error);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (user) {
      fetchChat(user.id);
    }
  }, [fetchChat, user]);

  return { messages };
};
