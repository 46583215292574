import { Grid } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type IProgressBar = {
  progress: number;
};

export const ProgressBar: React.FC<IProgressBar> = ({ progress }) => {
  return (
    <Grid sx={materialStyles.progressBarBlock}>
      <Grid
        sx={materialStyles.progressBarLine}
        style={{ width: `${progress}%` }}
      />
    </Grid>
  );
};
