import React from "react";
import { ITags } from "../../store/slices/tags";
import { ButtonBase, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";

type ITagItem = {
  tag: ITags;
  onTagClick?: (tag: ITags) => void;
  isChoosen?: boolean;
  disabled?: boolean;
  disableRipple?: boolean;
  onRemoveTag?: (tag: ITags) => void;
};

export const TagItem: React.FC<ITagItem> = ({
  tag,
  onTagClick,
  onRemoveTag,
  isChoosen = false,
  disabled = false,
  disableRipple = false,
}) => {
  return (
    <ButtonBase
      disableTouchRipple={disabled}
      disabled={disabled}
      disableRipple={disableRipple}
      sx={[materialStyles.tagItem, isChoosen && materialStyles.choosenTagItem]}
      onClick={() => onTagClick && onTagClick(tag)}
    >
      <Typography sx={materialStyles.tagItemTitle}>{tag.title}</Typography>
      {onRemoveTag ? (
        <ButtonBase onClick={() => onRemoveTag(tag)}>
          <CloseIcon sx={materialStyles.removeTagIcon} />
        </ButtonBase>
      ) : null}
    </ButtonBase>
  );
};
