import { IMaterialStyleType, ICssStyleType } from "../../types";
import { Colors, theme } from "../../theme";

export const styles: ICssStyleType = {
  userImageAvatar: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
};

export const USER_AVATAR_BACKGROUND = theme.palette.primary.light;

export const materialStyles: IMaterialStyleType = {
  defaultUserAvatar: {
    position: "relative",
    width: "48px",
    height: "48px",
    minWidth: "48px",
    minHeight: "48px",
    borderRadius: "50%",
    backgroundColor: USER_AVATAR_BACKGROUND,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  headerUserAvatar: {
    position: "relative",
    overflow: "hidden",
    width: "60px",
    height: "60px",
    minWidth: "60px",
    minHeight: "60px",
    borderRadius: "50%",
    backgroundColor: USER_AVATAR_BACKGROUND,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  defaultLettersUserAvatar: {
    color: Colors.black,
    fontSize: "18px",
    lineHeight: "24px",
  },
  headerLettersUserAvatar: {
    color: Colors.black,
    fontSize: "24px",
    lineHeight: "28px",
  },
  photoPageUserAvatar: {
    position: "relative",
    overflow: "hidden",
    width: "30px",
    height: "30px",
    minWidth: "30px",
    minHeight: "30px",
    borderRadius: "50%",
    backgroundColor: USER_AVATAR_BACKGROUND,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  photoAvatarsListsUserAvatar: {
    position: "relative",
    overflow: "hidden",
    width: "36px",
    height: "36px",
    minWidth: "36px",
    minHeight: "36px",
    borderRadius: "50%",
    backgroundColor: USER_AVATAR_BACKGROUND,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  photoPageLettersUserAvatar: {
    color: Colors.black,
    fontSize: "12px",
    lineHeight: "28px",
  },
  inPeopleListUserAvatar: {
    position: "relative",
    overflow: "hidden",
    width: "80px",
    height: "80px",
    minWidth: "80px",
    minHeight: "80px",
    borderRadius: "50%",
    backgroundColor: USER_AVATAR_BACKGROUND,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inPeopleListLettersUserAvatar: {
    color: Colors.black,
    fontSize: "32px",
    lineHeight: "42px",
  },
  inAvatarsListLettersUserAvatar: {
    color: Colors.black,
    fontSize: "14px",
    lineHeight: "18px",
  },
  popoverContainer: {
    marginTop: "8px",
    backgroundColor: theme.palette.grey[50],
    width: "230px",
  },
  actionItems: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  actionItem: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "10px",
    flex: 1,
  },
  actionItemTitle: {
    color: Colors.white,
  },
  userAvatarWithActionContainer: {
    display: "flex",
    gap: 1,
    alignItems: "center",
    flexDirection: "row",
  },
  userAvatarWithActionName: {
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
    fontSize: "16px",
    color: Colors.white,
  },
};
