import { createSlice } from "@reduxjs/toolkit";
import { IUserType } from "./user";
import { IPhotoType } from "./myPhotos";
import _ from "lodash";
import { IAlbumType } from "./albums";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../constants/api";
import { catchChangesByKeys } from "../../utils";

interface IUserProfileSliceInitialState {
  id: number | null;
  user: IUserType | null;
  userPhotos: {
    data: IPhotoType[];
    page: number;
    pageSize: number;
  };
  userAlbums: {
    data: IAlbumType[];
    page: number;
    pageSize: number;
  };
}

const userProfleSliceInitialState: IUserProfileSliceInitialState = {
  id: null,
  user: null,
  userPhotos: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  userAlbums: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
};

const userProfleSlice = createSlice({
  name: "userProfile",
  initialState: userProfleSliceInitialState,
  reducers: {
    setUserId(state, action) {
      state.id = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setProfileUserPhotos(state, action) {
      const userPhotos = state.userPhotos.data;

      if (_.isEmpty(userPhotos)) {
        state.userPhotos.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy([...userPhotos, ...action.payload], "id");

      const itemsWithCheckedChanges = catchChangesByKeys(
        updatedItems,
        action.payload,
        ["is_favorite"],
      );

      state.userPhotos.data = itemsWithCheckedChanges;
    },
    setProfileUserPhotosPage(state, action) {
      state.userPhotos.page = action.payload;
    },
    setProfileUserPhotosPageSize(state, action) {
      state.userPhotos.pageSize = action.payload;
    },
    resetProfileUserPhotos(state) {
      state.userPhotos.data = userProfleSliceInitialState.userPhotos.data;
      state.userPhotos.page = userProfleSliceInitialState.userPhotos.page;
      state.userPhotos.pageSize =
        userProfleSliceInitialState.userPhotos.pageSize;
    },
    setProfileUserAlbums(state, action) {
      const userAlbums = state.userAlbums.data;

      if (_.isEmpty(userAlbums)) {
        state.userAlbums.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy([...userAlbums, ...action.payload], "id");

      state.userAlbums.data = updatedItems;
    },
    setProfileUserAlbumsPage(state, action) {
      state.userAlbums.page = action.payload;
    },
    setProfileUserAlbumsPageSize(state, action) {
      state.userAlbums.pageSize = action.payload;
    },
    resetProfileUserAlbums(state) {
      state.userAlbums.data = userProfleSliceInitialState.userAlbums.data;
      state.userAlbums.page = userProfleSliceInitialState.userAlbums.page;
      state.userAlbums.pageSize =
        userProfleSliceInitialState.userAlbums.pageSize;
    },
    setSubscribe(state, action) {
      if (!state.user) {
        return;
      }
      state.user.is_subscribed = action.payload;
    },
    updateUserPhotosInStorage(state, action) {
      const newPhoto = action.payload;
      const photos = state.userPhotos.data;

      if (!newPhoto.id) {
        return;
      }

      const photoIndex = _.findIndex(
        photos,
        (photo) => photo.id === newPhoto.id,
      );
      const newPhotos = _.update(photos, photoIndex, () => newPhoto);

      state.userPhotos = newPhotos;
    },
    resetUserProfile(state) {
      state.user = userProfleSliceInitialState.user;
      state.id = userProfleSliceInitialState.id;
      state.userPhotos = userProfleSliceInitialState.userPhotos;
      state.userAlbums = userProfleSliceInitialState.userAlbums;
    },
  },
});

export default userProfleSlice.reducer;
export const {
  setUser,
  setUserId,
  resetUserProfile,
  setProfileUserPhotos,
  setSubscribe,
  updateUserPhotosInStorage,
  setProfileUserAlbums,
  setProfileUserAlbumsPage,
  setProfileUserAlbumsPageSize,
  resetProfileUserAlbums,
  setProfileUserPhotosPage,
  setProfileUserPhotosPageSize,
  resetProfileUserPhotos,
} = userProfleSlice.actions;
