import React, { useEffect } from "react";
import { socket } from "../../socket";
import { useAppDispatch, useAppSelector } from "../../store";
import { setSocketConnected } from "../../store/slices/socket";

type ICommonSocketHandle = {};

export const CommonSocketHandle: React.FC<ICommonSocketHandle> = () => {
  const { token } = useAppSelector((state) => state.user);
  const { isConnected } = useAppSelector((state) => state.socket);

  const dispatch = useAppDispatch();

  useEffect(() => {
    function onConnect() {
      console.log("========== Connected ==========");
      dispatch(setSocketConnected(true));
    }

    function onDisconnect() {
      console.log("========== Disconnect ==========");
      dispatch(setSocketConnected(false));
    }

    function onReconnect() {
      console.log("========== Reconnect ==========");
      dispatch(setSocketConnected(true));
    }

    if (token && !isConnected && !socket.connected) {
      socket.io.opts.query = { token };

      socket.connect();
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);

      socket.on("reconnect", onReconnect);
    }

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("reconnect", onReconnect);
    };
  }, [dispatch, isConnected, token]);

  useEffect(() => {
    if (token && socket.connected) {
      socket.emit("auth:login", { token });
    }
  }, [token]);

  return null;
};
