import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useUserPhoto } from "../../hooks/useUserPhoto";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../store";
import { LoaderWithBackdrop } from "../../components/loaders/LoaderWithBackdrop";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { Picture } from "../../components/modals/imageSlidesModal/components/Picture";
import { Image } from "../../components/common/Image";
import { FullSizeModalHeader } from "../../components/header/FullSizeModalHeader";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuth } from "../../hooks/useAuth";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import { PhotoInfoDrawer } from "../../components/modals/imageSlidesModal/components/PhotoInfoDrawer";
import { removeMediaById } from "../../store/thunks/media";
import { MediaType, removePhoto } from "../../store/slices/myPhotos";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { setShareActionId } from "../../store/slices/sharing";
import { ShareModal } from "../../components/modals/shareModal";
import { useTranslation } from "react-i18next";
import { CommonModalsDispatchContext } from "../../components/providers/CommonModalsProvider";
import { Video } from "../../components/common/Video";
import { YouTubeIFrame } from "../../components/common/YouTubeIFrame";
import { getYouTubeVideoUrlFromId } from "../../utils/youtube";

export const PhotoPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { photo } = useAppSelector((state) => state.userPhoto);
  const { user } = useAppSelector((state) => state.user);
  const isAuth = useAuth();

  const dispatch = useAppDispatch();

  const dispatchCommonModals = useContext(CommonModalsDispatchContext);

  const { getUserPhoto, isLoading } = useUserPhoto();

  const [isOpenPhotoInfo, setIsOpenPhotoInfo] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const isMyPhoto = useMemo(
    () => isAuth && user?.id === _.get(photo, "user_id", 0),
    [isAuth, user?.id, photo],
  );

  useEffect(() => {
    if (id) {
      getUserPhoto(_.toNumber(id));
    }
  }, [getUserPhoto, id]);

  const openOriginalPhoto = useCallback(() => {
    if (!photo) {
      return;
    }

    console.log("===");
    switch (photo.type) {
      case MediaType.YOUTUBE_VIDEO:
        console.log(getYouTubeVideoUrlFromId(photo.original));
        window.open(getYouTubeVideoUrlFromId(photo?.original));
        break;
      default:
        window.open(photo?.original || photo.full || photo.preview);
        break;
    }
  }, [photo]);

  const onOpenInfoPhoto = useCallback(() => {
    setIsOpenPhotoInfo(true);
  }, []);

  const onCloseInfoPhoto = useCallback(() => {
    setIsOpenPhotoInfo(false);
  }, []);

  const onRemovePhoto = useCallback(async () => {
    try {
      if (!photo) {
        return;
      }
      await dispatch(removeMediaById(photo.id));

      dispatch(removePhoto(photo.id));

      navigate("/");
    } catch (error) {
      console.error("Error while [onRemovePhoto]", error);
    }
  }, [dispatch, navigate, photo]);

  const onShareModalOpen = useCallback(() => {
    if (!photo) {
      return;
    }

    dispatch(setShareActionId(photo.id));
    setIsShareModalOpen(true);
  }, [dispatch, photo]);

  const onShareModalClose = useCallback(() => {
    setIsShareModalOpen(false);
  }, []);

  const goToHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onSignUp = useCallback(() => {
    dispatchCommonModals && dispatchCommonModals({ type: "SHOW_AUTH_MODAL" });
  }, [dispatchCommonModals]);

  return (
    <Box sx={materialStyles.page}>
      {photo ? (
        <>
          <FullSizeModalHeader>
            <Grid sx={materialStyles.iconBlocks}>
              <IconButton
                onClick={openOriginalPhoto}
                sx={materialStyles.hideOnMobile}
              >
                <DownloadIcon sx={materialStyles.headerIcon} />
              </IconButton>
              <IconButton onClick={onOpenInfoPhoto}>
                <InfoOutlinedIcon sx={materialStyles.headerIcon} />
              </IconButton>
              {isMyPhoto ? (
                <IconButton
                  sx={materialStyles.hideOnMobile}
                  onClick={onShareModalOpen}
                >
                  <ShareOutlinedIcon sx={materialStyles.headerIcon} />
                </IconButton>
              ) : null}
              {isMyPhoto ? (
                <IconButton
                  sx={materialStyles.hideOnMobile}
                  onClick={onRemovePhoto}
                >
                  <DeleteOutlineOutlined sx={materialStyles.headerIcon} />
                </IconButton>
              ) : null}
            </Grid>
          </FullSizeModalHeader>
          <Grid sx={materialStyles.photo}>
            <Picture sx={materialStyles.picture}>
              {photo.type === MediaType.PHOTO ? (
                <Image sx={materialStyles.media} src={photo?.full} />
              ) : null}
              {photo.type === MediaType.VIDEO ? (
                <Video
                  autoPlay={false}
                  muted
                  sx={materialStyles.media}
                  src={_.get(photo, "files[0].hd")}
                  controls
                  playsInline
                />
              ) : null}
              {photo.type === MediaType.YOUTUBE_VIDEO ? (
                <YouTubeIFrame
                  sx={[materialStyles.media, materialStyles.youTubeMedia]}
                  videoId={photo?.original}
                />
              ) : null}
            </Picture>
          </Grid>
        </>
      ) : null}
      {!isLoading && !photo ? (
        <Grid sx={materialStyles.notFoundContainer}>
          <Typography sx={materialStyles.notFoundTitle}>
            {t("not_found_page.photo_not_found_or_denied")}
          </Typography>
          <Typography sx={materialStyles.notFoundDescription}>
            {t("not_found_page.photo_does_not_exist")}
          </Typography>
          <Grid sx={materialStyles.notFoundButtons}>
            <Button
              onClick={goToHome}
              variant="contained"
              sx={materialStyles.goHomeButton}
            >
              {t("not_found_page.go_home")}
            </Button>
            {!isAuth ? (
              <>
                <Typography sx={materialStyles.orDivider}>
                  {t("common.or")}
                </Typography>
                <Button onClick={onSignUp} sx={materialStyles.loginButton}>
                  {t("auth_modal.login")}
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
      <LoaderWithBackdrop isShow={isLoading} />
      <PhotoInfoDrawer
        isLoading={isLoading}
        isOpen={isOpenPhotoInfo}
        onClose={onCloseInfoPhoto}
        photoId={photo?.id}
      />
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={onShareModalClose}
        type="PHOTO"
      />
    </Box>
  );
};
