import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {
  newAlbumForm: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
};

export const materialStyles: IMaterialStyleType = {
  innerModalForm: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  headerIcon: {
    color: Colors.white,
    width: "24px",
    height: "24px",
  },
  headerChildrenBlock: {
    display: "flex",
    flexDirection: "row",
    gap: "18px",
  },
  titleAndDescriptionBlock: {
    marginLeft: {
      xs: "20px",
      sm: "20px",
      md: "170px",
    },
    marginRight: {
      xs: "20px",
      sm: "20px",
      md: "170px",
    },
    marginTop: "30px",
  },
  albumTitleInput: {
    "& .MuiFilledInput-root": {
      backgroundColor: theme.palette.background.default,
      borderColor: theme.palette.background.default,

      "&.Mui-focused": {
        backgroundColor: theme.palette.grey[600],

        "& :hover": {
          backgroundColor: theme.palette.grey[600],
        },
      },

      "&.Mui-focused:after": {
        display: "block",
        borderBottom: `1px solid ${theme.palette.grey["A100"]}`,
      },

      "&::before, &::after": {
        display: "none",
      },

      "& :hover": {
        backgroundColor: theme.palette.grey[600],
      },
    },
    "& input": {
      paddingTop: "8px",
      paddingLeft: "2px",
      fontSize: "48px",
      color: theme.palette.grey["A100"],
      fontWeight: "bold",
    },
    "& fieldset": {
      border: "none",
    },
  },
  albumDescriptionInput: {
    border: "none",
    marginTop: "40px",

    color: theme.palette.grey[900],

    "& .MuiInputBase-root": {
      padding: 0,
      color: theme.palette.grey[900],
    },

    "& fieldset": {
      border: "none",
      padding: 0,
    },
  },
  mainBlock: {
    display: "flex",
    flex: 1,
    paddingLeft: {
      xs: "20px",
      sm: "20px",
      md: "170px",
    },
    paddingRight: {
      xs: "20px",
      sm: "20px",
      md: "170px",
    },
    flexDirection: "column",
    overflow: "auto",
  },
  mainImageBlock: {
    paddingTop: "32px",
    display: "flex",
    flex: 1,
    paddingLeft: {
      xs: "20px",
      sm: "20px",
      md: "170px",
    },
    paddingRight: {
      xs: "20px",
      sm: "20px",
      md: "170px",
    },
    flexDirection: "column",
    overflow: "auto",
  },
  emptyMainBlock: {
    alignItems: "center",
    justifyContent: "center",
  },
  mainBlockEmptyTitle: {
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "28px",
    color: theme.palette.grey[700],
    marginBottom: "25px",
  },
  selectPhotoButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "28px",
    textTransform: "none",
    letterSpacing: 0,
    fontWeight: 700,
    fontSize: "14px",
    padding: "12px 16px",
  },
  headerBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerTitle: {
    marginLeft: "8px",
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[300],
  },
  doneButton: {
    textTransform: "none",
    letterSpacing: 0,
    color: Colors.white,
    backgroundColor: theme.palette.primary.main,
    width: {
      xs: "100px",
      sm: "100px",
      md: "200px",
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: Colors.white,
      backgroundColor: theme.palette.grey[400],
    },
  },
  selectFromComputerButton: {
    textTransform: "none",
    letterSpacing: 0,
    marginRight: "36px",
  },
};
