import { Grid, TextField, IconButton, Button } from "@mui/material";
import React, { useCallback, useState } from "react";
import { materialStyles } from "./styles";
import { AuthModalHeader } from "./AuthModalHeader";
import { useTranslation } from "react-i18next";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useFormik } from "formik";
import _ from "lodash";
import { IAuthModalType } from "..";
import { useToast } from "rc-toastr";
import { useAppDispatch } from "../../../../store";
import { passwordRefresh } from "../../../../store/thunks/user";
import { resetRegistrationInfo } from "../../../../store/slices/registration";

type INewPasswordForm = {
  onChangeAuthModalType: (type: IAuthModalType) => void;
  onClose: () => void;
};

type IFormValues = {
  password: string;
  confirmPassword: string;
};

export const NewPasswordForm: React.FC<INewPasswordForm> = ({
  onClose,
  onChangeAuthModalType,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { toast } = useToast();

  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    async (details: IFormValues) => {
      if (details.password !== details.confirmPassword) {
        toast.error(t("auth_modal.passwords_not_match"));
        return;
      }

      try {
        await dispatch(passwordRefresh(details.password));
        dispatch(resetRegistrationInfo());
        onChangeAuthModalType("RESET_PASSWORD_CONGRATULATIONS");

        dispatch(resetRegistrationInfo());
        onClose();
        toast.success(t("auth_modal.change_password_success"));
      } catch (error) {
        console.error("Error while [onSubmit]", error);
      }
    },
    [dispatch, onChangeAuthModalType, onClose, t, toast]
  );

  const { values, handleChange, handleSubmit, isValid, errors } = useFormik({
    onSubmit,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate: (values) => {
      return _.pickBy({
        password: !_.trim(values.password),
        confirmPassword: !_.trim(values.confirmPassword),
      });
    },
  });

  const onShowPassword = useCallback((type: "PASSWORD" | "CONFIRM") => {
    if (type === "PASSWORD") {
      setShowPassword((state) => !state);
      return;
    }

    setShowConfirmPassword((state) => !state);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid sx={materialStyles.innerAuthModalBox}>
        <AuthModalHeader title={t("auth_modal.create_a_new_password")} />
        <Grid sx={materialStyles.formContainer}>
          <TextField
            error={Boolean(errors.password)}
            sx={materialStyles.authInput}
            name="password"
            value={values.password}
            onChange={handleChange}
            fullWidth
            type={showPassword ? "text" : "password"}
            label={t("auth_modal.new_password")}
            placeholder={t("auth_modal.enter_password")}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => onShowPassword("PASSWORD")}>
                  <RemoveRedEyeIcon sx={materialStyles.eyeIcon} />
                </IconButton>
              ),
            }}
          />
          <TextField
            error={Boolean(errors.confirmPassword)}
            sx={materialStyles.authInput}
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            fullWidth
            type={showConfirmPassword ? "text" : "password"}
            label={t("auth_modal.confirm_new_password")}
            placeholder={t("auth_modal.enter_password")}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => onShowPassword("CONFIRM")}>
                  <RemoveRedEyeIcon sx={materialStyles.eyeIcon} />
                </IconButton>
              ),
            }}
          />
          <Button
            type="submit"
            disabled={!isValid}
            sx={materialStyles.loginButton}
            variant="contained"
          >
            {t("auth_modal.change_password")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
