import { Colors, theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  page: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  pageTitle: {
    color: Colors.white,
    fontSize: "48px",
    lineHeight: "56px",
    fontWeight: 700,
  },
  pageDescription: {
    color: Colors.white,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    opacity: 0.75,
    whiteSpace: "pre",
    marginTop: "8px",
  },
  goHomeButton: {
    borderRadius: "7px",
    backgroundColor: theme.palette.primary.main,
    color: Colors.white,
    textTransform: "none",
    letterSpacing: 0,
    fontSize: "16px",
    lineHeight: "24px",

    padding: "12px 74px",

    marginTop: "24px",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
};
