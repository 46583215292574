import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { IPhotoType } from "../store/slices/myPhotos";

//TODO: Need to refactor this hook :(

export const useImageListSelectionByDate = (
  groupedImageData: Record<string, IPhotoType[]>,
  enableSelection: boolean,
  onSelected?: (ids: number[]) => void,
) => {
  const [selectedImages, setSelectedImages] = useState<number[]>([]);

  const onSelectedImage = useCallback(
    (id: number) => {
      if (!enableSelection) {
        return;
      }

      const isSelectedImage = _.find(
        selectedImages,
        (selectedId) => selectedId === id,
      );

      if (isSelectedImage) {
        const filteredSelectedImages = _.filter(
          selectedImages,
          (photoId) => photoId !== id,
        );
        setSelectedImages(_.uniq(filteredSelectedImages));
        return;
      }
      setSelectedImages(_.uniq([...selectedImages, id]));
    },
    [enableSelection, selectedImages],
  );

  const getIsSelectedDate = useCallback(
    (date: string) => {
      //TODO: Need to refactor :(

      const allPhotosInDate = groupedImageData[date];
      const photoIds = _.map(allPhotosInDate, (photo) => photo.id);

      const truthArray = _.chain(selectedImages)
        .map((selectedId) => _.includes(photoIds, selectedId))
        .filter((boolValue) => boolValue)
        .value();

      const isSelectedDate = truthArray.length === photoIds.length;

      return isSelectedDate;
    },
    [groupedImageData, selectedImages],
  );

  const onSelectedAllImageInDate = useCallback(
    (date: string) => {
      //TODO: Need to refactor :(

      if (!enableSelection) {
        return;
      }
      const allPhotosInDate = groupedImageData[date];
      const photoIds = _.map(allPhotosInDate, (photo) => photo.id);

      const isSelectedDate = getIsSelectedDate(date);

      if (isSelectedDate) {
        const filteredSelectedImages = _.filter(
          selectedImages,
          (photoId) => !_.includes(photoIds, photoId),
        );
        setSelectedImages(_.uniq(filteredSelectedImages));
        return;
      }
      setSelectedImages((state) => _.uniq([...state, ...photoIds]));
    },
    [getIsSelectedDate, groupedImageData, selectedImages, enableSelection],
  );

  useEffect(() => {
    if (onSelected) {
      onSelected(selectedImages);
    }
  }, [onSelected, selectedImages]);

  const clearAllSelections = useCallback(() => {
    setSelectedImages([]);
  }, []);

  return {
    onSelectedImage,
    selectedImages,
    onSelectedAllImageInDate,
    getIsSelectedDate,
    clearAllSelections,
  };
};
