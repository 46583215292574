import { Button, Grid, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles, styles } from "./styles";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  setNewUserAvatar,
  uploadNewUserAvatar,
} from "../../../../store/thunks/user";
import { setUserAvatar } from "../../../../store/slices/user";

type IMainSelectAvatarContent = {
  onClose: () => void;
};

export const MainSelectAvatarContent: React.FC<IMainSelectAvatarContent> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  const { url, photoId } = useAppSelector((state) => state.selectAvatar);
  const dispatch = useAppDispatch();

  const onSetNewProfileAvatar = useCallback(async () => {
    try {
      switch (true) {
        case Boolean(photoId):
          if (!photoId) {
            return;
          }
          const response = await dispatch(setNewUserAvatar(photoId));
          if (!response) {
            return;
          }
          dispatch(setUserAvatar(response));
          break;
        case !Boolean(photoId):
          if (!url) {
            return;
          }
          const fetchedFile = await fetch(url);
          const imageInBlob = await fetchedFile.blob();

          const uploadedAvatarUrl = await dispatch(
            uploadNewUserAvatar(imageInBlob)
          );
          if (!uploadedAvatarUrl) {
            return;
          }
          dispatch(setUserAvatar(uploadedAvatarUrl));
          break;
      }
      onClose();
    } catch (error) {
      console.error("Error while [onSetNewProfileAvatar]", error);
    }
  }, [dispatch, onClose, photoId, url]);

  return (
    <Grid sx={materialStyles.innerModalContainer}>
      <Typography sx={materialStyles.modalTitle}>
        {t("photos.set_profile_picture")}
      </Typography>
      {url ? (
        <Grid sx={materialStyles.selectedImageContainer}>
          <img style={styles.selectedImage} src={url} alt="selectAvatar" />
        </Grid>
      ) : null}
      <Grid sx={materialStyles.buttonContainer}>
        <Button onClick={onSetNewProfileAvatar} sx={materialStyles.button}>
          {t("photos.set_as_profile_picture")}
        </Button>
      </Grid>
    </Grid>
  );
};
