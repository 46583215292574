import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { ModalHeader } from "../../../header/ModalHeader";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { VideoEditor } from "../../../videoEditor";
import { saveAfterVideoEditor } from "../../../../store/slices/uploadFile";

type TVideoEditorFormForm = {
  onClose: () => void;
};

export const VideoEditorForm: React.FC<TVideoEditorFormForm> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { video } = useAppSelector((state) => state.videoEditor);

  const onSaveVideo = useCallback(
    (fileUrl: string) => {
      if (!video) {
        return;
      }

      dispatch(saveAfterVideoEditor({ id: video.id, url: fileUrl }));

      onClose();
    },
    [dispatch, onClose, video],
  );

  return (
    <Box sx={materialStyles.formContainer}>
      <ModalHeader
        title={t("add_photo.video_editor")}
        subtitle={t("add_photo.video_editor_description")}
      />
      {video ? (
        <VideoEditor
          file={video}
          sx={materialStyles.videoEditorContainer}
          onSave={onSaveVideo}
        />
      ) : null}
    </Box>
  );
};
