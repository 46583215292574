import { createSlice } from "@reduxjs/toolkit";
import { IPhotoType } from "./myPhotos";
import _ from "lodash";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../constants/api";

interface IFavoritesSliceInitialState {
  favoritePhotos: {
    data: IPhotoType[];
    page: number;
    pageSize: number;
  };
}

const favoritesSliceInitialState: IFavoritesSliceInitialState = {
  favoritePhotos: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState: favoritesSliceInitialState,
  reducers: {
    setFavoritePhotos(state, action) {
      const favoritePhotos = state.favoritePhotos.data;

      if (_.isEmpty(favoritePhotos)) {
        state.favoritePhotos.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy(
        [...state.favoritePhotos.data, ...action.payload],
        "id"
      );

      state.favoritePhotos.data = updatedItems;
    },
    addPhotoToFavorites(state, action) {
      state.favoritePhotos.data = [
        ...state.favoritePhotos.data,
        action.payload,
      ];
    },
    removePhotoFromFavorites(state, action) {
      const newFavorites = _.filter(
        state.favoritePhotos.data,
        (item) => item.id !== action.payload
      );
      state.favoritePhotos.data = newFavorites;
    },
    resetFavorites(state) {
      state.favoritePhotos = favoritesSliceInitialState.favoritePhotos;
    },
    setMyFavoritesPage(state, action) {
      state.favoritePhotos.page = action.payload;
    },
    setMyFavoritesPageSize(state, action) {
      state.favoritePhotos.pageSize = action.payload;
    },
  },
});

export default favoritesSlice.reducer;
export const {
  setFavoritePhotos,
  addPhotoToFavorites,
  removePhotoFromFavorites,
  resetFavorites,
  setMyFavoritesPage,
  setMyFavoritesPageSize,
} = favoritesSlice.actions;
