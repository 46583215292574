import React from "react";
import {
  PRIVACY_GROUP_TYPES,
  PrivacyGroup,
} from "../../../../store/slices/groups";
import { ButtonBase, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { IMaterialStyleItem } from "../../../../types";
import { useTranslation } from "react-i18next";
import { MaterialTooltip } from "../../../common/MaterialTooltip";

type IGroupItem = {
  group: PrivacyGroup;
  isSelected?: boolean;
  onClick?: (group: PrivacyGroup, isSelected?: boolean) => void;
  disabled?: boolean;
  sx?: IMaterialStyleItem;
};

export const GroupItem: React.FC<IGroupItem> = ({
  group,
  isSelected,
  onClick,
  disabled,
  sx,
}) => {
  const { t } = useTranslation();
  return (
    <MaterialTooltip
      placement="top"
      title={t(`sharing.groups_tooltips_by_type.${group.type}`)}
    >
      <ButtonBase
        disableTouchRipple={disabled}
        disabled={disabled}
        disableRipple={disabled}
        sx={[
          materialStyles.groupItem,
          sx ? sx : false,

          Boolean(isSelected) && materialStyles.choosenGroupItem,
          Boolean(isSelected) &&
            Boolean(group.isAll) &&
            materialStyles.choosenAllGroupItem,
          Boolean(
            isSelected && group.type === PRIVACY_GROUP_TYPES.RESTRICTED,
          ) && materialStyles.restrictedGroupItem,
          Boolean(disabled) && materialStyles.disabledGroupItem,
        ]}
        onClick={() => onClick && onClick(group, isSelected)}
      >
        <Typography sx={materialStyles.groupItemTitle}>
          {group.title}
        </Typography>
      </ButtonBase>
    </MaterialTooltip>
  );
};
