import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/index";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import "./assets/i18n";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { ToastProvider } from "rc-toastr";
import "rc-toastr/dist/index.css";
import { TOAST_CONFIG } from "./utils";
import { GOOGLE_API_KEY, GOOGLE_CLIENT_ID } from "./constants";
import { CommonUserHandle } from "./components/handlers/CommonUserHandle";
import { CommonErrorHandle } from "./components/handlers/CommonErrorHandle";
import { CommonSystemStateHandle } from "./components/handlers/CommonSystemStateHandle";
import { CommonSocketHandle } from "./components/handlers/CommonSocketHandle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AppToaster } from "./components/toaster";
import { Fragment } from "react";
import { APIProvider } from "@vis.gl/react-google-maps";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <APIProvider apiKey={GOOGLE_API_KEY}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <Provider store={store}>
            <ToastProvider
              config={{
                ...TOAST_CONFIG,
                renderAs: ({ toast, onClose, visible }) => (
                  <AppToaster
                    toast={toast}
                    onClose={onClose}
                    visible={visible}
                  />
                ),
              }}
            >
              <Fragment>
                <CommonErrorHandle />
                <CommonUserHandle />
                <CommonSystemStateHandle />
                <CommonSocketHandle />
                <RouterProvider router={router} />
              </Fragment>
            </ToastProvider>
          </Provider>
        </GoogleOAuthProvider>
      </APIProvider>
    </LocalizationProvider>
  </ThemeProvider>,
);

reportWebVitals();
