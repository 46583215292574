import React from "react";
import { IMaterialStyleItem } from "../../../types";
import { Grid } from "@mui/material";

type IBlockDetails = {
  children: React.ReactNode;
  sx?: IMaterialStyleItem;
};

export const BlockDetails: React.FC<IBlockDetails> = ({ children, sx }) => {
  return <Grid sx={sx}>{children}</Grid>;
};
