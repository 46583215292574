import React, { useCallback } from "react";
import { IUserComplaintModalType } from "..";
import { Button, Grid, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../store";
import {
  complaintOnAlbum,
  complaintOnMedia,
  complaintOnUser,
} from "../../../../store/thunks/complaint";
import { IComplaintReasonType } from "../../../../hooks/useComplaintReasons";

type IConfirmComplaintContent = {
  onClose: () => void;
  onChangeComplaintModalType: (type: IUserComplaintModalType) => void;
  type: IComplaintReasonType;
};

export const ConfirmComplaintContent: React.FC<IConfirmComplaintContent> = ({
  onClose,
  onChangeComplaintModalType,
  type,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onConfirm = useCallback(async () => {
    try {
      switch (type) {
        case "USER":
          await dispatch(complaintOnUser());
          break;
        case "ALBUM":
          await dispatch(complaintOnAlbum());
          break;
        case "PHOTO":
          await dispatch(complaintOnMedia());
          break;
      }
      onChangeComplaintModalType("SUCCEESS_COMPLAINR_FORM");
    } catch (error) {
      console.error("Error while [onConfirm]", error);
    }
  }, [dispatch, onChangeComplaintModalType, type]);

  const onCancel = useCallback(() => {
    onChangeComplaintModalType("REPORT_REASONS_FORM");
  }, [onChangeComplaintModalType]);

  return (
    <Grid sx={materialStyles.innerModal}>
      <Grid sx={materialStyles.confirmContainer}>
        <Typography sx={materialStyles.confirmModalHeaderTitle}>
          {
            {
              ALBUM: t("complaint.you_sure_want_file_complaint_album"),
              PHOTO: t("complaint.you_sure_want_file_complaint_media"),
              USER: t("complaint.you_sure_want_file_complaint_user"),
            }[type]
          }
        </Typography>
        <Button sx={materialStyles.confirmButton} onClick={onConfirm}>
          {t("common.confirm")}
        </Button>
        <Button sx={materialStyles.cancelButton} onClick={onCancel}>
          {t("common.cancel")}
        </Button>
      </Grid>
    </Grid>
  );
};
