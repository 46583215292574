import React, { useCallback, useEffect } from "react";
import { UserLayout } from "../../components/layout/UserLayout";
import { Box } from "@mui/material";
import { materialStyles } from "./styles";
import { MessagesHeader } from "../../components/header/MessagesHeader";
import { useFetchChats } from "../../hooks/useFetchChats";
import { ChatsList } from "../../components/lists/chatsList";
import { useAppDispatch, useAppSelector } from "../../store";
import { Message, resetMessages } from "../../store/slices/messages";
import { setChatUser } from "../../store/slices/chat";
import { ChatComponent } from "../../components/chat";
import { LoaderWithBackdrop } from "../../components/loaders/LoaderWithBackdrop";
import { socket } from "../../socket";

export const MessagesPage: React.FC = () => {
  const { chats } = useFetchChats();

  const { user } = useAppSelector((state) => state.chat);
  const { loading } = useAppSelector((state) => state.loader);
  const { isConnected } = useAppSelector((state) => state.socket);

  const dispatch = useAppDispatch();

  const onChatClick = useCallback(
    (id: Message["id"], user?: Message["user"]) => {
      if (isConnected) {
        socket.emit("chat:read", id);
      }
      dispatch(setChatUser(user));
    },
    [dispatch, isConnected],
  );

  useEffect(() => {
    return () => {
      dispatch(resetMessages());
    };
  }, [dispatch]);

  return (
    <UserLayout>
      <Box sx={materialStyles.messagesPage}>
        <Box
          sx={[
            materialStyles.messagesListContainer,
            {
              display: {
                xs: user ? "none" : "flex",
                sm: user ? "none" : "flex",
                md: "flex",
              },
            },
          ]}
        >
          <MessagesHeader />
          <ChatsList
            onChatClick={onChatClick}
            chats={chats}
            activeChatUser={user}
          />
        </Box>
        <Box
          sx={[
            materialStyles.messagesActiveChat,
            {
              display: {
                xs: user ? "flex" : "none",
                sm: user ? "flex" : "none",
                md: "flex",
              },
            },
          ]}
        >
          <ChatComponent />
        </Box>
      </Box>
      <LoaderWithBackdrop isShow={loading} />
    </UserLayout>
  );
};
