import { createSlice } from "@reduxjs/toolkit";

interface IComplaintSliceInitialState {
  actionId: number | null;
  message: string | null;
}

const complaintSliceInitialState: IComplaintSliceInitialState = {
  actionId: null,
  message: null,
};

const complaintSlice = createSlice({
  name: "complaint",
  initialState: complaintSliceInitialState,
  reducers: {
    setMessage(state, action) {
      state.message = action.payload;
    },
    setActionId(state, action) {
      state.actionId = action.payload;
    },
    resetComplaint(state) {
      state.message = complaintSliceInitialState.message;
      state.actionId = complaintSliceInitialState.actionId;
    },
  },
});

export default complaintSlice.reducer;
export const { setActionId, setMessage, resetComplaint } =
  complaintSlice.actions;
