import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getAlbumPhotos } from "../store/thunks/albums";
import {
  resetUserAlbumPhotos,
  setUserAlbumPhotos,
  setUserAlbumPhotosPage,
  setUserAlbumPhotosPageSize,
} from "../store/slices/userAlbum";
import { DEFAULT_PAGE_SIZE } from "../constants/api";

type TFetchUserAlbumPhotosById = {
  isEnable?: boolean;
  id: number | null;
};

export const useFetchUserAlbumPhotosById = ({
  id,
  isEnable,
}: TFetchUserAlbumPhotosById) => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { albumPhotos } = useAppSelector((state) => state.userAlbum);

  const fetchAlbumPhotos = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        if (!isAuth) {
          return;
        }

        if (!id) {
          return;
        }

        const response = await dispatch(getAlbumPhotos({ id, page, pageSize }));
        dispatch(setUserAlbumPhotos(response));
      } catch (error) {
        console.error("Error while [fetchAlbumPhotos]", error);
      }
    },
    [dispatch, id, isAuth]
  );

  const refetchUserAlbumPhotos = useCallback(() => {
    fetchAlbumPhotos(albumPhotos.page);
  }, [fetchAlbumPhotos, albumPhotos.page]);

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setUserAlbumPhotosPage(albumPhotos.page + 1));
    dispatch(setUserAlbumPhotosPageSize(DEFAULT_PAGE_SIZE));
  }, [albumPhotos.page, dispatch]);

  useEffect(() => {
    if (isEnable) {
      fetchAlbumPhotos();
    }

    return () => {
      dispatch(resetUserAlbumPhotos());
    };
  }, [dispatch, fetchAlbumPhotos, isEnable]);

  return {
    albumPhotos: albumPhotos.data,
    refetchUserAlbumPhotos,
    onUpdatePageOrPageSize,
  };
};
