import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useToast } from "rc-toastr";
import { setError } from "../../store/slices/errors";
import { UNKNOWN_ERROR, getMessageFromResponseError } from "../../utils";

type ICommonAuthHandle = {};

export const CommonErrorHandle: React.FC<ICommonAuthHandle> = () => {
  const { error } = useAppSelector((state) => state.errors);
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      if (!error.message) {
        toast.error(getMessageFromResponseError(UNKNOWN_ERROR));
      } else {
        toast.error(getMessageFromResponseError(error));
      }
      dispatch(setError(null));
    }
  }, [dispatch, error, toast]);

  return null;
};
