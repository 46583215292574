import React from "react";
import { SideMenu } from "../sideMenu";
import { Drawer } from "@mui/material";
import { materialStyles } from "./styles";

type IDrawerSideMenu = {
  isOpen: boolean;
  onClose: () => void;
};

export const DrawerSideMenu: React.FC<IDrawerSideMenu> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Drawer
      hideBackdrop
      sx={materialStyles.drawerContainer}
      PaperProps={{ sx: materialStyles.drawerPaperContainer }}
      open={isOpen}
      onClose={onClose}
      variant="persistent"
    >
      <SideMenu />
    </Drawer>
  );
};
