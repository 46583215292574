import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type IFullSizeModalHeader = {
  children?: React.ReactNode;
  Icon?: React.ReactNode;
  title?: string;
  TitleComponent?: React.ReactNode;
  onIconClick?: () => void;
  gradientBackground?: boolean;
  positionRelative?: boolean;
  iconPosition?: "left" | "right";
};

export const FullSizeModalHeader: React.FC<IFullSizeModalHeader> = ({
  children = null,
  Icon,
  title,
  TitleComponent,
  onIconClick,
  gradientBackground = false,
  positionRelative = false,
  iconPosition = "left",
}) => {
  return (
    <Grid
      sx={[
        materialStyles.fullSizeModalHeader,
        gradientBackground && materialStyles.fullSizeModalHeaderWithGradient,
        positionRelative && materialStyles.fullSizeModalPositionRelative,
      ]}
    >
      {TitleComponent ? (
        TitleComponent
      ) : (
        <Grid>
          {Icon && iconPosition === "left" ? (
            <IconButton onClick={onIconClick}>{Icon}</IconButton>
          ) : null}
          {title ? <Typography>{title}</Typography> : null}
        </Grid>
      )}
      <Grid sx={materialStyles.rightFullSizeHeaderComponent}>
        {children}
        {Icon && iconPosition === "right" ? (
          <IconButton onClick={onIconClick}>{Icon}</IconButton>
        ) : null}
      </Grid>
    </Grid>
  );
};
