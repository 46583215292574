import { theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  uploadModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadModalBox: {
    backgroundColor: theme.palette.background.default,
    border: "none",
    borderRadius: "6px",
    width: {
      xs: `98vw`,
      sm: `98vw`,
      md: "1000px",
    },
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
};
