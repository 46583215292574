import { useReducer } from "react";

export type TVideoEditorTimingsOptions = {
  end: number;
  start: number;
};

export type TVideoEditor = {
  timings: TVideoEditorTimingsOptions;
  isMuted: boolean;
  isPlaying: boolean;
  initialVideoDuration: number;
};

type TVideoEditorState = {
  videoEditor: TVideoEditor;
  loading: boolean;
};

type TVideoEditorAction =
  | {
      type: "RESET";
    }
  | {
      type: "SET_DEFAULT_VIDEO_TIMINGS_OPTIONS" | "SET_TIMINGS";
      options: TVideoEditorTimingsOptions;
    }
  | {
      type: "SET_LOADING" | "SET_PLAYING" | "SET_MUTED";
      value: boolean;
    };

const initialVideoEditorState: TVideoEditorState = {
  videoEditor: {
    timings: {
      end: 0,
      start: 0,
    },
    isMuted: false,
    isPlaying: false,
    initialVideoDuration: 0,
  },
  loading: false,
};

const videoEditorReducer = (
  state: TVideoEditorState,
  action: TVideoEditorAction,
) => {
  switch (action.type) {
    case "SET_DEFAULT_VIDEO_TIMINGS_OPTIONS":
      return {
        ...state,
        videoEditor: {
          ...state.videoEditor,
          timings: action.options,
          initialVideoDuration: action.options.end,
        },
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.value,
      };
    case "SET_TIMINGS":
      return {
        ...state,
        videoEditor: {
          ...state.videoEditor,
          timings: action.options,
        },
      };
    case "SET_PLAYING":
      return {
        ...state,
        videoEditor: {
          ...state.videoEditor,
          isPlaying: action.value,
        },
      };
    case "SET_MUTED":
      return {
        ...state,
        videoEditor: {
          ...state.videoEditor,
          isMuted: action.value,
        },
      };
    case "RESET":
      return {
        ...state,
        videoEditor: {
          ...state.videoEditor,
          timings: {
            start: 0,
            end: state.videoEditor.initialVideoDuration,
          },
          isMuted: initialVideoEditorState.videoEditor.isMuted,
        },
      };
  }
};

export const useVideoEditorReducer = () => {
  const [videoEditorState, dispatchVideoEditor] = useReducer(
    videoEditorReducer,
    initialVideoEditorState,
  );

  return {
    videoEditorState,
    dispatchVideoEditor,
  };
};
