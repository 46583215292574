import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../constants/api";
import { IUserType } from "./user";
import _ from "lodash";
import { catchChangesByKeys } from "../../utils";

export interface Message {
  id: number | "DRAFT";
  message: string;
  created_at: string;
  user_id: number;
  user: IUserType;
  sender_id: number;
  sender: IUserType;
  is_read: boolean;
}

interface IMessagesSliceInitialState {
  chats: {
    data: Message[];
    page: number;
    pageSize: number;
  };
  count: number;
}

const messagesSliceInitialState: IMessagesSliceInitialState = {
  chats: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  count: 0,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState: messagesSliceInitialState,
  reducers: {
    setMessages(state, action) {
      const chats = state.chats.data;

      if (_.isEmpty(chats)) {
        state.chats.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy([...chats, ...action.payload], "user_id");

      const itemsWithCheckedChanges = catchChangesByKeys(
        updatedItems,
        action.payload,
        ["message"],
      );

      state.chats.data = itemsWithCheckedChanges;
    },
    addNewChatMessages(state, action) {
      state.chats.data = [action.payload, ...state.chats.data];
    },
    addDraftChatMessage(state, action) {
      state.chats.data = [action.payload, ...state.chats.data];
    },
    updateChatInMessages(state, action) {
      const { id, message } = action.payload;

      const filteredMessage = _.filter(
        state.chats.data,
        (chat) => chat.id !== id,
      );

      state.chats.data = [...filteredMessage, message];
    },
    removeAllDraftChats(state) {
      state.chats.data = _.filter(
        state.chats.data,
        (chat) => chat.id !== "DRAFT",
      );
    },
    setChatsPage(state, action) {
      state.chats.page = action.payload;
    },
    setChatsPageSize(state, action) {
      state.chats.pageSize = action.payload;
    },
    resetMessages(state) {
      state.chats = messagesSliceInitialState.chats;
      state.count = messagesSliceInitialState.count;
    },
  },
});

export default messagesSlice.reducer;
export const {
  setMessages,
  addDraftChatMessage,
  setChatsPage,
  setChatsPageSize,
  resetMessages,
  removeAllDraftChats,
  updateChatInMessages,
  addNewChatMessages,
} = messagesSlice.actions;
