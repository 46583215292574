import { Box, Typography } from "@mui/material";
import React from "react";
import { UserAvatar } from "../user/UserAvatar";
import { materialStyles } from "./styles";
import { IUserType } from "../../store/slices/user";

type IUserAutocompleteOption = {
  user: IUserType;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  onOptionClick: (user: IUserType) => void;
} & React.HTMLAttributes<HTMLLIElement>;

export const UserAutocompleteOption: React.FC<IUserAutocompleteOption> = ({
  user,
  onClick,
  onOptionClick,
  ...props
}) => {
  return (
    <Box
      component="li"
      onClick={(event: any) => {
        if (onClick) {
          onClick(event);
        }
        onOptionClick(user);
      }}
      sx={materialStyles.searchOption}
      {...props}
    >
      <UserAvatar user={user} type="PHOTO_PAGE" />
      <Typography sx={materialStyles.searchUserName}>
        {user.username}
      </Typography>
    </Box>
  );
};
