import { AppDispatch, RootState } from "..";
import axios from "axios";
import { API_URL } from "../api";
import { setLoading } from "../slices/loading";
import { setError } from "../slices/errors";
import {
  getResponseFromAxiosError,
  parseFiltersParamsForAllowedMediaRequest,
} from "../../utils";
import { IMapFilters } from "../slices/map";
import { checkOrRefreshToken } from "./user";
import { DEFAULT_PAGE_SIZE } from "../../constants/api";

export const getPublicMedia = (page?: number, pageSize?: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));

      const { data } = await axios.get(`${API_URL}/globe/public`, {
        params: {
          page,
          limit: pageSize || DEFAULT_PAGE_SIZE,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getPublicMedia]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getAllowedMedia = (
  page?: number,
  pageSize?: number,
  filters?: IMapFilters,
  subset?: string,
  sort_by?: string,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const parsedFilters = parseFiltersParamsForAllowedMediaRequest(filters);

      const { data } = await axios.get(`${API_URL}/globe/allowed`, {
        params: {
          page,
          limit: pageSize || DEFAULT_PAGE_SIZE,
          subset,
          sort_by,
          ...parsedFilters,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getAllowedMedia]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getGlobeMediaById = (id?: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/globe/media/${id}`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getGlobeMediaById]", error);
    }
  };
};

export const getUserByUsername = (username: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/account/u/${username}`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getUserByUsername]", error);
    }
  };
};
