import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  modalInnerBlock: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: {
      xs: "19px 18px 19px",
      sm: "19px 18px 19px",
      md: "19px 18px 41px",
    },
  },
  modalHeaderBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
  modalHeaderTitle: {
    color: Colors.white,
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 700,
  },
  modalHeaderSubtitle: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[900],
    textAlign: "center",
  },
  supportConversationContainer: {
    p: 2,
    mt: 2,
    borderRadius: 2,
    backgroundColor: theme.palette.grey[600],
    display: "flex",
    flexDirection: "column-reverse",
    height: "400px",
    overflow: "auto",
  },
  chatMessagesContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column-reverse",
    overflow: "auto",
    padding: "0px 24px",
  },
  myChatMessageContainer: {
    justifyContent: "flex-end",
  },
  chatMessageContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px",
  },
  messageContainer: {
    backgroundColor: theme.palette.grey[900],
    padding: "8px 16px",
    borderRadius: "12px",
    marginRight: "16px",
  },
  myMessageContainer: {
    backgroundColor: theme.palette.primary.main,
  },
  message: {
    color: Colors.white,
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "left",
  },
  sendInput: {
    borderRadius: "30px",
    mt: 2,
    backgroundColor: theme.palette.grey[600],

    "& textarea": {
      color: Colors.white,
    },

    "& .MuiOutlinedInput-root": {
      padding: "9px 13px",
    },

    "& fieldset": {
      height: "44px",
      border: "none",
    },

    "& ::placeholder": {
      color: `${theme.palette.grey[200]}`,
      opacity: 1,
    },
  },
  sendButton: {
    padding: 0,
    textTransform: "none",
    letterSpacing: 0,
    fontSize: "14px",
    minWidth: "48px",

    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
  imageMessage: {
    maxWidth: "200px",
    maxHeight: "200px",
    borderRadius: 1,
    objectFit: "contain",
  },
};
