import { AppDispatch, RootState } from "..";
import axios from "axios";
import { API_URL } from "../api";
import { setLoading } from "../slices/loading";
import { setRefreshToken, setToken, setUser, setUserId } from "../slices/user";
import { setError } from "../slices/errors";
import {
  CODE_NOT_CORRECT_OR_EXPIRED_ERROR,
  getResponseFromAxiosError,
  isTokenExpired,
  wrapToAxiosError,
} from "../../utils";
import { CodeResponse } from "@react-oauth/google";
import i18n from "../../assets/i18n";
import { VerifyCodeNotCorrectOrExpiredError } from "../classes";
import { DEFAULT_PAGE_SIZE } from "../../constants/api";

export const checkOrRefreshToken = (token?: string | null) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const isExpired = isTokenExpired(token);
      const { user } = getState();

      if (!isExpired) {
        return token;
      }

      const localRefreshToken = localStorage.getItem("refreshToken");

      if (!user.refreshToken && !localRefreshToken) {
        return;
      }

      const { data } = await axios.get(`${API_URL}/auth/refresh`, {
        headers: {
          Authorization: `Bearer ${user.refreshToken || localRefreshToken}`,
        },
      });

      dispatch(setToken({ token: data.token }));
      dispatch(setRefreshToken({ refreshToken: data.refresh }));

      localStorage.setItem("token", data.token);
      localStorage.setItem("refreshToken", data.refresh);

      return data.token;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [loginUser]", error);
    }
  };
};

export const loginUser = ({
  email,
  phone,
  password,
}: {
  email?: string;
  phone?: string;
  password: string;
}) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));

      const { data } = await axios.post(`${API_URL}/auth/login`, {
        email,
        phone,
        password,
      });

      if (!data) {
        throw new Error();
      }

      localStorage.setItem("token", data.token);
      localStorage.setItem("refreshToken", data.refresh);

      dispatch(setToken({ token: data.token }));
      dispatch(setRefreshToken({ refreshToken: data.refresh }));
      dispatch(setUserId(data.id));
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [loginUser]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const signUpUser = ({
  name,
  password,
}: {
  name: string;
  password: string;
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { regUser } = getState();

      const { data } = await axios.post(`${API_URL}/auth/registration`, {
        email: regUser.regUser?.email,
        code: regUser.regUser?.code,
        phone: regUser.regUser?.phone,
        username: name,
        password,
      });

      if (!data) {
        throw new Error();
      }

      dispatch(setToken({ token: data.token }));
      dispatch(setRefreshToken({ refreshToken: data.refresh }));
      dispatch(setUserId(data.id));

      localStorage.setItem("token", data.token);
      localStorage.setItem("refreshToken", data.refresh);
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error(error);
      throw new Error("Error method [signUpUser]");
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getMe = (token?: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(
        checkOrRefreshToken(token || user.token),
      );

      const response = await axios.get(`${API_URL}/account`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      const { data } = response;

      if (!data) {
        throw new Error();
      }

      const tokenFromLocalStorage = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refreshToken");

      dispatch(setUser(data));
      dispatch(setUserId(data.id));
      dispatch(setToken({ token: checkedToken || tokenFromLocalStorage }));
      dispatch(setRefreshToken({ refreshToken }));
    } catch (error: any) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getMe]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const sendEmailValidation = (mail: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(`${API_URL}/tokens/mail-send`, {
        mail,
      });

      if (!data) {
        throw new Error();
      }

      if (!data.success) {
        throw wrapToAxiosError({
          error: i18n.t("auth_modal.maybe_account_with_your_email"),
          statusCode: 0,
          message: i18n.t("auth_modal.maybe_account_with_your_email"),
        });
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [sendEmailValidation]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const sendPhoneValidation = (mail: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(`${API_URL}/tokens/phone-send`, {
        phone: mail,
      });

      if (!data) {
        throw new Error();
      }

      if (!data.success) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [sendPhoneValidation]", error);
      throw new Error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const validateCodeEmail = (code: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { regUser } = getState();
      const { data } = await axios.post(`${API_URL}/tokens/mail-validate`, {
        token: code,
        mail: regUser.regUser?.email,
      });
      if (!data) {
        throw new Error();
      }
      if (!data.success) {
        throw wrapToAxiosError({
          error: i18n.t("auth_modal.you_have_entered_incorrect_code"),
          statusCode: 0,
          message: i18n.t("auth_modal.you_have_entered_incorrect_code"),
        });
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [validateCodeEmail]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const validateCodePhone = (code: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { regUser } = getState();
      const { data } = await axios.post(`${API_URL}/tokens/phone-validate`, {
        token: code,
        phone: regUser.regUser?.phone,
      });
      if (!data) {
        throw new Error();
      }
      if (!data.success) {
        throw wrapToAxiosError({
          error: i18n.t("auth_modal.you_have_entered_incorrect_code"),
          statusCode: 0,
          message: i18n.t("auth_modal.you_have_entered_incorrect_code"),
        });
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [validateCodePhone]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const passwordReset = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { regUser } = getState();
      const { data } = await axios.post(`${API_URL}/auth/password-reset`, {
        email: regUser.regUser?.email,
        phone: regUser.regUser?.phone,
      });
      if (!data) {
        throw new Error();
      }
      if (!data.success) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error))); //TODO Add error
      console.error("Error method [passwordReset]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const passwordVerify = (code: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { regUser } = getState();
      const { data } = await axios.post(`${API_URL}/auth/password-verify`, {
        email: regUser.regUser?.email,
        phone: regUser.regUser?.phone,
        token: code,
      });
      if (!data) {
        throw new Error();
      }
      if (!data.success) {
        throw new VerifyCodeNotCorrectOrExpiredError();
      }
    } catch (error: any) {
      if (error instanceof VerifyCodeNotCorrectOrExpiredError) {
        dispatch(setError(CODE_NOT_CORRECT_OR_EXPIRED_ERROR));
        console.error("Error method [passwordVerify]", error);
        throw error;
      }

      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [passwordVerify]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const passwordRefresh = (newPassword: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { regUser } = getState();
      const { data } = await axios.post(`${API_URL}/auth/password-refresh`, {
        email: regUser.regUser?.email,
        phone: regUser.regUser?.phone,
        token: regUser.regUser?.code,
        password: newPassword,
      });
      if (!data.id) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [passwordRefresh]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const googleAuthCallback = (codeResponse: CodeResponse) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(`${API_URL}/auth/google/callback`, {
        params: codeResponse,
      });

      if (!data) {
        throw new Error();
      }

      dispatch(setToken({ token: data.token }));
      dispatch(setRefreshToken({ refreshToken: data.refresh }));

      localStorage.setItem("token", data.token);
      localStorage.setItem("refreshToken", data.refresh);

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [googleAuthCallback]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const facebookAuthCallback = (response: any) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(`${API_URL}/auth/facebook/token`, {
        ...response,
      });

      if (!data) {
        throw new Error();
      }

      dispatch(setToken({ token: data.token }));
      dispatch(setRefreshToken({ refreshToken: data.refresh }));

      localStorage.setItem("token", data.token);
      localStorage.setItem("refreshToken", data.refresh);
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [facebookAuthCallback]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getUserById = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/account/${id}`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      // const { data: userPhotosData } = await axios.get(
      //   `${API_URL}/globe/allowed`,
      //   {
      //     params: {
      //       user_id: id,
      //       limit: 300,
      //     },
      //     headers: {
      //       Authorization: `Bearer ${checkedToken}`,
      //     },
      //   }
      // );

      // if (!userPhotosData) {
      //   throw new Error();
      // }

      // const { data: userAlbumsData } = await axios.get(
      //   `${API_URL}/globe/albums`,
      //   {
      //     params: {
      //       user_id: id,
      //       limit: 300,
      //     },
      //     headers: {
      //       Authorization: `Bearer ${checkedToken}`,
      //     },
      //   }
      // );

      // if (!userAlbumsData) {
      //   throw new Error();
      // }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getUserById]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getUserAlbumsById = ({
  id,
  page,
  pageSize,
}: {
  id: number;
  page?: number;
  pageSize?: number;
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/globe/albums`, {
        params: {
          user_id: id,
          page,
          limit: pageSize || DEFAULT_PAGE_SIZE,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [getUserAlbumsById]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getUserPhotosById = ({
  id,
  page,
  pageSize,
}: {
  id: number;
  page?: number;
  pageSize?: number;
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/globe/allowed`, {
        params: {
          user_id: id,
          page,
          limit: pageSize || DEFAULT_PAGE_SIZE,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [getUserPhotosById]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const setNewUserAvatar = (photoId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(
        `${API_URL}/media/${photoId}/make-avatar`,
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }
      return data.avatar;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [setNewUserAvatar]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const changeUsername = (username: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/account/username`,
        {
          username,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data && !data.success) {
        throw new Error();
      }

      return username;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [changeUsername]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const changePassword = (password: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/account/password`,
        {
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data && !data.success) {
        throw new Error();
      }

      return password;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [changePassword]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const changeEmail = (email: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/account/email`,
        {
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data && !data.success) {
        throw new Error();
      }

      return email;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [changeEmail]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const deleteAccount = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.delete(`${API_URL}/account`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data && !data.success) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [deleteAccount]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const uploadNewUserAvatar = (file: Blob) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/account/avatar`,
        {
          avatar: file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }
      return data.avatar;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error method [setNewUserAvatar]", error);
      throw new Error();
    } finally {
      dispatch(setLoading(false));
    }
  };
};
