import { createSlice } from "@reduxjs/toolkit";
import { IPreparedFileInfo } from "./uploadFile";

interface IVideoEditorSliceInitialState {
  video: IPreparedFileInfo | null;
}

const videoEditorSliceInitialState: IVideoEditorSliceInitialState = {
  video: null,
};

const videoEditorSlice = createSlice({
  name: "videoEditor",
  initialState: videoEditorSliceInitialState,
  reducers: {
    setVideo(state, action) {
      state.video = action.payload;
    },
    resetVideoEditor(state) {
      state.video = videoEditorSliceInitialState.video;
    },
  },
});

export default videoEditorSlice.reducer;
export const { setVideo, resetVideoEditor } = videoEditorSlice.actions;
