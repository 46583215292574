import React, { useCallback, useMemo } from "react";
import { Box, ButtonBase, Grid, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { UserAvatar } from "../../../user/UserAvatar";
import { useAppSelector } from "../../../../store";
import { ISupportMessage } from "../../../../store/slices/supportRequests";
import _ from "lodash";
import { Image } from "../../../common/Image";

type TChatMessage = {
  message: ISupportMessage;
};

export const SupportMessage: React.FC<TChatMessage> = ({ message }) => {
  const { user } = useAppSelector((state) => state.user);

  const isMyMessage = useMemo(
    () => message.from.id === user?.id,
    [message.from.id, user?.id],
  );

  const onOpenFile = useCallback((url: string | undefined) => {
    window.open(url);
  }, []);

  return (
    <Box
      sx={[
        materialStyles.chatMessageContainer,
        isMyMessage && materialStyles.myChatMessageContainer,
      ]}
    >
      <Grid
        sx={[
          materialStyles.messageContainer,
          isMyMessage && materialStyles.myMessageContainer,
        ]}
      >
        {_.isEmpty(message.files) ? (
          <Typography sx={materialStyles.message}>{message.message}</Typography>
        ) : (
          <ButtonBase
            onClick={() => onOpenFile(_.first(message.files)?.path)}
            sx={materialStyles.message}
          >
            <Image
              sx={materialStyles.imageMessage}
              src={_.first(message.files)?.path}
            />
          </ButtonBase>
        )}
      </Grid>
      {isMyMessage ? <UserAvatar type="DEFAULT" user={user} /> : null}
    </Box>
  );
};
