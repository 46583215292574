import React, { useCallback, useMemo, useRef, useState } from "react";
import { UserLayout } from "../../components/layout/UserLayout";
import { PageTitle } from "../../components/titles/PageTitle";
import { useTranslation } from "react-i18next";
import { BlockEmptyWithIcon } from "../../components/blocks/blockEmpty/BlockEmptyWithIcon";
import TrashEmptyIcon from "../../assets/images/icons/trash-empty-icon.svg";
import {
  AppImageList,
  TAppImageListForwardRef,
} from "../../components/lists/imageList/AppImageList";
import { useAppDispatch, useAppSelector } from "../../store";
import _ from "lodash";
import { materialStyles } from "./styles";
import {
  forceRemovePhotoFromTrashByIds,
  restorePhotoFromTrashByIds,
} from "../../store/thunks/trash";
import { LoaderWithBackdrop } from "../../components/loaders/LoaderWithBackdrop";
import { removeFromTrash } from "../../store/slices/trash";
import { Button, Grid } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { AdditionalMenuButton } from "../../components/buttons/AdditionalMenuButton";
import { useFetchTrash } from "../../hooks/useFetchTrash";

type ITrashPage = {};

export const Trash: React.FC<ITrashPage> = () => {
  const { t } = useTranslation();
  const { loading } = useAppSelector((state) => state.loader);
  const dispatch = useAppDispatch();

  const imageListRef = useRef<TAppImageListForwardRef>(null);

  const { trash, onUpdatePageOrPageSize } = useFetchTrash();

  const [selectedImagesIds, setSelectedImagesIds] = useState<number[]>([]);

  const onRestorePhotosFromTrash = useCallback(async () => {
    try {
      _.forEach(selectedImagesIds, (id) => {
        dispatch(removeFromTrash(id));
      });

      await dispatch(restorePhotoFromTrashByIds(selectedImagesIds));

      imageListRef.current?.clearAllSelections();
    } catch (error) {
      console.error("Error while [onRestorePhotoFromTrash]", error);
    }
  }, [dispatch, selectedImagesIds]);

  const onForceRemovePhotos = useCallback(async () => {
    try {
      _.forEach(selectedImagesIds, (id) => {
        dispatch(removeFromTrash(id));
      });

      await dispatch(forceRemovePhotoFromTrashByIds(selectedImagesIds));

      imageListRef.current?.clearAllSelections();
    } catch (error) {
      console.error("Error while [onForceRemovePhoto]", error);
    }
  }, [dispatch, selectedImagesIds]);

  const onSelectedPhoto = useCallback(
    (ids: number[]) => setSelectedImagesIds(ids),
    [],
  );

  const menuActions = useMemo(
    () => [
      {
        title: t("trash.remove_frow_trash"),
        onPress: onForceRemovePhotos,
      },
      {
        title: t("trash.restore_selecte_photos"),
        onPress: onRestorePhotosFromTrash,
      },
    ],
    [onForceRemovePhotos, onRestorePhotosFromTrash, t],
  );

  return (
    <>
      <UserLayout onEndReached={onUpdatePageOrPageSize}>
        <PageTitle title={t("trash.trash")}>
          {!_.isEmpty(selectedImagesIds) ? (
            <Grid sx={materialStyles.buttonsHeaderContainer}>
              <Button
                onClick={onForceRemovePhotos}
                sx={materialStyles.removeFromTrashButton}
                startIcon={<DeleteOutlineIcon />}
              >
                {t("trash.remove_frow_trash")}
              </Button>
              <Button
                onClick={onRestorePhotosFromTrash}
                sx={materialStyles.restoreFromTrashButton}
                startIcon={<ReplayIcon />}
              >
                {t("trash.restore_selecte_photos")}
              </Button>
              <Grid sx={materialStyles.showOnMobile}>
                <AdditionalMenuButton menuItems={menuActions} />
              </Grid>
            </Grid>
          ) : null}
        </PageTitle>
        <AppImageList
          ref={imageListRef}
          enableSelection
          containerStyle={materialStyles.appImageList}
          photos={trash}
          onSelected={onSelectedPhoto}
          type="TRASH"
        />
        {_.isEmpty(trash) ? (
          <BlockEmptyWithIcon
            Icon={<img src={TrashEmptyIcon} alt="TrashEmptyIcon" />}
            title={t("trash.trashed_will_show_here")}
          />
        ) : null}
      </UserLayout>
      <LoaderWithBackdrop isShow={loading} />
    </>
  );
};
