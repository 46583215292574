import React, { useCallback, useState } from "react";
import { ITags, addNewTag } from "../../../store/slices/tags";
import { Box, ButtonBase, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { TagItem } from "../../items/TagItem";
import { materialStyles, styles } from "./styles";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import { createTag } from "../../../store/thunks/tags";
import { useAppDispatch } from "../../../store";

type ITagsList = {
  tags: ITags[];
  onTagClick?: (tag: ITags) => void;
  onRemoveTag?: (tag: ITags) => void;
  choosenTags?: ITags[];
  disabled?: boolean;
  disableRipple?: boolean;
  disabledCreate?: boolean;
  title?: string;
};

export const TagsList: React.FC<ITagsList> = ({
  tags,
  onTagClick,
  choosenTags,
  disabled = false,
  disabledCreate = false,
  disableRipple,
  onRemoveTag,
  title,
}) => {
  const [newTag, setNewTag] = useState("");
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const onChangeNewTag = useCallback((event: any) => {
    const { value } = event.target;

    setNewTag(value);
  }, []);

  const onCreateNewTag = useCallback(async () => {
    try {
      const createdTag = await dispatch(createTag(newTag));

      if (!createdTag) {
        throw new Error();
      }

      onTagClick && onTagClick(createdTag);
      dispatch(addNewTag(createdTag));
      setNewTag("");
    } catch (error: any) {
      console.error("Error while [onCreateNewTag]", error);
    }
  }, [dispatch, newTag, onTagClick]);

  return (
    <Box>
      {title ? (
        <Typography sx={materialStyles.tagsListTitle}>{title}</Typography>
      ) : null}
      <Grid sx={materialStyles.tagsListContainer}>
        {!disabledCreate ? (
          <Grid sx={materialStyles.newTagItem}>
            <input
              type="text"
              placeholder={t("common.new_hashtag")}
              style={styles.newTagInput}
              value={newTag}
              onChange={onChangeNewTag}
            />
            {!_.isEmpty(newTag) ? (
              <ButtonBase onClick={onCreateNewTag}>
                <DoneIcon sx={materialStyles.doneIcon} />
              </ButtonBase>
            ) : null}
          </Grid>
        ) : null}
        {_.map(tags, (tag) => (
          <TagItem
            disabled={disabled}
            disableRipple={disableRipple}
            key={tag.id}
            onTagClick={onTagClick}
            tag={tag}
            isChoosen={_.some(choosenTags, (tagItem) => tagItem.id === tag.id)}
            onRemoveTag={onRemoveTag}
          />
        ))}
      </Grid>
    </Box>
  );
};
