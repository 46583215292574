import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getMyTrash } from "../store/thunks/trash";
import {
  resetTrash,
  setTrash,
  setTrashPage,
  setTrashPageSize,
} from "../store/slices/trash";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../constants/api";

export const useFetchTrash = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { trash } = useAppSelector((state) => state.trash);

  const fetchTrash = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        if (!isAuth) {
          return;
        }

        const media = await dispatch(getMyTrash(page, pageSize));

        dispatch(setTrash(media));
      } catch (error) {
        console.error("Error while [fetchTrash]", error);
      }
    },
    [dispatch, isAuth]
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setTrashPage(trash.page + 1));
    dispatch(setTrashPageSize(DEFAULT_PAGE_SIZE));
  }, [trash.page, dispatch]);

  const refetchTrash = useCallback(() => {
    fetchTrash(trash.page);
  }, [fetchTrash, trash.page]);

  useEffect(() => {
    fetchTrash(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);

    return () => {
      dispatch(resetTrash());
    };
  }, [dispatch, fetchTrash]);

  useEffect(() => {
    if (trash.page !== DEFAULT_PAGE) {
      fetchTrash(trash.page, trash.pageSize);
    }
  }, [trash.page, trash.pageSize, fetchTrash]);

  return {
    trash: trash.data,
    refetchTrash,
    onUpdatePageOrPageSize,
  };
};
