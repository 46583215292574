import React, { useCallback } from "react";
import { UserLayout } from "../../components/layout/UserLayout";
import { Button, Grid, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store";
import { LoaderWithBackdrop } from "../../components/loaders/LoaderWithBackdrop";

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading } = useAppSelector((state) => state.loader);

  const goToHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <>
      <UserLayout disableMargin>
        <Grid sx={materialStyles.page}>
          <Typography sx={materialStyles.pageTitle}>
            {t("not_found_page.not_found")}
          </Typography>
          <Typography sx={materialStyles.pageDescription}>
            {t("not_found_page.page_does_not_exist")}
          </Typography>
          <Button
            onClick={goToHome}
            variant="contained"
            sx={materialStyles.goHomeButton}
          >
            {t("not_found_page.go_home")}
          </Button>
        </Grid>
      </UserLayout>
      <LoaderWithBackdrop isShow={loading} />
    </>
  );
};
