import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  postsContainer: {
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    gap: "48px",
    marginTop: "54px",
    paddingBottom: "48px",
  },
};
