import { AppDispatch, RootState } from "..";
import axios from "axios";
import { API_URL } from "../api";
import { setLoading } from "../slices/loading";
import { setError } from "../slices/errors";
import { getResponseFromAxiosError } from "../../utils";
import { checkOrRefreshToken } from "./user";

export const complaintOnUser = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user, complaint } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/users/${complaint.actionId}/complain`,
        {
          code: 1,
          message: complaint.message,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        }
      );

      if (!data) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [complaintOnUser]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const complaintOnAlbum = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user, complaint } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/albums/${complaint.actionId}/complain`,
        {
          code: 1,
          message: complaint.message,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        }
      );

      if (!data) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [complaintOnAlbum]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const complaintOnMedia = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user, complaint } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/media/${complaint.actionId}/complain`,
        {
          code: 1,
          message: complaint.message,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        }
      );

      if (!data) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [complaintOnAlbum]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
