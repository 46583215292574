import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {
  image: {
    maxHeight: "250px",
  },
};

export const materialStyles: IMaterialStyleType = {
  imageList: {
    gridTemplateColumns: {
      xs: "repeat(2, 1fr) !important",
      sm: "repeat(2, 1fr) !important",
      md: "repeat(5, 1fr) !important",
    },
    gap: {
      xs: "7px !important",
      sm: "7px !important",
      md: "0px !important",
    },
  },
  imageListItem: {
    transition: "0.2s",

    "&:hover": {
      "& #additionalButton": {
        opacity: 1,
      },
    },
  },
  selectedImageListItem: {
    padding: "10px",
    backgroundColor: theme.palette.grey[600],
  },
  imageListDate: {
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.grey[300],
  },
  imageListDateContainer: {
    paddingLeft: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  checkBoxButtonInDateContainer: {},
  checkBoxButton: {
    position: "absolute",
    top: "-8px",
    left: "-8px",
  },
  trashRestoreButton: {
    position: "absolute",
    opacity: {
      xs: 1,
      sm: 1,
      md: 0,
    },

    transition: "0.3s",
  },
  additionPhotoIcon: {
    color: Colors.white,
  },
  forceRemoveButton: {
    position: "absolute",
    right: 0,

    opacity: {
      xs: 1,
      sm: 1,
      md: 0,
    },

    transition: "0.3s",
  },
  favoritesButton: {
    position: "absolute",
    right: 0,
    top: "84%",

    opacity: {
      xs: 1,
      sm: 1,
      md: 0,
    },

    transition: "0.3s",
  },
  activeFavoritesButton: {
    position: "absolute",
    right: 0,
    top: "84%",

    opacity: 1,
    transition: "0.3s",
  },
  favoritePhotoIcon: {
    color: theme.palette.error.main,
  },
};
