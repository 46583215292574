import { Grid, Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";
import DnsIcon from "@mui/icons-material/Dns";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store";

type IServerInfo = {};

export const ServerInfo: React.FC<IServerInfo> = () => {
  const { t } = useTranslation();
  const { server } = useAppSelector((state) => state.system);

  if (!server) {
    return null;
  }

  return (
    <Grid sx={materialStyles.infoBlock}>
      <DnsIcon sx={materialStyles.blockIcon} />
      <Grid sx={materialStyles.infoTextBlock}>
        <Typography sx={materialStyles.infoBlockTitle}>
          {t("common.server")}
        </Typography>
        <Grid sx={materialStyles.infoBlockItem}>
          <Typography sx={materialStyles.infoBlockValue}>
            {t("common.status")}
          </Typography>
          <Typography sx={materialStyles.infoBlockActiveValue}>
            {server.online ? t("common.online") : t("common.offline")}
          </Typography>
        </Grid>
        <Grid sx={materialStyles.infoBlockItem}>
          <Typography sx={materialStyles.infoBlockValue}>
            {t("common.version")}
          </Typography>
          <Typography sx={materialStyles.infoBlockActiveValue}>
            {server.version}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
