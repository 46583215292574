import React from "react";
import { IUserComplaintModalType } from "..";
import { Button, Grid, Typography } from "@mui/material";
import { materialStyles, styles } from "./styles";
import { useTranslation } from "react-i18next";
import SuccessCircleIcon from "../../../../assets/images/icons/success-circle-icon.svg";
import { IComplaintReasonType } from "../../../../hooks/useComplaintReasons";

type ISuccessComplaintContent = {
  onClose: () => void;
  onChangeComplaintModalType: (type: IUserComplaintModalType) => void;
  type: IComplaintReasonType;
};

export const SuccessComplaintContent: React.FC<ISuccessComplaintContent> = ({
  onClose,
  onChangeComplaintModalType,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <Grid sx={materialStyles.innerModal}>
      <Grid sx={materialStyles.confirmContainer}>
        <img
          style={styles.successIcon}
          src={SuccessCircleIcon}
          alt="SuccessCircleIcon"
        />
        <Typography sx={materialStyles.confirmModalHeaderTitle}>
          {
            {
              ALBUM: t("complaint.thanks_reporting_album"),
              PHOTO: t("complaint.thanks_reporting_media"),
              USER: t("complaint.thanks_reporting_user"),
            }[type]
          }
        </Typography>
        <Typography sx={materialStyles.headerSubtitle}>
          {t("complaint.notification_once_review_your_report")}
        </Typography>
        <Button sx={materialStyles.closeButton} onClick={onClose}>
          {t("common.close")}
        </Button>
      </Grid>
    </Grid>
  );
};
