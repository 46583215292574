import { Button, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

type IUploadPhotoHeader = {
  title: string;
  onBack?: () => void;
  onNext?: () => void;
  onClose?: () => void;
  nextButtonTitle?: string;
  information?: string;
  disadledNextButton?: boolean;
  nextButtonType?: "PRIMARY" | "SECONDARY";
};

export const UploadFileHeader: React.FC<IUploadPhotoHeader> = ({
  title,
  nextButtonTitle,
  information,
  onBack,
  onNext,
  onClose,
  disadledNextButton,
  nextButtonType = "PRIMARY",
}) => {
  const { t } = useTranslation();
  return (
    <Grid sx={materialStyles.uploadModalHeaderBlock}>
      {onBack ? (
        <IconButton onClick={onBack}>
          <ArrowBackIcon sx={materialStyles.backIcon} />
        </IconButton>
      ) : null}
      <Typography sx={materialStyles.uploadPhotoHeaderTitle}>
        {title}
      </Typography>
      <Grid sx={materialStyles.headerNextContainer}>
        {information ? (
          <Typography sx={materialStyles.information}>{information}</Typography>
        ) : null}
        {onNext ? (
          <Button
            variant={nextButtonType === "SECONDARY" ? "text" : "contained"}
            onClick={onNext}
            sx={
              {
                PRIMARY: materialStyles.nextButton,
                SECONDARY: materialStyles.nextButton,
              }[nextButtonType]
            }
            disabled={disadledNextButton}
            color={nextButtonType === "PRIMARY" ? "success" : undefined}
          >
            {nextButtonTitle || t("common.next")}
          </Button>
        ) : null}
      </Grid>
      {onClose ? (
        <IconButton onClick={onClose} sx={materialStyles.closeButton}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </Grid>
  );
};
