import { createSlice } from "@reduxjs/toolkit";

type TUploaderStack = {
  event: string;
  procent: number;
};

interface IUploaderStackSliceInitialState {
  stack: TUploaderStack | null;
}

const uploaderStackSliceInitialState: IUploaderStackSliceInitialState = {
  stack: null,
};

const uploaderStack = createSlice({
  name: "uploaderStack",
  initialState: uploaderStackSliceInitialState,
  reducers: {
    setUploaderStack(state, action) {
      state.stack = action.payload;
    },
    setCurrentStackProcent(state, action) {
      if (!state.stack) {
        return;
      }

      state.stack.procent = action.payload;
    },
    resetStack(state) {
      state.stack = uploaderStackSliceInitialState.stack;
    },
  },
});

export default uploaderStack.reducer;
export const { setUploaderStack, setCurrentStackProcent, resetStack } =
  uploaderStack.actions;
