import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { resetUser } from "../store/slices/user";
import { resetAlbums } from "../store/slices/albums";
import { resetPhotos } from "../store/slices/myPhotos";
import { resetExplore } from "../store/slices/explore";
import { resetMapMedia } from "../store/slices/map";
import { resetTrash } from "../store/slices/trash";
import { resetFavorites } from "../store/slices/favorites";
import { resetCloudStorage } from "../store/slices/system";
import { resetTags } from "../store/slices/tags";
import { socket } from "../socket";
import { resetGroups } from "../store/slices/groups";
import { resetMessages } from "../store/slices/messages";
import { resetSupportRequest } from "../store/slices/supportRequests";

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const { isConnected } = useAppSelector((state) => state.socket);

  const resetStorage = useCallback(() => {
    if (isConnected) {
      socket.emit("auth:logout");
    }

    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    dispatch(resetUser());
    dispatch(resetAlbums());
    dispatch(resetPhotos());
    dispatch(resetExplore());
    dispatch(resetMapMedia());
    dispatch(resetTrash());
    dispatch(resetFavorites());
    dispatch(resetCloudStorage());
    dispatch(resetTags());
    dispatch(resetGroups());
    dispatch(resetMessages());
    dispatch(resetSupportRequest());
  }, [dispatch, isConnected]);

  return { resetStorage };
};
