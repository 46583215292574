import { useCallback, useEffect, useReducer } from "react";
import { useAppSelector } from "../store";
import { socket } from "../socket";
import { ISupportMessage } from "../store/slices/supportRequests";
import { useFetchSupportRequestMessages } from "./useFetchSupportRequestMessages";

type TLocalSupportMessagesState = {
  messages: ISupportMessage[];
};

type TLocalMessagesAction =
  | {
      type: "SET_MESSAGES";
      messeges: ISupportMessage[];
    }
  | {
      type: "ADD_MESSAGE";
      message: ISupportMessage;
    }
  | { type: "RESET_MESSAGES" };

const initialSupportMessagesState: TLocalSupportMessagesState = {
  messages: [],
};

const localSupportMessagesReducer = (
  state: TLocalSupportMessagesState,
  action: TLocalMessagesAction,
) => {
  switch (action.type) {
    case "SET_MESSAGES":
      return { ...state, messages: action.messeges };
    case "ADD_MESSAGE":
      return { ...state, messages: [action.message, ...state.messages] };
    case "RESET_MESSAGES":
      return initialSupportMessagesState;
    default:
      return state;
  }
};
export const useLocalProccessSocketSupportMessages = () => {
  const [messagesState, dispatchMessagesState] = useReducer(
    localSupportMessagesReducer,
    initialSupportMessagesState,
  );

  const { messages } = useFetchSupportRequestMessages();

  const { isConnected } = useAppSelector((state) => state.socket);

  useEffect(() => {
    dispatchMessagesState({ type: "SET_MESSAGES", messeges: messages });
  }, [messages]);

  const handleChatSend = useCallback((message: ISupportMessage) => {
    dispatchMessagesState({ type: "ADD_MESSAGE", message });
  }, []);

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    socket.on("support:reply", handleChatSend);

    return () => {
      if (isConnected) {
        socket.off("support:reply", handleChatSend);
      }
    };
  }, [handleChatSend, isConnected]);

  return { messagesState };
};
