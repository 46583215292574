import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";
import { withHexOpacity } from "../../../../utils";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  drawer: {
    zIndex: 1300,
    top: "unset",
    bottom: "unset",
    left: "unset",
    right: "unset",
  },
  drawerContainer: {
    backgroundColor: "transparent",
    boxShadow: "none",
    width: {
      xs: "100%",
      sm: "100%",
      md: "520px",
    },
  },
  drawerGroupsContainer: {
    boxShadow: "none",
    backgroundColor: "transparent",
    height: "fit-content",
    marginTop: "145px",
    width: {
      xs: "100%",
      sm: "100%",
      md: "200px",
    },
    paddingRight: "40px",
    alignItems: "flex-end",
    maxHeight: "240px",
    overflow: "auto",
  },
  headerIcon: {
    color: Colors.white,
  },
  innerInfoBlock: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "6px",
    padding: {
      xs: "24px",
      sm: "24px",
      md: "32px 48px 32px 48px",
    },
    marginBottom: "24px",
    marginTop: "18px",
    marginRight: "18px",
    marginLeft: "18px",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "auto",
  },
  commentsInnerInfoBlock: {
    display: "flex",
    flex: 1,
    alignItems: "flex-start",
    flexDirection: "column",
    marginBottom: "24px",
  },
  infoBlockHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "18px",
  },
  headerTitle: {
    color: Colors.white,
    fontSize: "20px",
    fontWeight: "700",
    marginRight: "14px",
  },
  descriptionBlock: {
    marginBottom: "36px",
  },
  detailsBlock: {},
  sharingBlock: {
    marginTop: "24px",
  },
  sharingBlockTitle: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[700],
    marginBottom: "8px",
  },
  tagList: {
    marginTop: "24px",
  },
  userInfoBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "24px",
    width: "fit-content",
  },
  userInfoName: {
    marginLeft: "10px",
    color: Colors.white,
  },
  infoBlockSubtitle: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "10%",
    color: theme.palette.grey[700],
    textTransform: "uppercase",
    marginBottom: "36px",
  },
  loaderContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  copyIcon: {
    width: "16px",
    height: "16px",
    color: theme.palette.grey[900],
  },
  groupItemContainer: {
    display: "flex",
    alignItems: "center",
    padding: "6px 14px 6px 14px",
    borderRadius: "20px",
    backgroundColor: theme.palette.grey["600"],
    border: `1px solid ${withHexOpacity(Colors.white, 0.12)}`,
    flexDirection: "row",
    marginBottom: "10px",
    height: "38px",
    marginRight: "10px",
    width: "fit-content",
  },
  groupSelectedItemContainer: {
    border: `1px solid ${withHexOpacity(Colors.white, 0.8)}`,
  },
  groupDisabledContainer: {
    // backgroundColor: withHexOpacity(theme.palette.grey["600"], 0.5),
    opacity: 0.7,
  },
  groupItemTitle: {
    color: Colors.white,
  },

  slideItemButton: {
    color: Colors.white,
    position: "absolute",
    zIndex: 1,
    backgroundColor: withHexOpacity("#313131", 0.2),
    right: "8px",
    top: "8px",
  },
  slideItemCoverContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",

    "&:hover": {
      "#description-container": {
        opacity: 1,
      },
    },
  },
  descriptionContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    opacity: 0,
    transition: "0.2s",
  },
  descriptionBoxContainer: {
    maxWidth: "98%",
    wordWrap: "break-word",
    p: "10px",
    maxHeight: "150px",
    overflow: "auto",
    backgroundColor: "rgb(50 50 50 / 80%)",
    borderRadius: "10px",
    color: "#faf9f6",
    margin: "15px",
  },
};
