import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useMemo } from "react";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faThumbsDown } from "@fortawesome/free-regular-svg-icons";

export type IEmotionsKeys = "like" | "heart" | "rage";

export type IEmotion = {
  emotion: IconProp;
  key: IEmotionsKeys;
};

export const useEmotions = () => {
  const emotions: IEmotion[] = useMemo(
    () => [
      {
        emotion: faThumbsUp,
        key: "like",
      },
      {
        emotion: faHeart,
        key: "heart",
      },
      {
        emotion: faThumbsDown,
        key: "rage",
      },
    ],
    [],
  );

  return { emotions };
};
