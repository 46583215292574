import { Colors, HEADER_HEIGHT, theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {
  link: {
    textDecoration: "none",
  },
};

export const materialStyles: IMaterialStyleType = {
  listButton: {
    padding: "10px 30px 10px 30px !important",
  },
  menuItemIcon: {
    color: "black",
  },
  menuItemText: {
    color: "black",
    fontSize: 18,
    textAlign: "center",
    marginLeft: "10px !important",
  },
  drawer: {
    border: "none !important",
  },
  closeButtonBlock: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  page: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "row",
    flex: 1,
    height: "100vh",
    maxWidth: "100vw",
  },
  userLayout: {
    padding: {
      xs: `${HEADER_HEIGHT + 0}px 12px 0px 12px`,
      sm: `${HEADER_HEIGHT + 0}px 12px 0px 12px`,
      md: `${HEADER_HEIGHT + 32}px 29px 0px 29px`,
    },
    flex: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  backdrop: {
    zIndex: 1101,
  },
  disableMarginUserLayout: {
    padding: {
      xs: 0,
      sm: 0,
      md: 0,
    },
  },
  desktopSideMenu: {
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
  },
  mobileSideMenu: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
    },
  },
  closer: {
    position: "absolute",
    backgroundColor: theme.palette.grey[50],
    top: "50%",
    zIndex: 10,
    height: "45px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    display: "flex",
    alignItems: "center",
    flexDirection: "center",
  },
  closerIconContainer: {
    width: "20px",
    height: "20px",
    color: Colors.white,
  },
  closerIcon: {
    width: "18px",
    height: "18px",
  },
};
