import {
  Button,
  ButtonBase,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IPhotoType } from "../../../../store/slices/myPhotos";
import _ from "lodash";
import { UserAvatar } from "../../../user/UserAvatar";
import { materialStyles, styles } from "./styles";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  addFavoritesPhoto,
  removeFavoritesPhoto,
} from "../../../../store/thunks/favorites";
import { IUserType } from "../../../../store/slices/user";
import {
  subscribeOnUser,
  unsubscribeOnUser,
} from "../../../../store/thunks/explore";
import {
  removeAllMediaByUserId,
  removeSubscription,
  setSubscription,
} from "../../../../store/slices/explore";
import { useAuth } from "../../../../hooks/useAuth";
import { FavoritesButton } from "../../../buttons/FavoritesButton";
import MessageIcon from "../../../../assets/images/icons/message-icon.svg";
import { getTotalEmojiFromMedia } from "../../../../utils";
import { useSocketMediaHandle } from "../../../../hooks/useSocketMediaHandle";
import { useLocalProccessSocketEmotions } from "../../../../hooks/useLocalProccessSocketEmotions";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { IExploreTypeTab } from "../../../../pages/Explore";

type IPostItem = {
  photo: IPhotoType;
  onPhotoClick?: (photo: IPhotoType) => void;
  onPhotoCommentsClick?: (photo: IPhotoType) => void;
  onUserProfileClick?: (user?: IUserType) => void;
  onPhotoMapClick?: (photo: IPhotoType) => void;
  type: IExploreTypeTab;
};

export const PostItem: React.FC<IPostItem> = ({
  photo,
  onPhotoClick,
  onUserProfileClick,
  onPhotoCommentsClick,
  onPhotoMapClick,
  type,
}) => {
  const { t } = useTranslation();

  const { id } = useAppSelector((state) => state.user);
  const { isConnected } = useAppSelector((state) => state.socket);

  const { onSendEmotion, localEmotions } = useLocalProccessSocketEmotions({
    id: photo.id,
    emotions: photo.emotions,
    emotionsOwn: _.first(photo.emotionsOwn),
  });

  const isAuth = useAuth();

  const [isFavorite, setIsFavorite] = useState(photo.is_favorite);

  const [isSubscribed, setIsSubscriber] = useState(
    _.get(photo, "user.is_subscribed", false)
  );

  const dispatch = useAppDispatch();
  const { onSocketJoin, onSocketLeave } = useSocketMediaHandle(photo.id);

  const isMyPost = useMemo(() => photo.user?.id === id, [id, photo.user?.id]);

  const onAddOrRemoveFromFavorites = useCallback(
    async (photo: IPhotoType) => {
      try {
        if (isFavorite) {
          await dispatch(removeFavoritesPhoto(photo.id));
          setIsFavorite(!isFavorite);
          return;
        }
        await dispatch(addFavoritesPhoto(photo.id));
        setIsFavorite(!isFavorite);
      } catch (error) {
        console.error("Error while [onAddOrRemoveFromFavorites]", error);
      }
    },
    [dispatch, isFavorite]
  );

  const onSubscribe = useCallback(
    async (user?: IUserType) => {
      try {
        if (!user) {
          return;
        }
        await dispatch(subscribeOnUser(user.id));
        dispatch(setSubscription(user));
        setIsSubscriber(true);
      } catch (error) {
        console.error("Error while onSubscribe", error);
      }
    },
    [dispatch]
  );

  const onUnsubscribe = useCallback(
    async (user?: IUserType) => {
      try {
        if (!user) {
          return;
        }
        await dispatch(unsubscribeOnUser(user.id));
        dispatch(removeSubscription(user.id));

        if (type === "SUBSCRIPTIONS") {
          dispatch(removeAllMediaByUserId(user.id));
        }

        setIsSubscriber(false);
      } catch (error) {
        console.error("Error while onUnsubscribe", error);
      }
    },
    [dispatch, type]
  );

  useEffect(() => {
    if (isConnected) {
      onSocketJoin();
    }

    return () => {
      if (isConnected) {
        onSocketLeave();
      }
    };
  }, [isConnected, onSocketJoin, onSocketLeave]);

  useEffect(() => {
    setIsFavorite(photo.is_favorite);
  }, [photo.is_favorite]);

  return (
    <Grid sx={materialStyles.postContainer}>
      <Grid sx={materialStyles.postHeaderContainer}>
        <ButtonBase
          disabled={isMyPost}
          disableRipple={isMyPost}
          onClick={() =>
            !isMyPost && onUserProfileClick && onUserProfileClick(photo.user)
          }
          sx={materialStyles.postUserContainer}
        >
          <UserAvatar type="HEADER" user={photo.user} />
          <Typography sx={materialStyles.postUsername}>
            {_.get(photo, "user.username", "UserName")}
          </Typography>
        </ButtonBase>
        <Grid sx={materialStyles.headerPostButtons}>
          <IconButton onClick={() => onPhotoMapClick && onPhotoMapClick(photo)}>
            <MapOutlinedIcon sx={materialStyles.headerPostIcon} />
          </IconButton>
          {isAuth && !isMyPost && !isSubscribed ? (
            <Button
              onClick={() =>
                isSubscribed
                  ? onUnsubscribe(photo.user)
                  : onSubscribe(photo.user)
              }
              endIcon={<DoneIcon />}
              sx={
                isSubscribed
                  ? materialStyles.unfollowButton
                  : materialStyles.followButton
              }
              variant="contained"
            >
              {isSubscribed ? t("explore.unfollow") : t("explore.follow")}
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <Grid sx={materialStyles.imageContainer}>
        <img
          onClick={() => onPhotoClick && onPhotoClick(photo)}
          style={styles.postImage}
          src={photo.full || photo.preview}
          alt="preview"
        />
        {isAuth ? (
          <Grid sx={materialStyles.activeFavoritesButton}>
            <FavoritesButton
              isFavorite={isFavorite}
              iconButtonId={isFavorite ? undefined : "additionalButton"}
              onAddOrRemoveFavoritePhoto={() =>
                onAddOrRemoveFromFavorites && onAddOrRemoveFromFavorites(photo)
              }
              photoEmotions={localEmotions.emotions}
              emotionsOwn={localEmotions.emotionsOwn}
              onSendEmotion={onSendEmotion}
            />
            <ButtonBase
              onClick={() =>
                onPhotoCommentsClick && onPhotoCommentsClick(photo)
              }
              sx={materialStyles.commentsButton}
            >
              <img src={MessageIcon} alt="MessageIcon" />
              <Typography sx={materialStyles.commentsButtonText}>
                {t("explore.say_something")}
              </Typography>
            </ButtonBase>
          </Grid>
        ) : null}
      </Grid>
      {photo.description ? (
        <Typography sx={materialStyles.postDescription}>
          {photo.description}
        </Typography>
      ) : null}
      {isAuth ? (
        <Grid sx={materialStyles.activityBlock}>
          <Typography sx={materialStyles.activityText}>
            {t("explore.replies_count", { count: photo.comments })}
          </Typography>
          <Grid sx={materialStyles.activityDivider} />
          <Typography sx={materialStyles.activityText}>
            {t("explore.likes_count", {
              count: getTotalEmojiFromMedia(localEmotions.emotions),
            })}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};
