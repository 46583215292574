import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getUserById } from "../store/thunks/user";
import { setUser } from "../store/slices/userProfile";

type TFetchUserProfileById = {
  isEnable?: boolean;
  id: number | null;
};

export const useFetchUserProfileById = ({
  isEnable,
  id,
}: TFetchUserProfileById) => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { user } = useAppSelector((state) => state.userProfile);

  const fetchUserProfile = useCallback(async () => {
    if (!isAuth) {
      return;
    }

    if (!id) {
      return;
    }

    try {
      const response = await dispatch(getUserById(id));
      if (!response) {
        throw new Error();
      }
      dispatch(setUser(response));
    } catch (error: any) {
      console.error("Error while [fetchUserProfile]", error);
    }
  }, [dispatch, id, isAuth]);

  useEffect(() => {
    if (isEnable) {
      fetchUserProfile();
    }
  }, [fetchUserProfile, isEnable]);

  return {
    user,
  };
};
