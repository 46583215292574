import styled from "@emotion/styled";
import { theme } from "../../theme";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

export const MaterialTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ),
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
