import React, { Fragment, useCallback, useRef } from "react";
import { Image } from "../../../common/Image";
import { IMaterialStyleItem } from "../../../../types";
import { Box, IconButton, styled, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { materialStyles } from "./styles";

const Div = styled("div")({});

type TSlideItem = {
  onLoad?: () => void;
  sx?: IMaterialStyleItem;
  src?: string;
  onClose?: () => void;
  discription?: string;
};

type SlideItemIconPositions = {
  width: number;
  height: number;
};

export const SlideItem: React.FC<TSlideItem> = ({
  onLoad: onLoadFromProps,
  sx,
  src,
  onClose,
  discription,
}) => {
  const slideItemRef = useRef<HTMLImageElement>(null);
  const coverBlockSizeRef = useRef<SlideItemIconPositions | null>(null);

  const onLoad = useCallback(() => {
    if (!slideItemRef.current) {
      return;
    }

    const memoSlideItem = slideItemRef.current;

    const rect = memoSlideItem?.getBoundingClientRect();

    coverBlockSizeRef.current = {
      width: rect.width,
      height: rect.height,
    };

    onLoadFromProps && onLoadFromProps();
  }, [onLoadFromProps]);

  return (
    <Fragment>
      <Image ref={slideItemRef} onLoad={onLoad} sx={sx} src={src} />
      <Div
        sx={[
          materialStyles.slideItemCoverContainer,
          {
            width: coverBlockSizeRef.current?.width,
            height: coverBlockSizeRef.current?.height,
          },
        ]}
      >
        <IconButton onClick={onClose} sx={materialStyles.slideItemButton}>
          <CloseIcon />
        </IconButton>
        {discription ? (
          <Box
            id="description-container"
            sx={materialStyles.descriptionContainer}
          >
            <Box sx={materialStyles.descriptionBoxContainer}>
              <Typography>{discription}</Typography>
            </Box>
          </Box>
        ) : null}
      </Div>
    </Fragment>
  );
};
