import { Box, IconButton, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { materialStyles } from "./styles";
import { useAppDispatch, useAppSelector } from "../../../store";
import { UserAvatar } from "../../user/UserAvatar";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { resetChat } from "../../../store/slices/chat";

export const ChatUser: React.FC = () => {
  const { user } = useAppSelector((state) => state.chat);

  const dispatch = useAppDispatch();

  const onCloseChat = useCallback(() => {
    dispatch(resetChat());
  }, [dispatch]);

  return (
    <Box sx={materialStyles.chatUserContainer}>
      <IconButton
        onClick={onCloseChat}
        sx={materialStyles.charUserMobileBackButton}
      >
        <ArrowBackOutlinedIcon />
      </IconButton>
      <UserAvatar type="DEFAULT" user={user} />
      <Typography sx={materialStyles.chatUsername}>{user?.username}</Typography>
    </Box>
  );
};
