import {
  Box,
  Button,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { materialStyles } from "./styles";
import { useFormik } from "formik";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  createSupportRequest,
  uploadFileToSupportRequest,
} from "../../../../store/thunks/support";
import { addSupportRequests } from "../../../../store/slices/supportRequests";
import { MaterialFileInput } from "../../../common/MaterialFileInput";

type ICreateSupportRequestForm = {
  onClose: () => void;
};

type IFormValues = {
  email: string;
  message: string;
};

export const CreateSupportRequestForm: React.FC<ICreateSupportRequestForm> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.user);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    async (details: IFormValues) => {
      try {
        const data = await dispatch(
          createSupportRequest(details.email, details.message),
        );

        if (!data) {
          throw new Error();
        }

        if (uploadedFile) {
          await dispatch(uploadFileToSupportRequest(data.id, uploadedFile));
        }

        dispatch(addSupportRequests(data));
        onClose();
      } catch (error) {
        console.error("Error  while [onSubmit]", error);
      }
    },
    [dispatch, onClose, uploadedFile],
  );

  const { values, errors, handleSubmit, isValid, handleChange } = useFormik({
    onSubmit,
    initialValues: {
      email: user?.email || "",
      message: "",
    },
    validate: (values) => {
      return _.pickBy({
        email: !_.trim(values.email),
        message: !_.trim(values.message),
      });
    },
  });

  const updloadImageForSupport = useCallback((event: any) => {
    const { files } = event.target;
    const file = _.first(files) as File;
    setUploadedFile(file);
  }, []);

  return (
    <Grid sx={materialStyles.modalInnerBlock}>
      <form onSubmit={handleSubmit}>
        <Grid sx={materialStyles.modalHeaderBlock}>
          <Typography sx={materialStyles.modalHeaderTitle}>
            {t("settings.create_support_request")}
          </Typography>
          <Typography sx={materialStyles.modalHeaderSubtitle}>
            {t("settings.create_support_request_description")}
          </Typography>
        </Grid>
        <Grid sx={materialStyles.modalForm}>
          <TextField
            error={Boolean(errors.email)}
            name="email"
            value={values.email}
            onChange={handleChange}
            sx={materialStyles.input}
            fullWidth
            label={t("settings.email")}
            placeholder={t("settings.enter_email")}
          />
          <TextField
            error={Boolean(errors.message)}
            name="message"
            multiline
            minRows={3}
            maxRows={5}
            value={values.message}
            onChange={handleChange}
            sx={materialStyles.message}
            fullWidth
            label={t("settings.message")}
            placeholder={t("settings.enter_message")}
          />
          <Box sx={materialStyles.uploadFileContainer}>
            <FormLabel htmlFor="outlined-button-file">
              <Button
                component="span"
                sx={materialStyles.uploadMessageFile}
                variant="outlined"
              >
                {t("settings.upload_image")}
                <MaterialFileInput
                  accept="image/*"
                  id="outlined-button-file"
                  multiple
                  type="file"
                  onChange={updloadImageForSupport}
                />
              </Button>
            </FormLabel>
            {uploadedFile ? (
              <Typography sx={materialStyles.uploadedFileName}>
                {uploadedFile.name}
              </Typography>
            ) : null}
          </Box>
          <Button
            disabled={!isValid}
            type="submit"
            sx={materialStyles.saveButton}
            variant="contained"
          >
            {t("common.create")}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};
