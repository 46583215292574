import { Box, IconButton, Modal } from "@mui/material";
import React, { useCallback, useState } from "react";
import { materialStyles } from "./styles";
import { useAppDispatch, useAppSelector } from "../../../store";
import { LoaderWithBackdrop } from "../../loaders/LoaderWithBackdrop";
import { UserNameEditForm } from "./components/UsernameEditForm";
import { resetUserEditStorage } from "../../../store/slices/userEdit";
import { EmailEditForm } from "./components/EmailEditForm";
import { PasswordEditForm } from "./components/PasswordEditForm";
import { ConfirmUserEditModalForm } from "./components/ConfirmUserEditModalContent";
import { SuccessUserEditModalContent } from "./components/SuccessUserEditModalContent";
import CloseIcon from "@mui/icons-material/Close";

export type IUserEditModalType =
  | "EDIT_MODAL"
  | "CONFIRM_MODAL"
  | "SUCCESS_MODAL";

export type IUserEditModalContentType = "USERNAME" | "EMAIL" | "PASSWORD";

type IUserEditModal = {
  isOpen: boolean;
  onClose: () => void;
  type: IUserEditModalContentType | null;
  initialModalType?: IUserEditModalType;
};

export const UserEditModal: React.FC<IUserEditModal> = ({
  isOpen,
  onClose,
  type,
  initialModalType = "EDIT_MODAL",
}) => {
  const [userEditModalType, setUserEditModalType] = useState(initialModalType);
  const { loading } = useAppSelector((state) => state.loader);

  const dispatch = useAppDispatch();

  const onChangeModalType = useCallback(
    (type: IUserEditModalType) => setUserEditModalType(type),
    [],
  );

  const onCloseModal = useCallback(() => {
    setUserEditModalType(initialModalType);
    dispatch(resetUserEditStorage());
    onClose();
  }, [dispatch, initialModalType, onClose]);

  if (!type) {
    return null;
  }

  return (
    <Modal sx={materialStyles.modal} open={isOpen} onClose={onCloseModal}>
      <Box sx={materialStyles.modalBox}>
        <IconButton onClick={onCloseModal} sx={materialStyles.closeButton}>
          <CloseIcon />
        </IconButton>
        {type === "USERNAME" && userEditModalType === "EDIT_MODAL" ? (
          <UserNameEditForm
            onClose={onCloseModal}
            onChangeModalType={onChangeModalType}
          />
        ) : null}
        {type === "EMAIL" && userEditModalType === "EDIT_MODAL" ? (
          <EmailEditForm
            onClose={onCloseModal}
            onChangeModalType={onChangeModalType}
          />
        ) : null}
        {type === "PASSWORD" && userEditModalType === "EDIT_MODAL" ? (
          <PasswordEditForm
            onClose={onCloseModal}
            onChangeModalType={onChangeModalType}
          />
        ) : null}
        {userEditModalType === "CONFIRM_MODAL" ? (
          <ConfirmUserEditModalForm
            type={type}
            onChangeModalType={onChangeModalType}
            onClose={onCloseModal}
          />
        ) : null}
        {userEditModalType === "SUCCESS_MODAL" ? (
          <SuccessUserEditModalContent
            type={type}
            onChangeModalType={onChangeModalType}
            onClose={onCloseModal}
          />
        ) : null}
        <LoaderWithBackdrop isShow={loading} />
      </Box>
    </Modal>
  );
};
