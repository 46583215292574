import { createSlice } from "@reduxjs/toolkit";

export enum PRIVACY_GROUP_TYPES {
  ALL = 0,
  FRIENDS = 1,
  FOLLOWERS = 2,
  FAMILY = 3,
  RESTRICTED = 8,
  AUTHORIZED = 7,
  CUSTOM = 9,
}

export type PrivacyGroup = {
  id: number;
  is_custom: boolean;
  title: string;
  isAll?: boolean;
  is_editable: boolean;
  type: number;
};

interface IGroupsSliceInitialState {
  groups: PrivacyGroup[];
}

const groupsSliceInitialState: IGroupsSliceInitialState = {
  groups: [],
};

const groupsSlice = createSlice({
  name: "groups",
  initialState: groupsSliceInitialState,
  reducers: {
    setGroups(state, action) {
      state.groups = action.payload;
    },
    addNewGroup(state, action) {
      state.groups = [...state.groups, action.payload];
    },
    resetGroups(state) {
      state.groups = groupsSliceInitialState.groups;
    },
  },
});

export default groupsSlice.reducer;
export const { setGroups, addNewGroup, resetGroups } = groupsSlice.actions;
