import {
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  addMediaComments,
  IMediaComment,
} from "../../../../store/slices/userPhoto";
import { CommentsList } from "../../../lists/commentsList";
import { addNewMediaComment } from "../../../../store/thunks/media";
import { socket } from "../../../../socket";

type PhotoCommentsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  photoId?: number;
  isLoading?: boolean;
};

export const PhotoCommentsDrawer: React.FC<PhotoCommentsDrawerProps> = ({
  isOpen,
  onClose,
  photoId,
  isLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { comments, photo } = useAppSelector((state) => state.userPhoto);
  const { isConnected } = useAppSelector((state) => state.socket);

  const onCloseComments = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSendComment = useCallback(
    async (text: string) => {
      try {
        if (!photo) {
          return;
        }

        await dispatch(addNewMediaComment(photo.id, text));
      } catch (error: any) {
        console.error("Error while [onSendComment]", error);
      }
    },
    [dispatch, photo]
  );

  useEffect(() => {
    const onAddComment = (comment: IMediaComment) => {
      if (comment.media_id === photo?.id) {
        dispatch(addMediaComments(comment));
      }
    };

    if (isConnected) {
      socket.on("comment:add", onAddComment);
    }
    return () => {
      if (isConnected) {
        socket.off("comment:add", onAddComment);
      }
    };
  }, [dispatch, photo, isConnected]);

  return (
    <Drawer
      sx={materialStyles.drawer}
      PaperProps={{ sx: materialStyles.drawerContainer }}
      anchor="right"
      hideBackdrop
      open={isOpen}
      onClose={onClose}
    >
      <Grid
        sx={[
          materialStyles.innerInfoBlock,
          materialStyles.commentsInnerInfoBlock,
        ]}
      >
        <Grid sx={materialStyles.infoBlockHeader}>
          <IconButton onClick={onCloseComments}>
            <CloseOutlinedIcon sx={materialStyles.headerIcon} />
          </IconButton>
          <Typography sx={materialStyles.headerTitle}>
            {t("photos.activity")}
          </Typography>
          {isLoading ? <CircularProgress size={20} /> : null}
        </Grid>
        <Typography sx={materialStyles.infoBlockSubtitle}>
          {t("photos.comments", { count: _.size(comments) })}
        </Typography>
        <CommentsList comments={comments} onSendComment={onSendComment} />
      </Grid>
    </Drawer>
  );
};
