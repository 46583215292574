import { Typography } from "@mui/material";
import React from "react";
import { materialStyles } from "./styles";

type IBlockDetailsTitle = {
  title: string;
};

export const BlockDetailsTitle: React.FC<IBlockDetailsTitle> = ({ title }) => {
  return <Typography sx={materialStyles.blockDetailsTitle}>{title}</Typography>;
};
