import { ButtonBase, Typography, Grid, Switch } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { materialStyles } from "./styles";

type IButtonWithIcon = {
  title: string;
  subtitle?: string;
  onClick?: () => void;
  onClickSwitch?: (e: any, value: boolean) => void;
  checked?: boolean;
  Icon?: React.ReactElement;
  actionType?: "ARROW" | "SWITCHER";
};

export const ButtonWithIcon: React.FC<IButtonWithIcon> = ({
  title,
  subtitle,
  onClick,
  Icon,
  actionType = "ARROW",
  checked,
  onClickSwitch,
}) => {
  return (
    <ButtonBase
      sx={[
        materialStyles.buttonWithIcon,
        actionType === "SWITCHER" && materialStyles.buttonWithIconAndSwitcher,
      ]}
      onClick={onClick}
      disableRipple={actionType === "SWITCHER"}
    >
      <Grid sx={materialStyles.buttonWithIconContainer}>
        {Icon ? Icon : null}
        <Grid
          container
          display="flex"
          direction="column"
          alignItems="flex-start"
        >
          <Typography sx={materialStyles.buttonWithIconTitle}>
            {title}
          </Typography>
          {subtitle ? (
            <Typography sx={materialStyles.buttonWithIconSubtitle}>
              {subtitle}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      {actionType === "ARROW" ? (
        <ArrowForwardIosIcon sx={materialStyles.arrowIcon} />
      ) : null}
      {actionType === "SWITCHER" ? (
        <Switch
          checked={checked}
          sx={materialStyles.buttonWithIconSwitcher}
          onChange={onClickSwitch}
        />
      ) : null}
    </ButtonBase>
  );
};
