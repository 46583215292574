import { theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  modal: {
    display: "flex",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    display: "flex",
  },
};
