import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { materialStyles } from "./styles";
import { useAppSelector } from "../../store";
import _ from "lodash";

export const MessagesHeader: React.FC = () => {
  const { t } = useTranslation();
  const { chats } = useAppSelector((state) => state.messages);
  const { user } = useAppSelector((state) => state.user);

  const notReadChatCount = useMemo(
    () =>
      _.chain(chats.data)
        .filter((item) => !item.is_read && item.sender_id !== user?.id)
        .size()
        .value(),
    [chats.data, user?.id],
  );
  return (
    <Box sx={materialStyles.messagesHeaderContainer}>
      <Typography sx={materialStyles.messagesHeaderTitle}>
        {t("messages.messages")}
      </Typography>
      {notReadChatCount ? (
        <Typography sx={materialStyles.messagesHeaderCounter}>
          {notReadChatCount}
        </Typography>
      ) : null}
    </Box>
  );
};
