import { createSlice } from "@reduxjs/toolkit";

type IServerState = {
  online: boolean;
  version: string;
};

type ICloudStorageState = {
  total: number;
  used: number;
  trash: number;
};

interface ISystemSliceInitialState {
  server: IServerState | null;
  storage: ICloudStorageState | null;
}

const systemSliceInitialState: ISystemSliceInitialState = {
  server: null,
  storage: null,
};

const systemSlice = createSlice({
  name: "system",
  initialState: systemSliceInitialState,
  reducers: {
    setServerState(state, action) {
      state.server = action.payload;
    },
    setCloudStorage(state, action) {
      state.storage = action.payload;
    },
    resetCloudStorage(state) {
      state.storage = systemSliceInitialState.storage;
    },
  },
});

export default systemSlice.reducer;
export const { setServerState, setCloudStorage, resetCloudStorage } =
  systemSlice.actions;
