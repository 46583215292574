import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useAuth } from "./useAuth";
import { getPeoplesWithPagination } from "../store/thunks/explore";
import {
  resetExplorePeoples,
  setExplorePeoples,
  setExplorePeoplesPage,
  setExplorePeoplesPageSize,
  setExplorePeoplesTotalElements,
} from "../store/slices/explore";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../constants/api";

export const useFetchPeoples = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { peoples } = useAppSelector((state) => state.explore);

  const fetchPeoples = useCallback(
    async (page?: number, pageSize?: number) => {
      try {
        const response = await dispatch(
          getPeoplesWithPagination(page, pageSize)
        );
        dispatch(setExplorePeoples(response?.list));
        dispatch(setExplorePeoplesTotalElements(response?.totalElements));
      } catch (error) {
        console.error("Error while [fetchPeoples]", error);
      }
    },
    [dispatch]
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setExplorePeoplesPage(peoples.page + 1));
    dispatch(setExplorePeoplesPageSize(DEFAULT_PAGE_SIZE));
  }, [peoples.page, dispatch]);

  const refetchPeoples = useCallback(() => {
    fetchPeoples(peoples.page);
  }, [fetchPeoples, peoples.page]);

  useEffect(() => {
    fetchPeoples();

    return () => {
      dispatch(resetExplorePeoples());
    };
  }, [dispatch, fetchPeoples, isAuth]);

  useEffect(() => {
    if (peoples.page !== DEFAULT_PAGE) {
      fetchPeoples(peoples.page, peoples.pageSize);
    }
  }, [peoples.page, peoples.pageSize, fetchPeoples]);

  return {
    peoples: peoples.data,
    totalElements: peoples.totalElements,
    refetchPeoples,
    onUpdatePageOrPageSize,
  };
};
