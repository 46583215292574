import React, { useCallback, useState } from "react";
import { IUserEditModalType } from "..";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { materialStyles, styles } from "./styles";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import _ from "lodash";
import { useAppDispatch } from "../../../../store";
import { setNewUserPassword } from "../../../../store/slices/userEdit";
import { useToast } from "rc-toastr";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

type IPasswordEditForm = {
  onClose: () => void;
  onChangeModalType: (type: IUserEditModalType) => void;
};

type IPasswordEditFormValues = {
  password: string;
  confirmPassword: string;
};

export const PasswordEditForm: React.FC<IPasswordEditForm> = ({
  onClose,
  onChangeModalType,
}) => {
  const { t } = useTranslation();

  const { toast } = useToast();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    (details: IPasswordEditFormValues) => {
      if (details.password !== details.confirmPassword) {
        toast.error(t("edit_user.passwords_dont_match"));
        return;
      }
      dispatch(setNewUserPassword(details.password));
      onChangeModalType("CONFIRM_MODAL");
    },
    [dispatch, onChangeModalType, t, toast]
  );

  const { values, errors, handleChange, handleSubmit, isValid } = useFormik({
    onSubmit,
    initialValues: { password: "", confirmPassword: "" },
    validate: (values) => {
      return _.pickBy({
        password: !_.trim(values.password),
        confirmPassword: !_.trim(values.confirmPassword),
      });
    },
  });

  const onShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const onShowConfrimPassword = useCallback(() => {
    setShowConfirmPassword(!showConfirmPassword);
  }, [showConfirmPassword]);

  return (
    <Grid sx={materialStyles.innerModalContainer}>
      <Typography sx={materialStyles.editModalTitle}>
        {t("edit_user.edit_password")}
      </Typography>
      <form onSubmit={handleSubmit} style={styles.formContainer}>
        <TextField
          name="password"
          type={showPassword ? "text" : "password"}
          error={Boolean(errors.password)}
          value={values.password}
          onChange={handleChange}
          sx={materialStyles.editInput}
          InputProps={{
            endAdornment: (
              <IconButton onClick={onShowPassword}>
                <RemoveRedEyeIcon sx={materialStyles.eyeIcon} />
              </IconButton>
            ),
          }}
          fullWidth
          label={t("edit_user.new_password")}
          placeholder={t("edit_user.enter_password")}
        />
        <TextField
          name="confirmPassword"
          type={showConfirmPassword ? "text" : "password"}
          error={Boolean(errors.confirmPassword)}
          value={values.confirmPassword}
          onChange={handleChange}
          sx={materialStyles.editInput}
          InputProps={{
            endAdornment: (
              <IconButton onClick={onShowConfrimPassword}>
                <RemoveRedEyeIcon sx={materialStyles.eyeIcon} />
              </IconButton>
            ),
          }}
          fullWidth
          label={t("edit_user.confirm_password")}
          placeholder={t("edit_user.repeat_password")}
        />
        <Button
          disabled={!isValid}
          variant="contained"
          sx={materialStyles.button}
          type="submit"
        >
          {t("edit.edit")}
        </Button>
      </form>
    </Grid>
  );
};
