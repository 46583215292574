import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import {
  IMapFilters,
  resetMapAllowedMedia,
  setMapAllowedMedia,
  setMapAllowedMediaPage,
  setMapAllowedMediaPageSize,
  setMapPhotosFilters,
} from "../store/slices/map";
import { useAuth } from "./useAuth";
import { getAllowedMedia } from "../store/thunks/globe";
import {
  DEFAULT_GLOBE_PAGE_SIZE,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "../constants/api";
import { useParams } from "react-router-dom";
import { useFetchUserByUsername } from "./useFetchUserByUsername";

export const useFetchMapAllowedMedia = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAuth();

  const { username: defaultUsernameForFilters } = useParams();

  const { user: defaultUserForFilters } = useFetchUserByUsername(
    defaultUsernameForFilters,
  );

  const { allowedMedia, filters } = useAppSelector((state) => state.map);
  const { mode } = useAppSelector((state) => state.visualMode);

  const fetchAllowedMedia = useCallback(
    async (page?: number, pageSize?: number, memoFilters?: IMapFilters) => {
      try {
        if (!isAuth) {
          return;
        }

        const media = await dispatch(
          getAllowedMedia(page, pageSize, memoFilters),
        );

        dispatch(setMapAllowedMedia(media));
      } catch (error) {
        console.error("Error while [fetchAllowedMedia]", error);
      }
    },
    [dispatch, isAuth],
  );

  const onUpdatePageOrPageSize = useCallback(() => {
    dispatch(setMapAllowedMediaPage(allowedMedia.page + 1));
    dispatch(setMapAllowedMediaPageSize(DEFAULT_PAGE_SIZE));
  }, [allowedMedia.page, dispatch]);

  const refetchAllowedMedia = useCallback(() => {
    const pageSize = {
      GRID: allowedMedia.pageSize,
      GLOBE: DEFAULT_GLOBE_PAGE_SIZE,
    }[mode];

    fetchAllowedMedia(allowedMedia.page, pageSize, filters);
  }, [
    allowedMedia.page,
    allowedMedia.pageSize,
    fetchAllowedMedia,
    filters,
    mode,
  ]);

  useEffect(() => {
    const defaultPageSize = {
      GRID: DEFAULT_PAGE_SIZE,
      GLOBE: DEFAULT_GLOBE_PAGE_SIZE,
    }[mode];

    fetchAllowedMedia(DEFAULT_PAGE, defaultPageSize, filters);

    return () => {
      dispatch(resetMapAllowedMedia());
    };
  }, [dispatch, fetchAllowedMedia, filters, mode]);

  useEffect(() => {
    if (allowedMedia.page !== DEFAULT_PAGE) {
      fetchAllowedMedia(allowedMedia.page, allowedMedia.pageSize, filters);
    }
  }, [allowedMedia.page, allowedMedia.pageSize, fetchAllowedMedia, filters]);

  useEffect(() => {
    if (!defaultUserForFilters) {
      return;
    }

    dispatch(setMapPhotosFilters({ peoples: [defaultUserForFilters] }));
  }, [defaultUserForFilters, dispatch]);

  return {
    allowedMedia: allowedMedia.data,
    onUpdatePageOrPageSize,
    refetchAllowedMedia,
  };
};
