import { Colors, theme } from "../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  commentsListWithSendInput: {
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "column",
    overflow: "hidden",
  },
  commentsList: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "16px",
    overflow: "auto",
    padding: "11px 16px",
  },
  sendInputContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    padding: "9px 16px",
  },
  sendInput: {
    marginLeft: "16px",
    borderRadius: "30px",
    backgroundColor: Colors.black,

    "& textarea": {
      color: Colors.white,
    },

    "& .MuiOutlinedInput-root": {
      padding: "9px 13px",
    },

    "& fieldset": {
      height: "44px",
      border: "none",
    },

    "& ::placeholder": {
      color: `${theme.palette.grey[200]}`,
      opacity: 1,
    },
  },
  sendButton: {
    padding: 0,
    textTransform: "none",
    letterSpacing: 0,
    fontSize: "14px",
    minWidth: "48px",

    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
};
