import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";

export const styles: ICssStyleType = {
  selectedImage: {
    width: "230px",
    height: "230px",
    borderRadius: "50%",
    margin: "24px 0",
    objectFit: "cover",
  },
};

export const materialStyles: IMaterialStyleType = {
  innerModalContainer: {
    padding: "24px 19px 31px 19px",
    flex: 1,
  },
  modalTitle: {
    color: Colors.white,
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 700,
    textAlign: "center",
  },
  selectedImageContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  button: {
    textTransform: "none",
    letterSpacing: 0,
    fontSize: 14,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "10px 32px",
    borderRadius: "30px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  innerUploadModalBox: {
    width: {
      xs: `98vw`,
      sm: `98vw`,
      md: "px",
    },
    flex: 1,
  },
  uploadModalHeaderBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "19px 22px",
  },
  uploadPhotoHeaderTitle: {
    color: Colors.white,
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 700,
  },
  backIcon: {
    color: Colors.white,
  },
  nextButton: {
    textTransform: "none",
    letterSpacing: 0,
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
  },
  uploadPhotoContent: {
    height: {
      xs: `70vh`,
      sm: `70vh`,
      md: "460px",
    },
    display: "flex",
    overflow: "hidden",
  },
  uploadPhotoDropZone: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  uploadPhotoSubtitle: {
    color: theme.palette.grey[900],
    fontSize: "20px",
    lineHeight: "28px",
    marginTop: "24px",
    marginBottom: "19px",
  },
  uploadButton: {
    backgroundColor: theme.palette.error.main,
    textTransform: "none",
    color: Colors.white,
    fontSize: "16px",
    lineHeight: "20px",
    borderRadius: "8px",
    fontWeight: 500,
    width: "200px",
    height: "50px",

    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
};
