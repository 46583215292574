import _ from "lodash";
import moment from "moment";
import { useCallback, useState } from "react";

//TODO: Need to refactor this hook :(( use useReducer
export const useFilters = (filters: any) => {
  const [localFilters, setLocalFilters] = useState(filters);

  const onChangeFilters = useCallback(
    (value: any, key: string) => {
      const filterField = _.get(localFilters, key);
      const isFilterFieldArray = _.isArray(filterField);
      const isFilterFieldObject = Boolean(filterField && filterField.id);
      const isFilterFieldIsDate = moment.isMoment(value);

      switch (true) {
        case isFilterFieldArray:
          const isAlreadyExistInFilter = _.some(
            filterField,
            (fieldValue) => fieldValue.id === value.id
          );

          if (isAlreadyExistInFilter) {
            const filteredField = _.filter(
              filterField,
              (fieldValue) => fieldValue.id !== value.id
            );

            setLocalFilters({ ...localFilters, [key]: filteredField });
            break;
          }

          setLocalFilters({ ...localFilters, [key]: [...filterField, value] });
          break;
        case isFilterFieldObject:
          if (filterField && value && filterField.id === value.id) {
            setLocalFilters({ ...localFilters, [key]: null });
            break;
          }
          setLocalFilters({ ...localFilters, [key]: value });
          break;
        case isFilterFieldIsDate:
          const parseDate = value.toISOString();
          setLocalFilters({ ...localFilters, [key]: parseDate });
          break;
        default:
          setLocalFilters({ ...localFilters, [key]: value });
          break;
      }
    },
    [localFilters]
  );

  const onResetFilter = useCallback(
    (key: string) => {
      const filterField = _.get(localFilters, key);
      const isFilterFieldArray = _.isArray(filterField);
      const isFilterFieldObject = Boolean(filterField && filterField.id);
      const isFilterFieldIsDate = moment(filterField).isValid();

      switch (true) {
        case isFilterFieldArray:
          setLocalFilters({ ...localFilters, [key]: [] });
          break;
        case isFilterFieldObject:
          setLocalFilters({ ...localFilters, [key]: null });
          break;
        case isFilterFieldIsDate:
          setLocalFilters({ ...localFilters, [key]: undefined });
          break;
        default:
          setLocalFilters({ ...localFilters, [key]: null });
          break;
      }
    },
    [localFilters]
  );

  return {
    localFilters,
    onChangeFilters,
    onResetFilter,
  };
};
