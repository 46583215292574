import { Grid, Button, Typography, TextField, IconButton } from "@mui/material";
import React, { useState, useCallback } from "react";
import { materialStyles } from "./styles";
import { IAuthModalType } from "..";
import { AuthModalHeader } from "./AuthModalHeader";
import { useTranslation } from "react-i18next";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useFormik } from "formik";
import _ from "lodash";
import { useAppDispatch } from "../../../../store";
import { resetRegistrationInfo } from "../../../../store/slices/registration";
import { signUpUser } from "../../../../store/thunks/user";

type IFinalRegistrationForm = {
  onChangeAuthModalType: (type: IAuthModalType) => void;
  onClose: () => void;
};

type IFinalRegistrationFormValues = {
  name: string;
  password: string;
};

export const FinalRegistrationForm: React.FC<IFinalRegistrationForm> = ({
  onChangeAuthModalType,
  onClose,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    async (details: IFinalRegistrationFormValues) => {
      try {
        await dispatch(
          signUpUser({ name: details.name, password: details.password })
        );
        onClose();
        dispatch(resetRegistrationInfo());
      } catch (error) {
        console.error("Error while [onSubmit]", error);
      }
    },
    [dispatch, onClose]
  );

  const { values, handleChange, handleSubmit, isValid, errors } = useFormik({
    onSubmit,
    initialValues: {
      name: "",
      password: "",
    },
    validate: (values) => {
      return _.pickBy({
        name: !_.trim(values.name),
        password: !_.trim(values.password),
      });
    },
  });

  const onShowPassword = useCallback(() => {
    setShowPassword((state) => !state);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid sx={materialStyles.innerAuthModalBox}>
        <AuthModalHeader title={t("auth_modal.set_up_your_account")} />
        <Typography sx={materialStyles.modalSubtitle}>
          {t("auth_modal.we_very_happy_see_you")}
        </Typography>
        <Grid sx={materialStyles.formContainer}>
          <TextField
            error={Boolean(errors.name)}
            name="name"
            value={values.name}
            onChange={handleChange}
            sx={materialStyles.authInput}
            fullWidth
            label={t("auth_modal.name")}
            placeholder={t("auth_modal.enter_your_name")}
          />
          <TextField
            error={Boolean(errors.password)}
            sx={materialStyles.authInput}
            name="password"
            value={values.password}
            onChange={handleChange}
            fullWidth
            type={showPassword ? "text" : "password"}
            label={t("auth_modal.password")}
            placeholder={t("auth_modal.enter_password")}
            InputProps={{
              endAdornment: (
                <IconButton onClick={onShowPassword}>
                  <RemoveRedEyeIcon sx={materialStyles.eyeIcon} />
                </IconButton>
              ),
            }}
          />
          <Button
            type="submit"
            disabled={!isValid}
            sx={materialStyles.loginButton}
            variant="contained"
          >
            {t("auth_modal.save_and_get_started")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
